import { events } from 'analytics'
import clsx from 'clsx'
import Autocomplete, { Item, SelectedItem } from 'core/components/Autocomplete'
import Badge from 'core/components/Badge'
import OrgCategoryBadge from 'core/components/Badge/OrgCategoryBadge'
import Button from 'core/components/Button'
import { ChevronDown, X } from 'core/components/Icon'
import ExcludeIcon from 'core/components/Icon/ExcludeIcon'
import { ellipsis } from 'core/styles/text'
import { AnimatePresence, motion } from 'framer-motion'
import { ComponentPropsWithoutRef, useCallback, useMemo, useState } from 'react'
import { SelectableOrg } from '.'
import { TRANSITION } from './InterestOption'
import { distributionGradientBorder } from './gradientBorder'

type Props = Omit<ComponentPropsWithoutRef<'div'>, 'children'> & {
	bookbuildId: string
	excludedOrgIds: string[]
	selectableOrgs: SelectableOrg[]
	expanded: boolean
	compact?: boolean
	onExcludedOrgIdsChanged: (excludedOrgIds: string[]) => void
	onExpandChange: (expanded: boolean) => void
}

const SearchOrgs = ({
	orgs,
	onSelect,
}: {
	orgs: SelectableOrg[]
	onSelect: (id: string) => void
}) => {
	const allOrgs: Item[] = useMemo(
		() => (orgs || []).map((o) => ({ id: o.id, label: o.name })),
		[orgs]
	)

	const [visibleOrgs, setVisibleOrgs] = useState<Item[]>([])

	const handleSearch = useCallback(
		(query: string) => {
			if (!query) return setVisibleOrgs([])

			setVisibleOrgs(
				allOrgs.filter((item) =>
					item.label.toLowerCase().includes(query.toLowerCase())
				)
			)
		},
		[allOrgs]
	)

	const handleSelect = useCallback(
		(item: SelectedItem) => {
			setVisibleOrgs([])
			if (item) {
				onSelect(item.id)
			}
		},
		[onSelect]
	)

	const handleClear = useCallback(() => {
		setVisibleOrgs([])
	}, [])

	return (
		<Autocomplete
			items={visibleOrgs}
			selected={null}
			inputWidth="100%"
			inputProps={{ color: 'gray-light', placeholder: 'Enter firm name' }}
			menuWidth="100%"
			menuMaxHeight="190px"
			menuProps={{ className: '!bg-blue-darkish font-bold !px-0 !py-1' }}
			menuItemProps={{ className: 'font-bold px-4 py-2' }}
			className="mx-6"
			onSearch={handleSearch}
			onSelect={handleSelect}
			onClear={handleClear}
		/>
	)
}

const DistributionExclusions = ({
	bookbuildId,
	excludedOrgIds,
	selectableOrgs = [],
	compact = false,
	expanded = false,
	className,
	onExcludedOrgIdsChanged,
	onExpandChange,
	...props
}: Props) => {
	const autocompleteOrgs = useMemo(
		() => selectableOrgs.filter((o) => !excludedOrgIds.includes(o.id)),
		[excludedOrgIds, selectableOrgs]
	)

	const excludedOrgs = useMemo(
		() =>
			excludedOrgIds
				.map((id) => selectableOrgs.find((org) => org.id === id))
				.filter(Boolean) as SelectableOrg[],
		[excludedOrgIds, selectableOrgs]
	)

	const track = useCallback(
		(org: SelectableOrg, trigger: string) => {
			const category =
				org.category == null || undefined
					? 'no classification'
					: org.category

			const data = {
				'BookBuild ID': bookbuildId,
				'Firm Classification': category,
			}

			if (trigger === 'add') {
				events.distributionExclusionsFirmAdded(data)
			} else {
				events.distributionExclusionsFirmRemoved(data)
			}
		},

		[bookbuildId]
	)

	const handleSelect = useCallback(
		(id: string) => {
			onExcludedOrgIdsChanged([id, ...excludedOrgIds])

			const excludedOrg = selectableOrgs.find((org) => id === org.id)

			if (!excludedOrg) return

			track(excludedOrg, 'add')
		},

		[excludedOrgIds, onExcludedOrgIdsChanged, selectableOrgs, track]
	)

	const handleRemove = useCallback(
		(org: SelectableOrg) => {
			const removedOrgId = org.id

			onExcludedOrgIdsChanged(
				excludedOrgIds.filter((id) => id != removedOrgId)
			)

			const removedOrg = selectableOrgs.find(
				(org) => removedOrgId === org.id
			)

			if (!removedOrg) return

			track(removedOrg, 'remove')
		},
		[excludedOrgIds, onExcludedOrgIdsChanged, selectableOrgs, track]
	)

	return (
		<motion.div
			className="flex select-none flex-col overflow-hidden"
			initial={false}
			transition={TRANSITION}
			data-testid="exclusions"
			animate={{
				height: expanded ? (compact ? 263 : 329) : 53,
			}}
		>
			<div
				{...props}
				className={clsx(
					'relative z-1 flex flex-1 flex-col overflow-hidden rounded-xl bg-blue-dark',
					className
				)}
			>
				<button
					className={clsx(
						'flex flex-none items-center gap-3 px-[1.375rem] !text-gray focus-visible:outline-none focus-visible:outline-offset-0',
						distributionGradientBorder({
							bgColor: 'blue-dark',
							textColor: 'gray-dark',
							hoverable: true,
							focusable: true,
						})
					)}
					style={{
						height: expanded ? (compact ? 61 : 70) : 53,
					}}
					onClick={() => onExpandChange(!expanded)}
					type="button"
				>
					<Badge
						highlighted={!!excludedOrgs.length}
						className={clsx('box-content', className)}
						style={{
							// calc the width from the amount of digits in the number
							width: excludedOrgs.length.toString().length + 'ch',
						}}
					>
						{excludedOrgs.length}
					</Badge>{' '}
					<h4 className="text-sm font-bold">Excluded Firms</h4>
					<ChevronDown
						size={16}
						className={clsx(
							'ml-auto stroke-[3] text-gray transition-transform',
							expanded && '-rotate-180'
						)}
					/>
				</button>

				<motion.div
					initial={false}
					transition={TRANSITION}
					animate={{ opacity: expanded ? 1 : 0 }}
					className="flex flex-none flex-col"
					style={{ height: 260 }}
				>
					{!!expanded && (
						<SearchOrgs
							orgs={autocompleteOrgs}
							onSelect={handleSelect}
						/>
					)}

					{!!excludedOrgs.length ? (
						<div
							className={clsx(
								'relative max-h-full flex-1 animate-fade-in overflow-y-auto overflow-x-hidden rounded-xl scrollbar-hide',
								// before
								'before:absolute before:left-0 before:top-0 before:z-2 before:h-5 before:w-full before:bg-gradient-to-b before:from-blue-dark before:to-transparent before:content-[""]',
								// after
								'after:absolute after:bottom-0 after:left-0 after:z-2 after:h-5 after:w-full after:rounded-xl after:bg-gradient-to-b after:from-transparent after:to-blue-dark after:content-[""]'
							)}
						>
							<ul className="relative max-h-full overflow-y-auto overflow-x-hidden pb-1 pt-4 scrollbar-hide">
								<AnimatePresence>
									{excludedOrgs.map((org) => (
										<motion.li
											key={org.id}
											animate={{
												opacity: 1,
												height: 32,
											}}
											exit={{ opacity: 0, height: 0 }}
											className="flex items-center justify-between gap-6 overflow-hidden whitespace-nowrap px-6 text-sm"
										>
											<span className={clsx(ellipsis)}>
												{org.name}{' '}
											</span>
											<div className="flex items-center gap-9">
												<OrgCategoryBadge
													category={org.category}
												/>

												<Button
													before={X}
													variant="bare"
													size="tiny"
													color="gray"
													title="Remove"
													onClick={() =>
														handleRemove(org)
													}
													tabIndex={expanded ? 0 : -1}
												/>
											</div>
										</motion.li>
									))}
								</AnimatePresence>
							</ul>
						</div>
					) : (
						<motion.div
							animate={{
								opacity: 1,
								height: 114,
							}}
							className="m-6 flex flex-1 animate-fade-in flex-col items-center justify-center gap-4 rounded-[10px] bg-blue-darker text-center text-gray"
						>
							<ExcludeIcon className="h-[22px] w-[22px] fill-gray" />
							You haven&apos;t excluded any firms yet
						</motion.div>
					)}
				</motion.div>
			</div>
		</motion.div>
	)
}

export default DistributionExclusions
