import clsx from 'clsx'
import { ComponentProps, ElementType, forwardRef } from 'react'
import Loading from '../Loading'

type Props = ComponentProps<'button'> & {
	color?: ButtonColor
	size?: 'tiny' | 'small' | 'medium' | 'large'
	variant?: 'boxed' | 'bordered' | 'bare' | 'bordered-boxed'
	pulsating?: boolean
	loading?: boolean
	before?: ElementType | null
	after?: ElementType | null
}

export type ButtonColor = (typeof colors)[number]

export const colors = [
	'yellow',
	'magenta',
	'green',
	'gray',
	'gray-dark',
	'gray-light',
	'white',
	'blue-highlight',
] as const

const Button = forwardRef<HTMLButtonElement, Props>(function Button(
	{
		children,
		color = 'yellow',
		size = 'medium',
		variant = 'boxed',
		type = 'button',
		role = 'button',
		pulsating,
		loading,
		className,
		before: Before,
		after: After,
		...props
	},
	ref
) {
	const yellow = color === 'yellow'
	const magenta = color === 'magenta'
	const green = color === 'green'
	const gray = color === 'gray'
	const grayDark = color === 'gray-dark'
	const grayLight = color === 'gray-light'
	const white = color === 'white'
	const blueHighlight = color === 'blue-highlight'

	const tiny = size === 'tiny'
	const small = size === 'small'
	const medium = size === 'medium'
	const large = size === 'large'

	const bare = variant === 'bare'
	const boxed = variant === 'boxed'
	const bordered = variant === 'bordered'
	const borderedBoxed = variant === 'bordered-boxed'

	return (
		<button
			{...props}
			type={type}
			role={role}
			ref={ref}
			className={clsx(
				'relative inline-flex origin-center select-none items-center justify-center whitespace-nowrap text-center transition-all',
				'hover:opacity-90',
				'active:scale-95 active:opacity-95',
				'disabled:cursor-not-allowed disabled:opacity-75',

				tiny && 'h-5 gap-1.5 text-xs leading-3',
				small && 'h-7 gap-2 text-sm',
				medium && 'h-8 gap-2 text-sm',
				large && 'h-10 gap-2.5 text-base',

				bordered && 'border border-current',

				(bare || bordered) && [
					yellow && 'text-yellow hover:text-yellow-darkish',
					magenta && 'text-magenta hover:text-magenta-darkish',
					green && 'text-green hover:text-green-darkish',
					gray && 'text-gray hover:text-gray-dark',
					grayLight && 'text-gray-light hover:text-gray',
					grayDark && 'text-gray-dark hover:text-gray',
					white && 'text-white hover:text-gray',
					blueHighlight &&
						'text-blue-highlight hover:text-blue-highlight',
				],

				(boxed || bordered || borderedBoxed) && [
					'rounded-sm',
					tiny && 'px-2',
					small && 'px-3',
					medium && 'px-3',
					large && 'px-4',
				],

				boxed && [
					'font-bold text-blue-dark hover:text-black',
					yellow && 'bg-yellow hover:bg-yellow-darkish',
					magenta && 'bg-magenta hover:bg-magenta-darkish',
					green && 'bg-green hover:bg-green-darkish',
					gray && 'bg-gray hover:bg-gray-dark',
					grayLight && 'bg-gray-light hover:bg-gray',
					grayDark && 'bg-gray-dark text-white hover:bg-gray',
					white && 'bg-white hover:bg-gray',
					blueHighlight &&
						'bg-blue-highlight text-white hover:bg-blue-highlight',
				],

				borderedBoxed && [
					'border font-bold',
					green && 'border-green bg-[#408E86] text-white',
					// TODO: add the rest of the colors
				],

				pulsating && [
					yellow && 'animate-pulsating-yellow',
					magenta && 'animate-pulsating-magenta',
					green && 'animate-pulsating-green',
					gray && 'animate-pulsating-gray',
					grayLight && 'animate-pulsating-gray-light',
					grayDark && 'animate-pulsating-gray-dark',
					white && 'animate-pulsating-white',
					blueHighlight && 'animate-pulsating-blue-highlight',
				],

				className
			)}
		>
			{!!Before && (
				<Before
					aria-hidden
					className="aspect-square h-[max(62.5%,2ch)] w-auto text-current"
				/>
			)}

			{children}

			{!!After && (
				<After
					aria-hidden
					className="aspect-square h-[max(62.5%,2ch)] w-auto text-current"
				/>
			)}

			{!!loading && (
				<Loading
					color={boxed ? 'blue-darkest' : color}
					className="h-[max(18.75%,0.5ch)]"
				/>
			)}
		</button>
	)
})

export default Button
