import gql from 'graphql-tag'
import * as Urql from 'urql'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
	[K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
	T extends { [key: string]: unknown },
	K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
	| T
	| {
			[P in keyof T]?: P extends ' $fragmentName' | '__typename'
				? T[P]
				: never
	  }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: { input: string; output: string }
	String: { input: string; output: string }
	Boolean: { input: boolean; output: boolean }
	Int: { input: number; output: number }
	Float: { input: number; output: number }
	Any: { input: unknown; output: unknown }
	BigInt: { input: number; output: number }
	Decimal: { input: number; output: number }
	Map: { input: unknown; output: unknown }
	Ticker: { input: string; output: string }
	TickerOrISIN: { input: string; output: string }
	Time: { input: string; output: string }
	Timestamp: { input: string; output: string }
	Upload: { input: File; output: File }
}

export type ApiKey = {
	__typename?: 'APIKey'
	createdAt: Scalars['Time']['output']
	expiryDate: Scalars['Time']['output']
	id: Scalars['ID']['output']
	organization: Organization
}

export enum ActivityType {
	LiveItem = 'LiveItem',
	PassiveItem = 'PassiveItem',
	ResponseItem = 'ResponseItem',
}

export type AdminUpdateUserInput = {
	bloombergUserId?: InputMaybe<Scalars['ID']['input']>
	disabled: Scalars['Boolean']['input']
	entities: Array<UserEntityInput>
	name: Scalars['String']['input']
	traderId?: InputMaybe<Scalars['ID']['input']>
}

export type Allocation = {
	__typename?: 'Allocation'
	allocation: Scalars['BigInt']['output']
	broker: BrokerPublic
	counterparty?: Maybe<Organization>
	createdAt: Scalars['Time']['output']
	daysAdtv?: Maybe<Scalars['Float']['output']>
	deal: Deal
	dealPercentage: Scalars['Float']['output']
	executedDaysAdtv?: Maybe<Scalars['Float']['output']>
	executedNotionalUsd?: Maybe<Scalars['Float']['output']>
	executedParticipantTariffBps?: Maybe<Scalars['Int']['output']>
	executedVolume?: Maybe<Scalars['BigInt']['output']>
	grossCash: Scalars['Float']['output']
	id: Scalars['ID']['output']
	isPrimaryAdv: Scalars['Boolean']['output']
	notionalCash: Scalars['Float']['output']
	notionalGbp: Scalars['Float']['output']
	notionalUsd: Scalars['Float']['output']
	order?: Maybe<Order>
	organization?: Maybe<Organization>
	proRata?: Maybe<Scalars['Float']['output']>
	scoreAdjusted?: Maybe<Scalars['Float']['output']>
	scoreTime?: Maybe<Scalars['Float']['output']>
	scoreVwap?: Maybe<Scalars['Float']['output']>
	side: Side
	tariff?: Maybe<Scalars['Int']['output']>
	trades: Array<Trade>
	vwap?: Maybe<Scalars['Float']['output']>
}

export type Bookbuild = {
	__typename?: 'Bookbuild'
	allocability: Array<BookbuildAllocability>
	broker: BrokerPublic
	brokerLimitViolation?: Maybe<BrokerLimitViolation>
	bypassBrokerLimits: Scalars['Boolean']['output']
	canCancel: Scalars['Boolean']['output']
	canEnd: Scalars['Boolean']['output']
	canPartialComplete: Scalars['Boolean']['output']
	cancelRequestJustification?: Maybe<Scalars['String']['output']>
	cancelRequestedAt?: Maybe<Scalars['Time']['output']>
	cancelRequestedBy?: Maybe<Scalars['String']['output']>
	cancelledAt?: Maybe<Scalars['Time']['output']>
	cancelledJustification?: Maybe<Scalars['String']['output']>
	captureDownsized: Scalars['Boolean']['output']
	clientOrderId?: Maybe<Scalars['String']['output']>
	createdAt: Scalars['Time']['output']
	daysAdtv?: Maybe<Scalars['Float']['output']>
	deal?: Maybe<Deal>
	distribution: BookbuildDistribution
	endedAt?: Maybe<Scalars['Time']['output']>
	endsAt: Scalars['Time']['output']
	entity: Entity
	executingAt?: Maybe<Scalars['Time']['output']>
	failedAt?: Maybe<Scalars['Time']['output']>
	failedBy?: Maybe<Scalars['String']['output']>
	failedJustification?: Maybe<Scalars['String']['output']>
	fixCloudLogs: Array<FixCloudLog>
	fixLogs: Array<FixLogEdge>
	id: Scalars['ID']['output']
	instrument: Instrument
	instrumentPrimaryAdv?: Maybe<Scalars['BigInt']['output']>
	isPrimaryAdv: Scalars['Boolean']['output']
	notionalCash: Scalars['Float']['output']
	notionalGbp: Scalars['Float']['output']
	notionalUsd: Scalars['Float']['output']
	opportunities: Array<Opportunity>
	orders: Array<Order>
	organization: Organization
	potentialDeal?: Maybe<PotentialDeal>
	previewReach?: Maybe<PreviewReach>
	price: Scalars['Decimal']['output']
	reach: BookbuildReach
	recentPriceDiscovery?: Maybe<PriceDiscovery>
	referencePrice: Scalars['Float']['output']
	side: Side
	startedAt?: Maybe<Scalars['Time']['output']>
	status: Status
	strategy: DealStrategy
	suspendedAt?: Maybe<Scalars['Time']['output']>
	suspendedReason?: Maybe<Scalars['String']['output']>
	targetTariff?: Maybe<Scalars['Int']['output']>
	updatedAt: Scalars['Time']['output']
	upsizeDaysAdtv?: Maybe<Scalars['Float']['output']>
	upsizeNotionalCash?: Maybe<Scalars['Float']['output']>
	upsizeNotionalGbp?: Maybe<Scalars['Float']['output']>
	upsizeNotionalUsd?: Maybe<Scalars['Float']['output']>
	upsizePrice?: Maybe<Scalars['Decimal']['output']>
	upsizeTariff?: Maybe<Scalars['Int']['output']>
	upsizeVolume?: Maybe<Scalars['BigInt']['output']>
	user?: Maybe<User>
	volume: Scalars['BigInt']['output']
}

export type BookbuildAllocability = {
	__typename?: 'BookbuildAllocability'
	allocablePercent: Scalars['Float']['output']
	allocations?: Maybe<Scalars['String']['output']>
	date: Scalars['Time']['output']
	dealPrice?: Maybe<Scalars['Float']['output']>
	dealVolume?: Maybe<Scalars['BigInt']['output']>
	demandPercent: Scalars['Float']['output']
	downsized: Scalars['Boolean']['output']
	id: Scalars['ID']['output']
	totalOrders: Scalars['Int']['output']
}

export type BookbuildDistribution = {
	__typename?: 'BookbuildDistribution'
	directOrgIds?: Maybe<Array<Scalars['ID']['output']>>
	excludedOrgIds: Array<Scalars['ID']['output']>
	liveWatchlists: Scalars['Boolean']['output']
	passiveInterest: Scalars['Boolean']['output']
	priceDiscovery: Scalars['Boolean']['output']
}

export type BookbuildMetrics = {
	__typename?: 'BookbuildMetrics'
	count: Scalars['Int']['output']
	cumulativeNotional: Scalars['Float']['output']
	date: Scalars['Time']['output']
	id: Scalars['ID']['output']
	notional: Scalars['Float']['output']
}

export type BookbuildPublic = {
	__typename?: 'BookbuildPublic'
	allocability: Array<BookbuildAllocability>
	cancelledAt?: Maybe<Scalars['Time']['output']>
	daysAdtv?: Maybe<Scalars['Float']['output']>
	endedAt?: Maybe<Scalars['Time']['output']>
	endsAt: Scalars['Time']['output']
	executingAt?: Maybe<Scalars['Time']['output']>
	failedAt?: Maybe<Scalars['Time']['output']>
	id: Scalars['ID']['output']
	instrument: Instrument
	instrumentPrimaryAdv?: Maybe<Scalars['BigInt']['output']>
	referencePrice: Scalars['Float']['output']
	side: Side
	startedAt?: Maybe<Scalars['Time']['output']>
	volume: Scalars['BigInt']['output']
}

export type BookbuildReach = {
	__typename?: 'BookbuildReach'
	directFirms: Scalars['Int']['output']
	liveWatchlistsFirms: Scalars['Int']['output']
	passiveInterestFirms: Scalars['Int']['output']
	priceDiscoveryFirms: Scalars['Int']['output']
}

export type Bookbuilds = {
	__typename?: 'Bookbuilds'
	items: Array<Bookbuild>
	total: Scalars['Int']['output']
}

export enum BookbuildsSortCol {
	Adv = 'ADV',
	Broker = 'Broker',
	CreatedAt = 'CreatedAt',
	Name = 'Name',
	Org = 'Org',
	Side = 'Side',
	Status = 'Status',
	User = 'User',
}

export type Broker = {
	__typename?: 'Broker'
	fixEmsId: Scalars['String']['output']
	id: Scalars['ID']['output']
	name: Scalars['String']['output']
	organizations: Array<BrokerOrganization>
	sessions: Array<BrokerSession>
	strategy: BrokerStrategy
	technicalSupportLink?: Maybe<Scalars['String']['output']>
	thresholdPercentage: Scalars['Int']['output']
}

export type BrokerLimitViolation = {
	__typename?: 'BrokerLimitViolation'
	allocatedOrderExposureLimitPercentage?: Maybe<Scalars['Float']['output']>
	allocatedOrderExposureUsd?: Maybe<Scalars['Float']['output']>
	currentExposureLimitPercentage: Scalars['Float']['output']
	currentExposureUsd: Scalars['Float']['output']
	entityBroker: EntityBroker
	id: Scalars['ID']['output']
	notionalUsd: Scalars['Float']['output']
	potentialContainsUpsizes: Scalars['Boolean']['output']
	potentialExposureLimitPercentage: Scalars['Float']['output']
	potentialExposureUsd: Scalars['Float']['output']
}

export type BrokerOrganization = {
	__typename?: 'BrokerOrganization'
	entities: Array<EntityBroker>
	id: Scalars['ID']['output']
	name: Scalars['String']['output']
}

export type BrokerPublic = {
	__typename?: 'BrokerPublic'
	id: Scalars['ID']['output']
	name: Scalars['String']['output']
}

export type BrokerSession = {
	__typename?: 'BrokerSession'
	executionVenue: ExecutionVenue
	id: Scalars['ID']['output']
	ignoreTradingCalendar: Scalars['Boolean']['output']
	maxMessagesPerSecond: Scalars['Int']['output']
	password?: Maybe<Scalars['String']['output']>
	sessionEndTime: Scalars['String']['output']
	sessionStartTime: Scalars['String']['output']
	sessionTimeZone: Scalars['String']['output']
	socketConnectHost: Scalars['String']['output']
	socketConnectPort: Scalars['Int']['output']
	tempPassword?: Maybe<Scalars['String']['output']>
	turquoiseCompId: Scalars['String']['output']
	turquoiseMemberId: Scalars['String']['output']
	turquoiseTraderGroup: Scalars['String']['output']
	weekdays: Scalars['String']['output']
}

export type BrokerSessionInput = {
	executionVenue: ExecutionVenue
	id?: InputMaybe<Scalars['ID']['input']>
	ignoreTradingCalendar: Scalars['Boolean']['input']
	maxMessagesPerSecond: Scalars['Int']['input']
	password?: InputMaybe<Scalars['String']['input']>
	sessionEndTime: Scalars['String']['input']
	sessionStartTime: Scalars['String']['input']
	sessionTimeZone: Scalars['String']['input']
	socketConnectHost: Scalars['String']['input']
	socketConnectPort: Scalars['Int']['input']
	tempPassword?: InputMaybe<Scalars['String']['input']>
	turquoiseCompId: Scalars['String']['input']
	turquoiseMemberId: Scalars['String']['input']
	turquoiseTraderGroup: Scalars['String']['input']
	weekdays: Scalars['String']['input']
}

export enum BrokerStrategy {
	Bernstein = 'bernstein',
	Default = 'default',
}

export type Connection = {
	__typename?: 'Connection'
	id: Scalars['ID']['output']
	name: Scalars['String']['output']
	strategy: Scalars['String']['output']
}

export type Country = {
	__typename?: 'Country'
	id: Scalars['String']['output']
	name: Scalars['String']['output']
}

export type CreateApiKeyInput = {
	expiryDate: Scalars['Time']['input']
	orgID: Scalars['ID']['input']
}

export type CreateApiKeyResponse = {
	__typename?: 'CreateAPIKeyResponse'
	apiKey: ApiKey
	token: Scalars['String']['output']
}

export type CreateBookbuildInput = {
	brokerID: Scalars['ID']['input']
	clientOrderId?: InputMaybe<Scalars['String']['input']>
	endsAt: Scalars['Time']['input']
	instrumentID: Scalars['ID']['input']
	price: Scalars['Decimal']['input']
	side: Side
	upsizePrice?: InputMaybe<Scalars['Decimal']['input']>
	upsizeVolume?: InputMaybe<Scalars['BigInt']['input']>
	volume: Scalars['BigInt']['input']
}

export type CreateBrokerInput = {
	fixEmsId: Scalars['String']['input']
	name: Scalars['String']['input']
	sessions: Array<BrokerSessionInput>
	strategy: BrokerStrategy
	technicalSupportLink?: InputMaybe<Scalars['String']['input']>
	thresholdPercentage: Scalars['Int']['input']
}

export type CreateOrderInput = {
	bookbuildId: Scalars['ID']['input']
	brokerID: Scalars['String']['input']
	clientOrderId?: InputMaybe<Scalars['String']['input']>
	price: Scalars['Decimal']['input']
	upsizePrice?: InputMaybe<Scalars['Decimal']['input']>
	upsizeVolume?: InputMaybe<Scalars['BigInt']['input']>
	volume: Scalars['BigInt']['input']
}

export type CreateUserInput = {
	bloombergUserId?: InputMaybe<Scalars['ID']['input']>
	disabled: Scalars['Boolean']['input']
	email: Scalars['String']['input']
	entities: Array<UserEntityInput>
	name: Scalars['String']['input']
	orgId: Scalars['ID']['input']
	roleIds: Array<Scalars['ID']['input']>
	timeZone: Scalars['String']['input']
	traderId?: InputMaybe<Scalars['ID']['input']>
}

export type Deal = {
	__typename?: 'Deal'
	allocations: Array<Allocation>
	bookbuild: BookbuildPublic
	cashGross: Scalars['Float']['output']
	completedAt?: Maybe<Scalars['Time']['output']>
	createdAt: Scalars['Time']['output']
	daysAdtv?: Maybe<Scalars['Float']['output']>
	executedDaysAdtv?: Maybe<Scalars['Float']['output']>
	executedNotionalGbp?: Maybe<Scalars['Float']['output']>
	executedNotionalUsd?: Maybe<Scalars['Float']['output']>
	executedOriginatorTariffBps?: Maybe<Scalars['Int']['output']>
	executedVolume?: Maybe<Scalars['BigInt']['output']>
	id: Scalars['ID']['output']
	isPrimaryAdv: Scalars['Boolean']['output']
	notionalCash: Scalars['Float']['output']
	notionalGbp: Scalars['Float']['output']
	notionalUsd: Scalars['Float']['output']
	price: Scalars['Float']['output']
	referencePrice: Scalars['Float']['output']
	spreadsheet?: Maybe<Scalars['String']['output']>
	tariff?: Maybe<Scalars['Int']['output']>
	totalOrders: Scalars['Int']['output']
	trades: Array<Trade>
	volume: Scalars['BigInt']['output']
}

export enum DealStrategy {
	Balanced = 'Balanced',
	Price = 'Price',
	Volume = 'Volume',
	VolumePlus = 'VolumePlus',
}

export type DeleteApiKeyInput = {
	id: Scalars['ID']['input']
	orgID: Scalars['ID']['input']
}

export type DistributeAndCommitBookbuildInput = {
	excludedOrgIDs: Array<Scalars['ID']['input']>
	liveWatchlists: Scalars['Boolean']['input']
	orgIds: Array<Scalars['ID']['input']>
	passiveInterest: Scalars['Boolean']['input']
	priceDiscovery: Scalars['Boolean']['input']
}

export type DistributionContra = {
	__typename?: 'DistributionContra'
	contributions: Array<DistributionScoreFactor>
	isPadded: Scalars['Boolean']['output']
	org: Organization
	score: Scalars['Float']['output']
}

export type DistributionOrgInput = {
	exclude: Scalars['Boolean']['input']
	id: Scalars['ID']['input']
}

export type DistributionScore = {
	__typename?: 'DistributionScore'
	score: Scalars['Float']['output']
	suggestDistribution: Scalars['Boolean']['output']
	suggestedContrasCount: Scalars['Int']['output']
	totalContrasCount: Scalars['Int']['output']
}

export type DistributionScoreAttributes = {
	__typename?: 'DistributionScoreAttributes'
	contras: Array<DistributionContra>
	factors: Array<DistributionScoreFactor>
}

export type DistributionScoreFactor = {
	__typename?: 'DistributionScoreFactor'
	attributes?: Maybe<Array<Maybe<Scalars['String']['output']>>>
	label: Scalars['String']['output']
	score: Scalars['Float']['output']
}

export type DistributionScoreWeight = {
	__typename?: 'DistributionScoreWeight'
	factor: Scalars['String']['output']
	weight: Scalars['Float']['output']
}

export type DistributionScoreWeightsInput = {
	factor: Scalars['String']['input']
	weight: Scalars['Float']['input']
}

export type EmsSession = {
	__typename?: 'EmsSession'
	beginString: FixBeginString
	createdAt: Scalars['Time']['output']
	ems: Scalars['String']['output']
	endDay?: Maybe<Scalars['String']['output']>
	endTime: Scalars['String']['output']
	heartbeatIntervalSec?: Maybe<Scalars['Int']['output']>
	id: Scalars['ID']['output']
	ioiSession?: Maybe<IoiSession>
	maxMessagesPerSecond: Scalars['Int']['output']
	name: Scalars['String']['output']
	organizations: Array<Organization>
	reconnectIntervalSec?: Maybe<Scalars['Int']['output']>
	refreshOnLogon: Scalars['Boolean']['output']
	resetOnDisconnect: Scalars['Boolean']['output']
	resetOnLogon: Scalars['Boolean']['output']
	resetOnLogout: Scalars['Boolean']['output']
	senderCompID: Scalars['String']['output']
	sessionType: FixSessionType
	socketAddressHost: Scalars['String']['output']
	socketAddressPort: Scalars['Int']['output']
	startDay?: Maybe<Scalars['String']['output']>
	startTime: Scalars['String']['output']
	strategy: EmsSessionStrategy
	supportDistributionPopup: Scalars['Boolean']['output']
	targetCompID: Scalars['String']['output']
	technicalSupportLink?: Maybe<Scalars['String']['output']>
	timeZone?: Maybe<Scalars['String']['output']>
	tlsCAFilepath?: Maybe<Scalars['String']['output']>
	tlsCertificateFilepath?: Maybe<Scalars['String']['output']>
	tlsPrivateKeyFilepath?: Maybe<Scalars['String']['output']>
	updatedAt: Scalars['Time']['output']
	weekdays: Scalars['String']['output']
}

export type EmsSessionInput = {
	beginString: FixBeginString
	ems: Scalars['String']['input']
	endDay?: InputMaybe<Scalars['String']['input']>
	endTime: Scalars['String']['input']
	heartbeatIntervalSec?: InputMaybe<Scalars['Int']['input']>
	ioiSessionID?: InputMaybe<Scalars['ID']['input']>
	maxMessagesPerSecond: Scalars['Int']['input']
	name: Scalars['String']['input']
	reconnectIntervalSec?: InputMaybe<Scalars['Int']['input']>
	refreshOnLogon: Scalars['Boolean']['input']
	resetOnDisconnect: Scalars['Boolean']['input']
	resetOnLogon: Scalars['Boolean']['input']
	resetOnLogout: Scalars['Boolean']['input']
	senderCompID: Scalars['String']['input']
	sessionType: FixSessionType
	socketAddressHost: Scalars['String']['input']
	socketAddressPort: Scalars['Int']['input']
	startDay?: InputMaybe<Scalars['String']['input']>
	startTime: Scalars['String']['input']
	strategy: EmsSessionStrategy
	supportDistributionPopup: Scalars['Boolean']['input']
	targetCompID: Scalars['String']['input']
	technicalSupportLink?: InputMaybe<Scalars['String']['input']>
	timeZone?: InputMaybe<Scalars['String']['input']>
	tlsCAFilepath?: InputMaybe<Scalars['String']['input']>
	tlsCertificateFilepath?: InputMaybe<Scalars['String']['input']>
	tlsPrivateKeyFilepath?: InputMaybe<Scalars['String']['input']>
	weekdays: Scalars['String']['input']
}

export enum EmsSessionStrategy {
	BloombergEmsx = 'BloombergEMSX',
	Default = 'Default',
	Flextrade = 'Flextrade',
	Portware = 'Portware',
	Triton = 'Triton',
	Tsi = 'Tsi',
}

export type Entity = {
	__typename?: 'Entity'
	brokers: Array<EntityBroker>
	defaultBroker: Broker
	id: Scalars['ID']['output']
	lei: Scalars['String']['output']
	name: Scalars['String']['output']
}

export type EntityBroker = {
	__typename?: 'EntityBroker'
	broker: Broker
	brokerCode: Scalars['String']['output']
	entity: Entity
	limitUsd: Scalars['BigInt']['output']
	turquoiseShortcode: Scalars['BigInt']['output']
}

export type EntityBrokerInput = {
	brokerCode: Scalars['String']['input']
	brokerId: Scalars['ID']['input']
	limitUsd: Scalars['BigInt']['input']
	turquoiseShortcode: Scalars['BigInt']['input']
}

export type EntityInput = {
	brokers: Array<EntityBrokerInput>
	defaultBrokerId: Scalars['String']['input']
	id?: InputMaybe<Scalars['ID']['input']>
	lei: Scalars['String']['input']
	name: Scalars['String']['input']
}

export type Event = {
	__typename?: 'Event'
	activityType: EventActivityType
	entityId?: Maybe<Scalars['ID']['output']>
	id: Scalars['ID']['output']
	organization?: Maybe<Organization>
	recordedAt: Scalars['Time']['output']
	side?: Maybe<Side>
	status: Scalars['String']['output']
	user?: Maybe<User>
}

export enum EventActivityType {
	Bookbuild = 'BOOKBUILD',
	LiveItem = 'LIVE_ITEM',
	Order = 'ORDER',
	OriginationAlert = 'ORIGINATION_ALERT',
	PassiveItem = 'PASSIVE_ITEM',
	PriceDiscovery = 'PRICE_DISCOVERY',
	PriceDiscoveryResponse = 'PRICE_DISCOVERY_RESPONSE',
	TrendingEquities = 'TRENDING_EQUITIES',
	TrendingEquityResponse = 'TRENDING_EQUITY_RESPONSE',
}

export type Events = {
	__typename?: 'Events'
	items: Array<Event>
	total: Scalars['Int']['output']
}

export enum ExecutionVenue {
	Tqex = 'TQEX',
	Trqx = 'TRQX',
}

export enum FixBeginString {
	Fix42 = 'FIX42',
	Fixt11 = 'FIXT11',
}

export enum FixSessionType {
	Acceptor = 'acceptor',
	Initiator = 'initiator',
}

export type FixCloudLog = {
	__typename?: 'FixCloudLog'
	direction?: Maybe<Scalars['String']['output']>
	id: Scalars['ID']['output']
	message: Scalars['String']['output']
	recordedAt: Scalars['Timestamp']['output']
	session: Scalars['String']['output']
}

export type FixCloudLogConnection = {
	__typename?: 'FixCloudLogConnection'
	items: Array<FixCloudLog>
	nextToken?: Maybe<Scalars['String']['output']>
}

export type FixLog = {
	__typename?: 'FixLog'
	direction?: Maybe<Scalars['String']['output']>
	id: Scalars['ID']['output']
	message: Scalars['String']['output']
	recordedAt: Scalars['Timestamp']['output']
	session: Scalars['String']['output']
}

export type FixLogConnection = {
	__typename?: 'FixLogConnection'
	edges: Array<FixLogEdge>
	pageInfo: PageInfo
}

export type FixLogEdge = {
	__typename?: 'FixLogEdge'
	cursor: Scalars['ID']['output']
	node: FixLog
}

export type Holder = {
	__typename?: 'Holder'
	fuzzyHoldingAmount: Scalars['String']['output']
	organization: OrganizationPublic
	rawHoldingAmount: Scalars['Float']['output']
	reportDate?: Maybe<Scalars['Timestamp']['output']>
	sharesHeld: Scalars['BigInt']['output']
	trend: HoldingTrend
	valueChangedADVPercent: Scalars['Float']['output']
	valueChangedUSD: Scalars['BigInt']['output']
	valueHeldUSD: Scalars['BigInt']['output']
}

export enum HoldingTrend {
	Down = 'Down',
	Flat = 'Flat',
	Up = 'Up',
}

export type HoldingsOwner = {
	__typename?: 'HoldingsOwner'
	city: Scalars['String']['output']
	country: Scalars['String']['output']
	holderType: Scalars['String']['output']
	id: Scalars['ID']['output']
	lei: Scalars['String']['output']
	name: Scalars['String']['output']
	ownerID: Scalars['Int']['output']
	parentID?: Maybe<Scalars['Int']['output']>
	parentName?: Maybe<Scalars['String']['output']>
	totalChildren: Scalars['Int']['output']
	totalHoldings: Scalars['Int']['output']
	totalHoldingsNotionalUsd: Scalars['BigInt']['output']
}

export type HoldingsOwnersResponse = {
	__typename?: 'HoldingsOwnersResponse'
	items: Array<HoldingsOwner>
	total: Scalars['Int']['output']
}

export type Instrument = {
	__typename?: 'Instrument'
	active: Scalars['Boolean']['output']
	activity: Events
	averageDailyVolume?: Maybe<Scalars['BigInt']['output']>
	averageVolume5Days?: Maybe<Scalars['BigInt']['output']>
	averageVolume30Days?: Maybe<Scalars['BigInt']['output']>
	calendarID: Scalars['String']['output']
	closeVolume1day?: Maybe<Scalars['BigInt']['output']>
	countActivityEvents: Scalars['Int']['output']
	countActivityEventsLastMonth: Scalars['Int']['output']
	createdAt: Scalars['Time']['output']
	currency: Scalars['String']['output']
	deactivatedAt?: Maybe<Scalars['Time']['output']>
	description: Scalars['String']['output']
	dollarDailyAverage30Days?: Maybe<Scalars['Float']['output']>
	dynamicRefPriceCollarPercent: Scalars['Int']['output']
	earningsDate?: Maybe<Scalars['Time']['output']>
	executionVenue: Scalars['String']['output']
	holdings: InstrumentHoldings
	id: Scalars['ID']['output']
	isin: Scalars['String']['output']
	liquidity?: Maybe<Scalars['Float']['output']>
	liquidityAmount10Days?: Maybe<Scalars['BigInt']['output']>
	liquidityCategory?: Maybe<LiquidityCategory>
	liquidityVolume10Days?: Maybe<Scalars['Float']['output']>
	marketCapCategory?: Maybe<MarketCapCategory>
	marketCapUsdM?: Maybe<Scalars['Float']['output']>
	marketCountry: Scalars['String']['output']
	marketCountryCode: Scalars['String']['output']
	marketID: Scalars['String']['output']
	marketName: Scalars['String']['output']
	notionalAverageDailyVolumeUsd?: Maybe<Scalars['Float']['output']>
	operatingMarketID: Scalars['String']['output']
	percentChangeClose2days?: Maybe<Scalars['Float']['output']>
	percentChangeClose30days?: Maybe<Scalars['Float']['output']>
	percentChangeClose120days?: Maybe<Scalars['Float']['output']>
	preTradeLISValueCurrency: Scalars['BigInt']['output']
	primaryBloombergTicker: Scalars['String']['output']
	ranking?: Maybe<InstrumentRanking>
	referencePrice: Scalars['Float']['output']
	ric: Scalars['String']['output']
	sector: Scalars['String']['output']
	securityType: Scalars['String']['output']
	securityTypeDescription: Scalars['String']['output']
	sedol: Scalars['String']['output']
	staticRefPriceCollarPercent: Scalars['Int']['output']
	updatedAt: Scalars['Time']['output']
	volatility5Days?: Maybe<Scalars['Float']['output']>
	volatility10Days?: Maybe<Scalars['Float']['output']>
}

export type InstrumentActivityArgs = {
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
}

export type InstrumentCountActivityEventsArgs = {
	exceptOrgId?: InputMaybe<Scalars['ID']['input']>
}

export type InstrumentCountActivityEventsLastMonthArgs = {
	exceptOrgId?: InputMaybe<Scalars['ID']['input']>
}

export type InstrumentHoldings = {
	__typename?: 'InstrumentHoldings'
	fuzzyTotalAmount: Scalars['String']['output']
	holders: Array<Holder>
	rawTotalAmount: Scalars['Float']['output']
	totalSharesHeld: Scalars['BigInt']['output']
	totalValueHeldUSD: Scalars['BigInt']['output']
}

export type InstrumentRanking = {
	__typename?: 'InstrumentRanking'
	keyHoldersContribution: Scalars['Float']['output']
	keyHoldersWeight: Scalars['Float']['output']
	liquidityContribution: Scalars['Float']['output']
	liquidityWeight: Scalars['Float']['output']
	marketCapContribution: Scalars['Float']['output']
	marketCapWeight: Scalars['Float']['output']
	ranking: Scalars['Int']['output']
	score: Scalars['Float']['output']
	totalHeldPercentageContribution: Scalars['Float']['output']
	totalHeldPercentageWeight: Scalars['Float']['output']
	totalHoldersContribution: Scalars['Float']['output']
	totalHoldersWeight: Scalars['Float']['output']
}

export type Instruments = {
	__typename?: 'Instruments'
	items: Array<Instrument>
	total: Scalars['Int']['output']
}

export enum InstrumentsSortCol {
	Adv = 'ADV',
	EarningsDate = 'EarningsDate',
	Events = 'Events',
	Holding = 'Holding',
	Liquidity = 'Liquidity',
	MarketCap = 'MarketCap',
	Name = 'Name',
	Ranking = 'Ranking',
}

export type Intersection = {
	__typename?: 'Intersection'
	buyItems: Array<IntersectionItem>
	instrument: Instrument
	latestOriginationAlert?: Maybe<OriginationAlert>
	sellItems: Array<IntersectionItem>
}

export type IntersectionItem = {
	__typename?: 'IntersectionItem'
	isLive: Scalars['Boolean']['output']
	organization: Organization
	watchlistItemId: Scalars['ID']['output']
}

export type Intersections = {
	__typename?: 'Intersections'
	items: Array<Intersection>
	total: Scalars['Int']['output']
}

export enum IntersectionsSortCol {
	Adv = 'ADV',
	Holding = 'Holding',
	MarketCap = 'MarketCap',
	Name = 'Name',
	Ranking = 'Ranking',
}

export type IoiSession = {
	__typename?: 'IoiSession'
	beginString: FixBeginString
	createdAt: Scalars['Time']['output']
	ems: Scalars['String']['output']
	emsSession?: Maybe<EmsSession>
	endDay?: Maybe<Scalars['String']['output']>
	endTime: Scalars['String']['output']
	heartbeatIntervalSec?: Maybe<Scalars['Int']['output']>
	id: Scalars['ID']['output']
	maxMessagesPerSecond: Scalars['Int']['output']
	name: Scalars['String']['output']
	reconnectIntervalSec?: Maybe<Scalars['Int']['output']>
	refreshOnLogon: Scalars['Boolean']['output']
	resetOnDisconnect: Scalars['Boolean']['output']
	resetOnLogon: Scalars['Boolean']['output']
	resetOnLogout: Scalars['Boolean']['output']
	senderCompID: Scalars['String']['output']
	sessionType: FixSessionType
	socketConnectHost?: Maybe<Scalars['String']['output']>
	socketConnectPort?: Maybe<Scalars['Int']['output']>
	startDay?: Maybe<Scalars['String']['output']>
	startTime: Scalars['String']['output']
	state: SessionState
	strategy: IoiSessionStrategy
	targetCompID: Scalars['String']['output']
	technicalSupportLink?: Maybe<Scalars['String']['output']>
	timeZone?: Maybe<Scalars['String']['output']>
	tlsCertExpiry?: Maybe<Scalars['Time']['output']>
	tlsSkipVerification: Scalars['Boolean']['output']
	updatedAt: Scalars['Time']['output']
	weekdays?: Maybe<Scalars['String']['output']>
}

export type IoiSessionInput = {
	beginString: FixBeginString
	ems: Scalars['String']['input']
	endDay?: InputMaybe<Scalars['String']['input']>
	endTime: Scalars['String']['input']
	heartbeatIntervalSec?: InputMaybe<Scalars['Int']['input']>
	maxMessagesPerSecond: Scalars['Int']['input']
	name: Scalars['String']['input']
	reconnectIntervalSec?: InputMaybe<Scalars['Int']['input']>
	refreshOnLogon: Scalars['Boolean']['input']
	removeTlsConfig?: InputMaybe<Scalars['Boolean']['input']>
	resetOnDisconnect: Scalars['Boolean']['input']
	resetOnLogon: Scalars['Boolean']['input']
	resetOnLogout: Scalars['Boolean']['input']
	senderCompID: Scalars['String']['input']
	sessionType: FixSessionType
	socketConnectHost?: InputMaybe<Scalars['String']['input']>
	socketConnectPort?: InputMaybe<Scalars['Int']['input']>
	startDay?: InputMaybe<Scalars['String']['input']>
	startTime: Scalars['String']['input']
	strategy: IoiSessionStrategy
	targetCompID: Scalars['String']['input']
	technicalSupportLink?: InputMaybe<Scalars['String']['input']>
	timeZone: Scalars['String']['input']
	tlsCaBundle?: InputMaybe<Scalars['Upload']['input']>
	tlsCert?: InputMaybe<Scalars['Upload']['input']>
	tlsPrivateKey?: InputMaybe<Scalars['Upload']['input']>
	tlsSkipVerification: Scalars['Boolean']['input']
	weekdays?: InputMaybe<Scalars['String']['input']>
}

export enum IoiSessionStrategy {
	BloombergIoi = 'BloombergIOI',
	Default = 'Default',
	Unspecified = 'Unspecified',
}

export enum LiquidityCategory {
	High = 'High',
	Low = 'Low',
	Medium = 'Medium',
}

export type LiveWatchlistEntry = {
	__typename?: 'LiveWatchlistEntry'
	currency: Scalars['String']['output']
	currentContraCount: Scalars['Int']['output']
	daysAdtv?: Maybe<Scalars['Float']['output']>
	distributionScore?: Maybe<DistributionScore>
	id: Scalars['ID']['output']
	instrumentAverageDailyVolume?: Maybe<Scalars['BigInt']['output']>
	instrumentDescription: Scalars['String']['output']
	instrumentId: Scalars['ID']['output']
	instrumentMarketCapUsdM?: Maybe<Scalars['Float']['output']>
	instrumentMarketName: Scalars['String']['output']
	instrumentRank: Scalars['Int']['output']
	instrumentReferencePrice?: Maybe<Scalars['Decimal']['output']>
	notionalUsd?: Maybe<Scalars['Float']['output']>
	orgId: Scalars['ID']['output']
	orgName: Scalars['String']['output']
	previousEntries: Array<LiveWatchlistEntry>
	previousVolume?: Maybe<Scalars['BigInt']['output']>
	price?: Maybe<Scalars['Decimal']['output']>
	recentContraCount: Scalars['Int']['output']
	side: Side
	timestamp: Scalars['Time']['output']
	type: WatchlistEntryType
	userId: Scalars['ID']['output']
	userName: Scalars['String']['output']
	volume?: Maybe<Scalars['BigInt']['output']>
}

export type LiveWatchlistEntryItems = {
	__typename?: 'LiveWatchlistEntryItems'
	items: Array<LiveWatchlistEntry>
	total: Scalars['Int']['output']
}

export type LiveWatchlistItem = {
	__typename?: 'LiveWatchlistItem'
	createdAt: Scalars['Time']['output']
	currency?: Maybe<Scalars['String']['output']>
	currentContraCount: Scalars['Int']['output']
	distributionScore?: Maybe<DistributionScore>
	hasLivePriceDiscovery: Scalars['Boolean']['output']
	hasLivePriceDiscoveryOpportunity: Scalars['Boolean']['output']
	id: Scalars['ID']['output']
	instrument: Instrument
	intersection?: Maybe<Intersection>
	latestOriginationAlert?: Maybe<OriginationAlert>
	notionalAmountUsd?: Maybe<Scalars['Decimal']['output']>
	organization: Organization
	price?: Maybe<Scalars['Decimal']['output']>
	recentContraCount: Scalars['Int']['output']
	side: Side
	user: User
	volume?: Maybe<Scalars['BigInt']['output']>
}

export type LiveWatchlistItemInput = {
	instrumentID: Scalars['ID']['input']
	price?: InputMaybe<Scalars['Decimal']['input']>
	side: Side
	volume?: InputMaybe<Scalars['BigInt']['input']>
}

export type LiveWatchlistItems = {
	__typename?: 'LiveWatchlistItems'
	allowManualPriceDiscovery: Scalars['Boolean']['output']
	automated: Scalars['Boolean']['output']
	automationRequested: Scalars['Boolean']['output']
	enableManualDeletion: Scalars['Boolean']['output']
	items: Array<LiveWatchlistItem>
	preventManualDeletion: Scalars['Boolean']['output']
	total: Scalars['Int']['output']
	updatedAt?: Maybe<Scalars['Time']['output']>
}

export enum LiveWatchlistItemsSortCol {
	Adv = 'ADV',
	Created = 'Created',
	CurrentContra = 'CurrentContra',
	Holders = 'Holders',
	Holding = 'Holding',
	MarketCap = 'MarketCap',
	Name = 'Name',
	NotionalAmountUsd = 'NotionalAmountUsd',
	Price = 'Price',
	Ranking = 'Ranking',
	RecentContra = 'RecentContra',
	Score = 'Score',
	Side = 'Side',
	Volume = 'Volume',
	VolumeDays = 'VolumeDays',
}

export enum MarketCapCategory {
	Large = 'Large',
	Mega = 'Mega',
	Micro = 'Micro',
	Mid = 'Mid',
	Small = 'Small',
}

export type Metrics = {
	__typename?: 'Metrics'
	bookbuilds: Array<BookbuildMetrics>
}

export type MetricsInput = {
	ems?: InputMaybe<Scalars['String']['input']>
	endDate: Scalars['Time']['input']
	orgId?: InputMaybe<Scalars['ID']['input']>
	startDate: Scalars['Time']['input']
}

export type Mutation = {
	__typename?: 'Mutation'
	addLiveWatchlistItem: LiveWatchlistItem
	addPassiveWatchlistItem: PassiveWatchlistItem
	adminCancelBookbuild: Bookbuild
	adminCancelOrder: Order
	adminCompleteBookbuildDeal: Scalars['Boolean']['output']
	adminEndBookbuild: Scalars['Boolean']['output']
	adminFailBookbuild: Bookbuild
	adminPartialCompleteBookbuild: Scalars['Boolean']['output']
	adminSetBookbuildStrategy: Bookbuild
	adminSetBypassBrokerLimits: Bookbuild
	adminSetCaptureDownsizedBookbuild: Bookbuild
	adminUpdateUser: User
	cancelBookbuild: Bookbuild
	cancelOrder: Order
	cancelPriceDiscovery: Scalars['Boolean']['output']
	clearLiveWatchlistItems: Scalars['Boolean']['output']
	clearPassiveWatchlistItems: Scalars['Boolean']['output']
	clearRecentLiveWatchlistItems: Scalars['Boolean']['output']
	createAPIKey: CreateApiKeyResponse
	createBookbuild: Bookbuild
	createBroker: Broker
	createEMSSession: EmsSession
	createIoiSession: IoiSession
	createOrUpdateProfile: Profile
	createOrder: Order
	createOrganization: Organization
	createPriceDiscovery: PriceDiscovery
	createTrendingEquitiesList: TrendingEquitiesList
	createUser: User
	createWatchlistSession: WatchlistSession
	deleteAPIKey: Scalars['Boolean']['output']
	deleteBroker: Broker
	deleteEMSSession: EmsSession
	deleteIoiSession: IoiSession
	deleteLiveWatchlistItem: LiveWatchlistItem
	deletePassiveWatchlistItem: PassiveWatchlistItem
	deleteUser: Scalars['Boolean']['output']
	deleteWatchlistSession: WatchlistSession
	disableUser: Scalars['Boolean']['output']
	distributeAndCommitBookbuild: Bookbuild
	failTrade: Trade
	hideTrendingEquitiesHint: Scalars['Boolean']['output']
	markAllNotificationsAsRead: Scalars['Boolean']['output']
	markNotificationsAsRead: Array<Notification>
	reloadIoiEngine: Scalars['Boolean']['output']
	reloadWatchlistEngine: Scalars['Boolean']['output']
	removeUnsupportedTicker: UnsupportedTicker
	requestLiveWatchlistAutomation: Scalars['Boolean']['output']
	respondNoToPriceDiscoveryAsAdmin: Scalars['Boolean']['output']
	respondToPriceDiscovery?: Maybe<PriceDiscoveryOpportunity>
	respondToTrendingEquity: Scalars['Boolean']['output']
	retryTrade: Trade
	sendOriginationAlert: Scalars['Boolean']['output']
	sendPriceDiscoveryOriginationAlert: Scalars['Boolean']['output']
	sendTrendingEquitiesEmail: Scalars['Boolean']['output']
	sendUserWelcomeEmail: Scalars['Boolean']['output']
	setDistributionScoreWeights: Scalars['Boolean']['output']
	setLiveWatchlistItems: SetWatchlistResponse
	setOrganizationHoldingsOwners: Scalars['Boolean']['output']
	setPassiveWatchlistItems: SetWatchlistResponse
	setPassiveWatchlistSettings: PassiveWatchlistSettings
	updateBookbuildSensitivity: Bookbuild
	updateBroker: Broker
	updateDistributionOrgs: Array<OrganizationPublic>
	updateEMSSession: EmsSession
	updateIoiSession: IoiSession
	updateOpportunity: Opportunity
	updateOrder: Order
	updateOrganization: Organization
	updatePreferences: UserPreferences
	updateRoles: User
	updateTrendingEquitiesList: TrendingEquitiesList
	updateUser: User
	updateWatchlistSession: WatchlistSession
}

export type MutationAddLiveWatchlistItemArgs = {
	input: LiveWatchlistItemInput
}

export type MutationAddPassiveWatchlistItemArgs = {
	input: PassiveWatchlistItemInput
}

export type MutationAdminCancelBookbuildArgs = {
	id: Scalars['ID']['input']
	justification: Scalars['String']['input']
}

export type MutationAdminCancelOrderArgs = {
	id: Scalars['ID']['input']
	justification: Scalars['String']['input']
}

export type MutationAdminCompleteBookbuildDealArgs = {
	bookbuildID: Scalars['ID']['input']
}

export type MutationAdminEndBookbuildArgs = {
	id: Scalars['ID']['input']
}

export type MutationAdminFailBookbuildArgs = {
	id: Scalars['ID']['input']
	justification: Scalars['String']['input']
}

export type MutationAdminPartialCompleteBookbuildArgs = {
	id: Scalars['ID']['input']
}

export type MutationAdminSetBookbuildStrategyArgs = {
	id: Scalars['ID']['input']
	strategy: DealStrategy
}

export type MutationAdminSetBypassBrokerLimitsArgs = {
	bypassBrokerLimits: Scalars['Boolean']['input']
	id: Scalars['ID']['input']
}

export type MutationAdminSetCaptureDownsizedBookbuildArgs = {
	captureDownsized: Scalars['Boolean']['input']
	id: Scalars['ID']['input']
}

export type MutationAdminUpdateUserArgs = {
	input: AdminUpdateUserInput
	userId: Scalars['ID']['input']
}

export type MutationCancelBookbuildArgs = {
	id: Scalars['ID']['input']
	justification: Scalars['String']['input']
}

export type MutationCancelOrderArgs = {
	id: Scalars['ID']['input']
	justification: Scalars['String']['input']
}

export type MutationCancelPriceDiscoveryArgs = {
	id: Scalars['ID']['input']
}

export type MutationCreateApiKeyArgs = {
	key: CreateApiKeyInput
}

export type MutationCreateBookbuildArgs = {
	bookbuild: CreateBookbuildInput
}

export type MutationCreateBrokerArgs = {
	input: CreateBrokerInput
}

export type MutationCreateEmsSessionArgs = {
	session: EmsSessionInput
}

export type MutationCreateIoiSessionArgs = {
	session: IoiSessionInput
}

export type MutationCreateOrUpdateProfileArgs = {
	profile: ProfileInput
}

export type MutationCreateOrderArgs = {
	order: CreateOrderInput
}

export type MutationCreateOrganizationArgs = {
	organization: OrganizationInput
}

export type MutationCreatePriceDiscoveryArgs = {
	input: PriceDiscoveryInput
}

export type MutationCreateTrendingEquitiesListArgs = {
	list: TrendingEquitiesListInput
}

export type MutationCreateUserArgs = {
	user: CreateUserInput
}

export type MutationCreateWatchlistSessionArgs = {
	session: WatchlistSessionInput
}

export type MutationDeleteApiKeyArgs = {
	key: DeleteApiKeyInput
}

export type MutationDeleteBrokerArgs = {
	id: Scalars['ID']['input']
}

export type MutationDeleteEmsSessionArgs = {
	id: Scalars['ID']['input']
}

export type MutationDeleteIoiSessionArgs = {
	id: Scalars['ID']['input']
}

export type MutationDeleteLiveWatchlistItemArgs = {
	force: Scalars['Boolean']['input']
	id: Scalars['ID']['input']
}

export type MutationDeletePassiveWatchlistItemArgs = {
	id: Scalars['ID']['input']
}

export type MutationDeleteUserArgs = {
	id: Scalars['ID']['input']
}

export type MutationDeleteWatchlistSessionArgs = {
	id: Scalars['ID']['input']
}

export type MutationDisableUserArgs = {
	id: Scalars['ID']['input']
}

export type MutationDistributeAndCommitBookbuildArgs = {
	id: Scalars['ID']['input']
	input: DistributeAndCommitBookbuildInput
}

export type MutationFailTradeArgs = {
	id: Scalars['ID']['input']
}

export type MutationMarkNotificationsAsReadArgs = {
	ids: Array<Scalars['ID']['input']>
}

export type MutationRemoveUnsupportedTickerArgs = {
	ticker: Scalars['String']['input']
}

export type MutationRespondNoToPriceDiscoveryAsAdminArgs = {
	onBehalfOfUserId: Scalars['ID']['input']
	priceDiscoveryOpportunityId: Scalars['ID']['input']
}

export type MutationRespondToPriceDiscoveryArgs = {
	input: PriceDiscoveryResponseInput
}

export type MutationRespondToTrendingEquityArgs = {
	instrumentID: Scalars['ID']['input']
	side?: InputMaybe<Side>
}

export type MutationRetryTradeArgs = {
	id: Scalars['ID']['input']
}

export type MutationSendOriginationAlertArgs = {
	reason?: InputMaybe<Scalars['String']['input']>
	watchlistItemId: Scalars['ID']['input']
}

export type MutationSendPriceDiscoveryOriginationAlertArgs = {
	input: PriceDiscoveryOriginationAlertInput
	priceDiscoveryId: Scalars['ID']['input']
}

export type MutationSendTrendingEquitiesEmailArgs = {
	listID: Scalars['ID']['input']
}

export type MutationSendUserWelcomeEmailArgs = {
	id: Scalars['ID']['input']
}

export type MutationSetDistributionScoreWeightsArgs = {
	weights: Array<DistributionScoreWeightsInput>
}

export type MutationSetLiveWatchlistItemsArgs = {
	files: Array<Scalars['Upload']['input']>
}

export type MutationSetOrganizationHoldingsOwnersArgs = {
	orgId: Scalars['ID']['input']
	ownerIds: Array<Scalars['Int']['input']>
}

export type MutationSetPassiveWatchlistItemsArgs = {
	files: Array<Scalars['Upload']['input']>
}

export type MutationSetPassiveWatchlistSettingsArgs = {
	settings: PassiveWatchlistSettingsInput
}

export type MutationUpdateBookbuildSensitivityArgs = {
	id: Scalars['ID']['input']
	input: UpdateBookbuildSensitivityInput
}

export type MutationUpdateBrokerArgs = {
	id: Scalars['ID']['input']
	input: UpdateBrokerInput
}

export type MutationUpdateDistributionOrgsArgs = {
	orgs: Array<DistributionOrgInput>
}

export type MutationUpdateEmsSessionArgs = {
	id: Scalars['ID']['input']
	session: EmsSessionInput
}

export type MutationUpdateIoiSessionArgs = {
	id: Scalars['ID']['input']
	session: IoiSessionInput
}

export type MutationUpdateOpportunityArgs = {
	id: Scalars['ID']['input']
	interested: Scalars['Boolean']['input']
}

export type MutationUpdateOrderArgs = {
	id: Scalars['ID']['input']
	order: UpdateOrderInput
}

export type MutationUpdateOrganizationArgs = {
	id: Scalars['ID']['input']
	organization: OrganizationInput
}

export type MutationUpdatePreferencesArgs = {
	preferences: PreferencesInput
	userId: Scalars['ID']['input']
}

export type MutationUpdateRolesArgs = {
	roleIds: Array<Scalars['ID']['input']>
	userId: Scalars['ID']['input']
}

export type MutationUpdateTrendingEquitiesListArgs = {
	id: Scalars['ID']['input']
	list: TrendingEquitiesListInput
}

export type MutationUpdateUserArgs = {
	user: UpdateUserInput
}

export type MutationUpdateWatchlistSessionArgs = {
	id: Scalars['ID']['input']
	session: WatchlistSessionInput
}

export type Notification = {
	__typename?: 'Notification'
	createdAt: Scalars['Time']['output']
	description: Scalars['String']['output']
	id: Scalars['ID']['output']
	readAt?: Maybe<Scalars['Time']['output']>
	sticky: Scalars['Boolean']['output']
	title: Scalars['String']['output']
	type: Scalars['String']['output']
	url: Scalars['String']['output']
}

export type Notifications = {
	__typename?: 'Notifications'
	items: Array<Notification>
	total: Scalars['Int']['output']
	totalUnread: Scalars['Int']['output']
}

export type Opportunities = {
	__typename?: 'Opportunities'
	items: Array<Opportunity>
	total: Scalars['Int']['output']
}

export enum OpportunitiesSort {
	AZ = 'a_z',
	Expiry = 'expiry',
	Size = 'size',
	ZA = 'z_a',
}

export enum OpportunitiesSortCol {
	CreatedAt = 'CreatedAt',
	EquityName = 'EquityName',
	ExpiredAt = 'ExpiredAt',
	Interested = 'Interested',
	Order = 'Order',
	Side = 'Side',
	Status = 'Status',
}

export enum OpportunitiesView {
	All = 'all',
	Direct = 'direct',
	Profile = 'profile',
	Watchlist = 'watchlist',
}

export type Opportunity = {
	__typename?: 'Opportunity'
	bookbuild: BookbuildPublic
	directed: Scalars['Boolean']['output']
	id: Scalars['ID']['output']
	interested?: Maybe<Scalars['Boolean']['output']>
	interestedUpdatedAt?: Maybe<Scalars['Time']['output']>
	interestedUpdatedBy?: Maybe<User>
	liveWatchlistMatches?: Maybe<Array<WatchlistItem>>
	matchedBy?: Maybe<OpportunityInterest>
	order?: Maybe<Order>
	organization: Organization
	passiveInterestMatches?: Maybe<Array<OpportunityMatchItem>>
	status: Status
}

export enum OpportunityInterest {
	LiveWatchlistItem = 'LiveWatchlistItem',
	PassiveInterest = 'PassiveInterest',
	PriceDiscovery = 'PriceDiscovery',
	Profile = 'Profile',
}

export type OpportunityMatchItem = {
	__typename?: 'OpportunityMatchItem'
	id: Scalars['ID']['output']
}

export type Order = {
	__typename?: 'Order'
	allocation?: Maybe<Allocation>
	amendedAt?: Maybe<Scalars['Time']['output']>
	bookbuild: BookbuildPublic
	broker: BrokerPublic
	brokerLimitViolation?: Maybe<BrokerLimitViolation>
	cancelledAt?: Maybe<Scalars['Time']['output']>
	clientOrderId?: Maybe<Scalars['String']['output']>
	createdAt: Scalars['Time']['output']
	daysAdtv?: Maybe<Scalars['Float']['output']>
	entity: Entity
	id: Scalars['ID']['output']
	isPrimaryAdv: Scalars['Boolean']['output']
	notionalCash: Scalars['Float']['output']
	notionalGbp: Scalars['Float']['output']
	notionalUsd: Scalars['Float']['output']
	organization: Organization
	price: Scalars['Float']['output']
	score?: Maybe<OrderScore>
	status: Status
	targetTariff?: Maybe<Scalars['Int']['output']>
	upsizeDaysAdtv?: Maybe<Scalars['Float']['output']>
	upsizeNotionalCash?: Maybe<Scalars['Float']['output']>
	upsizeNotionalGbp?: Maybe<Scalars['Float']['output']>
	upsizeNotionalUsd?: Maybe<Scalars['Float']['output']>
	upsizePrice?: Maybe<Scalars['Float']['output']>
	upsizeTariff?: Maybe<Scalars['Int']['output']>
	upsizeVolume?: Maybe<Scalars['BigInt']['output']>
	user: User
	volume: Scalars['BigInt']['output']
}

export enum OrderScore {
	High = 'high',
	Low = 'low',
	Medium = 'medium',
}

export type Orders = {
	__typename?: 'Orders'
	items: Array<Order>
	total: Scalars['Int']['output']
}

export enum OrdersSortCol {
	Broker = 'Broker',
	LastUpdated = 'LastUpdated',
	Name = 'Name',
	Org = 'Org',
	Side = 'Side',
	Status = 'Status',
	User = 'User',
}

export enum OrgCategory {
	HedgeFund = 'hedgeFund',
	LongOnly = 'longOnly',
	None = 'none',
}

export enum OrgType {
	Admin = 'admin',
	User = 'user',
}

export type Organization = {
	__typename?: 'Organization'
	allowManualPriceDiscovery: Scalars['Boolean']['output']
	brokerLimitViolations: Array<BrokerLimitViolation>
	category: OrgCategory
	connection?: Maybe<Connection>
	disabledCurrencies: Array<Scalars['String']['output']>
	disabledIsins: Array<Scalars['String']['output']>
	disabledMarketIds: Array<Scalars['String']['output']>
	emsSession?: Maybe<EmsSession>
	entities: Array<Entity>
	fixCompId?: Maybe<Scalars['String']['output']>
	holdingsOwners: Array<HoldingsOwner>
	id: Scalars['ID']['output']
	inactivityTimeoutMins: Scalars['Int']['output']
	intersectionsCount: Scalars['Int']['output']
	liveWatchlistAutomated: Scalars['Boolean']['output']
	liveWatchlistAutomationRequestedAt?: Maybe<Scalars['Time']['output']>
	liveWatchlistAutomationRequestedBy?: Maybe<Scalars['String']['output']>
	liveWatchlistClearEnabled: Scalars['Boolean']['output']
	liveWatchlistItemsCount: Scalars['Int']['output']
	liveWatchlistUpdatedAt?: Maybe<Scalars['Time']['output']>
	name: Scalars['String']['output']
	passiveWatchlistAutomigration: Scalars['Boolean']['output']
	passiveWatchlistItemsCount: Scalars['Int']['output']
	passiveWatchlistUpdatedAt?: Maybe<Scalars['Time']['output']>
	type: OrgType
	userEvents: UserEvents
	users: Array<User>
	usersCount: Scalars['Int']['output']
	watchlistSession?: Maybe<WatchlistSession>
}

export type OrganizationUserEventsArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type OrganizationInput = {
	allowManualPriceDiscovery: Scalars['Boolean']['input']
	category: OrgCategory
	connectionId: Scalars['String']['input']
	disabledCurrencies: Array<Scalars['String']['input']>
	disabledIsins: Array<Scalars['String']['input']>
	disabledMarketIds: Array<Scalars['String']['input']>
	emsSessionId?: InputMaybe<Scalars['String']['input']>
	entities: Array<EntityInput>
	fixCompId?: InputMaybe<Scalars['String']['input']>
	inactivityTimeoutMins: Scalars['Int']['input']
	liveWatchlistAutomated: Scalars['Boolean']['input']
	liveWatchlistAutomationRequestedAt?: InputMaybe<Scalars['Time']['input']>
	liveWatchlistAutomationRequestedBy?: InputMaybe<Scalars['String']['input']>
	liveWatchlistClearEnabled: Scalars['Boolean']['input']
	name: Scalars['String']['input']
	passiveWatchlistAutomigration: Scalars['Boolean']['input']
	type: OrgType
	watchlistSessionId?: InputMaybe<Scalars['String']['input']>
}

export type OrganizationItems = {
	__typename?: 'OrganizationItems'
	items: Array<Organization>
	total: Scalars['Int']['output']
}

export enum OrganizationItemsSortCol {
	Active = 'Active',
	Automated = 'Automated',
	Intersections = 'Intersections',
	Name = 'Name',
	Passive = 'Passive',
	UpdatedAt = 'UpdatedAt',
}

export type OrganizationPublic = {
	__typename?: 'OrganizationPublic'
	category: OrgCategory
	exclude: Scalars['Boolean']['output']
	id: Scalars['ID']['output']
	name: Scalars['String']['output']
	recentlyAdded: Scalars['Boolean']['output']
}

export type OriginationAlert = {
	__typename?: 'OriginationAlert'
	contactedByUser: User
	createdAt: Scalars['Time']['output']
	durationHours?: Maybe<Scalars['Int']['output']>
	durationMinutes?: Maybe<Scalars['Int']['output']>
	id: Scalars['ID']['output']
	instrument: Instrument
	launchTime?: Maybe<Scalars['Time']['output']>
	price?: Maybe<Scalars['Decimal']['output']>
	priceDiscovery?: Maybe<PriceDiscovery>
	reason?: Maybe<Scalars['String']['output']>
	side: Side
	suggestion?: Maybe<Scalars['String']['output']>
	volume?: Maybe<Scalars['BigInt']['output']>
}

export type OriginationAlerts = {
	__typename?: 'OriginationAlerts'
	items: Array<OriginationAlert>
	total: Scalars['Int']['output']
}

export enum OriginationAlertsSortCol {
	ContactedBy = 'ContactedBy',
	Date = 'Date',
	EquityName = 'EquityName',
}

export type PageInfo = {
	__typename?: 'PageInfo'
	endCursor?: Maybe<Scalars['ID']['output']>
	hasNextPage: Scalars['Boolean']['output']
	hasPreviousPage: Scalars['Boolean']['output']
	startCursor?: Maybe<Scalars['ID']['output']>
}

export type PassiveWatchlistItem = {
	__typename?: 'PassiveWatchlistItem'
	autoMigrated: Scalars['Boolean']['output']
	createdAt: Scalars['Time']['output']
	currentContraCount: Scalars['Int']['output']
	id: Scalars['ID']['output']
	instrument: Instrument
	organization: Organization
	recentContraCount: Scalars['Int']['output']
	side: Side
	user: User
}

export type PassiveWatchlistItemInput = {
	instrumentID: Scalars['ID']['input']
	side: Side
}

export type PassiveWatchlistItems = {
	__typename?: 'PassiveWatchlistItems'
	items: Array<PassiveWatchlistItem>
	settings?: Maybe<PassiveWatchlistSettings>
	total: Scalars['Int']['output']
	updatedAt?: Maybe<Scalars['Time']['output']>
}

export enum PassiveWatchlistItemsSortCol {
	Created = 'Created',
	CurrentContra = 'CurrentContra',
	Holders = 'Holders',
	Holding = 'Holding',
	Name = 'Name',
	Ranking = 'Ranking',
	RecentContra = 'RecentContra',
	Side = 'Side',
}

export type PassiveWatchlistSettings = {
	__typename?: 'PassiveWatchlistSettings'
	advPercent?: Maybe<Scalars['Float']['output']>
	autoMigration: Scalars['Boolean']['output']
	buyDiscountPercent?: Maybe<Scalars['Float']['output']>
	reactionTimeHours?: Maybe<Scalars['Int']['output']>
	sellPremiumPercent?: Maybe<Scalars['Float']['output']>
}

export type PassiveWatchlistSettingsInput = {
	advPercent?: InputMaybe<Scalars['Float']['input']>
	autoMigration: Scalars['Boolean']['input']
	buyDiscountPercent?: InputMaybe<Scalars['Float']['input']>
	reactionTimeHours?: InputMaybe<Scalars['Int']['input']>
	sellPremiumPercent?: InputMaybe<Scalars['Float']['input']>
}

export enum PmRegion {
	Apac = 'APAC',
	Europe = 'EUROPE',
	Us = 'US',
}

export type PotentialAllocation = {
	__typename?: 'PotentialAllocation'
	broker: BrokerPublic
	daysAdtv?: Maybe<Scalars['Float']['output']>
	dealPercentage: Scalars['Float']['output']
	id: Scalars['ID']['output']
	isPrimaryAdv: Scalars['Boolean']['output']
	notionalCash: Scalars['Float']['output']
	notionalGbp: Scalars['Float']['output']
	notionalUsd: Scalars['Float']['output']
	order: Order
	organization: Organization
	tariff: Scalars['Int']['output']
	volume: Scalars['BigInt']['output']
}

export type PotentialDeal = {
	__typename?: 'PotentialDeal'
	allocations: Array<PotentialAllocation>
	bookbuild: Bookbuild
	broker: BrokerPublic
	daysAdtv?: Maybe<Scalars['Float']['output']>
	id: Scalars['ID']['output']
	isPrimaryAdv: Scalars['Boolean']['output']
	notionalCash: Scalars['Float']['output']
	notionalGbp: Scalars['Float']['output']
	notionalUsd: Scalars['Float']['output']
	organization: Organization
	price: Scalars['Float']['output']
	tariff: Scalars['Int']['output']
	volume: Scalars['BigInt']['output']
}

export type PreferencesInput = {
	opportunitiesSort?: InputMaybe<OpportunitiesSort>
	opportunitiesView?: InputMaybe<OpportunitiesView>
	timeZone?: InputMaybe<Scalars['String']['input']>
}

export type PreviewReach = {
	__typename?: 'PreviewReach'
	liveWatchlistsFirms: Scalars['Int']['output']
	passiveInterestFirms: Scalars['Int']['output']
	priceDiscoveryFirms: Scalars['Int']['output']
}

export type PriceDiscoveries = {
	__typename?: 'PriceDiscoveries'
	items: Array<PriceDiscovery>
	total: Scalars['Int']['output']
}

export type PriceDiscovery = {
	__typename?: 'PriceDiscovery'
	cancelledAt?: Maybe<Scalars['Time']['output']>
	cancelledBy?: Maybe<User>
	createdAt: Scalars['Time']['output']
	daysAdtv?: Maybe<Scalars['Float']['output']>
	endedAt?: Maybe<Scalars['Time']['output']>
	endsAt: Scalars['Time']['output']
	holdersCount: Scalars['Int']['output']
	id: Scalars['ID']['output']
	instrument: Instrument
	interestedCount: Scalars['Int']['output']
	manualDistributionFirms: Array<OrganizationPublic>
	manualSelectionFirmsCount: Scalars['Int']['output']
	notInterestedCount: Scalars['Int']['output']
	notionalUsd: Scalars['Float']['output']
	opportunities: Array<PriceDiscoveryOpportunity>
	organization: Organization
	originationAlert?: Maybe<OriginationAlert>
	price?: Maybe<Scalars['Decimal']['output']>
	side: Side
	status: PriceDiscoveryStatus
	suggestedDistributionFirms: Array<OrganizationPublic>
	suggestedDistributionFirmsCount: Scalars['Int']['output']
	updatedAt: Scalars['Time']['output']
	user: User
	volume: Scalars['BigInt']['output']
	yesCount: Scalars['Int']['output']
}

export type PriceDiscoveryInput = {
	endsAt: Scalars['Time']['input']
	instrumentID: Scalars['ID']['input']
	orgIds: Array<Scalars['ID']['input']>
	price?: InputMaybe<Scalars['Decimal']['input']>
	side: Side
	suggestedDistribution: Scalars['Boolean']['input']
	suggestedDistributionOrgsCount?: InputMaybe<Scalars['Int']['input']>
	volume: Scalars['BigInt']['input']
}

export type PriceDiscoveryOpportunities = {
	__typename?: 'PriceDiscoveryOpportunities'
	items: Array<PriceDiscoveryOpportunity>
	total: Scalars['Int']['output']
}

export type PriceDiscoveryOpportunity = {
	__typename?: 'PriceDiscoveryOpportunity'
	adminRespondedAt?: Maybe<Scalars['Time']['output']>
	adminRespondedBy?: Maybe<User>
	daysAdtv?: Maybe<Scalars['Float']['output']>
	hoursRequired?: Maybe<Scalars['Float']['output']>
	id: Scalars['ID']['output']
	interested?: Maybe<Scalars['Boolean']['output']>
	isHolder: Scalars['Boolean']['output']
	manualDistribution: Scalars['Boolean']['output']
	notionalUsd?: Maybe<Scalars['Float']['output']>
	organization: Organization
	pmRegion?: Maybe<PmRegion>
	priceDiscovery: PriceDiscoveryPublic
	respondedAt?: Maybe<Scalars['Time']['output']>
	respondedBy?: Maybe<User>
	sensitivityType?: Maybe<PriceDiscoverySensitivityType>
	sensitivityValue?: Maybe<Scalars['Decimal']['output']>
	suggestedDistribution: Scalars['Boolean']['output']
	volume?: Maybe<Scalars['BigInt']['output']>
}

export type PriceDiscoveryOriginationAlertInput = {
	durationHours: Scalars['Int']['input']
	durationMinutes: Scalars['Int']['input']
	launchTime?: InputMaybe<Scalars['Time']['input']>
	suggestion: Scalars['String']['input']
	volume: Scalars['BigInt']['input']
}

export type PriceDiscoveryPublic = {
	__typename?: 'PriceDiscoveryPublic'
	cancelledAt?: Maybe<Scalars['Time']['output']>
	createdAt: Scalars['Time']['output']
	daysAdtvLowerBound?: Maybe<Scalars['Float']['output']>
	daysAdtvUpperBound?: Maybe<Scalars['Float']['output']>
	endedAt?: Maybe<Scalars['Time']['output']>
	endsAt: Scalars['Time']['output']
	id: Scalars['ID']['output']
	instrument: Instrument
	side: Side
	status: PriceDiscoveryStatus
}

export type PriceDiscoveryResponseInput = {
	hoursRequired?: InputMaybe<Scalars['Float']['input']>
	id: Scalars['ID']['input']
	interested?: InputMaybe<Scalars['Boolean']['input']>
	pmRegion?: InputMaybe<PmRegion>
	sensitivityType?: InputMaybe<PriceDiscoverySensitivityType>
	sensitivityValue?: InputMaybe<Scalars['Decimal']['input']>
	volume?: InputMaybe<Scalars['BigInt']['input']>
}

export enum PriceDiscoverySensitivityType {
	AtMarket = 'AT_MARKET',
	Discount = 'DISCOUNT',
	Premium = 'PREMIUM',
	Price = 'PRICE',
}

export enum PriceDiscoverySortCol {
	CreatedAt = 'CREATED_AT',
	EndTime = 'END_TIME',
	Equity = 'EQUITY',
	InterestedFirms = 'INTERESTED_FIRMS',
	NotInterestedFirms = 'NOT_INTERESTED_FIRMS',
	Org = 'ORG',
	Side = 'SIDE',
	Status = 'STATUS',
	YesFirms = 'YES_FIRMS',
}

export enum PriceDiscoveryStatus {
	Active = 'ACTIVE',
	Cancelled = 'CANCELLED',
	Completed = 'COMPLETED',
	Ended = 'ENDED',
	Expired = 'EXPIRED',
}

export type Profile = {
	__typename?: 'Profile'
	countries: Array<Country>
	createdAt: Scalars['Time']['output']
	id: Scalars['ID']['output']
	marketCapMax?: Maybe<Scalars['Int']['output']>
	marketCapMin?: Maybe<Scalars['Int']['output']>
	sectors: Array<Sector>
	updatedAt: Scalars['Time']['output']
	user: User
}

export type ProfileInput = {
	countries: Array<Scalars['String']['input']>
	marketCapMax?: InputMaybe<Scalars['Int']['input']>
	marketCapMin?: InputMaybe<Scalars['Int']['input']>
	sectors: Array<Scalars['String']['input']>
}

export type ProfileInstrumentCounts = {
	__typename?: 'ProfileInstrumentCounts'
	total: Scalars['Int']['output']
}

export type Query = {
	__typename?: 'Query'
	bookbuild?: Maybe<Bookbuild>
	bookbuilds: Bookbuilds
	broker?: Maybe<Broker>
	brokers: Array<Broker>
	connections: Array<Connection>
	countries: Array<Country>
	deal?: Maybe<Deal>
	deals: Array<Deal>
	distributionBrokers: Array<BrokerPublic>
	distributionOrgs: Array<OrganizationPublic>
	distributionScoreWeights: Array<DistributionScoreWeight>
	emsNames: Array<Scalars['String']['output']>
	emsSession?: Maybe<EmsSession>
	emsSessions: Array<EmsSession>
	exportLiveWatchlistEntries: Array<LiveWatchlistEntry>
	exportUsersToCSV: Scalars['String']['output']
	findInstruments: Array<Instrument>
	fixCloudLogs?: Maybe<FixCloudLogConnection>
	fixLogs?: Maybe<FixLogConnection>
	fixSessions: Array<Scalars['String']['output']>
	holdingsOwners: HoldingsOwnersResponse
	instrument: Instrument
	instrumentDistributionScoreAttributes: DistributionScoreAttributes
	instruments: Instruments
	intersections: Intersections
	ioiSession?: Maybe<IoiSession>
	ioiSessions: Array<IoiSession>
	listKeys: Array<ApiKey>
	liveWatchlist: LiveWatchlistItems
	liveWatchlistEntries: LiveWatchlistEntryItems
	me: User
	metrics: Metrics
	notifications: Notifications
	opportunities: Opportunities
	opportunity?: Maybe<Opportunity>
	order?: Maybe<Order>
	orders: Orders
	organization?: Maybe<Organization>
	organizationUserEvents: UserEvents
	organizations: Array<Organization>
	organizationsPaginated: OrganizationItems
	originationAlerts: OriginationAlerts
	passiveWatchlist: PassiveWatchlistItems
	previewProfileInstrumentsCount: ProfileInstrumentCounts
	priceDiscoveries: PriceDiscoveries
	priceDiscovery?: Maybe<PriceDiscovery>
	priceDiscoveryOpportunities: PriceDiscoveryOpportunities
	profile?: Maybe<Profile>
	rankedInstruments: Array<RankedInstrument>
	recentLiveWatchlist: LiveWatchlistItems
	roles: Array<Role>
	sectors: Array<Sector>
	todayOpportunities: Array<Opportunity>
	trendingEquitiesActive?: Maybe<TrendingEquitiesList>
	trendingEquitiesList?: Maybe<TrendingEquitiesList>
	trendingEquitiesLists: TrendingEquitiesListItems
	unsupportedTickers: UnsupportedTickers
	user?: Maybe<User>
	userEvents: UserEvents
	users: Users
	watchlistSession?: Maybe<WatchlistSession>
	watchlistSessions: Array<WatchlistSession>
	yfinance: Scalars['Any']['output']
}

export type QueryBookbuildArgs = {
	id: Scalars['ID']['input']
}

export type QueryBookbuildsArgs = {
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: BookbuildsSortCol
	sortDesc: Scalars['Boolean']['input']
	status?: InputMaybe<Array<Status>>
}

export type QueryBrokerArgs = {
	id: Scalars['ID']['input']
	withPassword?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryDealArgs = {
	id: Scalars['ID']['input']
}

export type QueryDealsArgs = {
	status?: InputMaybe<Array<Status>>
}

export type QueryEmsSessionArgs = {
	id: Scalars['ID']['input']
}

export type QueryExportLiveWatchlistEntriesArgs = {
	limit: Scalars['Int']['input']
	orgId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryExportUsersToCsvArgs = {
	orgId: Scalars['String']['input']
}

export type QueryFindInstrumentsArgs = {
	identifier: Scalars['String']['input']
}

export type QueryFixCloudLogsArgs = {
	limit?: InputMaybe<Scalars['Int']['input']>
	pageToken?: InputMaybe<Scalars['String']['input']>
	session?: InputMaybe<Scalars['String']['input']>
}

export type QueryFixLogsArgs = {
	after?: InputMaybe<Scalars['ID']['input']>
	before?: InputMaybe<Scalars['ID']['input']>
	first?: InputMaybe<Scalars['Int']['input']>
	last?: InputMaybe<Scalars['Int']['input']>
	session?: InputMaybe<Scalars['String']['input']>
}

export type QueryHoldingsOwnersArgs = {
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	ownerId?: InputMaybe<Scalars['Int']['input']>
	searchQuery?: InputMaybe<Scalars['String']['input']>
}

export type QueryInstrumentArgs = {
	id: Scalars['ID']['input']
}

export type QueryInstrumentDistributionScoreAttributesArgs = {
	instrumentId: Scalars['ID']['input']
	orgId: Scalars['ID']['input']
	side: Side
}

export type QueryInstrumentsArgs = {
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	orgId?: InputMaybe<Scalars['ID']['input']>
	sortBy: InstrumentsSortCol
	sortDesc: Scalars['Boolean']['input']
}

export type QueryIntersectionsArgs = {
	excludePassive?: InputMaybe<Scalars['Boolean']['input']>
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	orgId?: InputMaybe<Scalars['ID']['input']>
	sortBy: IntersectionsSortCol
	sortDesc: Scalars['Boolean']['input']
}

export type QueryIoiSessionArgs = {
	id: Scalars['ID']['input']
}

export type QueryIoiSessionsArgs = {
	filter?: InputMaybe<Scalars['String']['input']>
	sortBy?: InputMaybe<SessionsSortCol>
	sortDesc?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryLiveWatchlistArgs = {
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	orgId?: InputMaybe<Scalars['ID']['input']>
	sortBy: LiveWatchlistItemsSortCol
	sortDesc: Scalars['Boolean']['input']
}

export type QueryLiveWatchlistEntriesArgs = {
	instrumentId?: InputMaybe<Scalars['ID']['input']>
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	orgId?: InputMaybe<Scalars['ID']['input']>
	previousEntriesLimit: Scalars['Int']['input']
	userId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryMetricsArgs = {
	input: MetricsInput
}

export type QueryNotificationsArgs = {
	limit: Scalars['Int']['input']
}

export type QueryOpportunitiesArgs = {
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: OpportunitiesSortCol
	sortDesc: Scalars['Boolean']['input']
}

export type QueryOpportunityArgs = {
	id: Scalars['ID']['input']
}

export type QueryOrderArgs = {
	id: Scalars['ID']['input']
}

export type QueryOrdersArgs = {
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: OrdersSortCol
	sortDesc: Scalars['Boolean']['input']
	status?: InputMaybe<Array<Status>>
}

export type QueryOrganizationArgs = {
	id: Scalars['ID']['input']
}

export type QueryOrganizationUserEventsArgs = {
	excludeTypes?: InputMaybe<Array<Scalars['String']['input']>>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
	orgId: Scalars['ID']['input']
}

export type QueryOrganizationsArgs = {
	type?: InputMaybe<OrgType>
}

export type QueryOrganizationsPaginatedArgs = {
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: OrganizationItemsSortCol
	sortDesc: Scalars['Boolean']['input']
	type?: InputMaybe<OrgType>
}

export type QueryOriginationAlertsArgs = {
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	orgId?: InputMaybe<Scalars['ID']['input']>
	since?: InputMaybe<Scalars['Time']['input']>
	sortBy: OriginationAlertsSortCol
	sortDesc: Scalars['Boolean']['input']
}

export type QueryPassiveWatchlistArgs = {
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	orgId?: InputMaybe<Scalars['ID']['input']>
	sortBy: PassiveWatchlistItemsSortCol
	sortDesc: Scalars['Boolean']['input']
}

export type QueryPreviewProfileInstrumentsCountArgs = {
	input?: InputMaybe<ProfileInput>
}

export type QueryPriceDiscoveriesArgs = {
	activeSince?: InputMaybe<Scalars['Time']['input']>
	filter?: InputMaybe<Scalars['String']['input']>
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: PriceDiscoverySortCol
	sortDesc: Scalars['Boolean']['input']
}

export type QueryPriceDiscoveryArgs = {
	id: Scalars['ID']['input']
}

export type QueryPriceDiscoveryOpportunitiesArgs = {
	activeSince?: InputMaybe<Scalars['Time']['input']>
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
}

export type QueryRankedInstrumentsArgs = {
	countryCodes?: InputMaybe<Array<Scalars['String']['input']>>
	ownerIds?: InputMaybe<Array<Scalars['Int']['input']>>
}

export type QueryRecentLiveWatchlistArgs = {
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	orgId?: InputMaybe<Scalars['ID']['input']>
	sortBy: LiveWatchlistItemsSortCol
	sortDesc: Scalars['Boolean']['input']
}

export type QueryTrendingEquitiesListArgs = {
	id: Scalars['ID']['input']
}

export type QueryTrendingEquitiesListsArgs = {
	filter?: InputMaybe<Scalars['String']['input']>
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: TrendingEquitiesSortCol
	sortDesc: Scalars['Boolean']['input']
}

export type QueryUnsupportedTickersArgs = {
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: UnsupportedTickerSortCol
	sortDesc: Scalars['Boolean']['input']
}

export type QueryUserArgs = {
	id: Scalars['ID']['input']
}

export type QueryUserEventsArgs = {
	excludeTypes?: InputMaybe<Array<Scalars['String']['input']>>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
	userId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryUsersArgs = {
	filter?: InputMaybe<Scalars['String']['input']>
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: UsersSortCol
	sortDesc: Scalars['Boolean']['input']
}

export type QueryWatchlistSessionArgs = {
	id: Scalars['ID']['input']
}

export type QueryWatchlistSessionsArgs = {
	filter?: InputMaybe<Scalars['String']['input']>
	sortBy?: InputMaybe<SessionsSortCol>
	sortDesc?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryYfinanceArgs = {
	isin: Scalars['String']['input']
	priceHistoryDays?: InputMaybe<Scalars['Int']['input']>
	priceHistoryInterval?: InputMaybe<Scalars['String']['input']>
	sedol: Scalars['String']['input']
}

export type RankedInstrument = {
	__typename?: 'RankedInstrument'
	instrument: Instrument
	liquidity: Scalars['Float']['output']
	lisUsd: Scalars['Int']['output']
	ownersHeldPercentage: Scalars['Float']['output']
	ownersHeldUsdMil: Scalars['Float']['output']
	ranking: Scalars['Int']['output']
	score: Scalars['Float']['output']
	totalHeldPercentage: Scalars['Float']['output']
	totalHeldUsdMil: Scalars['Float']['output']
	totalHolders: Scalars['Int']['output']
}

export type Role = {
	__typename?: 'Role'
	id: Scalars['ID']['output']
	name: Scalars['String']['output']
}

export type Sector = {
	__typename?: 'Sector'
	name: Scalars['String']['output']
}

export type SensitivityPoint = {
	price: Scalars['Decimal']['input']
	volume: Scalars['BigInt']['input']
}

export enum SessionState {
	Active = 'ACTIVE',
	AwaitingDeletion = 'AWAITING_DELETION',
	AwaitingRestart = 'AWAITING_RESTART',
	AwaitingStart = 'AWAITING_START',
	Unspecified = 'UNSPECIFIED',
}

export enum SessionStrategy {
	Default = 'DEFAULT',
	Emsx = 'EMSX',
	Emsxioi = 'EMSXIOI',
	Flextrade = 'FLEXTRADE',
	Portware = 'PORTWARE',
	Triton = 'TRITON',
	Tsi = 'TSI',
}

export enum SessionsSortCol {
	Ems = 'EMS',
	Name = 'NAME',
	SenderCompId = 'SENDER_COMP_ID',
	SessionType = 'SESSION_TYPE',
	State = 'STATE',
	TargetCompId = 'TARGET_COMP_ID',
}

export type SetWatchlistResponse = {
	__typename?: 'SetWatchlistResponse'
	itemsAddedCount: Scalars['Int']['output']
	unsupportedTickers: Array<UnsupportedTicker>
}

export enum Side {
	Buy = 'BUY',
	Sell = 'SELL',
}

export enum Status {
	Allocated = 'allocated',
	Cancelled = 'cancelled',
	Completed = 'completed',
	Draft = 'draft',
	Executing = 'executing',
	Expired = 'expired',
	Failed = 'failed',
	Live = 'live',
	PartiallyCompleted = 'partially_completed',
	Suspended = 'suspended',
}

export type Trade = {
	__typename?: 'Trade'
	acknowledgedAt?: Maybe<Scalars['Time']['output']>
	broker: BrokerPublic
	cancelledAt?: Maybe<Scalars['Time']['output']>
	counterpartyBroker: BrokerPublic
	createdAt: Scalars['Time']['output']
	crossId: Scalars['ID']['output']
	executedAt?: Maybe<Scalars['Time']['output']>
	failedAt?: Maybe<Scalars['Time']['output']>
	grossCash: Scalars['Float']['output']
	id: Scalars['ID']['output']
	organization: Organization
	rejectedAt?: Maybe<Scalars['Time']['output']>
	rejectedReason?: Maybe<Scalars['String']['output']>
	sentAt?: Maybe<Scalars['Time']['output']>
	volume: Scalars['BigInt']['output']
}

export type TrendingEquitiesInstrument = {
	__typename?: 'TrendingEquitiesInstrument'
	changedAt?: Maybe<Scalars['Time']['output']>
	changedBy?: Maybe<User>
	id: Scalars['ID']['output']
	instrument: Instrument
	isHolder?: Maybe<Scalars['Boolean']['output']>
	responses: Array<TrendingEquitiesResponse>
	state?: Maybe<TrendingInstrumentState>
}

export type TrendingEquitiesList = {
	__typename?: 'TrendingEquitiesList'
	active: Scalars['Boolean']['output']
	createdAt: Scalars['Time']['output']
	emailSentAt?: Maybe<Scalars['Time']['output']>
	emailSentBy?: Maybe<User>
	endedAt?: Maybe<Scalars['Time']['output']>
	id: Scalars['ID']['output']
	instruments: Array<TrendingEquitiesInstrument>
	participants?: Maybe<Array<Organization>>
	updatedAt: Scalars['Time']['output']
}

export type TrendingEquitiesListInput = {
	instrumentIDs: Array<Scalars['ID']['input']>
	organizationIDs: Array<Scalars['ID']['input']>
}

export type TrendingEquitiesListItems = {
	__typename?: 'TrendingEquitiesListItems'
	items: Array<TrendingEquitiesList>
	total: Scalars['Int']['output']
}

export type TrendingEquitiesResponse = {
	__typename?: 'TrendingEquitiesResponse'
	activityType: ActivityType
	createdAt: Scalars['Time']['output']
	id: Scalars['ID']['output']
	organization: Organization
	side: Side
	updatedAt: Scalars['Time']['output']
	user: User
}

export enum TrendingEquitiesSortCol {
	Active = 'Active',
	CreatedAt = 'CreatedAt',
	EmailLastSent = 'EmailLastSent',
	Participants = 'Participants',
}

export enum TrendingInstrumentState {
	Added = 'Added',
	Created = 'Created',
	Removed = 'Removed',
}

export enum UnsupportedSource {
	LiveWatchlistApi = 'LiveWatchlistAPI',
	LiveWatchlistFix = 'LiveWatchlistFIX',
	LiveWatchlistUpload = 'LiveWatchlistUpload',
	Origination = 'Origination',
	PassiveWatchlistUpload = 'PassiveWatchlistUpload',
	Unspecified = 'Unspecified',
}

export type UnsupportedTicker = {
	__typename?: 'UnsupportedTicker'
	createdAt: Scalars['Time']['output']
	id: Scalars['ID']['output']
	lastReferencedAt: Scalars['Time']['output']
	ticker: Scalars['String']['output']
	tickerOrganizations: Array<UnsupportedTickerOrganization>
}

export type UnsupportedTickerOrganization = {
	__typename?: 'UnsupportedTickerOrganization'
	organization: Organization
	tickerSource: UnsupportedSource
}

export enum UnsupportedTickerSortCol {
	LastReferencedAt = 'LastReferencedAt',
	Ticker = 'Ticker',
}

export type UnsupportedTickers = {
	__typename?: 'UnsupportedTickers'
	items: Array<UnsupportedTicker>
	total: Scalars['Int']['output']
}

export type UpdateBookbuildSensitivityInput = {
	brokerID: Scalars['ID']['input']
	upsizePrice?: InputMaybe<Scalars['Decimal']['input']>
	upsizeVolume?: InputMaybe<Scalars['BigInt']['input']>
}

export type UpdateBrokerInput = {
	fixEmsId: Scalars['String']['input']
	name: Scalars['String']['input']
	sessions: Array<BrokerSessionInput>
	strategy: BrokerStrategy
	technicalSupportLink?: InputMaybe<Scalars['String']['input']>
	thresholdPercentage: Scalars['Int']['input']
}

export type UpdateOrderInput = {
	brokerID: Scalars['String']['input']
	price: Scalars['Decimal']['input']
	upsizePrice?: InputMaybe<Scalars['Decimal']['input']>
	upsizeVolume?: InputMaybe<Scalars['BigInt']['input']>
	volume: Scalars['BigInt']['input']
}

export type UpdateUserInput = {
	name: Scalars['String']['input']
}

export type User = {
	__typename?: 'User'
	auth0Id?: Maybe<Scalars['String']['output']>
	authStrategy?: Maybe<Scalars['String']['output']>
	bloombergUserId?: Maybe<Scalars['ID']['output']>
	bookbuilds: Array<Bookbuild>
	disabled: Scalars['Boolean']['output']
	email: Scalars['String']['output']
	entities: Array<UserEntity>
	events: UserEvents
	exclusions: Array<OrganizationPublic>
	hideTrendingEquitiesHint: Scalars['Boolean']['output']
	id: Scalars['ID']['output']
	lastAppOpenedAt?: Maybe<Scalars['Time']['output']>
	lastEvent?: Maybe<UserEvent>
	minFirmsDistribution: Scalars['Int']['output']
	name: Scalars['String']['output']
	orders: Array<Order>
	organization: Organization
	preferences: UserPreferences
	profile?: Maybe<Profile>
	roles: Array<Role>
	sessionId: Scalars['Int']['output']
	sessionLastEventAt?: Maybe<Scalars['Time']['output']>
	sessionStartedAt?: Maybe<Scalars['Time']['output']>
	traderId?: Maybe<Scalars['ID']['output']>
	watchlistEmailSentAt?: Maybe<Scalars['Time']['output']>
	welcomeEmailSentAt?: Maybe<Scalars['Time']['output']>
}

export type UserEventsArgs = {
	excludeTypes?: InputMaybe<Array<Scalars['String']['input']>>
	limit?: InputMaybe<Scalars['Int']['input']>
	offset?: InputMaybe<Scalars['Int']['input']>
}

export type UserEntity = {
	__typename?: 'UserEntity'
	assignedAt: Scalars['Time']['output']
	entity: Entity
	isDefault: Scalars['Boolean']['output']
}

export type UserEntityInput = {
	entityID: Scalars['ID']['input']
	isDefault: Scalars['Boolean']['input']
}

export type UserEvent = {
	__typename?: 'UserEvent'
	createdAt: Scalars['Time']['output']
	eventProperties?: Maybe<Scalars['Any']['output']>
	eventSource: Scalars['String']['output']
	eventTime: Scalars['Time']['output']
	eventType: Scalars['String']['output']
	id: Scalars['ID']['output']
	organization: Organization
	updatedAt?: Maybe<Scalars['Time']['output']>
	user: User
}

export type UserEvents = {
	__typename?: 'UserEvents'
	items: Array<UserEvent>
	total: Scalars['Int']['output']
	types: Array<Scalars['String']['output']>
}

export type UserPreferences = {
	__typename?: 'UserPreferences'
	opportunitiesSort?: Maybe<OpportunitiesSort>
	opportunitiesView: OpportunitiesView
	timeZone: Scalars['String']['output']
}

export type Users = {
	__typename?: 'Users'
	items: Array<User>
	total: Scalars['Int']['output']
}

export enum UsersSortCol {
	Email = 'Email',
	LastActive = 'LastActive',
	LastAppOpened = 'LastAppOpened',
	Name = 'Name',
	OrgName = 'OrgName',
	OrgType = 'OrgType',
}

export enum WatchlistEntryType {
	Added = 'added',
	Removed = 'removed',
	Updated = 'updated',
}

export type WatchlistItem = {
	__typename?: 'WatchlistItem'
	id: Scalars['ID']['output']
	instrument: Instrument
	organization: Organization
}

export type WatchlistSession = {
	__typename?: 'WatchlistSession'
	beginString: FixBeginString
	createdAt: Scalars['Time']['output']
	ems: Scalars['String']['output']
	endDay?: Maybe<Scalars['String']['output']>
	endTime: Scalars['String']['output']
	heartbeatIntervalSec?: Maybe<Scalars['Int']['output']>
	id: Scalars['ID']['output']
	maxMessagesPerSecond: Scalars['Int']['output']
	name: Scalars['String']['output']
	organizations: Array<Organization>
	reconnectIntervalSec?: Maybe<Scalars['Int']['output']>
	refreshOnLogon: Scalars['Boolean']['output']
	resetOnDisconnect: Scalars['Boolean']['output']
	resetOnLogon: Scalars['Boolean']['output']
	resetOnLogout: Scalars['Boolean']['output']
	senderCompID: Scalars['String']['output']
	sessionType: FixSessionType
	socketConnectHost?: Maybe<Scalars['String']['output']>
	socketConnectPort?: Maybe<Scalars['Int']['output']>
	startDay?: Maybe<Scalars['String']['output']>
	startTime: Scalars['String']['output']
	state: SessionState
	targetCompID: Scalars['String']['output']
	technicalSupportLink?: Maybe<Scalars['String']['output']>
	timeZone: Scalars['String']['output']
	updatedAt: Scalars['Time']['output']
	weekdays?: Maybe<Scalars['String']['output']>
}

export type WatchlistSessionInput = {
	beginString: FixBeginString
	ems: Scalars['String']['input']
	endDay?: InputMaybe<Scalars['String']['input']>
	endTime: Scalars['String']['input']
	heartbeatIntervalSec?: InputMaybe<Scalars['Int']['input']>
	maxMessagesPerSecond: Scalars['Int']['input']
	name: Scalars['String']['input']
	reconnectIntervalSec?: InputMaybe<Scalars['Int']['input']>
	refreshOnLogon: Scalars['Boolean']['input']
	resetOnDisconnect: Scalars['Boolean']['input']
	resetOnLogon: Scalars['Boolean']['input']
	resetOnLogout: Scalars['Boolean']['input']
	senderCompID: Scalars['String']['input']
	sessionType: FixSessionType
	socketConnectHost?: InputMaybe<Scalars['String']['input']>
	socketConnectPort?: InputMaybe<Scalars['Int']['input']>
	startDay?: InputMaybe<Scalars['String']['input']>
	startTime: Scalars['String']['input']
	targetCompID: Scalars['String']['input']
	technicalSupportLink?: InputMaybe<Scalars['String']['input']>
	timeZone: Scalars['String']['input']
	weekdays?: InputMaybe<Scalars['String']['input']>
}

export enum WatchlistSessionStrategy {
	Default = 'Default',
}

export type UserFragment = {
	__typename?: 'User'
	id: string
	email: string
	disabled: boolean
	sessionId: number
	name: string
	minFirmsDistribution: number
	auth0Id?: string | null
	roles: Array<{ __typename?: 'Role'; id: string }>
	organization: {
		__typename?: 'Organization'
		id: string
		name: string
		type: OrgType
		inactivityTimeoutMins: number
	}
	entities: Array<{
		__typename?: 'UserEntity'
		isDefault: boolean
		entity: {
			__typename?: 'Entity'
			id: string
			name: string
			lei: string
			defaultBroker: { __typename?: 'Broker'; id: string; name: string }
			brokers: Array<{
				__typename?: 'EntityBroker'
				broker: { __typename?: 'Broker'; id: string; name: string }
			}>
		}
	}>
	preferences: {
		__typename?: 'UserPreferences'
		opportunitiesView: OpportunitiesView
		opportunitiesSort?: OpportunitiesSort | null
	}
	exclusions: Array<{
		__typename?: 'OrganizationPublic'
		id: string
		name: string
		category: OrgCategory
		exclude: boolean
		recentlyAdded: boolean
	}>
}

export type UserEntityFragment = {
	__typename?: 'UserEntity'
	isDefault: boolean
	entity: {
		__typename?: 'Entity'
		id: string
		name: string
		lei: string
		defaultBroker: { __typename?: 'Broker'; id: string; name: string }
		brokers: Array<{
			__typename?: 'EntityBroker'
			broker: { __typename?: 'Broker'; id: string; name: string }
		}>
	}
}

export type SectorFragment = { __typename?: 'Sector'; name: string }

export type ProfileFragment = {
	__typename?: 'Profile'
	id: string
	createdAt: string
	updatedAt: string
	marketCapMax?: number | null
	marketCapMin?: number | null
	sectors: Array<{ __typename?: 'Sector'; name: string }>
	countries: Array<{ __typename?: 'Country'; id: string; name: string }>
}

export type BookbuildFragment = {
	__typename?: 'Bookbuild'
	id: string
	daysAdtv?: number | null
	upsizeDaysAdtv?: number | null
	volume: number
	price: number
	upsizeVolume?: number | null
	upsizePrice?: number | null
	side: Side
	createdAt: string
	endsAt: string
	startedAt?: string | null
	cancelRequestedAt?: string | null
	cancelRequestedBy?: string | null
	cancelRequestJustification?: string | null
	cancelledAt?: string | null
	cancelledJustification?: string | null
	failedAt?: string | null
	failedBy?: string | null
	failedJustification?: string | null
	endedAt?: string | null
	executingAt?: string | null
	status: Status
	referencePrice: number
	clientOrderId?: string | null
	strategy: DealStrategy
	notionalUsd: number
	notionalGbp: number
	notionalCash: number
	instrumentPrimaryAdv?: number | null
	targetTariff?: number | null
	upsizeTariff?: number | null
	captureDownsized: boolean
	instrument: {
		__typename?: 'Instrument'
		id: string
		isin: string
		marketID: string
		currency: string
		description: string
		sedol: string
		primaryBloombergTicker: string
		referencePrice: number
		averageDailyVolume?: number | null
		preTradeLISValueCurrency: number
		marketName: string
		staticRefPriceCollarPercent: number
	}
	distribution: {
		__typename?: 'BookbuildDistribution'
		priceDiscovery: boolean
		liveWatchlists: boolean
		passiveInterest: boolean
		directOrgIds?: Array<string> | null
		excludedOrgIds: Array<string>
	}
	allocability: Array<{
		__typename?: 'BookbuildAllocability'
		id: string
		date: string
		demandPercent: number
		allocablePercent: number
		totalOrders: number
		dealPrice?: number | null
		dealVolume?: number | null
		allocations?: string | null
		downsized: boolean
	}>
	reach: {
		__typename?: 'BookbuildReach'
		priceDiscoveryFirms: number
		liveWatchlistsFirms: number
		passiveInterestFirms: number
		directFirms: number
	}
	deal?: {
		__typename?: 'Deal'
		id: string
		price: number
		volume: number
		daysAdtv?: number | null
		totalOrders: number
		referencePrice: number
		cashGross: number
		createdAt: string
		completedAt?: string | null
		tariff?: number | null
	} | null
	broker: { __typename?: 'BrokerPublic'; id: string; name: string }
	entity: { __typename?: 'Entity'; id: string; name: string }
	brokerLimitViolation?: {
		__typename?: 'BrokerLimitViolation'
		currentExposureUsd: number
		potentialExposureUsd: number
		allocatedOrderExposureUsd?: number | null
		currentExposureLimitPercentage: number
		potentialExposureLimitPercentage: number
		allocatedOrderExposureLimitPercentage?: number | null
		notionalUsd: number
		entityBroker: {
			__typename?: 'EntityBroker'
			limitUsd: number
			broker: { __typename?: 'Broker'; id: string; name: string }
		}
	} | null
}

export type DealFragment = {
	__typename?: 'Deal'
	id: string
	price: number
	volume: number
	daysAdtv?: number | null
	cashGross: number
	referencePrice: number
	createdAt: string
	completedAt?: string | null
	bookbuild: {
		__typename?: 'BookbuildPublic'
		id: string
		side: Side
		endedAt?: string | null
		instrument: {
			__typename?: 'Instrument'
			id: string
			isin: string
			marketID: string
			currency: string
			description: string
			sedol: string
			primaryBloombergTicker: string
			referencePrice: number
			averageDailyVolume?: number | null
			preTradeLISValueCurrency: number
			marketName: string
			staticRefPriceCollarPercent: number
		}
	}
	allocations: Array<{
		__typename?: 'Allocation'
		id: string
		side: Side
		allocation: number
		grossCash: number
		createdAt: string
		scoreTime?: number | null
		scoreVwap?: number | null
		tariff?: number | null
		organization?: {
			__typename?: 'Organization'
			id: string
			name: string
		} | null
		counterparty?: {
			__typename?: 'Organization'
			id: string
			name: string
		} | null
		deal: {
			__typename?: 'Deal'
			id: string
			completedAt?: string | null
			referencePrice: number
			price: number
			volume: number
			daysAdtv?: number | null
			cashGross: number
		}
	}>
}

export type AllocationFragment = {
	__typename?: 'Allocation'
	id: string
	side: Side
	allocation: number
	grossCash: number
	createdAt: string
	scoreTime?: number | null
	scoreVwap?: number | null
	tariff?: number | null
	organization?: {
		__typename?: 'Organization'
		id: string
		name: string
	} | null
	counterparty?: {
		__typename?: 'Organization'
		id: string
		name: string
	} | null
	deal: {
		__typename?: 'Deal'
		id: string
		completedAt?: string | null
		referencePrice: number
		price: number
		volume: number
		daysAdtv?: number | null
		cashGross: number
	}
}

export type LiveWatchlistItemFragment = {
	__typename?: 'LiveWatchlistItem'
	id: string
	createdAt: string
	side: Side
	volume?: number | null
	price?: number | null
	currency?: string | null
	hasLivePriceDiscovery: boolean
	hasLivePriceDiscoveryOpportunity: boolean
	user: { __typename?: 'User'; id: string; name: string }
	instrument: {
		__typename?: 'Instrument'
		id: string
		description: string
		marketName: string
		averageDailyVolume?: number | null
		primaryBloombergTicker: string
		currency: string
		holdings: {
			__typename?: 'InstrumentHoldings'
			fuzzyTotalAmount: string
			holders: Array<{
				__typename?: 'Holder'
				fuzzyHoldingAmount: string
				trend: HoldingTrend
				reportDate?: string | null
				valueChangedADVPercent: number
				valueChangedUSD: number
				organization: {
					__typename?: 'OrganizationPublic'
					id: string
					name: string
					category: OrgCategory
					exclude: boolean
					recentlyAdded: boolean
				}
			}>
		}
	}
	distributionScore?: {
		__typename?: 'DistributionScore'
		totalContrasCount: number
		suggestedContrasCount: number
		suggestDistribution: boolean
	} | null
}

export type BookbuildPublicFragment = {
	__typename?: 'BookbuildPublic'
	id: string
	side: Side
	daysAdtv?: number | null
	volume: number
	startedAt?: string | null
	endsAt: string
	endedAt?: string | null
	cancelledAt?: string | null
	failedAt?: string | null
	executingAt?: string | null
	referencePrice: number
	instrumentPrimaryAdv?: number | null
	instrument: {
		__typename?: 'Instrument'
		id: string
		isin: string
		marketID: string
		currency: string
		description: string
		sedol: string
		primaryBloombergTicker: string
		referencePrice: number
		averageDailyVolume?: number | null
		preTradeLISValueCurrency: number
		marketName: string
		staticRefPriceCollarPercent: number
	}
	allocability: Array<{
		__typename?: 'BookbuildAllocability'
		allocablePercent: number
		demandPercent: number
	}>
}

export type LiveWatchlistEntryFragment = {
	__typename?: 'LiveWatchlistEntry'
	id: string
	type: WatchlistEntryType
	timestamp: string
	side: Side
	volume?: number | null
	previousVolume?: number | null
	price?: number | null
	currency: string
	daysAdtv?: number | null
	notionalUsd?: number | null
	orgId: string
	orgName: string
	userId: string
	userName: string
	instrumentId: string
	instrumentDescription: string
	instrumentRank: number
	instrumentMarketName: string
	instrumentMarketCapUsdM?: number | null
	instrumentAverageDailyVolume?: number | null
	instrumentReferencePrice?: number | null
	currentContraCount: number
	recentContraCount: number
	distributionScore?: {
		__typename?: 'DistributionScore'
		score: number
	} | null
}

export type OpportunityOrderFragment = {
	__typename?: 'Order'
	id: string
	amendedAt?: string | null
	cancelledAt?: string | null
	score?: OrderScore | null
	clientOrderId?: string | null
	volume: number
	price: number
	upsizeVolume?: number | null
	upsizePrice?: number | null
	allocation?: {
		__typename?: 'Allocation'
		id: string
		side: Side
		allocation: number
		grossCash: number
		createdAt: string
		scoreTime?: number | null
		scoreVwap?: number | null
		tariff?: number | null
		organization?: {
			__typename?: 'Organization'
			id: string
			name: string
		} | null
		counterparty?: {
			__typename?: 'Organization'
			id: string
			name: string
		} | null
		deal: {
			__typename?: 'Deal'
			id: string
			completedAt?: string | null
			referencePrice: number
			price: number
			volume: number
			daysAdtv?: number | null
			cashGross: number
		}
	} | null
	broker: { __typename?: 'BrokerPublic'; id: string; name: string }
	entity: { __typename?: 'Entity'; id: string; name: string }
}

export type OpportunityFragment = {
	__typename?: 'Opportunity'
	id: string
	directed: boolean
	interested?: boolean | null
	matchedBy?: OpportunityInterest | null
	status: Status
	bookbuild: {
		__typename?: 'BookbuildPublic'
		id: string
		side: Side
		daysAdtv?: number | null
		volume: number
		startedAt?: string | null
		endsAt: string
		endedAt?: string | null
		cancelledAt?: string | null
		failedAt?: string | null
		executingAt?: string | null
		referencePrice: number
		instrumentPrimaryAdv?: number | null
		instrument: {
			__typename?: 'Instrument'
			id: string
			isin: string
			marketID: string
			currency: string
			description: string
			sedol: string
			primaryBloombergTicker: string
			referencePrice: number
			averageDailyVolume?: number | null
			preTradeLISValueCurrency: number
			marketName: string
			staticRefPriceCollarPercent: number
		}
		allocability: Array<{
			__typename?: 'BookbuildAllocability'
			allocablePercent: number
			demandPercent: number
		}>
	}
	order?: {
		__typename?: 'Order'
		id: string
		amendedAt?: string | null
		cancelledAt?: string | null
		score?: OrderScore | null
		clientOrderId?: string | null
		volume: number
		price: number
		upsizeVolume?: number | null
		upsizePrice?: number | null
		allocation?: {
			__typename?: 'Allocation'
			id: string
			side: Side
			allocation: number
			grossCash: number
			createdAt: string
			scoreTime?: number | null
			scoreVwap?: number | null
			tariff?: number | null
			organization?: {
				__typename?: 'Organization'
				id: string
				name: string
			} | null
			counterparty?: {
				__typename?: 'Organization'
				id: string
				name: string
			} | null
			deal: {
				__typename?: 'Deal'
				id: string
				completedAt?: string | null
				referencePrice: number
				price: number
				volume: number
				daysAdtv?: number | null
				cashGross: number
			}
		} | null
		broker: { __typename?: 'BrokerPublic'; id: string; name: string }
		entity: { __typename?: 'Entity'; id: string; name: string }
	} | null
	liveWatchlistMatches?: Array<{
		__typename?: 'WatchlistItem'
		id: string
	}> | null
	passiveInterestMatches?: Array<{
		__typename?: 'OpportunityMatchItem'
		id: string
	}> | null
}

export type NotificationFragment = {
	__typename?: 'Notification'
	id: string
	createdAt: string
	readAt?: string | null
	url: string
	title: string
	description: string
	sticky: boolean
}

export type OrderFragment = {
	__typename?: 'Order'
	id: string
	createdAt: string
	amendedAt?: string | null
	cancelledAt?: string | null
	score?: OrderScore | null
	clientOrderId?: string | null
	volume: number
	price: number
	upsizeVolume?: number | null
	upsizePrice?: number | null
	status: Status
	notionalUsd: number
	notionalGbp: number
	notionalCash: number
	upsizeNotionalUsd?: number | null
	upsizeNotionalGbp?: number | null
	upsizeNotionalCash?: number | null
	daysAdtv?: number | null
	upsizeDaysAdtv?: number | null
	targetTariff?: number | null
	upsizeTariff?: number | null
	bookbuild: {
		__typename?: 'BookbuildPublic'
		instrumentPrimaryAdv?: number | null
		id: string
		side: Side
		daysAdtv?: number | null
		volume: number
		startedAt?: string | null
		endsAt: string
		endedAt?: string | null
		cancelledAt?: string | null
		failedAt?: string | null
		executingAt?: string | null
		referencePrice: number
		instrument: {
			__typename?: 'Instrument'
			id: string
			isin: string
			marketID: string
			currency: string
			description: string
			sedol: string
			primaryBloombergTicker: string
			referencePrice: number
			averageDailyVolume?: number | null
			preTradeLISValueCurrency: number
			marketName: string
			staticRefPriceCollarPercent: number
		}
		allocability: Array<{
			__typename?: 'BookbuildAllocability'
			allocablePercent: number
			demandPercent: number
		}>
	}
	allocation?: {
		__typename?: 'Allocation'
		id: string
		side: Side
		allocation: number
		grossCash: number
		createdAt: string
		scoreTime?: number | null
		scoreVwap?: number | null
		tariff?: number | null
		organization?: {
			__typename?: 'Organization'
			id: string
			name: string
		} | null
		counterparty?: {
			__typename?: 'Organization'
			id: string
			name: string
		} | null
		deal: {
			__typename?: 'Deal'
			id: string
			completedAt?: string | null
			referencePrice: number
			price: number
			volume: number
			daysAdtv?: number | null
			cashGross: number
		}
	} | null
	broker: { __typename?: 'BrokerPublic'; id: string; name: string }
	brokerLimitViolation?: {
		__typename?: 'BrokerLimitViolation'
		currentExposureUsd: number
		potentialExposureUsd: number
		allocatedOrderExposureUsd?: number | null
		currentExposureLimitPercentage: number
		potentialExposureLimitPercentage: number
		allocatedOrderExposureLimitPercentage?: number | null
		entityBroker: {
			__typename?: 'EntityBroker'
			broker: { __typename?: 'Broker'; id: string; name: string }
		}
	} | null
}

export type OrganizationFragment = {
	__typename?: 'Organization'
	id: string
	name: string
	type: OrgType
	inactivityTimeoutMins: number
	fixCompId?: string | null
	usersCount: number
	category: OrgCategory
	connection?: {
		__typename?: 'Connection'
		id: string
		name: string
		strategy: string
	} | null
	entities: Array<{
		__typename?: 'Entity'
		id: string
		name: string
		lei: string
	}>
	holdingsOwners: Array<{
		__typename?: 'HoldingsOwner'
		id: string
		ownerID: number
		name: string
		holderType: string
		lei: string
		country: string
		city: string
		parentID?: number | null
		parentName?: string | null
		totalHoldings: number
		totalHoldingsNotionalUsd: number
		totalChildren: number
	}>
}

export type HoldingsOwnerFragment = {
	__typename?: 'HoldingsOwner'
	id: string
	ownerID: number
	name: string
	holderType: string
	lei: string
	country: string
	city: string
	parentID?: number | null
	parentName?: string | null
	totalHoldings: number
	totalHoldingsNotionalUsd: number
	totalChildren: number
}

export type OrganizationPublicFragment = {
	__typename?: 'OrganizationPublic'
	id: string
	name: string
	category: OrgCategory
	exclude: boolean
	recentlyAdded: boolean
}

export type ConnectionFragment = {
	__typename?: 'Connection'
	id: string
	name: string
	strategy: string
}

export type FixLogFragment = {
	__typename?: 'FixLog'
	id: string
	recordedAt: string
	direction?: string | null
	session: string
	message: string
}

export type ApiKeyFragment = {
	__typename?: 'APIKey'
	id: string
	createdAt: string
	expiryDate: string
	organization: { __typename?: 'Organization'; id: string; name: string }
}

export type InstrumentFragment = {
	__typename?: 'Instrument'
	id: string
	isin: string
	marketID: string
	currency: string
	description: string
	sedol: string
	primaryBloombergTicker: string
	referencePrice: number
	averageDailyVolume?: number | null
	preTradeLISValueCurrency: number
	marketName: string
	staticRefPriceCollarPercent: number
}

export type HolderFragment = {
	__typename?: 'Holder'
	fuzzyHoldingAmount: string
	trend: HoldingTrend
	reportDate?: string | null
	valueChangedUSD: number
	valueChangedADVPercent: number
	organization: {
		__typename?: 'OrganizationPublic'
		id: string
		name: string
		category: OrgCategory
		exclude: boolean
		recentlyAdded: boolean
	}
}

export type TradeFragment = {
	__typename?: 'Trade'
	id: string
	crossId: string
	createdAt: string
	sentAt?: string | null
	acknowledgedAt?: string | null
	executedAt?: string | null
	rejectedAt?: string | null
	rejectedReason?: string | null
	failedAt?: string | null
	volume: number
	grossCash: number
	organization: { __typename?: 'Organization'; id: string; name: string }
}

export type CountryFragment = {
	__typename?: 'Country'
	id: string
	name: string
}

export type EmsFragment = {
	__typename?: 'EmsSession'
	id: string
	createdAt: string
	updatedAt: string
	name: string
	ems: string
	sessionType: FixSessionType
	beginString: FixBeginString
	senderCompID: string
	targetCompID: string
	socketAddressHost: string
	socketAddressPort: number
	maxMessagesPerSecond: number
	startDay?: string | null
	startTime: string
	endDay?: string | null
	endTime: string
	timeZone?: string | null
	weekdays: string
	heartbeatIntervalSec?: number | null
	reconnectIntervalSec?: number | null
	tlsPrivateKeyFilepath?: string | null
	tlsCertificateFilepath?: string | null
	tlsCAFilepath?: string | null
	refreshOnLogon: boolean
	resetOnLogon: boolean
	resetOnLogout: boolean
	resetOnDisconnect: boolean
	strategy: EmsSessionStrategy
	supportDistributionPopup: boolean
	technicalSupportLink?: string | null
	ioiSession?: { __typename?: 'IoiSession'; id: string; name: string } | null
	organizations: Array<{ __typename?: 'Organization'; id: string }>
}

export type IoiFragment = {
	__typename?: 'IoiSession'
	id: string
	createdAt: string
	updatedAt: string
	name: string
	ems: string
	sessionType: FixSessionType
	beginString: FixBeginString
	senderCompID: string
	targetCompID: string
	socketConnectHost?: string | null
	socketConnectPort?: number | null
	maxMessagesPerSecond: number
	startDay?: string | null
	endDay?: string | null
	weekdays?: string | null
	startTime: string
	endTime: string
	timeZone?: string | null
	heartbeatIntervalSec?: number | null
	reconnectIntervalSec?: number | null
	refreshOnLogon: boolean
	resetOnLogon: boolean
	resetOnLogout: boolean
	resetOnDisconnect: boolean
	technicalSupportLink?: string | null
	state: SessionState
	tlsSkipVerification: boolean
	tlsCertExpiry?: string | null
	strategy: IoiSessionStrategy
	emsSession?: { __typename?: 'EmsSession'; id: string; name: string } | null
}

export type WatchlistSessionFragment = {
	__typename?: 'WatchlistSession'
	id: string
	createdAt: string
	updatedAt: string
	name: string
	ems: string
	sessionType: FixSessionType
	beginString: FixBeginString
	senderCompID: string
	targetCompID: string
	socketConnectHost?: string | null
	socketConnectPort?: number | null
	maxMessagesPerSecond: number
	startDay?: string | null
	startTime: string
	endDay?: string | null
	endTime: string
	timeZone: string
	weekdays?: string | null
	heartbeatIntervalSec?: number | null
	reconnectIntervalSec?: number | null
	refreshOnLogon: boolean
	resetOnLogon: boolean
	resetOnLogout: boolean
	resetOnDisconnect: boolean
	state: SessionState
	technicalSupportLink?: string | null
	organizations: Array<{ __typename?: 'Organization'; id: string }>
}

export type TrendingEquitiesListFragment = {
	__typename?: 'TrendingEquitiesList'
	id: string
	createdAt: string
	updatedAt: string
	endedAt?: string | null
	active: boolean
	emailSentAt?: string | null
	emailSentBy?: { __typename?: 'User'; id: string; name: string } | null
	participants?: Array<{
		__typename?: 'Organization'
		id: string
		name: string
	}> | null
	instruments: Array<{
		__typename?: 'TrendingEquitiesInstrument'
		id: string
		state?: TrendingInstrumentState | null
		changedAt?: string | null
		changedBy?: { __typename?: 'User'; id: string; name: string } | null
		instrument: {
			__typename?: 'Instrument'
			id: string
			description: string
			marketName: string
			primaryBloombergTicker: string
		}
		responses: Array<{
			__typename?: 'TrendingEquitiesResponse'
			id: string
			createdAt: string
			updatedAt: string
			side: Side
			activityType: ActivityType
			user: { __typename?: 'User'; id: string; name: string }
			organization: {
				__typename?: 'Organization'
				id: string
				name: string
			}
		}>
	}>
}

export type UserEventsFragment = {
	__typename?: 'UserEvents'
	total: number
	types: Array<string>
	items: Array<{
		__typename?: 'UserEvent'
		id: string
		eventTime: string
		eventType: string
		eventSource: string
		eventProperties?: unknown | null
		user: { __typename?: 'User'; id: string; name: string }
	}>
}

export type CreateBookbuildMutationVariables = Exact<{
	bookbuild: CreateBookbuildInput
}>

export type CreateBookbuildMutation = {
	__typename?: 'Mutation'
	createBookbuild: {
		__typename?: 'Bookbuild'
		id: string
		daysAdtv?: number | null
		upsizeDaysAdtv?: number | null
		volume: number
		price: number
		upsizeVolume?: number | null
		upsizePrice?: number | null
		side: Side
		createdAt: string
		endsAt: string
		startedAt?: string | null
		cancelRequestedAt?: string | null
		cancelRequestedBy?: string | null
		cancelRequestJustification?: string | null
		cancelledAt?: string | null
		cancelledJustification?: string | null
		failedAt?: string | null
		failedBy?: string | null
		failedJustification?: string | null
		endedAt?: string | null
		executingAt?: string | null
		status: Status
		referencePrice: number
		clientOrderId?: string | null
		strategy: DealStrategy
		notionalUsd: number
		notionalGbp: number
		notionalCash: number
		instrumentPrimaryAdv?: number | null
		targetTariff?: number | null
		upsizeTariff?: number | null
		captureDownsized: boolean
		instrument: {
			__typename?: 'Instrument'
			id: string
			isin: string
			marketID: string
			currency: string
			description: string
			sedol: string
			primaryBloombergTicker: string
			referencePrice: number
			averageDailyVolume?: number | null
			preTradeLISValueCurrency: number
			marketName: string
			staticRefPriceCollarPercent: number
		}
		distribution: {
			__typename?: 'BookbuildDistribution'
			priceDiscovery: boolean
			liveWatchlists: boolean
			passiveInterest: boolean
			directOrgIds?: Array<string> | null
			excludedOrgIds: Array<string>
		}
		allocability: Array<{
			__typename?: 'BookbuildAllocability'
			id: string
			date: string
			demandPercent: number
			allocablePercent: number
			totalOrders: number
			dealPrice?: number | null
			dealVolume?: number | null
			allocations?: string | null
			downsized: boolean
		}>
		reach: {
			__typename?: 'BookbuildReach'
			priceDiscoveryFirms: number
			liveWatchlistsFirms: number
			passiveInterestFirms: number
			directFirms: number
		}
		deal?: {
			__typename?: 'Deal'
			id: string
			price: number
			volume: number
			daysAdtv?: number | null
			totalOrders: number
			referencePrice: number
			cashGross: number
			createdAt: string
			completedAt?: string | null
			tariff?: number | null
		} | null
		broker: { __typename?: 'BrokerPublic'; id: string; name: string }
		entity: { __typename?: 'Entity'; id: string; name: string }
		brokerLimitViolation?: {
			__typename?: 'BrokerLimitViolation'
			currentExposureUsd: number
			potentialExposureUsd: number
			allocatedOrderExposureUsd?: number | null
			currentExposureLimitPercentage: number
			potentialExposureLimitPercentage: number
			allocatedOrderExposureLimitPercentage?: number | null
			notionalUsd: number
			entityBroker: {
				__typename?: 'EntityBroker'
				limitUsd: number
				broker: { __typename?: 'Broker'; id: string; name: string }
			}
		} | null
	}
}

export type UpdateBookbuildSensitivityMutationVariables = Exact<{
	id: Scalars['ID']['input']
	input: UpdateBookbuildSensitivityInput
}>

export type UpdateBookbuildSensitivityMutation = {
	__typename?: 'Mutation'
	updateBookbuildSensitivity: {
		__typename?: 'Bookbuild'
		id: string
		daysAdtv?: number | null
		upsizeDaysAdtv?: number | null
		volume: number
		price: number
		upsizeVolume?: number | null
		upsizePrice?: number | null
		side: Side
		createdAt: string
		endsAt: string
		startedAt?: string | null
		cancelRequestedAt?: string | null
		cancelRequestedBy?: string | null
		cancelRequestJustification?: string | null
		cancelledAt?: string | null
		cancelledJustification?: string | null
		failedAt?: string | null
		failedBy?: string | null
		failedJustification?: string | null
		endedAt?: string | null
		executingAt?: string | null
		status: Status
		referencePrice: number
		clientOrderId?: string | null
		strategy: DealStrategy
		notionalUsd: number
		notionalGbp: number
		notionalCash: number
		instrumentPrimaryAdv?: number | null
		targetTariff?: number | null
		upsizeTariff?: number | null
		captureDownsized: boolean
		instrument: {
			__typename?: 'Instrument'
			id: string
			isin: string
			marketID: string
			currency: string
			description: string
			sedol: string
			primaryBloombergTicker: string
			referencePrice: number
			averageDailyVolume?: number | null
			preTradeLISValueCurrency: number
			marketName: string
			staticRefPriceCollarPercent: number
		}
		distribution: {
			__typename?: 'BookbuildDistribution'
			priceDiscovery: boolean
			liveWatchlists: boolean
			passiveInterest: boolean
			directOrgIds?: Array<string> | null
			excludedOrgIds: Array<string>
		}
		allocability: Array<{
			__typename?: 'BookbuildAllocability'
			id: string
			date: string
			demandPercent: number
			allocablePercent: number
			totalOrders: number
			dealPrice?: number | null
			dealVolume?: number | null
			allocations?: string | null
			downsized: boolean
		}>
		reach: {
			__typename?: 'BookbuildReach'
			priceDiscoveryFirms: number
			liveWatchlistsFirms: number
			passiveInterestFirms: number
			directFirms: number
		}
		deal?: {
			__typename?: 'Deal'
			id: string
			price: number
			volume: number
			daysAdtv?: number | null
			totalOrders: number
			referencePrice: number
			cashGross: number
			createdAt: string
			completedAt?: string | null
			tariff?: number | null
		} | null
		broker: { __typename?: 'BrokerPublic'; id: string; name: string }
		entity: { __typename?: 'Entity'; id: string; name: string }
		brokerLimitViolation?: {
			__typename?: 'BrokerLimitViolation'
			currentExposureUsd: number
			potentialExposureUsd: number
			allocatedOrderExposureUsd?: number | null
			currentExposureLimitPercentage: number
			potentialExposureLimitPercentage: number
			allocatedOrderExposureLimitPercentage?: number | null
			notionalUsd: number
			entityBroker: {
				__typename?: 'EntityBroker'
				limitUsd: number
				broker: { __typename?: 'Broker'; id: string; name: string }
			}
		} | null
	}
}

export type CancelBookbuildMutationVariables = Exact<{
	id: Scalars['ID']['input']
	justification: Scalars['String']['input']
}>

export type CancelBookbuildMutation = {
	__typename?: 'Mutation'
	cancelBookbuild: {
		__typename?: 'Bookbuild'
		id: string
		daysAdtv?: number | null
		upsizeDaysAdtv?: number | null
		volume: number
		price: number
		upsizeVolume?: number | null
		upsizePrice?: number | null
		side: Side
		createdAt: string
		endsAt: string
		startedAt?: string | null
		cancelRequestedAt?: string | null
		cancelRequestedBy?: string | null
		cancelRequestJustification?: string | null
		cancelledAt?: string | null
		cancelledJustification?: string | null
		failedAt?: string | null
		failedBy?: string | null
		failedJustification?: string | null
		endedAt?: string | null
		executingAt?: string | null
		status: Status
		referencePrice: number
		clientOrderId?: string | null
		strategy: DealStrategy
		notionalUsd: number
		notionalGbp: number
		notionalCash: number
		instrumentPrimaryAdv?: number | null
		targetTariff?: number | null
		upsizeTariff?: number | null
		captureDownsized: boolean
		instrument: {
			__typename?: 'Instrument'
			id: string
			isin: string
			marketID: string
			currency: string
			description: string
			sedol: string
			primaryBloombergTicker: string
			referencePrice: number
			averageDailyVolume?: number | null
			preTradeLISValueCurrency: number
			marketName: string
			staticRefPriceCollarPercent: number
		}
		distribution: {
			__typename?: 'BookbuildDistribution'
			priceDiscovery: boolean
			liveWatchlists: boolean
			passiveInterest: boolean
			directOrgIds?: Array<string> | null
			excludedOrgIds: Array<string>
		}
		allocability: Array<{
			__typename?: 'BookbuildAllocability'
			id: string
			date: string
			demandPercent: number
			allocablePercent: number
			totalOrders: number
			dealPrice?: number | null
			dealVolume?: number | null
			allocations?: string | null
			downsized: boolean
		}>
		reach: {
			__typename?: 'BookbuildReach'
			priceDiscoveryFirms: number
			liveWatchlistsFirms: number
			passiveInterestFirms: number
			directFirms: number
		}
		deal?: {
			__typename?: 'Deal'
			id: string
			price: number
			volume: number
			daysAdtv?: number | null
			totalOrders: number
			referencePrice: number
			cashGross: number
			createdAt: string
			completedAt?: string | null
			tariff?: number | null
		} | null
		broker: { __typename?: 'BrokerPublic'; id: string; name: string }
		entity: { __typename?: 'Entity'; id: string; name: string }
		brokerLimitViolation?: {
			__typename?: 'BrokerLimitViolation'
			currentExposureUsd: number
			potentialExposureUsd: number
			allocatedOrderExposureUsd?: number | null
			currentExposureLimitPercentage: number
			potentialExposureLimitPercentage: number
			allocatedOrderExposureLimitPercentage?: number | null
			notionalUsd: number
			entityBroker: {
				__typename?: 'EntityBroker'
				limitUsd: number
				broker: { __typename?: 'Broker'; id: string; name: string }
			}
		} | null
	}
}

export type DistributeAndCommitBookbuildMutationVariables = Exact<{
	id: Scalars['ID']['input']
	distribution: DistributeAndCommitBookbuildInput
}>

export type DistributeAndCommitBookbuildMutation = {
	__typename?: 'Mutation'
	distributeAndCommitBookbuild: {
		__typename?: 'Bookbuild'
		id: string
		daysAdtv?: number | null
		upsizeDaysAdtv?: number | null
		volume: number
		price: number
		upsizeVolume?: number | null
		upsizePrice?: number | null
		side: Side
		createdAt: string
		endsAt: string
		startedAt?: string | null
		cancelRequestedAt?: string | null
		cancelRequestedBy?: string | null
		cancelRequestJustification?: string | null
		cancelledAt?: string | null
		cancelledJustification?: string | null
		failedAt?: string | null
		failedBy?: string | null
		failedJustification?: string | null
		endedAt?: string | null
		executingAt?: string | null
		status: Status
		referencePrice: number
		clientOrderId?: string | null
		strategy: DealStrategy
		notionalUsd: number
		notionalGbp: number
		notionalCash: number
		instrumentPrimaryAdv?: number | null
		targetTariff?: number | null
		upsizeTariff?: number | null
		captureDownsized: boolean
		instrument: {
			__typename?: 'Instrument'
			id: string
			isin: string
			marketID: string
			currency: string
			description: string
			sedol: string
			primaryBloombergTicker: string
			referencePrice: number
			averageDailyVolume?: number | null
			preTradeLISValueCurrency: number
			marketName: string
			staticRefPriceCollarPercent: number
		}
		distribution: {
			__typename?: 'BookbuildDistribution'
			priceDiscovery: boolean
			liveWatchlists: boolean
			passiveInterest: boolean
			directOrgIds?: Array<string> | null
			excludedOrgIds: Array<string>
		}
		allocability: Array<{
			__typename?: 'BookbuildAllocability'
			id: string
			date: string
			demandPercent: number
			allocablePercent: number
			totalOrders: number
			dealPrice?: number | null
			dealVolume?: number | null
			allocations?: string | null
			downsized: boolean
		}>
		reach: {
			__typename?: 'BookbuildReach'
			priceDiscoveryFirms: number
			liveWatchlistsFirms: number
			passiveInterestFirms: number
			directFirms: number
		}
		deal?: {
			__typename?: 'Deal'
			id: string
			price: number
			volume: number
			daysAdtv?: number | null
			totalOrders: number
			referencePrice: number
			cashGross: number
			createdAt: string
			completedAt?: string | null
			tariff?: number | null
		} | null
		broker: { __typename?: 'BrokerPublic'; id: string; name: string }
		entity: { __typename?: 'Entity'; id: string; name: string }
		brokerLimitViolation?: {
			__typename?: 'BrokerLimitViolation'
			currentExposureUsd: number
			potentialExposureUsd: number
			allocatedOrderExposureUsd?: number | null
			currentExposureLimitPercentage: number
			potentialExposureLimitPercentage: number
			allocatedOrderExposureLimitPercentage?: number | null
			notionalUsd: number
			entityBroker: {
				__typename?: 'EntityBroker'
				limitUsd: number
				broker: { __typename?: 'Broker'; id: string; name: string }
			}
		} | null
	}
}

export type AdminCancelBookbuildMutationVariables = Exact<{
	id: Scalars['ID']['input']
	justification: Scalars['String']['input']
}>

export type AdminCancelBookbuildMutation = {
	__typename?: 'Mutation'
	adminCancelBookbuild: {
		__typename?: 'Bookbuild'
		id: string
		daysAdtv?: number | null
		upsizeDaysAdtv?: number | null
		volume: number
		price: number
		upsizeVolume?: number | null
		upsizePrice?: number | null
		side: Side
		createdAt: string
		endsAt: string
		startedAt?: string | null
		cancelRequestedAt?: string | null
		cancelRequestedBy?: string | null
		cancelRequestJustification?: string | null
		cancelledAt?: string | null
		cancelledJustification?: string | null
		failedAt?: string | null
		failedBy?: string | null
		failedJustification?: string | null
		endedAt?: string | null
		executingAt?: string | null
		status: Status
		referencePrice: number
		clientOrderId?: string | null
		strategy: DealStrategy
		notionalUsd: number
		notionalGbp: number
		notionalCash: number
		instrumentPrimaryAdv?: number | null
		targetTariff?: number | null
		upsizeTariff?: number | null
		captureDownsized: boolean
		instrument: {
			__typename?: 'Instrument'
			id: string
			isin: string
			marketID: string
			currency: string
			description: string
			sedol: string
			primaryBloombergTicker: string
			referencePrice: number
			averageDailyVolume?: number | null
			preTradeLISValueCurrency: number
			marketName: string
			staticRefPriceCollarPercent: number
		}
		distribution: {
			__typename?: 'BookbuildDistribution'
			priceDiscovery: boolean
			liveWatchlists: boolean
			passiveInterest: boolean
			directOrgIds?: Array<string> | null
			excludedOrgIds: Array<string>
		}
		allocability: Array<{
			__typename?: 'BookbuildAllocability'
			id: string
			date: string
			demandPercent: number
			allocablePercent: number
			totalOrders: number
			dealPrice?: number | null
			dealVolume?: number | null
			allocations?: string | null
			downsized: boolean
		}>
		reach: {
			__typename?: 'BookbuildReach'
			priceDiscoveryFirms: number
			liveWatchlistsFirms: number
			passiveInterestFirms: number
			directFirms: number
		}
		deal?: {
			__typename?: 'Deal'
			id: string
			price: number
			volume: number
			daysAdtv?: number | null
			totalOrders: number
			referencePrice: number
			cashGross: number
			createdAt: string
			completedAt?: string | null
			tariff?: number | null
		} | null
		broker: { __typename?: 'BrokerPublic'; id: string; name: string }
		entity: { __typename?: 'Entity'; id: string; name: string }
		brokerLimitViolation?: {
			__typename?: 'BrokerLimitViolation'
			currentExposureUsd: number
			potentialExposureUsd: number
			allocatedOrderExposureUsd?: number | null
			currentExposureLimitPercentage: number
			potentialExposureLimitPercentage: number
			allocatedOrderExposureLimitPercentage?: number | null
			notionalUsd: number
			entityBroker: {
				__typename?: 'EntityBroker'
				limitUsd: number
				broker: { __typename?: 'Broker'; id: string; name: string }
			}
		} | null
	}
}

export type AdminFailBookbuildMutationVariables = Exact<{
	id: Scalars['ID']['input']
	justification: Scalars['String']['input']
}>

export type AdminFailBookbuildMutation = {
	__typename?: 'Mutation'
	adminFailBookbuild: {
		__typename?: 'Bookbuild'
		id: string
		daysAdtv?: number | null
		upsizeDaysAdtv?: number | null
		volume: number
		price: number
		upsizeVolume?: number | null
		upsizePrice?: number | null
		side: Side
		createdAt: string
		endsAt: string
		startedAt?: string | null
		cancelRequestedAt?: string | null
		cancelRequestedBy?: string | null
		cancelRequestJustification?: string | null
		cancelledAt?: string | null
		cancelledJustification?: string | null
		failedAt?: string | null
		failedBy?: string | null
		failedJustification?: string | null
		endedAt?: string | null
		executingAt?: string | null
		status: Status
		referencePrice: number
		clientOrderId?: string | null
		strategy: DealStrategy
		notionalUsd: number
		notionalGbp: number
		notionalCash: number
		instrumentPrimaryAdv?: number | null
		targetTariff?: number | null
		upsizeTariff?: number | null
		captureDownsized: boolean
		instrument: {
			__typename?: 'Instrument'
			id: string
			isin: string
			marketID: string
			currency: string
			description: string
			sedol: string
			primaryBloombergTicker: string
			referencePrice: number
			averageDailyVolume?: number | null
			preTradeLISValueCurrency: number
			marketName: string
			staticRefPriceCollarPercent: number
		}
		distribution: {
			__typename?: 'BookbuildDistribution'
			priceDiscovery: boolean
			liveWatchlists: boolean
			passiveInterest: boolean
			directOrgIds?: Array<string> | null
			excludedOrgIds: Array<string>
		}
		allocability: Array<{
			__typename?: 'BookbuildAllocability'
			id: string
			date: string
			demandPercent: number
			allocablePercent: number
			totalOrders: number
			dealPrice?: number | null
			dealVolume?: number | null
			allocations?: string | null
			downsized: boolean
		}>
		reach: {
			__typename?: 'BookbuildReach'
			priceDiscoveryFirms: number
			liveWatchlistsFirms: number
			passiveInterestFirms: number
			directFirms: number
		}
		deal?: {
			__typename?: 'Deal'
			id: string
			price: number
			volume: number
			daysAdtv?: number | null
			totalOrders: number
			referencePrice: number
			cashGross: number
			createdAt: string
			completedAt?: string | null
			tariff?: number | null
		} | null
		broker: { __typename?: 'BrokerPublic'; id: string; name: string }
		entity: { __typename?: 'Entity'; id: string; name: string }
		brokerLimitViolation?: {
			__typename?: 'BrokerLimitViolation'
			currentExposureUsd: number
			potentialExposureUsd: number
			allocatedOrderExposureUsd?: number | null
			currentExposureLimitPercentage: number
			potentialExposureLimitPercentage: number
			allocatedOrderExposureLimitPercentage?: number | null
			notionalUsd: number
			entityBroker: {
				__typename?: 'EntityBroker'
				limitUsd: number
				broker: { __typename?: 'Broker'; id: string; name: string }
			}
		} | null
	}
}

export type AdminCompleteBookbuildDealMutationVariables = Exact<{
	bookbuildID: Scalars['ID']['input']
}>

export type AdminCompleteBookbuildDealMutation = {
	__typename?: 'Mutation'
	adminCompleteBookbuildDeal: boolean
}

export type AdminPartialCompleteBookbuildMutationVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type AdminPartialCompleteBookbuildMutation = {
	__typename?: 'Mutation'
	adminPartialCompleteBookbuild: boolean
}

export type AdminSetBookbuildStrategyMutationVariables = Exact<{
	id: Scalars['ID']['input']
	strategy: DealStrategy
}>

export type AdminSetBookbuildStrategyMutation = {
	__typename?: 'Mutation'
	adminSetBookbuildStrategy: {
		__typename?: 'Bookbuild'
		id: string
		daysAdtv?: number | null
		upsizeDaysAdtv?: number | null
		volume: number
		price: number
		upsizeVolume?: number | null
		upsizePrice?: number | null
		side: Side
		createdAt: string
		endsAt: string
		startedAt?: string | null
		cancelRequestedAt?: string | null
		cancelRequestedBy?: string | null
		cancelRequestJustification?: string | null
		cancelledAt?: string | null
		cancelledJustification?: string | null
		failedAt?: string | null
		failedBy?: string | null
		failedJustification?: string | null
		endedAt?: string | null
		executingAt?: string | null
		status: Status
		referencePrice: number
		clientOrderId?: string | null
		strategy: DealStrategy
		notionalUsd: number
		notionalGbp: number
		notionalCash: number
		instrumentPrimaryAdv?: number | null
		targetTariff?: number | null
		upsizeTariff?: number | null
		captureDownsized: boolean
		instrument: {
			__typename?: 'Instrument'
			id: string
			isin: string
			marketID: string
			currency: string
			description: string
			sedol: string
			primaryBloombergTicker: string
			referencePrice: number
			averageDailyVolume?: number | null
			preTradeLISValueCurrency: number
			marketName: string
			staticRefPriceCollarPercent: number
		}
		distribution: {
			__typename?: 'BookbuildDistribution'
			priceDiscovery: boolean
			liveWatchlists: boolean
			passiveInterest: boolean
			directOrgIds?: Array<string> | null
			excludedOrgIds: Array<string>
		}
		allocability: Array<{
			__typename?: 'BookbuildAllocability'
			id: string
			date: string
			demandPercent: number
			allocablePercent: number
			totalOrders: number
			dealPrice?: number | null
			dealVolume?: number | null
			allocations?: string | null
			downsized: boolean
		}>
		reach: {
			__typename?: 'BookbuildReach'
			priceDiscoveryFirms: number
			liveWatchlistsFirms: number
			passiveInterestFirms: number
			directFirms: number
		}
		deal?: {
			__typename?: 'Deal'
			id: string
			price: number
			volume: number
			daysAdtv?: number | null
			totalOrders: number
			referencePrice: number
			cashGross: number
			createdAt: string
			completedAt?: string | null
			tariff?: number | null
		} | null
		broker: { __typename?: 'BrokerPublic'; id: string; name: string }
		entity: { __typename?: 'Entity'; id: string; name: string }
		brokerLimitViolation?: {
			__typename?: 'BrokerLimitViolation'
			currentExposureUsd: number
			potentialExposureUsd: number
			allocatedOrderExposureUsd?: number | null
			currentExposureLimitPercentage: number
			potentialExposureLimitPercentage: number
			allocatedOrderExposureLimitPercentage?: number | null
			notionalUsd: number
			entityBroker: {
				__typename?: 'EntityBroker'
				limitUsd: number
				broker: { __typename?: 'Broker'; id: string; name: string }
			}
		} | null
	}
}

export type AdminSetCaptureDownsizedBookbuildMutationVariables = Exact<{
	id: Scalars['ID']['input']
	captureDownsized: Scalars['Boolean']['input']
}>

export type AdminSetCaptureDownsizedBookbuildMutation = {
	__typename?: 'Mutation'
	adminSetCaptureDownsizedBookbuild: {
		__typename?: 'Bookbuild'
		id: string
		daysAdtv?: number | null
		upsizeDaysAdtv?: number | null
		volume: number
		price: number
		upsizeVolume?: number | null
		upsizePrice?: number | null
		side: Side
		createdAt: string
		endsAt: string
		startedAt?: string | null
		cancelRequestedAt?: string | null
		cancelRequestedBy?: string | null
		cancelRequestJustification?: string | null
		cancelledAt?: string | null
		cancelledJustification?: string | null
		failedAt?: string | null
		failedBy?: string | null
		failedJustification?: string | null
		endedAt?: string | null
		executingAt?: string | null
		status: Status
		referencePrice: number
		clientOrderId?: string | null
		strategy: DealStrategy
		notionalUsd: number
		notionalGbp: number
		notionalCash: number
		instrumentPrimaryAdv?: number | null
		targetTariff?: number | null
		upsizeTariff?: number | null
		captureDownsized: boolean
		instrument: {
			__typename?: 'Instrument'
			id: string
			isin: string
			marketID: string
			currency: string
			description: string
			sedol: string
			primaryBloombergTicker: string
			referencePrice: number
			averageDailyVolume?: number | null
			preTradeLISValueCurrency: number
			marketName: string
			staticRefPriceCollarPercent: number
		}
		distribution: {
			__typename?: 'BookbuildDistribution'
			priceDiscovery: boolean
			liveWatchlists: boolean
			passiveInterest: boolean
			directOrgIds?: Array<string> | null
			excludedOrgIds: Array<string>
		}
		allocability: Array<{
			__typename?: 'BookbuildAllocability'
			id: string
			date: string
			demandPercent: number
			allocablePercent: number
			totalOrders: number
			dealPrice?: number | null
			dealVolume?: number | null
			allocations?: string | null
			downsized: boolean
		}>
		reach: {
			__typename?: 'BookbuildReach'
			priceDiscoveryFirms: number
			liveWatchlistsFirms: number
			passiveInterestFirms: number
			directFirms: number
		}
		deal?: {
			__typename?: 'Deal'
			id: string
			price: number
			volume: number
			daysAdtv?: number | null
			totalOrders: number
			referencePrice: number
			cashGross: number
			createdAt: string
			completedAt?: string | null
			tariff?: number | null
		} | null
		broker: { __typename?: 'BrokerPublic'; id: string; name: string }
		entity: { __typename?: 'Entity'; id: string; name: string }
		brokerLimitViolation?: {
			__typename?: 'BrokerLimitViolation'
			currentExposureUsd: number
			potentialExposureUsd: number
			allocatedOrderExposureUsd?: number | null
			currentExposureLimitPercentage: number
			potentialExposureLimitPercentage: number
			allocatedOrderExposureLimitPercentage?: number | null
			notionalUsd: number
			entityBroker: {
				__typename?: 'EntityBroker'
				limitUsd: number
				broker: { __typename?: 'Broker'; id: string; name: string }
			}
		} | null
	}
}

export type AdminSetBypassBrokerLimitsMutationVariables = Exact<{
	id: Scalars['ID']['input']
	bypassBrokerLimits: Scalars['Boolean']['input']
}>

export type AdminSetBypassBrokerLimitsMutation = {
	__typename?: 'Mutation'
	adminSetBypassBrokerLimits: { __typename?: 'Bookbuild'; id: string }
}

export type CreateOrUpdateProfileMutationVariables = Exact<{
	profile: ProfileInput
}>

export type CreateOrUpdateProfileMutation = {
	__typename?: 'Mutation'
	createOrUpdateProfile: {
		__typename?: 'Profile'
		id: string
		createdAt: string
		updatedAt: string
		marketCapMax?: number | null
		marketCapMin?: number | null
		sectors: Array<{ __typename?: 'Sector'; name: string }>
		countries: Array<{ __typename?: 'Country'; id: string; name: string }>
	}
}

export type AddLiveWatchlistItemMutationVariables = Exact<{
	input: LiveWatchlistItemInput
}>

export type AddLiveWatchlistItemMutation = {
	__typename?: 'Mutation'
	addLiveWatchlistItem: { __typename?: 'LiveWatchlistItem'; id: string }
}

export type DeleteLiveWatchlistItemMutationVariables = Exact<{
	id: Scalars['ID']['input']
	force: Scalars['Boolean']['input']
}>

export type DeleteLiveWatchlistItemMutation = {
	__typename?: 'Mutation'
	deleteLiveWatchlistItem: { __typename?: 'LiveWatchlistItem'; id: string }
}

export type SetLiveWatchlistItemsMutationVariables = Exact<{
	files: Array<Scalars['Upload']['input']> | Scalars['Upload']['input']
}>

export type SetLiveWatchlistItemsMutation = {
	__typename?: 'Mutation'
	setLiveWatchlistItems: {
		__typename?: 'SetWatchlistResponse'
		itemsAddedCount: number
		unsupportedTickers: Array<{
			__typename?: 'UnsupportedTicker'
			ticker: string
		}>
	}
}

export type RequestLiveWatchlistAutomationMutationVariables = Exact<{
	[key: string]: never
}>

export type RequestLiveWatchlistAutomationMutation = {
	__typename?: 'Mutation'
	requestLiveWatchlistAutomation: boolean
}

export type ClearLiveWatchlistItemsMutationVariables = Exact<{
	[key: string]: never
}>

export type ClearLiveWatchlistItemsMutation = {
	__typename?: 'Mutation'
	clearLiveWatchlistItems: boolean
}

export type ClearRecentLiveWatchlistItemsMutationVariables = Exact<{
	[key: string]: never
}>

export type ClearRecentLiveWatchlistItemsMutation = {
	__typename?: 'Mutation'
	clearRecentLiveWatchlistItems: boolean
}

export type AddPassiveWatchlistItemMutationVariables = Exact<{
	input: PassiveWatchlistItemInput
}>

export type AddPassiveWatchlistItemMutation = {
	__typename?: 'Mutation'
	addPassiveWatchlistItem: { __typename?: 'PassiveWatchlistItem'; id: string }
}

export type DeletePassiveWatchlistItemMutationVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type DeletePassiveWatchlistItemMutation = {
	__typename?: 'Mutation'
	deletePassiveWatchlistItem: {
		__typename?: 'PassiveWatchlistItem'
		id: string
	}
}

export type SetPassiveWatchlistItemsMutationVariables = Exact<{
	files: Array<Scalars['Upload']['input']> | Scalars['Upload']['input']
}>

export type SetPassiveWatchlistItemsMutation = {
	__typename?: 'Mutation'
	setPassiveWatchlistItems: {
		__typename?: 'SetWatchlistResponse'
		itemsAddedCount: number
		unsupportedTickers: Array<{
			__typename?: 'UnsupportedTicker'
			ticker: string
		}>
	}
}

export type ClearPassiveWatchlistItemsMutationVariables = Exact<{
	[key: string]: never
}>

export type ClearPassiveWatchlistItemsMutation = {
	__typename?: 'Mutation'
	clearPassiveWatchlistItems: boolean
}

export type SetPassiveWatchlistSettingsMutationVariables = Exact<{
	settings: PassiveWatchlistSettingsInput
}>

export type SetPassiveWatchlistSettingsMutation = {
	__typename?: 'Mutation'
	setPassiveWatchlistSettings: {
		__typename?: 'PassiveWatchlistSettings'
		sellPremiumPercent?: number | null
		buyDiscountPercent?: number | null
		advPercent?: number | null
		autoMigration: boolean
		reactionTimeHours?: number | null
	}
}

export type UpdatePreferencesMutationVariables = Exact<{
	userId: Scalars['ID']['input']
	preferences: PreferencesInput
}>

export type UpdatePreferencesMutation = {
	__typename?: 'Mutation'
	updatePreferences: {
		__typename?: 'UserPreferences'
		opportunitiesView: OpportunitiesView
	}
}

export type CreateOrderMutationVariables = Exact<{
	order: CreateOrderInput
}>

export type CreateOrderMutation = {
	__typename?: 'Mutation'
	createOrder: {
		__typename?: 'Order'
		id: string
		createdAt: string
		amendedAt?: string | null
		cancelledAt?: string | null
		score?: OrderScore | null
		clientOrderId?: string | null
		volume: number
		price: number
		upsizeVolume?: number | null
		upsizePrice?: number | null
		status: Status
		notionalUsd: number
		notionalGbp: number
		notionalCash: number
		upsizeNotionalUsd?: number | null
		upsizeNotionalGbp?: number | null
		upsizeNotionalCash?: number | null
		daysAdtv?: number | null
		upsizeDaysAdtv?: number | null
		targetTariff?: number | null
		upsizeTariff?: number | null
		bookbuild: {
			__typename?: 'BookbuildPublic'
			instrumentPrimaryAdv?: number | null
			id: string
			side: Side
			daysAdtv?: number | null
			volume: number
			startedAt?: string | null
			endsAt: string
			endedAt?: string | null
			cancelledAt?: string | null
			failedAt?: string | null
			executingAt?: string | null
			referencePrice: number
			instrument: {
				__typename?: 'Instrument'
				id: string
				isin: string
				marketID: string
				currency: string
				description: string
				sedol: string
				primaryBloombergTicker: string
				referencePrice: number
				averageDailyVolume?: number | null
				preTradeLISValueCurrency: number
				marketName: string
				staticRefPriceCollarPercent: number
			}
			allocability: Array<{
				__typename?: 'BookbuildAllocability'
				allocablePercent: number
				demandPercent: number
			}>
		}
		allocation?: {
			__typename?: 'Allocation'
			id: string
			side: Side
			allocation: number
			grossCash: number
			createdAt: string
			scoreTime?: number | null
			scoreVwap?: number | null
			tariff?: number | null
			organization?: {
				__typename?: 'Organization'
				id: string
				name: string
			} | null
			counterparty?: {
				__typename?: 'Organization'
				id: string
				name: string
			} | null
			deal: {
				__typename?: 'Deal'
				id: string
				completedAt?: string | null
				referencePrice: number
				price: number
				volume: number
				daysAdtv?: number | null
				cashGross: number
			}
		} | null
		broker: { __typename?: 'BrokerPublic'; id: string; name: string }
		brokerLimitViolation?: {
			__typename?: 'BrokerLimitViolation'
			currentExposureUsd: number
			potentialExposureUsd: number
			allocatedOrderExposureUsd?: number | null
			currentExposureLimitPercentage: number
			potentialExposureLimitPercentage: number
			allocatedOrderExposureLimitPercentage?: number | null
			entityBroker: {
				__typename?: 'EntityBroker'
				broker: { __typename?: 'Broker'; id: string; name: string }
			}
		} | null
	}
}

export type UpdateOrderMutationVariables = Exact<{
	id: Scalars['ID']['input']
	order: UpdateOrderInput
}>

export type UpdateOrderMutation = {
	__typename?: 'Mutation'
	updateOrder: {
		__typename?: 'Order'
		id: string
		createdAt: string
		amendedAt?: string | null
		cancelledAt?: string | null
		score?: OrderScore | null
		clientOrderId?: string | null
		volume: number
		price: number
		upsizeVolume?: number | null
		upsizePrice?: number | null
		status: Status
		notionalUsd: number
		notionalGbp: number
		notionalCash: number
		upsizeNotionalUsd?: number | null
		upsizeNotionalGbp?: number | null
		upsizeNotionalCash?: number | null
		daysAdtv?: number | null
		upsizeDaysAdtv?: number | null
		targetTariff?: number | null
		upsizeTariff?: number | null
		bookbuild: {
			__typename?: 'BookbuildPublic'
			instrumentPrimaryAdv?: number | null
			id: string
			side: Side
			daysAdtv?: number | null
			volume: number
			startedAt?: string | null
			endsAt: string
			endedAt?: string | null
			cancelledAt?: string | null
			failedAt?: string | null
			executingAt?: string | null
			referencePrice: number
			instrument: {
				__typename?: 'Instrument'
				id: string
				isin: string
				marketID: string
				currency: string
				description: string
				sedol: string
				primaryBloombergTicker: string
				referencePrice: number
				averageDailyVolume?: number | null
				preTradeLISValueCurrency: number
				marketName: string
				staticRefPriceCollarPercent: number
			}
			allocability: Array<{
				__typename?: 'BookbuildAllocability'
				allocablePercent: number
				demandPercent: number
			}>
		}
		allocation?: {
			__typename?: 'Allocation'
			id: string
			side: Side
			allocation: number
			grossCash: number
			createdAt: string
			scoreTime?: number | null
			scoreVwap?: number | null
			tariff?: number | null
			organization?: {
				__typename?: 'Organization'
				id: string
				name: string
			} | null
			counterparty?: {
				__typename?: 'Organization'
				id: string
				name: string
			} | null
			deal: {
				__typename?: 'Deal'
				id: string
				completedAt?: string | null
				referencePrice: number
				price: number
				volume: number
				daysAdtv?: number | null
				cashGross: number
			}
		} | null
		broker: { __typename?: 'BrokerPublic'; id: string; name: string }
		brokerLimitViolation?: {
			__typename?: 'BrokerLimitViolation'
			currentExposureUsd: number
			potentialExposureUsd: number
			allocatedOrderExposureUsd?: number | null
			currentExposureLimitPercentage: number
			potentialExposureLimitPercentage: number
			allocatedOrderExposureLimitPercentage?: number | null
			entityBroker: {
				__typename?: 'EntityBroker'
				broker: { __typename?: 'Broker'; id: string; name: string }
			}
		} | null
	}
}

export type CancelOrderMutationVariables = Exact<{
	id: Scalars['ID']['input']
	justification: Scalars['String']['input']
}>

export type CancelOrderMutation = {
	__typename?: 'Mutation'
	cancelOrder: {
		__typename?: 'Order'
		id: string
		createdAt: string
		amendedAt?: string | null
		cancelledAt?: string | null
		score?: OrderScore | null
		clientOrderId?: string | null
		volume: number
		price: number
		upsizeVolume?: number | null
		upsizePrice?: number | null
		status: Status
		notionalUsd: number
		notionalGbp: number
		notionalCash: number
		upsizeNotionalUsd?: number | null
		upsizeNotionalGbp?: number | null
		upsizeNotionalCash?: number | null
		daysAdtv?: number | null
		upsizeDaysAdtv?: number | null
		targetTariff?: number | null
		upsizeTariff?: number | null
		bookbuild: {
			__typename?: 'BookbuildPublic'
			instrumentPrimaryAdv?: number | null
			id: string
			side: Side
			daysAdtv?: number | null
			volume: number
			startedAt?: string | null
			endsAt: string
			endedAt?: string | null
			cancelledAt?: string | null
			failedAt?: string | null
			executingAt?: string | null
			referencePrice: number
			instrument: {
				__typename?: 'Instrument'
				id: string
				isin: string
				marketID: string
				currency: string
				description: string
				sedol: string
				primaryBloombergTicker: string
				referencePrice: number
				averageDailyVolume?: number | null
				preTradeLISValueCurrency: number
				marketName: string
				staticRefPriceCollarPercent: number
			}
			allocability: Array<{
				__typename?: 'BookbuildAllocability'
				allocablePercent: number
				demandPercent: number
			}>
		}
		allocation?: {
			__typename?: 'Allocation'
			id: string
			side: Side
			allocation: number
			grossCash: number
			createdAt: string
			scoreTime?: number | null
			scoreVwap?: number | null
			tariff?: number | null
			organization?: {
				__typename?: 'Organization'
				id: string
				name: string
			} | null
			counterparty?: {
				__typename?: 'Organization'
				id: string
				name: string
			} | null
			deal: {
				__typename?: 'Deal'
				id: string
				completedAt?: string | null
				referencePrice: number
				price: number
				volume: number
				daysAdtv?: number | null
				cashGross: number
			}
		} | null
		broker: { __typename?: 'BrokerPublic'; id: string; name: string }
		brokerLimitViolation?: {
			__typename?: 'BrokerLimitViolation'
			currentExposureUsd: number
			potentialExposureUsd: number
			allocatedOrderExposureUsd?: number | null
			currentExposureLimitPercentage: number
			potentialExposureLimitPercentage: number
			allocatedOrderExposureLimitPercentage?: number | null
			entityBroker: {
				__typename?: 'EntityBroker'
				broker: { __typename?: 'Broker'; id: string; name: string }
			}
		} | null
	}
}

export type MarkAllNotificationsAsReadMutationVariables = Exact<{
	[key: string]: never
}>

export type MarkAllNotificationsAsReadMutation = {
	__typename?: 'Mutation'
	markAllNotificationsAsRead: boolean
}

export type MarkNotificationsAsReadMutationVariables = Exact<{
	ids: Array<Scalars['ID']['input']> | Scalars['ID']['input']
}>

export type MarkNotificationsAsReadMutation = {
	__typename?: 'Mutation'
	markNotificationsAsRead: Array<{ __typename?: 'Notification'; id: string }>
}

export type UpdateUserMutationVariables = Exact<{
	user: UpdateUserInput
}>

export type UpdateUserMutation = {
	__typename?: 'Mutation'
	updateUser: {
		__typename?: 'User'
		id: string
		email: string
		disabled: boolean
		sessionId: number
		name: string
		minFirmsDistribution: number
		auth0Id?: string | null
		roles: Array<{ __typename?: 'Role'; id: string }>
		organization: {
			__typename?: 'Organization'
			id: string
			name: string
			type: OrgType
			inactivityTimeoutMins: number
		}
		entities: Array<{
			__typename?: 'UserEntity'
			isDefault: boolean
			entity: {
				__typename?: 'Entity'
				id: string
				name: string
				lei: string
				defaultBroker: {
					__typename?: 'Broker'
					id: string
					name: string
				}
				brokers: Array<{
					__typename?: 'EntityBroker'
					broker: { __typename?: 'Broker'; id: string; name: string }
				}>
			}
		}>
		preferences: {
			__typename?: 'UserPreferences'
			opportunitiesView: OpportunitiesView
			opportunitiesSort?: OpportunitiesSort | null
		}
		exclusions: Array<{
			__typename?: 'OrganizationPublic'
			id: string
			name: string
			category: OrgCategory
			exclude: boolean
			recentlyAdded: boolean
		}>
	}
}

export type CreateUserMutationVariables = Exact<{
	user: CreateUserInput
}>

export type CreateUserMutation = {
	__typename?: 'Mutation'
	createUser: { __typename?: 'User'; id: string }
}

export type AdminUpdateUserMutationVariables = Exact<{
	userId: Scalars['ID']['input']
	input: AdminUpdateUserInput
	roleIds: Array<Scalars['ID']['input']> | Scalars['ID']['input']
	preferences: PreferencesInput
}>

export type AdminUpdateUserMutation = {
	__typename?: 'Mutation'
	adminUpdateUser: { __typename?: 'User'; id: string }
	updateRoles: { __typename?: 'User'; id: string }
	updatePreferences: { __typename?: 'UserPreferences'; timeZone: string }
}

export type DeleteUserMutationVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type DeleteUserMutation = {
	__typename?: 'Mutation'
	deleteUser: boolean
}

export type DisableUserMutationVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type DisableUserMutation = {
	__typename?: 'Mutation'
	disableUser: boolean
}

export type CreateOrganizationMutationVariables = Exact<{
	organization: OrganizationInput
}>

export type CreateOrganizationMutation = {
	__typename?: 'Mutation'
	createOrganization: {
		__typename?: 'Organization'
		id: string
		name: string
		type: OrgType
		inactivityTimeoutMins: number
		fixCompId?: string | null
		usersCount: number
		category: OrgCategory
		connection?: {
			__typename?: 'Connection'
			id: string
			name: string
			strategy: string
		} | null
		entities: Array<{
			__typename?: 'Entity'
			id: string
			name: string
			lei: string
		}>
		holdingsOwners: Array<{
			__typename?: 'HoldingsOwner'
			id: string
			ownerID: number
			name: string
			holderType: string
			lei: string
			country: string
			city: string
			parentID?: number | null
			parentName?: string | null
			totalHoldings: number
			totalHoldingsNotionalUsd: number
			totalChildren: number
		}>
	}
}

export type UpdateOrganizationMutationVariables = Exact<{
	id: Scalars['ID']['input']
	organization: OrganizationInput
}>

export type UpdateOrganizationMutation = {
	__typename?: 'Mutation'
	updateOrganization: {
		__typename?: 'Organization'
		id: string
		name: string
		type: OrgType
		inactivityTimeoutMins: number
		fixCompId?: string | null
		usersCount: number
		category: OrgCategory
		connection?: {
			__typename?: 'Connection'
			id: string
			name: string
			strategy: string
		} | null
		entities: Array<{
			__typename?: 'Entity'
			id: string
			name: string
			lei: string
		}>
		holdingsOwners: Array<{
			__typename?: 'HoldingsOwner'
			id: string
			ownerID: number
			name: string
			holderType: string
			lei: string
			country: string
			city: string
			parentID?: number | null
			parentName?: string | null
			totalHoldings: number
			totalHoldingsNotionalUsd: number
			totalChildren: number
		}>
	}
}

export type SendUserWelcomeEmailMutationVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type SendUserWelcomeEmailMutation = {
	__typename?: 'Mutation'
	sendUserWelcomeEmail: boolean
}

export type SendOriginationAlertMutationVariables = Exact<{
	watchlistItemId: Scalars['ID']['input']
	reason: Scalars['String']['input']
}>

export type SendOriginationAlertMutation = {
	__typename?: 'Mutation'
	sendOriginationAlert: boolean
}

export type CreateApiKeyMutationVariables = Exact<{
	key: CreateApiKeyInput
}>

export type CreateApiKeyMutation = {
	__typename?: 'Mutation'
	createAPIKey: {
		__typename?: 'CreateAPIKeyResponse'
		token: string
		apiKey: {
			__typename?: 'APIKey'
			id: string
			createdAt: string
			expiryDate: string
			organization: {
				__typename?: 'Organization'
				id: string
				name: string
			}
		}
	}
}

export type DeleteApiKeyMutationVariables = Exact<{
	key: DeleteApiKeyInput
}>

export type DeleteApiKeyMutation = {
	__typename?: 'Mutation'
	deleteAPIKey: boolean
}

export type CreateBrokerMutationVariables = Exact<{
	input: CreateBrokerInput
}>

export type CreateBrokerMutation = {
	__typename?: 'Mutation'
	createBroker: { __typename?: 'Broker'; id: string }
}

export type UpdateBrokerMutationVariables = Exact<{
	id: Scalars['ID']['input']
	input: UpdateBrokerInput
}>

export type UpdateBrokerMutation = {
	__typename?: 'Mutation'
	updateBroker: { __typename?: 'Broker'; id: string }
}

export type DeleteBrokerMutationVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type DeleteBrokerMutation = {
	__typename?: 'Mutation'
	deleteBroker: { __typename?: 'Broker'; id: string }
}

export type AdminEndBookbuildMutationVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type AdminEndBookbuildMutation = {
	__typename?: 'Mutation'
	adminEndBookbuild: boolean
}

export type RetryTradeMutationVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type RetryTradeMutation = {
	__typename?: 'Mutation'
	retryTrade: {
		__typename?: 'Trade'
		id: string
		crossId: string
		createdAt: string
		sentAt?: string | null
		acknowledgedAt?: string | null
		executedAt?: string | null
		rejectedAt?: string | null
		rejectedReason?: string | null
		failedAt?: string | null
		volume: number
		grossCash: number
		organization: { __typename?: 'Organization'; id: string; name: string }
	}
}

export type FailTradeMutationVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type FailTradeMutation = {
	__typename?: 'Mutation'
	failTrade: {
		__typename?: 'Trade'
		id: string
		crossId: string
		createdAt: string
		sentAt?: string | null
		acknowledgedAt?: string | null
		executedAt?: string | null
		rejectedAt?: string | null
		rejectedReason?: string | null
		failedAt?: string | null
		volume: number
		grossCash: number
		organization: { __typename?: 'Organization'; id: string; name: string }
	}
}

export type AdminCancelOrderMutationVariables = Exact<{
	id: Scalars['ID']['input']
	justification: Scalars['String']['input']
}>

export type AdminCancelOrderMutation = {
	__typename?: 'Mutation'
	adminCancelOrder: {
		__typename?: 'Order'
		id: string
		createdAt: string
		amendedAt?: string | null
		cancelledAt?: string | null
		score?: OrderScore | null
		clientOrderId?: string | null
		volume: number
		price: number
		upsizeVolume?: number | null
		upsizePrice?: number | null
		status: Status
		notionalUsd: number
		notionalGbp: number
		notionalCash: number
		upsizeNotionalUsd?: number | null
		upsizeNotionalGbp?: number | null
		upsizeNotionalCash?: number | null
		daysAdtv?: number | null
		upsizeDaysAdtv?: number | null
		targetTariff?: number | null
		upsizeTariff?: number | null
		bookbuild: {
			__typename?: 'BookbuildPublic'
			instrumentPrimaryAdv?: number | null
			id: string
			side: Side
			daysAdtv?: number | null
			volume: number
			startedAt?: string | null
			endsAt: string
			endedAt?: string | null
			cancelledAt?: string | null
			failedAt?: string | null
			executingAt?: string | null
			referencePrice: number
			instrument: {
				__typename?: 'Instrument'
				id: string
				isin: string
				marketID: string
				currency: string
				description: string
				sedol: string
				primaryBloombergTicker: string
				referencePrice: number
				averageDailyVolume?: number | null
				preTradeLISValueCurrency: number
				marketName: string
				staticRefPriceCollarPercent: number
			}
			allocability: Array<{
				__typename?: 'BookbuildAllocability'
				allocablePercent: number
				demandPercent: number
			}>
		}
		allocation?: {
			__typename?: 'Allocation'
			id: string
			side: Side
			allocation: number
			grossCash: number
			createdAt: string
			scoreTime?: number | null
			scoreVwap?: number | null
			tariff?: number | null
			organization?: {
				__typename?: 'Organization'
				id: string
				name: string
			} | null
			counterparty?: {
				__typename?: 'Organization'
				id: string
				name: string
			} | null
			deal: {
				__typename?: 'Deal'
				id: string
				completedAt?: string | null
				referencePrice: number
				price: number
				volume: number
				daysAdtv?: number | null
				cashGross: number
			}
		} | null
		broker: { __typename?: 'BrokerPublic'; id: string; name: string }
		brokerLimitViolation?: {
			__typename?: 'BrokerLimitViolation'
			currentExposureUsd: number
			potentialExposureUsd: number
			allocatedOrderExposureUsd?: number | null
			currentExposureLimitPercentage: number
			potentialExposureLimitPercentage: number
			allocatedOrderExposureLimitPercentage?: number | null
			entityBroker: {
				__typename?: 'EntityBroker'
				broker: { __typename?: 'Broker'; id: string; name: string }
			}
		} | null
	}
}

export type CreateEmsMutationVariables = Exact<{
	session: EmsSessionInput
}>

export type CreateEmsMutation = {
	__typename?: 'Mutation'
	createEMSSession: {
		__typename?: 'EmsSession'
		id: string
		createdAt: string
		updatedAt: string
		name: string
		ems: string
		sessionType: FixSessionType
		beginString: FixBeginString
		senderCompID: string
		targetCompID: string
		socketAddressHost: string
		socketAddressPort: number
		maxMessagesPerSecond: number
		startDay?: string | null
		startTime: string
		endDay?: string | null
		endTime: string
		timeZone?: string | null
		weekdays: string
		heartbeatIntervalSec?: number | null
		reconnectIntervalSec?: number | null
		tlsPrivateKeyFilepath?: string | null
		tlsCertificateFilepath?: string | null
		tlsCAFilepath?: string | null
		refreshOnLogon: boolean
		resetOnLogon: boolean
		resetOnLogout: boolean
		resetOnDisconnect: boolean
		strategy: EmsSessionStrategy
		supportDistributionPopup: boolean
		technicalSupportLink?: string | null
		ioiSession?: {
			__typename?: 'IoiSession'
			id: string
			name: string
		} | null
		organizations: Array<{ __typename?: 'Organization'; id: string }>
	}
}

export type UpdateEmsMutationVariables = Exact<{
	id: Scalars['ID']['input']
	session: EmsSessionInput
}>

export type UpdateEmsMutation = {
	__typename?: 'Mutation'
	updateEMSSession: {
		__typename?: 'EmsSession'
		id: string
		createdAt: string
		updatedAt: string
		name: string
		ems: string
		sessionType: FixSessionType
		beginString: FixBeginString
		senderCompID: string
		targetCompID: string
		socketAddressHost: string
		socketAddressPort: number
		maxMessagesPerSecond: number
		startDay?: string | null
		startTime: string
		endDay?: string | null
		endTime: string
		timeZone?: string | null
		weekdays: string
		heartbeatIntervalSec?: number | null
		reconnectIntervalSec?: number | null
		tlsPrivateKeyFilepath?: string | null
		tlsCertificateFilepath?: string | null
		tlsCAFilepath?: string | null
		refreshOnLogon: boolean
		resetOnLogon: boolean
		resetOnLogout: boolean
		resetOnDisconnect: boolean
		strategy: EmsSessionStrategy
		supportDistributionPopup: boolean
		technicalSupportLink?: string | null
		ioiSession?: {
			__typename?: 'IoiSession'
			id: string
			name: string
		} | null
		organizations: Array<{ __typename?: 'Organization'; id: string }>
	}
}

export type DeleteEmsMutationVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type DeleteEmsMutation = {
	__typename?: 'Mutation'
	deleteEMSSession: { __typename?: 'EmsSession'; id: string }
}

export type CreateIoiMutationVariables = Exact<{
	session: IoiSessionInput
}>

export type CreateIoiMutation = {
	__typename?: 'Mutation'
	createIoiSession: {
		__typename?: 'IoiSession'
		id: string
		createdAt: string
		updatedAt: string
		name: string
		ems: string
		sessionType: FixSessionType
		beginString: FixBeginString
		senderCompID: string
		targetCompID: string
		socketConnectHost?: string | null
		socketConnectPort?: number | null
		maxMessagesPerSecond: number
		startDay?: string | null
		endDay?: string | null
		weekdays?: string | null
		startTime: string
		endTime: string
		timeZone?: string | null
		heartbeatIntervalSec?: number | null
		reconnectIntervalSec?: number | null
		refreshOnLogon: boolean
		resetOnLogon: boolean
		resetOnLogout: boolean
		resetOnDisconnect: boolean
		technicalSupportLink?: string | null
		state: SessionState
		tlsSkipVerification: boolean
		tlsCertExpiry?: string | null
		strategy: IoiSessionStrategy
		emsSession?: {
			__typename?: 'EmsSession'
			id: string
			name: string
		} | null
	}
}

export type UpdateIoiMutationVariables = Exact<{
	id: Scalars['ID']['input']
	session: IoiSessionInput
}>

export type UpdateIoiMutation = {
	__typename?: 'Mutation'
	updateIoiSession: {
		__typename?: 'IoiSession'
		id: string
		createdAt: string
		updatedAt: string
		name: string
		ems: string
		sessionType: FixSessionType
		beginString: FixBeginString
		senderCompID: string
		targetCompID: string
		socketConnectHost?: string | null
		socketConnectPort?: number | null
		maxMessagesPerSecond: number
		startDay?: string | null
		endDay?: string | null
		weekdays?: string | null
		startTime: string
		endTime: string
		timeZone?: string | null
		heartbeatIntervalSec?: number | null
		reconnectIntervalSec?: number | null
		refreshOnLogon: boolean
		resetOnLogon: boolean
		resetOnLogout: boolean
		resetOnDisconnect: boolean
		technicalSupportLink?: string | null
		state: SessionState
		tlsSkipVerification: boolean
		tlsCertExpiry?: string | null
		strategy: IoiSessionStrategy
		emsSession?: {
			__typename?: 'EmsSession'
			id: string
			name: string
		} | null
	}
}

export type DeleteIoiMutationVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type DeleteIoiMutation = {
	__typename?: 'Mutation'
	deleteIoiSession: { __typename?: 'IoiSession'; id: string }
}

export type CreateWatchlistSessionMutationVariables = Exact<{
	session: WatchlistSessionInput
}>

export type CreateWatchlistSessionMutation = {
	__typename?: 'Mutation'
	createWatchlistSession: {
		__typename?: 'WatchlistSession'
		id: string
		createdAt: string
		updatedAt: string
		name: string
		ems: string
		sessionType: FixSessionType
		beginString: FixBeginString
		senderCompID: string
		targetCompID: string
		socketConnectHost?: string | null
		socketConnectPort?: number | null
		maxMessagesPerSecond: number
		startDay?: string | null
		startTime: string
		endDay?: string | null
		endTime: string
		timeZone: string
		weekdays?: string | null
		heartbeatIntervalSec?: number | null
		reconnectIntervalSec?: number | null
		refreshOnLogon: boolean
		resetOnLogon: boolean
		resetOnLogout: boolean
		resetOnDisconnect: boolean
		state: SessionState
		technicalSupportLink?: string | null
		organizations: Array<{ __typename?: 'Organization'; id: string }>
	}
}

export type UpdateWatchlistSessionMutationVariables = Exact<{
	id: Scalars['ID']['input']
	session: WatchlistSessionInput
}>

export type UpdateWatchlistSessionMutation = {
	__typename?: 'Mutation'
	updateWatchlistSession: {
		__typename?: 'WatchlistSession'
		id: string
		createdAt: string
		updatedAt: string
		name: string
		ems: string
		sessionType: FixSessionType
		beginString: FixBeginString
		senderCompID: string
		targetCompID: string
		socketConnectHost?: string | null
		socketConnectPort?: number | null
		maxMessagesPerSecond: number
		startDay?: string | null
		startTime: string
		endDay?: string | null
		endTime: string
		timeZone: string
		weekdays?: string | null
		heartbeatIntervalSec?: number | null
		reconnectIntervalSec?: number | null
		refreshOnLogon: boolean
		resetOnLogon: boolean
		resetOnLogout: boolean
		resetOnDisconnect: boolean
		state: SessionState
		technicalSupportLink?: string | null
		organizations: Array<{ __typename?: 'Organization'; id: string }>
	}
}

export type RespondToPriceDiscoveryMutationVariables = Exact<{
	input: PriceDiscoveryResponseInput
}>

export type RespondToPriceDiscoveryMutation = {
	__typename?: 'Mutation'
	respondToPriceDiscovery?: {
		__typename?: 'PriceDiscoveryOpportunity'
		id: string
		interested?: boolean | null
		volume?: number | null
		sensitivityType?: PriceDiscoverySensitivityType | null
		sensitivityValue?: number | null
		hoursRequired?: number | null
		pmRegion?: PmRegion | null
	} | null
}

export type DeleteWatchlistSessionMutationVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type DeleteWatchlistSessionMutation = {
	__typename?: 'Mutation'
	deleteWatchlistSession: { __typename?: 'WatchlistSession'; id: string }
}

export type DeleteUnsupportedTickerMutationVariables = Exact<{
	ticker: Scalars['String']['input']
}>

export type DeleteUnsupportedTickerMutation = {
	__typename?: 'Mutation'
	removeUnsupportedTicker: {
		__typename?: 'UnsupportedTicker'
		ticker: string
	}
}

export type UpdateOpportunityMutationVariables = Exact<{
	id: Scalars['ID']['input']
	interested: Scalars['Boolean']['input']
}>

export type UpdateOpportunityMutation = {
	__typename?: 'Mutation'
	updateOpportunity: {
		__typename?: 'Opportunity'
		id: string
		interested?: boolean | null
	}
}

export type SetOrganizationHoldingsOwnersMutationVariables = Exact<{
	orgId: Scalars['ID']['input']
	ownerIds: Array<Scalars['Int']['input']> | Scalars['Int']['input']
}>

export type SetOrganizationHoldingsOwnersMutation = {
	__typename?: 'Mutation'
	setOrganizationHoldingsOwners: boolean
}

export type CreateTrendingEquitiesListMutationVariables = Exact<{
	list: TrendingEquitiesListInput
}>

export type CreateTrendingEquitiesListMutation = {
	__typename?: 'Mutation'
	createTrendingEquitiesList: {
		__typename?: 'TrendingEquitiesList'
		id: string
		createdAt: string
		updatedAt: string
		endedAt?: string | null
		active: boolean
		emailSentAt?: string | null
		emailSentBy?: { __typename?: 'User'; id: string; name: string } | null
		participants?: Array<{
			__typename?: 'Organization'
			id: string
			name: string
		}> | null
		instruments: Array<{
			__typename?: 'TrendingEquitiesInstrument'
			id: string
			state?: TrendingInstrumentState | null
			changedAt?: string | null
			changedBy?: { __typename?: 'User'; id: string; name: string } | null
			instrument: {
				__typename?: 'Instrument'
				id: string
				description: string
				marketName: string
				primaryBloombergTicker: string
			}
			responses: Array<{
				__typename?: 'TrendingEquitiesResponse'
				id: string
				createdAt: string
				updatedAt: string
				side: Side
				activityType: ActivityType
				user: { __typename?: 'User'; id: string; name: string }
				organization: {
					__typename?: 'Organization'
					id: string
					name: string
				}
			}>
		}>
	}
}

export type UpdateTrendingEquitiesListMutationVariables = Exact<{
	id: Scalars['ID']['input']
	list: TrendingEquitiesListInput
}>

export type UpdateTrendingEquitiesListMutation = {
	__typename?: 'Mutation'
	updateTrendingEquitiesList: {
		__typename?: 'TrendingEquitiesList'
		id: string
		createdAt: string
		updatedAt: string
		endedAt?: string | null
		active: boolean
		emailSentAt?: string | null
		emailSentBy?: { __typename?: 'User'; id: string; name: string } | null
		participants?: Array<{
			__typename?: 'Organization'
			id: string
			name: string
		}> | null
		instruments: Array<{
			__typename?: 'TrendingEquitiesInstrument'
			id: string
			state?: TrendingInstrumentState | null
			changedAt?: string | null
			changedBy?: { __typename?: 'User'; id: string; name: string } | null
			instrument: {
				__typename?: 'Instrument'
				id: string
				description: string
				marketName: string
				primaryBloombergTicker: string
			}
			responses: Array<{
				__typename?: 'TrendingEquitiesResponse'
				id: string
				createdAt: string
				updatedAt: string
				side: Side
				activityType: ActivityType
				user: { __typename?: 'User'; id: string; name: string }
				organization: {
					__typename?: 'Organization'
					id: string
					name: string
				}
			}>
		}>
	}
}

export type RespondToTrendingEquityMutationVariables = Exact<{
	instrumentID: Scalars['ID']['input']
	side?: InputMaybe<Side>
}>

export type RespondToTrendingEquityMutation = {
	__typename?: 'Mutation'
	respondToTrendingEquity: boolean
}

export type SendTrendingEquitiesEmailMutationVariables = Exact<{
	listID: Scalars['ID']['input']
}>

export type SendTrendingEquitiesEmailMutation = {
	__typename?: 'Mutation'
	sendTrendingEquitiesEmail: boolean
}

export type HideTrendingEquitiesHintMutationVariables = Exact<{
	[key: string]: never
}>

export type HideTrendingEquitiesHintMutation = {
	__typename?: 'Mutation'
	hideTrendingEquitiesHint: boolean
}

export type CreatePriceDiscoveryMutationVariables = Exact<{
	input: PriceDiscoveryInput
}>

export type CreatePriceDiscoveryMutation = {
	__typename?: 'Mutation'
	createPriceDiscovery: { __typename?: 'PriceDiscovery'; id: string }
}

export type CancelPriceDiscoveryMutationVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type CancelPriceDiscoveryMutation = {
	__typename?: 'Mutation'
	cancelPriceDiscovery: boolean
}

export type SendPriceDiscoveryOriginationAlertMutationVariables = Exact<{
	priceDiscoveryId: Scalars['ID']['input']
	input: PriceDiscoveryOriginationAlertInput
}>

export type SendPriceDiscoveryOriginationAlertMutation = {
	__typename?: 'Mutation'
	sendPriceDiscoveryOriginationAlert: boolean
}

export type ReloadWatchlistEngineMutationVariables = Exact<{
	[key: string]: never
}>

export type ReloadWatchlistEngineMutation = {
	__typename?: 'Mutation'
	reloadWatchlistEngine: boolean
}

export type ReloadIoiEngineMutationVariables = Exact<{ [key: string]: never }>

export type ReloadIoiEngineMutation = {
	__typename?: 'Mutation'
	reloadIoiEngine: boolean
}

export type UpdateDistributionOrgsMutationVariables = Exact<{
	orgs: Array<DistributionOrgInput> | DistributionOrgInput
}>

export type UpdateDistributionOrgsMutation = {
	__typename?: 'Mutation'
	updateDistributionOrgs: Array<{
		__typename?: 'OrganizationPublic'
		id: string
		name: string
		exclude: boolean
		recentlyAdded: boolean
	}>
}

export type RespondNoToPriceDiscoveryAsAdminMutationVariables = Exact<{
	priceDiscoveryOpportunityId: Scalars['ID']['input']
	onBehalfOfUserId: Scalars['ID']['input']
}>

export type RespondNoToPriceDiscoveryAsAdminMutation = {
	__typename?: 'Mutation'
	respondNoToPriceDiscoveryAsAdmin: boolean
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
	__typename?: 'Query'
	me: {
		__typename?: 'User'
		id: string
		email: string
		disabled: boolean
		sessionId: number
		name: string
		minFirmsDistribution: number
		auth0Id?: string | null
		roles: Array<{ __typename?: 'Role'; id: string }>
		organization: {
			__typename?: 'Organization'
			id: string
			name: string
			type: OrgType
			inactivityTimeoutMins: number
		}
		entities: Array<{
			__typename?: 'UserEntity'
			isDefault: boolean
			entity: {
				__typename?: 'Entity'
				id: string
				name: string
				lei: string
				defaultBroker: {
					__typename?: 'Broker'
					id: string
					name: string
				}
				brokers: Array<{
					__typename?: 'EntityBroker'
					broker: { __typename?: 'Broker'; id: string; name: string }
				}>
			}
		}>
		preferences: {
			__typename?: 'UserPreferences'
			opportunitiesView: OpportunitiesView
			opportunitiesSort?: OpportunitiesSort | null
		}
		exclusions: Array<{
			__typename?: 'OrganizationPublic'
			id: string
			name: string
			category: OrgCategory
			exclude: boolean
			recentlyAdded: boolean
		}>
	}
}

export type LiveWatchlistQueryVariables = Exact<{
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: LiveWatchlistItemsSortCol
	sortDesc: Scalars['Boolean']['input']
	recentLiveLimit: Scalars['Int']['input']
	recentLiveOffset: Scalars['Int']['input']
	recentLiveSortBy: LiveWatchlistItemsSortCol
	recentLiveSortDesc: Scalars['Boolean']['input']
	alertsSince?: InputMaybe<Scalars['Time']['input']>
	priceDiscsActiveSince?: InputMaybe<Scalars['Time']['input']>
}>

export type LiveWatchlistQuery = {
	__typename?: 'Query'
	liveWatchlist: {
		__typename?: 'LiveWatchlistItems'
		total: number
		updatedAt?: string | null
		automated: boolean
		automationRequested: boolean
		allowManualPriceDiscovery: boolean
		preventManualDeletion: boolean
		items: Array<{
			__typename?: 'LiveWatchlistItem'
			id: string
			createdAt: string
			side: Side
			volume?: number | null
			price?: number | null
			currency?: string | null
			hasLivePriceDiscovery: boolean
			hasLivePriceDiscoveryOpportunity: boolean
			user: { __typename?: 'User'; id: string; name: string }
			instrument: {
				__typename?: 'Instrument'
				id: string
				description: string
				marketName: string
				averageDailyVolume?: number | null
				primaryBloombergTicker: string
				currency: string
				holdings: {
					__typename?: 'InstrumentHoldings'
					fuzzyTotalAmount: string
					holders: Array<{
						__typename?: 'Holder'
						fuzzyHoldingAmount: string
						trend: HoldingTrend
						reportDate?: string | null
						valueChangedADVPercent: number
						valueChangedUSD: number
						organization: {
							__typename?: 'OrganizationPublic'
							id: string
							name: string
							category: OrgCategory
							exclude: boolean
							recentlyAdded: boolean
						}
					}>
				}
			}
			distributionScore?: {
				__typename?: 'DistributionScore'
				totalContrasCount: number
				suggestedContrasCount: number
				suggestDistribution: boolean
			} | null
		}>
	}
	recentLiveWatchlist: {
		__typename?: 'LiveWatchlistItems'
		total: number
		items: Array<{
			__typename?: 'LiveWatchlistItem'
			id: string
			createdAt: string
			side: Side
			volume?: number | null
			price?: number | null
			currency?: string | null
			hasLivePriceDiscovery: boolean
			hasLivePriceDiscoveryOpportunity: boolean
			user: { __typename?: 'User'; id: string; name: string }
			instrument: {
				__typename?: 'Instrument'
				id: string
				description: string
				marketName: string
				averageDailyVolume?: number | null
				primaryBloombergTicker: string
				currency: string
				holdings: {
					__typename?: 'InstrumentHoldings'
					fuzzyTotalAmount: string
					holders: Array<{
						__typename?: 'Holder'
						fuzzyHoldingAmount: string
						trend: HoldingTrend
						reportDate?: string | null
						valueChangedADVPercent: number
						valueChangedUSD: number
						organization: {
							__typename?: 'OrganizationPublic'
							id: string
							name: string
							category: OrgCategory
							exclude: boolean
							recentlyAdded: boolean
						}
					}>
				}
			}
			distributionScore?: {
				__typename?: 'DistributionScore'
				totalContrasCount: number
				suggestedContrasCount: number
				suggestDistribution: boolean
			} | null
		}>
	}
	originationAlerts: {
		__typename?: 'OriginationAlerts'
		total: number
		items: Array<{
			__typename?: 'OriginationAlert'
			id: string
			side: Side
			volume?: number | null
			price?: number | null
			createdAt: string
			durationHours?: number | null
			durationMinutes?: number | null
			launchTime?: string | null
			suggestion?: string | null
			instrument: {
				__typename?: 'Instrument'
				id: string
				description: string
				marketName: string
				averageDailyVolume?: number | null
				currency: string
				primaryBloombergTicker: string
				holdings: {
					__typename?: 'InstrumentHoldings'
					fuzzyTotalAmount: string
					holders: Array<{
						__typename?: 'Holder'
						fuzzyHoldingAmount: string
						trend: HoldingTrend
						reportDate?: string | null
						valueChangedADVPercent: number
						valueChangedUSD: number
						organization: {
							__typename?: 'OrganizationPublic'
							id: string
							name: string
							category: OrgCategory
							exclude: boolean
							recentlyAdded: boolean
						}
					}>
				}
			}
			priceDiscovery?: {
				__typename?: 'PriceDiscovery'
				interestedCount: number
			} | null
		}>
	}
	priceDiscoveries: {
		__typename?: 'PriceDiscoveries'
		total: number
		items: Array<{
			__typename?: 'PriceDiscovery'
			id: string
			side: Side
			volume: number
			price?: number | null
			daysAdtv?: number | null
			interestedCount: number
			notInterestedCount: number
			manualSelectionFirmsCount: number
			suggestedDistributionFirmsCount: number
			holdersCount: number
			createdAt: string
			endsAt: string
			endedAt?: string | null
			status: PriceDiscoveryStatus
			cancelledAt?: string | null
			instrument: {
				__typename?: 'Instrument'
				id: string
				description: string
				marketName: string
				primaryBloombergTicker: string
				holdings: {
					__typename?: 'InstrumentHoldings'
					fuzzyTotalAmount: string
					holders: Array<{
						__typename?: 'Holder'
						reportDate?: string | null
					}>
				}
			}
			user: { __typename?: 'User'; name: string }
			cancelledBy?: { __typename?: 'User'; name: string } | null
			originationAlert?: {
				__typename?: 'OriginationAlert'
				id: string
			} | null
			manualDistributionFirms: Array<{
				__typename?: 'OrganizationPublic'
				id: string
				name: string
				category: OrgCategory
			}>
		}>
	}
	distributionOrgs: Array<{
		__typename?: 'OrganizationPublic'
		id: string
		name: string
		category: OrgCategory
		exclude: boolean
		recentlyAdded: boolean
	}>
}

export type PassiveWatchlistQueryVariables = Exact<{
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: PassiveWatchlistItemsSortCol
	sortDesc: Scalars['Boolean']['input']
}>

export type PassiveWatchlistQuery = {
	__typename?: 'Query'
	passiveWatchlist: {
		__typename?: 'PassiveWatchlistItems'
		total: number
		updatedAt?: string | null
		items: Array<{
			__typename?: 'PassiveWatchlistItem'
			id: string
			createdAt: string
			side: Side
			autoMigrated: boolean
			user: { __typename?: 'User'; id: string; name: string }
			instrument: {
				__typename?: 'Instrument'
				id: string
				description: string
				marketName: string
				holdings: {
					__typename?: 'InstrumentHoldings'
					fuzzyTotalAmount: string
					holders: Array<{
						__typename?: 'Holder'
						fuzzyHoldingAmount: string
						trend: HoldingTrend
						reportDate?: string | null
						valueChangedADVPercent: number
						valueChangedUSD: number
						organization: {
							__typename?: 'OrganizationPublic'
							id: string
							name: string
							category: OrgCategory
							exclude: boolean
							recentlyAdded: boolean
						}
					}>
				}
			}
		}>
		settings?: {
			__typename?: 'PassiveWatchlistSettings'
			sellPremiumPercent?: number | null
			buyDiscountPercent?: number | null
			advPercent?: number | null
			reactionTimeHours?: number | null
			autoMigration: boolean
		} | null
	}
}

export type ProfileQueryVariables = Exact<{ [key: string]: never }>

export type ProfileQuery = {
	__typename?: 'Query'
	profile?: {
		__typename?: 'Profile'
		id: string
		createdAt: string
		updatedAt: string
		marketCapMax?: number | null
		marketCapMin?: number | null
		sectors: Array<{ __typename?: 'Sector'; name: string }>
		countries: Array<{ __typename?: 'Country'; id: string; name: string }>
	} | null
	sectors: Array<{ __typename?: 'Sector'; name: string }>
	countries: Array<{ __typename?: 'Country'; id: string; name: string }>
}

export type PreviewProfileInstrumentCountsQueryVariables = Exact<{
	input: ProfileInput
}>

export type PreviewProfileInstrumentCountsQuery = {
	__typename?: 'Query'
	previewProfileInstrumentsCount: {
		__typename?: 'ProfileInstrumentCounts'
		total: number
	}
}

export type OrganizationsQueryVariables = Exact<{ [key: string]: never }>

export type OrganizationsQuery = {
	__typename?: 'Query'
	organizations: Array<{
		__typename?: 'Organization'
		id: string
		name: string
		type: OrgType
		category: OrgCategory
		usersCount: number
		emsSession?: { __typename?: 'EmsSession'; name: string } | null
	}>
}

export type OrganizationQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type OrganizationQuery = {
	__typename?: 'Query'
	organization?: {
		__typename?: 'Organization'
		disabledCurrencies: Array<string>
		disabledMarketIds: Array<string>
		disabledIsins: Array<string>
		liveWatchlistAutomated: boolean
		liveWatchlistClearEnabled: boolean
		liveWatchlistAutomationRequestedBy?: string | null
		liveWatchlistAutomationRequestedAt?: string | null
		passiveWatchlistAutomigration: boolean
		allowManualPriceDiscovery: boolean
		id: string
		name: string
		type: OrgType
		inactivityTimeoutMins: number
		fixCompId?: string | null
		usersCount: number
		category: OrgCategory
		emsSession?: {
			__typename?: 'EmsSession'
			id: string
			name: string
		} | null
		watchlistSession?: {
			__typename?: 'WatchlistSession'
			id: string
			name: string
		} | null
		entities: Array<{
			__typename?: 'Entity'
			id: string
			name: string
			lei: string
			defaultBroker: { __typename?: 'Broker'; id: string }
			brokers: Array<{
				__typename?: 'EntityBroker'
				brokerCode: string
				limitUsd: number
				turquoiseShortcode: number
				broker: { __typename?: 'Broker'; id: string; name: string }
			}>
		}>
		holdingsOwners: Array<{
			__typename?: 'HoldingsOwner'
			id: string
			ownerID: number
			name: string
			holderType: string
			lei: string
			country: string
			city: string
			parentID?: number | null
			parentName?: string | null
			totalHoldings: number
			totalHoldingsNotionalUsd: number
			totalChildren: number
		}>
		connection?: {
			__typename?: 'Connection'
			id: string
			name: string
			strategy: string
		} | null
	} | null
}

export type EditOrganizationQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type EditOrganizationQuery = {
	__typename?: 'Query'
	organization?: {
		__typename?: 'Organization'
		disabledCurrencies: Array<string>
		disabledMarketIds: Array<string>
		disabledIsins: Array<string>
		liveWatchlistAutomated: boolean
		liveWatchlistClearEnabled: boolean
		passiveWatchlistAutomigration: boolean
		allowManualPriceDiscovery: boolean
		id: string
		name: string
		type: OrgType
		inactivityTimeoutMins: number
		fixCompId?: string | null
		usersCount: number
		category: OrgCategory
		entities: Array<{
			__typename?: 'Entity'
			id: string
			name: string
			lei: string
			defaultBroker: { __typename?: 'Broker'; id: string }
			brokers: Array<{
				__typename?: 'EntityBroker'
				brokerCode: string
				limitUsd: number
				turquoiseShortcode: number
				broker: { __typename?: 'Broker'; id: string; name: string }
			}>
		}>
		emsSession?: {
			__typename?: 'EmsSession'
			id: string
			name: string
		} | null
		watchlistSession?: {
			__typename?: 'WatchlistSession'
			id: string
			name: string
		} | null
		connection?: {
			__typename?: 'Connection'
			id: string
			name: string
			strategy: string
		} | null
		holdingsOwners: Array<{
			__typename?: 'HoldingsOwner'
			id: string
			ownerID: number
			name: string
			holderType: string
			lei: string
			country: string
			city: string
			parentID?: number | null
			parentName?: string | null
			totalHoldings: number
			totalHoldingsNotionalUsd: number
			totalChildren: number
		}>
	} | null
	connections: Array<{
		__typename?: 'Connection'
		id: string
		name: string
		strategy: string
	}>
	brokers: Array<{ __typename?: 'Broker'; id: string; name: string }>
	emsSessions: Array<{ __typename?: 'EmsSession'; id: string; name: string }>
	watchlistSessions: Array<{
		__typename?: 'WatchlistSession'
		id: string
		name: string
	}>
	ioiSessions: Array<{ __typename?: 'IoiSession'; id: string; name: string }>
}

export type CreateOrganizationDataQueryVariables = Exact<{
	[key: string]: never
}>

export type CreateOrganizationDataQuery = {
	__typename?: 'Query'
	connections: Array<{
		__typename?: 'Connection'
		id: string
		name: string
		strategy: string
	}>
	brokers: Array<{ __typename?: 'Broker'; id: string; name: string }>
	emsSessions: Array<{ __typename?: 'EmsSession'; id: string; name: string }>
	watchlistSessions: Array<{
		__typename?: 'WatchlistSession'
		id: string
		name: string
	}>
	ioiSessions: Array<{ __typename?: 'IoiSession'; id: string; name: string }>
}

export type UserCreateDataQueryVariables = Exact<{ [key: string]: never }>

export type UserCreateDataQuery = {
	__typename?: 'Query'
	organizations: Array<{
		__typename?: 'Organization'
		id: string
		name: string
		type: OrgType
		inactivityTimeoutMins: number
		fixCompId?: string | null
		usersCount: number
		category: OrgCategory
		connection?: {
			__typename?: 'Connection'
			id: string
			name: string
			strategy: string
		} | null
		entities: Array<{
			__typename?: 'Entity'
			id: string
			name: string
			lei: string
		}>
		holdingsOwners: Array<{
			__typename?: 'HoldingsOwner'
			id: string
			ownerID: number
			name: string
			holderType: string
			lei: string
			country: string
			city: string
			parentID?: number | null
			parentName?: string | null
			totalHoldings: number
			totalHoldingsNotionalUsd: number
			totalChildren: number
		}>
	}>
	roles: Array<{ __typename?: 'Role'; id: string }>
}

export type RolesQueryVariables = Exact<{ [key: string]: never }>

export type RolesQuery = {
	__typename?: 'Query'
	roles: Array<{ __typename?: 'Role'; id: string }>
}

export type DistributeQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type DistributeQuery = {
	__typename?: 'Query'
	bookbuild?: {
		__typename?: 'Bookbuild'
		id: string
		daysAdtv?: number | null
		upsizeDaysAdtv?: number | null
		volume: number
		price: number
		upsizeVolume?: number | null
		upsizePrice?: number | null
		side: Side
		createdAt: string
		endsAt: string
		startedAt?: string | null
		cancelRequestedAt?: string | null
		cancelRequestedBy?: string | null
		cancelRequestJustification?: string | null
		cancelledAt?: string | null
		cancelledJustification?: string | null
		failedAt?: string | null
		failedBy?: string | null
		failedJustification?: string | null
		endedAt?: string | null
		executingAt?: string | null
		status: Status
		referencePrice: number
		clientOrderId?: string | null
		strategy: DealStrategy
		notionalUsd: number
		notionalGbp: number
		notionalCash: number
		instrumentPrimaryAdv?: number | null
		targetTariff?: number | null
		upsizeTariff?: number | null
		captureDownsized: boolean
		instrument: {
			__typename?: 'Instrument'
			id: string
			isin: string
			marketID: string
			currency: string
			description: string
			sedol: string
			primaryBloombergTicker: string
			referencePrice: number
			averageDailyVolume?: number | null
			preTradeLISValueCurrency: number
			marketName: string
			staticRefPriceCollarPercent: number
			holdings: {
				__typename?: 'InstrumentHoldings'
				holders: Array<{
					__typename?: 'Holder'
					fuzzyHoldingAmount: string
					trend: HoldingTrend
					reportDate?: string | null
					valueChangedUSD: number
					valueChangedADVPercent: number
					organization: {
						__typename?: 'OrganizationPublic'
						id: string
						name: string
						category: OrgCategory
						exclude: boolean
						recentlyAdded: boolean
					}
				}>
			}
		}
		previewReach?: {
			__typename?: 'PreviewReach'
			liveWatchlistsFirms: number
			passiveInterestFirms: number
			priceDiscoveryFirms: number
		} | null
		recentPriceDiscovery?: {
			__typename?: 'PriceDiscovery'
			id: string
			interestedCount: number
		} | null
		distribution: {
			__typename?: 'BookbuildDistribution'
			priceDiscovery: boolean
			liveWatchlists: boolean
			passiveInterest: boolean
			directOrgIds?: Array<string> | null
			excludedOrgIds: Array<string>
		}
		allocability: Array<{
			__typename?: 'BookbuildAllocability'
			id: string
			date: string
			demandPercent: number
			allocablePercent: number
			totalOrders: number
			dealPrice?: number | null
			dealVolume?: number | null
			allocations?: string | null
			downsized: boolean
		}>
		reach: {
			__typename?: 'BookbuildReach'
			priceDiscoveryFirms: number
			liveWatchlistsFirms: number
			passiveInterestFirms: number
			directFirms: number
		}
		deal?: {
			__typename?: 'Deal'
			id: string
			price: number
			volume: number
			daysAdtv?: number | null
			totalOrders: number
			referencePrice: number
			cashGross: number
			createdAt: string
			completedAt?: string | null
			tariff?: number | null
		} | null
		broker: { __typename?: 'BrokerPublic'; id: string; name: string }
		entity: { __typename?: 'Entity'; id: string; name: string }
		brokerLimitViolation?: {
			__typename?: 'BrokerLimitViolation'
			currentExposureUsd: number
			potentialExposureUsd: number
			allocatedOrderExposureUsd?: number | null
			currentExposureLimitPercentage: number
			potentialExposureLimitPercentage: number
			allocatedOrderExposureLimitPercentage?: number | null
			notionalUsd: number
			entityBroker: {
				__typename?: 'EntityBroker'
				limitUsd: number
				broker: { __typename?: 'Broker'; id: string; name: string }
			}
		} | null
	} | null
	distributionOrgs: Array<{
		__typename?: 'OrganizationPublic'
		id: string
		name: string
		category: OrgCategory
		exclude: boolean
		recentlyAdded: boolean
	}>
	distributionBrokers: Array<{
		__typename?: 'BrokerPublic'
		id: string
		name: string
	}>
}

export type DealSpreadsheetQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type DealSpreadsheetQuery = {
	__typename?: 'Query'
	deal?: { __typename?: 'Deal'; spreadsheet?: string | null } | null
}

export type BookbuildsQueryVariables = Exact<{
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: BookbuildsSortCol
	sortDesc: Scalars['Boolean']['input']
	status?: InputMaybe<Array<Status> | Status>
}>

export type BookbuildsQuery = {
	__typename?: 'Query'
	bookbuilds: {
		__typename?: 'Bookbuilds'
		total: number
		items: Array<{
			__typename?: 'Bookbuild'
			id: string
			daysAdtv?: number | null
			upsizeDaysAdtv?: number | null
			volume: number
			price: number
			upsizeVolume?: number | null
			upsizePrice?: number | null
			side: Side
			createdAt: string
			endsAt: string
			startedAt?: string | null
			cancelRequestedAt?: string | null
			cancelRequestedBy?: string | null
			cancelRequestJustification?: string | null
			cancelledAt?: string | null
			cancelledJustification?: string | null
			failedAt?: string | null
			failedBy?: string | null
			failedJustification?: string | null
			endedAt?: string | null
			executingAt?: string | null
			status: Status
			referencePrice: number
			clientOrderId?: string | null
			strategy: DealStrategy
			notionalUsd: number
			notionalGbp: number
			notionalCash: number
			instrumentPrimaryAdv?: number | null
			targetTariff?: number | null
			upsizeTariff?: number | null
			captureDownsized: boolean
			instrument: {
				__typename?: 'Instrument'
				id: string
				isin: string
				marketID: string
				currency: string
				description: string
				sedol: string
				primaryBloombergTicker: string
				referencePrice: number
				averageDailyVolume?: number | null
				preTradeLISValueCurrency: number
				marketName: string
				staticRefPriceCollarPercent: number
			}
			distribution: {
				__typename?: 'BookbuildDistribution'
				priceDiscovery: boolean
				liveWatchlists: boolean
				passiveInterest: boolean
				directOrgIds?: Array<string> | null
				excludedOrgIds: Array<string>
			}
			allocability: Array<{
				__typename?: 'BookbuildAllocability'
				id: string
				date: string
				demandPercent: number
				allocablePercent: number
				totalOrders: number
				dealPrice?: number | null
				dealVolume?: number | null
				allocations?: string | null
				downsized: boolean
			}>
			reach: {
				__typename?: 'BookbuildReach'
				priceDiscoveryFirms: number
				liveWatchlistsFirms: number
				passiveInterestFirms: number
				directFirms: number
			}
			deal?: {
				__typename?: 'Deal'
				id: string
				price: number
				volume: number
				daysAdtv?: number | null
				totalOrders: number
				referencePrice: number
				cashGross: number
				createdAt: string
				completedAt?: string | null
				tariff?: number | null
			} | null
			broker: { __typename?: 'BrokerPublic'; id: string; name: string }
			entity: { __typename?: 'Entity'; id: string; name: string }
			brokerLimitViolation?: {
				__typename?: 'BrokerLimitViolation'
				currentExposureUsd: number
				potentialExposureUsd: number
				allocatedOrderExposureUsd?: number | null
				currentExposureLimitPercentage: number
				potentialExposureLimitPercentage: number
				allocatedOrderExposureLimitPercentage?: number | null
				notionalUsd: number
				entityBroker: {
					__typename?: 'EntityBroker'
					limitUsd: number
					broker: { __typename?: 'Broker'; id: string; name: string }
				}
			} | null
		}>
	}
	distributionOrgs: Array<{
		__typename?: 'OrganizationPublic'
		id: string
		name: string
		category: OrgCategory
		exclude: boolean
		recentlyAdded: boolean
	}>
	distributionBrokers: Array<{
		__typename?: 'BrokerPublic'
		id: string
		name: string
	}>
}

export type BookbuildsDraftsTotalQueryVariables = Exact<{
	[key: string]: never
}>

export type BookbuildsDraftsTotalQuery = {
	__typename?: 'Query'
	bookbuilds: { __typename?: 'Bookbuilds'; total: number }
}

export type OpportunitiesInterestsQueryVariables = Exact<{
	[key: string]: never
}>

export type OpportunitiesInterestsQuery = {
	__typename?: 'Query'
	opportunities: { __typename?: 'Opportunities'; total: number }
	liveWatchlist: { __typename?: 'LiveWatchlistItems'; total: number }
	passiveWatchlist: { __typename?: 'PassiveWatchlistItems'; total: number }
	priceDiscoveryOpportunities: {
		__typename?: 'PriceDiscoveryOpportunities'
		total: number
	}
	trendingEquitiesActive?: {
		__typename?: 'TrendingEquitiesList'
		instruments: Array<{
			__typename?: 'TrendingEquitiesInstrument'
			id: string
		}>
	} | null
}

export type OpportunitiesTodayQueryVariables = Exact<{
	priceDiscoveryLimit: Scalars['Int']['input']
	priceDiscoveryOffset: Scalars['Int']['input']
	priceDiscoveryActiveSince?: InputMaybe<Scalars['Time']['input']>
}>

export type OpportunitiesTodayQuery = {
	__typename?: 'Query'
	todayOpportunities: Array<{
		__typename?: 'Opportunity'
		id: string
		directed: boolean
		interested?: boolean | null
		matchedBy?: OpportunityInterest | null
		status: Status
		bookbuild: {
			__typename?: 'BookbuildPublic'
			id: string
			side: Side
			daysAdtv?: number | null
			volume: number
			startedAt?: string | null
			endsAt: string
			endedAt?: string | null
			cancelledAt?: string | null
			failedAt?: string | null
			executingAt?: string | null
			referencePrice: number
			instrumentPrimaryAdv?: number | null
			instrument: {
				__typename?: 'Instrument'
				id: string
				isin: string
				marketID: string
				currency: string
				description: string
				sedol: string
				primaryBloombergTicker: string
				referencePrice: number
				averageDailyVolume?: number | null
				preTradeLISValueCurrency: number
				marketName: string
				staticRefPriceCollarPercent: number
			}
			allocability: Array<{
				__typename?: 'BookbuildAllocability'
				allocablePercent: number
				demandPercent: number
			}>
		}
		order?: {
			__typename?: 'Order'
			id: string
			amendedAt?: string | null
			cancelledAt?: string | null
			score?: OrderScore | null
			clientOrderId?: string | null
			volume: number
			price: number
			upsizeVolume?: number | null
			upsizePrice?: number | null
			allocation?: {
				__typename?: 'Allocation'
				id: string
				side: Side
				allocation: number
				grossCash: number
				createdAt: string
				scoreTime?: number | null
				scoreVwap?: number | null
				tariff?: number | null
				organization?: {
					__typename?: 'Organization'
					id: string
					name: string
				} | null
				counterparty?: {
					__typename?: 'Organization'
					id: string
					name: string
				} | null
				deal: {
					__typename?: 'Deal'
					id: string
					completedAt?: string | null
					referencePrice: number
					price: number
					volume: number
					daysAdtv?: number | null
					cashGross: number
				}
			} | null
			broker: { __typename?: 'BrokerPublic'; id: string; name: string }
			entity: { __typename?: 'Entity'; id: string; name: string }
		} | null
		liveWatchlistMatches?: Array<{
			__typename?: 'WatchlistItem'
			id: string
		}> | null
		passiveInterestMatches?: Array<{
			__typename?: 'OpportunityMatchItem'
			id: string
		}> | null
	}>
	trendingEquitiesActive?: {
		__typename?: 'TrendingEquitiesList'
		id: string
		instruments: Array<{
			__typename?: 'TrendingEquitiesInstrument'
			id: string
			isHolder?: boolean | null
			instrument: {
				__typename?: 'Instrument'
				id: string
				description: string
				marketName: string
				marketCountry: string
				sector: string
				primaryBloombergTicker: string
			}
			responses: Array<{
				__typename?: 'TrendingEquitiesResponse'
				id: string
				side: Side
				updatedAt: string
				user: { __typename?: 'User'; name: string; id: string }
			}>
		}>
	} | null
	priceDiscoveryOpportunities: {
		__typename?: 'PriceDiscoveryOpportunities'
		total: number
		items: Array<{
			__typename?: 'PriceDiscoveryOpportunity'
			id: string
			interested?: boolean | null
			volume?: number | null
			daysAdtv?: number | null
			notionalUsd?: number | null
			sensitivityType?: PriceDiscoverySensitivityType | null
			sensitivityValue?: number | null
			hoursRequired?: number | null
			pmRegion?: PmRegion | null
			respondedAt?: string | null
			isHolder: boolean
			organization: {
				__typename?: 'Organization'
				id: string
				name: string
			}
			priceDiscovery: {
				__typename?: 'PriceDiscoveryPublic'
				id: string
				side: Side
				endsAt: string
				endedAt?: string | null
				createdAt: string
				cancelledAt?: string | null
				daysAdtvLowerBound?: number | null
				daysAdtvUpperBound?: number | null
				status: PriceDiscoveryStatus
				instrument: {
					__typename?: 'Instrument'
					id: string
					description: string
					primaryBloombergTicker: string
					marketName: string
					currency: string
					averageDailyVolume?: number | null
					notionalAverageDailyVolumeUsd?: number | null
				}
			}
			respondedBy?: {
				__typename?: 'User'
				id: string
				name: string
			} | null
		}>
	}
	me: {
		__typename?: 'User'
		id: string
		hideTrendingEquitiesHint: boolean
		entities: Array<{
			__typename?: 'UserEntity'
			isDefault: boolean
			entity: {
				__typename?: 'Entity'
				id: string
				brokers: Array<{
					__typename?: 'EntityBroker'
					broker: { __typename?: 'Broker'; id: string; name: string }
				}>
			}
		}>
	}
}

export type OpportunitiesQueryVariables = Exact<{
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: OpportunitiesSortCol
	sortDesc: Scalars['Boolean']['input']
}>

export type OpportunitiesQuery = {
	__typename?: 'Query'
	opportunities: {
		__typename?: 'Opportunities'
		total: number
		items: Array<{
			__typename?: 'Opportunity'
			id: string
			directed: boolean
			interested?: boolean | null
			matchedBy?: OpportunityInterest | null
			status: Status
			bookbuild: {
				__typename?: 'BookbuildPublic'
				id: string
				side: Side
				daysAdtv?: number | null
				volume: number
				startedAt?: string | null
				endsAt: string
				endedAt?: string | null
				cancelledAt?: string | null
				failedAt?: string | null
				executingAt?: string | null
				referencePrice: number
				instrumentPrimaryAdv?: number | null
				instrument: {
					__typename?: 'Instrument'
					id: string
					isin: string
					marketID: string
					currency: string
					description: string
					sedol: string
					primaryBloombergTicker: string
					referencePrice: number
					averageDailyVolume?: number | null
					preTradeLISValueCurrency: number
					marketName: string
					staticRefPriceCollarPercent: number
				}
				allocability: Array<{
					__typename?: 'BookbuildAllocability'
					allocablePercent: number
					demandPercent: number
				}>
			}
			order?: {
				__typename?: 'Order'
				id: string
				amendedAt?: string | null
				cancelledAt?: string | null
				score?: OrderScore | null
				clientOrderId?: string | null
				volume: number
				price: number
				upsizeVolume?: number | null
				upsizePrice?: number | null
				allocation?: {
					__typename?: 'Allocation'
					id: string
					side: Side
					allocation: number
					grossCash: number
					createdAt: string
					scoreTime?: number | null
					scoreVwap?: number | null
					tariff?: number | null
					organization?: {
						__typename?: 'Organization'
						id: string
						name: string
					} | null
					counterparty?: {
						__typename?: 'Organization'
						id: string
						name: string
					} | null
					deal: {
						__typename?: 'Deal'
						id: string
						completedAt?: string | null
						referencePrice: number
						price: number
						volume: number
						daysAdtv?: number | null
						cashGross: number
					}
				} | null
				broker: {
					__typename?: 'BrokerPublic'
					id: string
					name: string
				}
				entity: { __typename?: 'Entity'; id: string; name: string }
			} | null
			liveWatchlistMatches?: Array<{
				__typename?: 'WatchlistItem'
				id: string
			}> | null
			passiveInterestMatches?: Array<{
				__typename?: 'OpportunityMatchItem'
				id: string
			}> | null
		}>
	}
}

export type NotificationsQueryVariables = Exact<{
	limit: Scalars['Int']['input']
}>

export type NotificationsQuery = {
	__typename?: 'Query'
	notifications: {
		__typename?: 'Notifications'
		total: number
		totalUnread: number
		items: Array<{
			__typename?: 'Notification'
			id: string
			createdAt: string
			readAt?: string | null
			url: string
			title: string
			description: string
			sticky: boolean
		}>
	}
}

export type OrdersQueryVariables = Exact<{
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: OrdersSortCol
	sortDesc: Scalars['Boolean']['input']
}>

export type OrdersQuery = {
	__typename?: 'Query'
	orders: {
		__typename?: 'Orders'
		total: number
		items: Array<{
			__typename?: 'Order'
			id: string
			createdAt: string
			amendedAt?: string | null
			cancelledAt?: string | null
			status: Status
			bookbuild: {
				__typename?: 'BookbuildPublic'
				side: Side
				endsAt: string
				instrument: {
					__typename?: 'Instrument'
					description: string
					marketName: string
					currency: string
				}
			}
			allocation?: {
				__typename?: 'Allocation'
				allocation: number
				deal: { __typename?: 'Deal'; price: number }
			} | null
		}>
	}
}

export type OrderQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type OrderQuery = {
	__typename?: 'Query'
	order?: {
		__typename?: 'Order'
		id: string
		createdAt: string
		amendedAt?: string | null
		cancelledAt?: string | null
		score?: OrderScore | null
		clientOrderId?: string | null
		volume: number
		price: number
		upsizeVolume?: number | null
		upsizePrice?: number | null
		status: Status
		notionalUsd: number
		notionalGbp: number
		notionalCash: number
		upsizeNotionalUsd?: number | null
		upsizeNotionalGbp?: number | null
		upsizeNotionalCash?: number | null
		daysAdtv?: number | null
		upsizeDaysAdtv?: number | null
		targetTariff?: number | null
		upsizeTariff?: number | null
		bookbuild: {
			__typename?: 'BookbuildPublic'
			instrumentPrimaryAdv?: number | null
			id: string
			side: Side
			daysAdtv?: number | null
			volume: number
			startedAt?: string | null
			endsAt: string
			endedAt?: string | null
			cancelledAt?: string | null
			failedAt?: string | null
			executingAt?: string | null
			referencePrice: number
			instrument: {
				__typename?: 'Instrument'
				id: string
				isin: string
				marketID: string
				currency: string
				description: string
				sedol: string
				primaryBloombergTicker: string
				referencePrice: number
				averageDailyVolume?: number | null
				preTradeLISValueCurrency: number
				marketName: string
				staticRefPriceCollarPercent: number
			}
			allocability: Array<{
				__typename?: 'BookbuildAllocability'
				allocablePercent: number
				demandPercent: number
			}>
		}
		allocation?: {
			__typename?: 'Allocation'
			id: string
			side: Side
			allocation: number
			grossCash: number
			createdAt: string
			scoreTime?: number | null
			scoreVwap?: number | null
			tariff?: number | null
			organization?: {
				__typename?: 'Organization'
				id: string
				name: string
			} | null
			counterparty?: {
				__typename?: 'Organization'
				id: string
				name: string
			} | null
			deal: {
				__typename?: 'Deal'
				id: string
				completedAt?: string | null
				referencePrice: number
				price: number
				volume: number
				daysAdtv?: number | null
				cashGross: number
			}
		} | null
		broker: { __typename?: 'BrokerPublic'; id: string; name: string }
		brokerLimitViolation?: {
			__typename?: 'BrokerLimitViolation'
			currentExposureUsd: number
			potentialExposureUsd: number
			allocatedOrderExposureUsd?: number | null
			currentExposureLimitPercentage: number
			potentialExposureLimitPercentage: number
			allocatedOrderExposureLimitPercentage?: number | null
			entityBroker: {
				__typename?: 'EntityBroker'
				broker: { __typename?: 'Broker'; id: string; name: string }
			}
		} | null
	} | null
}

export type BookbuildQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type BookbuildQuery = {
	__typename?: 'Query'
	bookbuild?: {
		__typename?: 'Bookbuild'
		id: string
		daysAdtv?: number | null
		upsizeDaysAdtv?: number | null
		volume: number
		price: number
		upsizeVolume?: number | null
		upsizePrice?: number | null
		side: Side
		createdAt: string
		endsAt: string
		startedAt?: string | null
		cancelRequestedAt?: string | null
		cancelRequestedBy?: string | null
		cancelRequestJustification?: string | null
		cancelledAt?: string | null
		cancelledJustification?: string | null
		failedAt?: string | null
		failedBy?: string | null
		failedJustification?: string | null
		endedAt?: string | null
		executingAt?: string | null
		status: Status
		referencePrice: number
		clientOrderId?: string | null
		strategy: DealStrategy
		notionalUsd: number
		notionalGbp: number
		notionalCash: number
		instrumentPrimaryAdv?: number | null
		targetTariff?: number | null
		upsizeTariff?: number | null
		captureDownsized: boolean
		instrument: {
			__typename?: 'Instrument'
			id: string
			isin: string
			marketID: string
			currency: string
			description: string
			sedol: string
			primaryBloombergTicker: string
			referencePrice: number
			averageDailyVolume?: number | null
			preTradeLISValueCurrency: number
			marketName: string
			staticRefPriceCollarPercent: number
		}
		distribution: {
			__typename?: 'BookbuildDistribution'
			priceDiscovery: boolean
			liveWatchlists: boolean
			passiveInterest: boolean
			directOrgIds?: Array<string> | null
			excludedOrgIds: Array<string>
		}
		allocability: Array<{
			__typename?: 'BookbuildAllocability'
			id: string
			date: string
			demandPercent: number
			allocablePercent: number
			totalOrders: number
			dealPrice?: number | null
			dealVolume?: number | null
			allocations?: string | null
			downsized: boolean
		}>
		reach: {
			__typename?: 'BookbuildReach'
			priceDiscoveryFirms: number
			liveWatchlistsFirms: number
			passiveInterestFirms: number
			directFirms: number
		}
		deal?: {
			__typename?: 'Deal'
			id: string
			price: number
			volume: number
			daysAdtv?: number | null
			totalOrders: number
			referencePrice: number
			cashGross: number
			createdAt: string
			completedAt?: string | null
			tariff?: number | null
		} | null
		broker: { __typename?: 'BrokerPublic'; id: string; name: string }
		entity: { __typename?: 'Entity'; id: string; name: string }
		brokerLimitViolation?: {
			__typename?: 'BrokerLimitViolation'
			currentExposureUsd: number
			potentialExposureUsd: number
			allocatedOrderExposureUsd?: number | null
			currentExposureLimitPercentage: number
			potentialExposureLimitPercentage: number
			allocatedOrderExposureLimitPercentage?: number | null
			notionalUsd: number
			entityBroker: {
				__typename?: 'EntityBroker'
				limitUsd: number
				broker: { __typename?: 'Broker'; id: string; name: string }
			}
		} | null
	} | null
	distributionOrgs: Array<{
		__typename?: 'OrganizationPublic'
		id: string
		name: string
		category: OrgCategory
		exclude: boolean
		recentlyAdded: boolean
	}>
	distributionBrokers: Array<{
		__typename?: 'BrokerPublic'
		id: string
		name: string
	}>
}

export type AdminUsersQueryVariables = Exact<{
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: UsersSortCol
	sortDesc: Scalars['Boolean']['input']
	filter?: InputMaybe<Scalars['String']['input']>
}>

export type AdminUsersQuery = {
	__typename?: 'Query'
	users: {
		__typename?: 'Users'
		total: number
		items: Array<{
			__typename?: 'User'
			id: string
			name: string
			email: string
			disabled: boolean
			welcomeEmailSentAt?: string | null
			sessionLastEventAt?: string | null
			lastAppOpenedAt?: string | null
			organization: {
				__typename?: 'Organization'
				id: string
				name: string
				type: OrgType
			}
		}>
	}
}

export type AdminUserQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type AdminUserQuery = {
	__typename?: 'Query'
	user?: {
		__typename?: 'User'
		id: string
		email: string
		name: string
		disabled: boolean
		traderId?: string | null
		bloombergUserId?: string | null
		sessionStartedAt?: string | null
		sessionLastEventAt?: string | null
		auth0Id?: string | null
		welcomeEmailSentAt?: string | null
		watchlistEmailSentAt?: string | null
		organization: {
			__typename?: 'Organization'
			id: string
			name: string
			type: OrgType
			entities: Array<{ __typename?: 'Entity'; id: string; name: string }>
		}
		entities: Array<{
			__typename?: 'UserEntity'
			isDefault: boolean
			entity: { __typename?: 'Entity'; id: string; name: string }
		}>
		roles: Array<{ __typename?: 'Role'; id: string }>
		preferences: { __typename?: 'UserPreferences'; timeZone: string }
	} | null
}

export type AdminUserEventsTooltipQueryVariables = Exact<{
	userId: Scalars['ID']['input']
	limit: Scalars['Int']['input']
}>

export type AdminUserEventsTooltipQuery = {
	__typename?: 'Query'
	user?: {
		__typename?: 'User'
		lastAppOpenedAt?: string | null
		events: {
			__typename?: 'UserEvents'
			total: number
			items: Array<{
				__typename?: 'UserEvent'
				id: string
				eventTime: string
				eventType: string
			}>
		}
	} | null
}

export type AdminUserEventsQueryVariables = Exact<{
	userId: Scalars['ID']['input']
	limit: Scalars['Int']['input']
	offset?: InputMaybe<Scalars['Int']['input']>
	excludeTypes?: InputMaybe<
		Array<Scalars['String']['input']> | Scalars['String']['input']
	>
}>

export type AdminUserEventsQuery = {
	__typename?: 'Query'
	userEvents: {
		__typename?: 'UserEvents'
		total: number
		types: Array<string>
		items: Array<{
			__typename?: 'UserEvent'
			id: string
			eventTime: string
			eventType: string
			eventSource: string
			eventProperties?: unknown | null
			user: { __typename?: 'User'; id: string; name: string }
		}>
	}
}

export type AdminOrgUserEventsQueryVariables = Exact<{
	orgId: Scalars['ID']['input']
	limit: Scalars['Int']['input']
	offset?: InputMaybe<Scalars['Int']['input']>
	excludeTypes?: InputMaybe<
		Array<Scalars['String']['input']> | Scalars['String']['input']
	>
}>

export type AdminOrgUserEventsQuery = {
	__typename?: 'Query'
	organizationUserEvents: {
		__typename?: 'UserEvents'
		total: number
		types: Array<string>
		items: Array<{
			__typename?: 'UserEvent'
			id: string
			eventTime: string
			eventType: string
			eventSource: string
			eventProperties?: unknown | null
			user: { __typename?: 'User'; id: string; name: string }
		}>
	}
}

export type AdminPlatformUserEventsQueryVariables = Exact<{
	limit: Scalars['Int']['input']
	offset?: InputMaybe<Scalars['Int']['input']>
	excludeTypes?: InputMaybe<
		Array<Scalars['String']['input']> | Scalars['String']['input']
	>
}>

export type AdminPlatformUserEventsQuery = {
	__typename?: 'Query'
	userEvents: {
		__typename?: 'UserEvents'
		total: number
		types: Array<string>
		items: Array<{
			__typename?: 'UserEvent'
			id: string
			eventTime: string
			eventType: string
			eventSource: string
			eventProperties?: unknown | null
			user: { __typename?: 'User'; id: string; name: string }
		}>
	}
}

export type FixSessionsQueryVariables = Exact<{ [key: string]: never }>

export type FixSessionsQuery = {
	__typename?: 'Query'
	fixSessions: Array<string>
}

export type FixLogsQueryVariables = Exact<{
	session?: InputMaybe<Scalars['String']['input']>
	first?: InputMaybe<Scalars['Int']['input']>
	after?: InputMaybe<Scalars['ID']['input']>
	last?: InputMaybe<Scalars['Int']['input']>
	before?: InputMaybe<Scalars['ID']['input']>
}>

export type FixLogsQuery = {
	__typename?: 'Query'
	fixLogs?: {
		__typename?: 'FixLogConnection'
		edges: Array<{
			__typename?: 'FixLogEdge'
			cursor: string
			node: {
				__typename?: 'FixLog'
				id: string
				recordedAt: string
				direction?: string | null
				session: string
				message: string
			}
		}>
		pageInfo: {
			__typename?: 'PageInfo'
			startCursor?: string | null
			endCursor?: string | null
			hasNextPage: boolean
			hasPreviousPage: boolean
		}
	} | null
	fixCloudLogs?: {
		__typename?: 'FixCloudLogConnection'
		nextToken?: string | null
		items: Array<{
			__typename?: 'FixCloudLog'
			id: string
			recordedAt: string
			direction?: string | null
			session: string
			message: string
		}>
	} | null
}

export type ApiKeysQueryVariables = Exact<{ [key: string]: never }>

export type ApiKeysQuery = {
	__typename?: 'Query'
	listKeys: Array<{
		__typename?: 'APIKey'
		id: string
		createdAt: string
		expiryDate: string
		organization: { __typename?: 'Organization'; id: string; name: string }
	}>
}

export type InstrumentQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type InstrumentQuery = {
	__typename?: 'Query'
	instrument: {
		__typename?: 'Instrument'
		id: string
		isin: string
		marketID: string
		currency: string
		description: string
		sedol: string
		primaryBloombergTicker: string
		referencePrice: number
		averageDailyVolume?: number | null
		preTradeLISValueCurrency: number
		marketName: string
		staticRefPriceCollarPercent: number
	}
}

export type FindInstrumentsQueryVariables = Exact<{
	identifier: Scalars['String']['input']
}>

export type FindInstrumentsQuery = {
	__typename?: 'Query'
	findInstruments: Array<{
		__typename?: 'Instrument'
		id: string
		isin: string
		marketID: string
		currency: string
		description: string
		sedol: string
		primaryBloombergTicker: string
		referencePrice: number
		averageDailyVolume?: number | null
		preTradeLISValueCurrency: number
		marketName: string
		staticRefPriceCollarPercent: number
		holdings: {
			__typename?: 'InstrumentHoldings'
			holders: Array<{
				__typename?: 'Holder'
				fuzzyHoldingAmount: string
				trend: HoldingTrend
				reportDate?: string | null
				valueChangedUSD: number
				valueChangedADVPercent: number
				organization: {
					__typename?: 'OrganizationPublic'
					id: string
					name: string
					category: OrgCategory
					exclude: boolean
					recentlyAdded: boolean
				}
			}>
		}
	}>
}

export type BrokersQueryVariables = Exact<{ [key: string]: never }>

export type BrokersQuery = {
	__typename?: 'Query'
	brokers: Array<{
		__typename?: 'Broker'
		id: string
		name: string
		fixEmsId: string
		sessions: Array<{
			__typename?: 'BrokerSession'
			turquoiseCompId: string
		}>
		organizations: Array<{ __typename?: 'BrokerOrganization'; id: string }>
	}>
}

export type BrokerQueryVariables = Exact<{
	id: Scalars['ID']['input']
	withPassword?: InputMaybe<Scalars['Boolean']['input']>
}>

export type BrokerQuery = {
	__typename?: 'Query'
	broker?: {
		__typename?: 'Broker'
		id: string
		name: string
		fixEmsId: string
		strategy: BrokerStrategy
		technicalSupportLink?: string | null
		thresholdPercentage: number
		sessions: Array<{
			__typename?: 'BrokerSession'
			id: string
			executionVenue: ExecutionVenue
			turquoiseCompId: string
			turquoiseMemberId: string
			turquoiseTraderGroup: string
			socketConnectHost: string
			socketConnectPort: number
			sessionStartTime: string
			sessionEndTime: string
			sessionTimeZone: string
			maxMessagesPerSecond: number
			weekdays: string
			ignoreTradingCalendar: boolean
			password?: string | null
			tempPassword?: string | null
		}>
		organizations: Array<{
			__typename?: 'BrokerOrganization'
			id: string
			name: string
			entities: Array<{
				__typename?: 'EntityBroker'
				brokerCode: string
				limitUsd: number
				turquoiseShortcode: number
				entity: {
					__typename?: 'Entity'
					id: string
					name: string
					defaultBroker: { __typename?: 'Broker'; id: string }
				}
			}>
		}>
	} | null
}

export type AdminBookbuildsQueryVariables = Exact<{
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: BookbuildsSortCol
	sortDesc: Scalars['Boolean']['input']
}>

export type AdminBookbuildsQuery = {
	__typename?: 'Query'
	bookbuilds: {
		__typename?: 'Bookbuilds'
		total: number
		items: Array<{
			__typename?: 'Bookbuild'
			id: string
			side: Side
			status: Status
			updatedAt: string
			cancelRequestedAt?: string | null
			createdAt: string
			endsAt: string
			daysAdtv?: number | null
			instrument: {
				__typename?: 'Instrument'
				id: string
				description: string
			}
			organization: { __typename?: 'Organization'; name: string }
			broker: { __typename?: 'BrokerPublic'; name: string }
		}>
	}
}

export type AdminBookbuildQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type AdminBookbuildQuery = {
	__typename?: 'Query'
	bookbuild?: {
		__typename?: 'Bookbuild'
		updatedAt: string
		suspendedAt?: string | null
		suspendedReason?: string | null
		bypassBrokerLimits: boolean
		notionalUsd: number
		upsizeNotionalUsd?: number | null
		canEnd: boolean
		canCancel: boolean
		canPartialComplete: boolean
		targetTariff?: number | null
		upsizeTariff?: number | null
		instrumentPrimaryAdv?: number | null
		id: string
		daysAdtv?: number | null
		upsizeDaysAdtv?: number | null
		volume: number
		price: number
		upsizeVolume?: number | null
		upsizePrice?: number | null
		side: Side
		createdAt: string
		endsAt: string
		startedAt?: string | null
		cancelRequestedAt?: string | null
		cancelRequestedBy?: string | null
		cancelRequestJustification?: string | null
		cancelledAt?: string | null
		cancelledJustification?: string | null
		failedAt?: string | null
		failedBy?: string | null
		failedJustification?: string | null
		endedAt?: string | null
		executingAt?: string | null
		status: Status
		referencePrice: number
		clientOrderId?: string | null
		strategy: DealStrategy
		notionalGbp: number
		notionalCash: number
		captureDownsized: boolean
		instrument: {
			__typename?: 'Instrument'
			primaryBloombergTicker: string
			sector: string
			marketCountryCode: string
			id: string
			isin: string
			marketID: string
			currency: string
			description: string
			sedol: string
			referencePrice: number
			averageDailyVolume?: number | null
			preTradeLISValueCurrency: number
			marketName: string
			staticRefPriceCollarPercent: number
			ranking?: {
				__typename?: 'InstrumentRanking'
				ranking: number
				score: number
			} | null
			holdings: {
				__typename?: 'InstrumentHoldings'
				fuzzyTotalAmount: string
				holders: Array<{
					__typename?: 'Holder'
					fuzzyHoldingAmount: string
					trend: HoldingTrend
					reportDate?: string | null
					valueChangedUSD: number
					valueChangedADVPercent: number
					organization: {
						__typename?: 'OrganizationPublic'
						id: string
						name: string
					}
				}>
			}
		}
		user?: {
			__typename?: 'User'
			name: string
			exclusions: Array<{ __typename?: 'OrganizationPublic'; id: string }>
		} | null
		organization: { __typename?: 'Organization'; id: string; name: string }
		entity: { __typename?: 'Entity'; id: string; name: string }
		orders: Array<{
			__typename?: 'Order'
			id: string
			createdAt: string
			amendedAt?: string | null
			cancelledAt?: string | null
			score?: OrderScore | null
			price: number
			volume: number
			daysAdtv?: number | null
			notionalUsd: number
			upsizeDaysAdtv?: number | null
			upsizeVolume?: number | null
			upsizePrice?: number | null
			upsizeNotionalUsd?: number | null
			targetTariff?: number | null
			upsizeTariff?: number | null
			organization: {
				__typename?: 'Organization'
				id: string
				name: string
			}
			broker: { __typename?: 'BrokerPublic'; name: string }
			brokerLimitViolation?: {
				__typename?: 'BrokerLimitViolation'
				currentExposureUsd: number
				potentialExposureUsd: number
				allocatedOrderExposureUsd?: number | null
				currentExposureLimitPercentage: number
				potentialExposureLimitPercentage: number
				allocatedOrderExposureLimitPercentage?: number | null
				notionalUsd: number
				entityBroker: {
					__typename?: 'EntityBroker'
					limitUsd: number
					broker: { __typename?: 'Broker'; id: string; name: string }
				}
			} | null
		}>
		opportunities: Array<{
			__typename?: 'Opportunity'
			id: string
			directed: boolean
			interested?: boolean | null
			interestedUpdatedAt?: string | null
			matchedBy?: OpportunityInterest | null
			organization: {
				__typename?: 'Organization'
				id: string
				name: string
				users: Array<{
					__typename?: 'User'
					id: string
					email: string
					name: string
					sessionStartedAt?: string | null
					sessionLastEventAt?: string | null
					lastAppOpenedAt?: string | null
					lastEvent?: {
						__typename?: 'UserEvent'
						id: string
						eventTime: string
						eventType: string
					} | null
				}>
			}
			order?: { __typename?: 'Order'; id: string } | null
			interestedUpdatedBy?: {
				__typename?: 'User'
				id: string
				email: string
				name: string
			} | null
		}>
		deal?: {
			__typename?: 'Deal'
			id: string
			price: number
			volume: number
			daysAdtv?: number | null
			totalOrders: number
			referencePrice: number
			cashGross: number
			createdAt: string
			completedAt?: string | null
			tariff?: number | null
			notionalUsd: number
			allocations: Array<{
				__typename?: 'Allocation'
				id: string
				side: Side
				allocation: number
				grossCash: number
				createdAt: string
				proRata?: number | null
				vwap?: number | null
				scoreTime?: number | null
				scoreVwap?: number | null
				scoreAdjusted?: number | null
				tariff?: number | null
				daysAdtv?: number | null
				notionalUsd: number
				dealPercentage: number
				organization?: {
					__typename?: 'Organization'
					id: string
					name: string
				} | null
				counterparty?: {
					__typename?: 'Organization'
					id: string
					name: string
				} | null
				order?: {
					__typename?: 'Order'
					id: string
					volume: number
					price: number
					upsizeVolume?: number | null
					upsizePrice?: number | null
					notionalUsd: number
					broker: { __typename?: 'BrokerPublic'; name: string }
					brokerLimitViolation?: {
						__typename?: 'BrokerLimitViolation'
						id: string
						notionalUsd: number
						allocatedOrderExposureUsd?: number | null
						allocatedOrderExposureLimitPercentage?: number | null
						potentialExposureUsd: number
						potentialExposureLimitPercentage: number
						entityBroker: {
							__typename?: 'EntityBroker'
							limitUsd: number
						}
					} | null
				} | null
				trades: Array<{
					__typename?: 'Trade'
					id: string
					crossId: string
					createdAt: string
					sentAt?: string | null
					acknowledgedAt?: string | null
					executedAt?: string | null
					rejectedAt?: string | null
					rejectedReason?: string | null
					failedAt?: string | null
					volume: number
					grossCash: number
					organization: {
						__typename?: 'Organization'
						id: string
						name: string
					}
				}>
				broker: {
					__typename?: 'BrokerPublic'
					id: string
					name: string
				}
			}>
			trades: Array<{
				__typename?: 'Trade'
				id: string
				crossId: string
				createdAt: string
				sentAt?: string | null
				acknowledgedAt?: string | null
				executedAt?: string | null
				rejectedAt?: string | null
				rejectedReason?: string | null
				failedAt?: string | null
				volume: number
				grossCash: number
				organization: {
					__typename?: 'Organization'
					id: string
					name: string
				}
			}>
		} | null
		fixLogs: Array<{
			__typename?: 'FixLogEdge'
			cursor: string
			node: {
				__typename?: 'FixLog'
				id: string
				recordedAt: string
				direction?: string | null
				session: string
				message: string
			}
		}>
		potentialDeal?: {
			__typename?: 'PotentialDeal'
			id: string
			daysAdtv?: number | null
			volume: number
			notionalUsd: number
			price: number
			tariff: number
			broker: { __typename?: 'BrokerPublic'; id: string; name: string }
			allocations: Array<{
				__typename?: 'PotentialAllocation'
				id: string
				volume: number
				daysAdtv?: number | null
				notionalUsd: number
				dealPercentage: number
				tariff: number
				organization: {
					__typename?: 'Organization'
					id: string
					name: string
				}
				broker: { __typename?: 'BrokerPublic'; name: string }
				order: {
					__typename?: 'Order'
					id: string
					brokerLimitViolation?: {
						__typename?: 'BrokerLimitViolation'
						id: string
						notionalUsd: number
						allocatedOrderExposureUsd?: number | null
						allocatedOrderExposureLimitPercentage?: number | null
						potentialExposureUsd: number
						potentialExposureLimitPercentage: number
						entityBroker: {
							__typename?: 'EntityBroker'
							limitUsd: number
						}
					} | null
				}
			}>
		} | null
		previewReach?: {
			__typename?: 'PreviewReach'
			liveWatchlistsFirms: number
			passiveInterestFirms: number
			priceDiscoveryFirms: number
		} | null
		distribution: {
			__typename?: 'BookbuildDistribution'
			priceDiscovery: boolean
			liveWatchlists: boolean
			passiveInterest: boolean
			directOrgIds?: Array<string> | null
			excludedOrgIds: Array<string>
		}
		allocability: Array<{
			__typename?: 'BookbuildAllocability'
			id: string
			date: string
			demandPercent: number
			allocablePercent: number
			totalOrders: number
			dealPrice?: number | null
			dealVolume?: number | null
			allocations?: string | null
			downsized: boolean
		}>
		reach: {
			__typename?: 'BookbuildReach'
			priceDiscoveryFirms: number
			liveWatchlistsFirms: number
			passiveInterestFirms: number
			directFirms: number
		}
		broker: { __typename?: 'BrokerPublic'; id: string; name: string }
		brokerLimitViolation?: {
			__typename?: 'BrokerLimitViolation'
			currentExposureUsd: number
			potentialExposureUsd: number
			allocatedOrderExposureUsd?: number | null
			currentExposureLimitPercentage: number
			potentialExposureLimitPercentage: number
			allocatedOrderExposureLimitPercentage?: number | null
			notionalUsd: number
			entityBroker: {
				__typename?: 'EntityBroker'
				limitUsd: number
				broker: { __typename?: 'Broker'; id: string; name: string }
			}
		} | null
	} | null
	distributionOrgs: Array<{
		__typename?: 'OrganizationPublic'
		id: string
		name: string
		category: OrgCategory
		exclude: boolean
		recentlyAdded: boolean
	}>
}

export type AdminBookbuildV2QueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type AdminBookbuildV2Query = {
	__typename?: 'Query'
	bookbuild?: {
		__typename?: 'Bookbuild'
		id: string
		bypassBrokerLimits: boolean
		canCancel: boolean
		cancelledAt?: string | null
		cancelledJustification?: string | null
		cancelRequestedAt?: string | null
		cancelRequestedBy?: string | null
		cancelRequestJustification?: string | null
		canEnd: boolean
		canPartialComplete: boolean
		captureDownsized: boolean
		createdAt: string
		daysAdtv?: number | null
		endedAt?: string | null
		endsAt: string
		executingAt?: string | null
		failedAt?: string | null
		failedBy?: string | null
		failedJustification?: string | null
		instrumentPrimaryAdv?: number | null
		notionalUsd: number
		price: number
		side: Side
		startedAt?: string | null
		status: Status
		strategy: DealStrategy
		suspendedAt?: string | null
		suspendedReason?: string | null
		targetTariff?: number | null
		updatedAt: string
		upsizeDaysAdtv?: number | null
		upsizeNotionalUsd?: number | null
		upsizePrice?: number | null
		upsizeTariff?: number | null
		upsizeVolume?: number | null
		volume: number
		allocability: Array<{
			__typename?: 'BookbuildAllocability'
			id: string
			date: string
			dealPrice?: number | null
			dealVolume?: number | null
			demandPercent: number
			downsized: boolean
			allocablePercent: number
			totalOrders: number
		}>
		brokerLimitViolation?: {
			__typename?: 'BrokerLimitViolation'
			id: string
			allocatedOrderExposureLimitPercentage?: number | null
			allocatedOrderExposureUsd?: number | null
			notionalUsd: number
			potentialExposureLimitPercentage: number
			potentialExposureUsd: number
			entityBroker: { __typename?: 'EntityBroker'; limitUsd: number }
		} | null
		broker: { __typename?: 'BrokerPublic'; id: string; name: string }
		distribution: {
			__typename?: 'BookbuildDistribution'
			directOrgIds?: Array<string> | null
			excludedOrgIds: Array<string>
			liveWatchlists: boolean
			passiveInterest: boolean
			priceDiscovery: boolean
		}
		deal?: {
			__typename?: 'Deal'
			id: string
			completedAt?: string | null
			createdAt: string
			daysAdtv?: number | null
			notionalUsd: number
			price: number
			tariff?: number | null
			volume: number
			executedVolume?: number | null
			executedDaysAdtv?: number | null
			executedNotionalUsd?: number | null
			executedOriginatorTariffBps?: number | null
			allocations: Array<{
				__typename?: 'Allocation'
				id: string
				allocation: number
				daysAdtv?: number | null
				dealPercentage: number
				notionalUsd: number
				executedVolume?: number | null
				executedDaysAdtv?: number | null
				executedNotionalUsd?: number | null
				executedParticipantTariffBps?: number | null
				side: Side
				tariff?: number | null
				broker: {
					__typename?: 'BrokerPublic'
					id: string
					name: string
				}
				counterparty?: {
					__typename?: 'Organization'
					id: string
					name: string
				} | null
				order?: {
					__typename?: 'Order'
					id: string
					price: number
					broker: {
						__typename?: 'BrokerPublic'
						id: string
						name: string
					}
					brokerLimitViolation?: {
						__typename?: 'BrokerLimitViolation'
						id: string
						allocatedOrderExposureLimitPercentage?: number | null
						allocatedOrderExposureUsd?: number | null
						notionalUsd: number
						potentialExposureLimitPercentage: number
						potentialExposureUsd: number
						entityBroker: {
							__typename?: 'EntityBroker'
							limitUsd: number
						}
					} | null
				} | null
				organization?: {
					__typename?: 'Organization'
					id: string
					name: string
				} | null
				trades: Array<{
					__typename?: 'Trade'
					id: string
					acknowledgedAt?: string | null
					createdAt: string
					crossId: string
					executedAt?: string | null
					failedAt?: string | null
					cancelledAt?: string | null
					grossCash: number
					rejectedAt?: string | null
					rejectedReason?: string | null
					sentAt?: string | null
					volume: number
					organization: {
						__typename?: 'Organization'
						id: string
						name: string
					}
				}>
			}>
		} | null
		instrument: {
			__typename?: 'Instrument'
			id: string
			currency: string
			description: string
			marketName: string
			preTradeLISValueCurrency: number
			primaryBloombergTicker: string
			referencePrice: number
			sedol: string
			holdings: {
				__typename?: 'InstrumentHoldings'
				fuzzyTotalAmount: string
				holders: Array<{
					__typename?: 'Holder'
					fuzzyHoldingAmount: string
					reportDate?: string | null
					trend: HoldingTrend
					valueChangedADVPercent: number
					valueChangedUSD: number
					organization: {
						__typename?: 'OrganizationPublic'
						id: string
						name: string
					}
				}>
			}
			ranking?: {
				__typename?: 'InstrumentRanking'
				ranking: number
				score: number
			} | null
		}
		opportunities: Array<{
			__typename?: 'Opportunity'
			id: string
			directed: boolean
			interested?: boolean | null
			interestedUpdatedAt?: string | null
			matchedBy?: OpportunityInterest | null
			interestedUpdatedBy?: {
				__typename?: 'User'
				id: string
				email: string
				name: string
			} | null
			order?: { __typename?: 'Order'; id: string } | null
			organization: {
				__typename?: 'Organization'
				id: string
				name: string
				users: Array<{
					__typename?: 'User'
					id: string
					email: string
					lastAppOpenedAt?: string | null
					name: string
					sessionLastEventAt?: string | null
					sessionStartedAt?: string | null
					lastEvent?: {
						__typename?: 'UserEvent'
						id: string
						eventTime: string
						eventType: string
					} | null
				}>
			}
		}>
		orders: Array<{
			__typename?: 'Order'
			id: string
			amendedAt?: string | null
			createdAt: string
			daysAdtv?: number | null
			notionalUsd: number
			price: number
			score?: OrderScore | null
			status: Status
			targetTariff?: number | null
			upsizeDaysAdtv?: number | null
			upsizeNotionalUsd?: number | null
			upsizePrice?: number | null
			upsizeTariff?: number | null
			upsizeVolume?: number | null
			volume: number
			user: { __typename?: 'User'; id: string; name: string }
			broker: { __typename?: 'BrokerPublic'; id: string; name: string }
			brokerLimitViolation?: {
				__typename?: 'BrokerLimitViolation'
				id: string
				allocatedOrderExposureLimitPercentage?: number | null
				allocatedOrderExposureUsd?: number | null
				notionalUsd: number
				potentialExposureLimitPercentage: number
				potentialExposureUsd: number
				entityBroker: { __typename?: 'EntityBroker'; limitUsd: number }
			} | null
			organization: {
				__typename?: 'Organization'
				id: string
				name: string
			}
		}>
		organization: { __typename?: 'Organization'; id: string; name: string }
		potentialDeal?: {
			__typename?: 'PotentialDeal'
			id: string
			daysAdtv?: number | null
			notionalUsd: number
			price: number
			tariff: number
			volume: number
			allocations: Array<{
				__typename?: 'PotentialAllocation'
				id: string
				daysAdtv?: number | null
				dealPercentage: number
				notionalUsd: number
				tariff: number
				volume: number
				order: {
					__typename?: 'Order'
					id: string
					brokerLimitViolation?: {
						__typename?: 'BrokerLimitViolation'
						id: string
						allocatedOrderExposureLimitPercentage?: number | null
						allocatedOrderExposureUsd?: number | null
						notionalUsd: number
						potentialExposureLimitPercentage: number
						potentialExposureUsd: number
						entityBroker: {
							__typename?: 'EntityBroker'
							limitUsd: number
						}
					} | null
				}
				broker: {
					__typename?: 'BrokerPublic'
					id: string
					name: string
				}
				organization: {
					__typename?: 'Organization'
					id: string
					name: string
				}
			}>
		} | null
		previewReach?: {
			__typename?: 'PreviewReach'
			liveWatchlistsFirms: number
			passiveInterestFirms: number
			priceDiscoveryFirms: number
		} | null
		reach: {
			__typename?: 'BookbuildReach'
			liveWatchlistsFirms: number
			passiveInterestFirms: number
			priceDiscoveryFirms: number
		}
		user?: {
			__typename?: 'User'
			name: string
			exclusions: Array<{ __typename?: 'OrganizationPublic'; id: string }>
		} | null
	} | null
	distributionOrgs: Array<{
		__typename?: 'OrganizationPublic'
		id: string
		name: string
		category: OrgCategory
		exclude: boolean
	}>
}

export type AdminBookbuildFixLogsQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type AdminBookbuildFixLogsQuery = {
	__typename?: 'Query'
	bookbuild?: {
		__typename?: 'Bookbuild'
		id: string
		status: Status
		fixLogs: Array<{
			__typename?: 'FixLogEdge'
			cursor: string
			node: {
				__typename?: 'FixLog'
				id: string
				recordedAt: string
				direction?: string | null
				session: string
				message: string
			}
		}>
		fixCloudLogs: Array<{
			__typename?: 'FixCloudLog'
			id: string
			recordedAt: string
			direction?: string | null
			session: string
			message: string
		}>
	} | null
}

export type AdminOrdersPaginatedQueryVariables = Exact<{
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: OrdersSortCol
	sortDesc: Scalars['Boolean']['input']
}>

export type AdminOrdersPaginatedQuery = {
	__typename?: 'Query'
	orders: {
		__typename?: 'Orders'
		total: number
		items: Array<{
			__typename?: 'Order'
			id: string
			createdAt: string
			amendedAt?: string | null
			cancelledAt?: string | null
			status: Status
			bookbuild: {
				__typename?: 'BookbuildPublic'
				side: Side
				endsAt: string
				instrument: {
					__typename?: 'Instrument'
					description: string
					marketName: string
				}
			}
			organization: { __typename?: 'Organization'; name: string }
			broker: { __typename?: 'BrokerPublic'; name: string }
		}>
	}
}

export type AdminOrderQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type AdminOrderQuery = {
	__typename?: 'Query'
	order?: {
		__typename?: 'Order'
		id: string
		createdAt: string
		amendedAt?: string | null
		cancelledAt?: string | null
		score?: OrderScore | null
		clientOrderId?: string | null
		volume: number
		price: number
		upsizeVolume?: number | null
		upsizePrice?: number | null
		status: Status
		notionalUsd: number
		notionalGbp: number
		notionalCash: number
		upsizeNotionalUsd?: number | null
		upsizeNotionalGbp?: number | null
		upsizeNotionalCash?: number | null
		daysAdtv?: number | null
		upsizeDaysAdtv?: number | null
		targetTariff?: number | null
		upsizeTariff?: number | null
		organization: { __typename?: 'Organization'; name: string }
		entity: { __typename?: 'Entity'; name: string }
		bookbuild: {
			__typename?: 'BookbuildPublic'
			instrumentPrimaryAdv?: number | null
			id: string
			side: Side
			daysAdtv?: number | null
			volume: number
			startedAt?: string | null
			endsAt: string
			endedAt?: string | null
			cancelledAt?: string | null
			failedAt?: string | null
			executingAt?: string | null
			referencePrice: number
			instrument: {
				__typename?: 'Instrument'
				id: string
				isin: string
				marketID: string
				currency: string
				description: string
				sedol: string
				primaryBloombergTicker: string
				referencePrice: number
				averageDailyVolume?: number | null
				preTradeLISValueCurrency: number
				marketName: string
				staticRefPriceCollarPercent: number
			}
			allocability: Array<{
				__typename?: 'BookbuildAllocability'
				allocablePercent: number
				demandPercent: number
			}>
		}
		allocation?: {
			__typename?: 'Allocation'
			id: string
			side: Side
			allocation: number
			grossCash: number
			createdAt: string
			scoreTime?: number | null
			scoreVwap?: number | null
			tariff?: number | null
			organization?: {
				__typename?: 'Organization'
				id: string
				name: string
			} | null
			counterparty?: {
				__typename?: 'Organization'
				id: string
				name: string
			} | null
			deal: {
				__typename?: 'Deal'
				id: string
				completedAt?: string | null
				referencePrice: number
				price: number
				volume: number
				daysAdtv?: number | null
				cashGross: number
			}
		} | null
		broker: { __typename?: 'BrokerPublic'; id: string; name: string }
		brokerLimitViolation?: {
			__typename?: 'BrokerLimitViolation'
			currentExposureUsd: number
			potentialExposureUsd: number
			allocatedOrderExposureUsd?: number | null
			currentExposureLimitPercentage: number
			potentialExposureLimitPercentage: number
			allocatedOrderExposureLimitPercentage?: number | null
			entityBroker: {
				__typename?: 'EntityBroker'
				broker: { __typename?: 'Broker'; id: string; name: string }
			}
		} | null
	} | null
}

export type ListEmsQueryVariables = Exact<{ [key: string]: never }>

export type ListEmsQuery = {
	__typename?: 'Query'
	emsSessions: Array<{
		__typename?: 'EmsSession'
		id: string
		name: string
		ems: string
		targetCompID: string
		senderCompID: string
		sessionType: FixSessionType
	}>
}

export type ViewEmsQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type ViewEmsQuery = {
	__typename?: 'Query'
	emsSession?: {
		__typename?: 'EmsSession'
		id: string
		createdAt: string
		updatedAt: string
		name: string
		ems: string
		sessionType: FixSessionType
		beginString: FixBeginString
		senderCompID: string
		targetCompID: string
		socketAddressHost: string
		socketAddressPort: number
		maxMessagesPerSecond: number
		startDay?: string | null
		startTime: string
		endDay?: string | null
		endTime: string
		timeZone?: string | null
		weekdays: string
		heartbeatIntervalSec?: number | null
		reconnectIntervalSec?: number | null
		tlsPrivateKeyFilepath?: string | null
		tlsCertificateFilepath?: string | null
		tlsCAFilepath?: string | null
		refreshOnLogon: boolean
		resetOnLogon: boolean
		resetOnLogout: boolean
		resetOnDisconnect: boolean
		strategy: EmsSessionStrategy
		supportDistributionPopup: boolean
		technicalSupportLink?: string | null
		ioiSession?: {
			__typename?: 'IoiSession'
			id: string
			name: string
		} | null
		organizations: Array<{ __typename?: 'Organization'; id: string }>
	} | null
}

export type CreateEmsFormQueryVariables = Exact<{ [key: string]: never }>

export type CreateEmsFormQuery = {
	__typename?: 'Query'
	emsNames: Array<string>
	ioiSessions: Array<{ __typename?: 'IoiSession'; id: string; name: string }>
}

export type EditEmsFormQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type EditEmsFormQuery = {
	__typename?: 'Query'
	emsNames: Array<string>
	emsSession?: {
		__typename?: 'EmsSession'
		id: string
		createdAt: string
		updatedAt: string
		name: string
		ems: string
		sessionType: FixSessionType
		beginString: FixBeginString
		senderCompID: string
		targetCompID: string
		socketAddressHost: string
		socketAddressPort: number
		maxMessagesPerSecond: number
		startDay?: string | null
		startTime: string
		endDay?: string | null
		endTime: string
		timeZone?: string | null
		weekdays: string
		heartbeatIntervalSec?: number | null
		reconnectIntervalSec?: number | null
		tlsPrivateKeyFilepath?: string | null
		tlsCertificateFilepath?: string | null
		tlsCAFilepath?: string | null
		refreshOnLogon: boolean
		resetOnLogon: boolean
		resetOnLogout: boolean
		resetOnDisconnect: boolean
		strategy: EmsSessionStrategy
		supportDistributionPopup: boolean
		technicalSupportLink?: string | null
		ioiSession?: {
			__typename?: 'IoiSession'
			id: string
			name: string
		} | null
		organizations: Array<{ __typename?: 'Organization'; id: string }>
	} | null
	ioiSessions: Array<{ __typename?: 'IoiSession'; id: string; name: string }>
}

export type ListIoiSessionsQueryVariables = Exact<{
	sortBy?: InputMaybe<SessionsSortCol>
	sortDesc?: InputMaybe<Scalars['Boolean']['input']>
	filter?: InputMaybe<Scalars['String']['input']>
}>

export type ListIoiSessionsQuery = {
	__typename?: 'Query'
	ioiSessions: Array<{
		__typename?: 'IoiSession'
		id: string
		name: string
		ems: string
		targetCompID: string
		senderCompID: string
		sessionType: FixSessionType
		state: SessionState
	}>
}

export type GetIoiSessionQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type GetIoiSessionQuery = {
	__typename?: 'Query'
	ioiSession?: {
		__typename?: 'IoiSession'
		id: string
		createdAt: string
		updatedAt: string
		name: string
		ems: string
		sessionType: FixSessionType
		beginString: FixBeginString
		senderCompID: string
		targetCompID: string
		socketConnectHost?: string | null
		socketConnectPort?: number | null
		maxMessagesPerSecond: number
		startDay?: string | null
		endDay?: string | null
		weekdays?: string | null
		startTime: string
		endTime: string
		timeZone?: string | null
		heartbeatIntervalSec?: number | null
		reconnectIntervalSec?: number | null
		refreshOnLogon: boolean
		resetOnLogon: boolean
		resetOnLogout: boolean
		resetOnDisconnect: boolean
		technicalSupportLink?: string | null
		state: SessionState
		tlsSkipVerification: boolean
		tlsCertExpiry?: string | null
		strategy: IoiSessionStrategy
		emsSession?: {
			__typename?: 'EmsSession'
			id: string
			name: string
		} | null
	} | null
}

export type CreateIoiFormQueryVariables = Exact<{ [key: string]: never }>

export type CreateIoiFormQuery = {
	__typename?: 'Query'
	emsNames: Array<string>
}

export type EditIoiFormQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type EditIoiFormQuery = {
	__typename?: 'Query'
	emsNames: Array<string>
	ioiSession?: {
		__typename?: 'IoiSession'
		id: string
		createdAt: string
		updatedAt: string
		name: string
		ems: string
		sessionType: FixSessionType
		beginString: FixBeginString
		senderCompID: string
		targetCompID: string
		socketConnectHost?: string | null
		socketConnectPort?: number | null
		maxMessagesPerSecond: number
		startDay?: string | null
		endDay?: string | null
		weekdays?: string | null
		startTime: string
		endTime: string
		timeZone?: string | null
		heartbeatIntervalSec?: number | null
		reconnectIntervalSec?: number | null
		refreshOnLogon: boolean
		resetOnLogon: boolean
		resetOnLogout: boolean
		resetOnDisconnect: boolean
		technicalSupportLink?: string | null
		state: SessionState
		tlsSkipVerification: boolean
		tlsCertExpiry?: string | null
		strategy: IoiSessionStrategy
		emsSession?: {
			__typename?: 'EmsSession'
			id: string
			name: string
		} | null
	} | null
}

export type ListWatchlistSessionsQueryVariables = Exact<{
	sortBy?: InputMaybe<SessionsSortCol>
	sortDesc?: InputMaybe<Scalars['Boolean']['input']>
	filter?: InputMaybe<Scalars['String']['input']>
}>

export type ListWatchlistSessionsQuery = {
	__typename?: 'Query'
	watchlistSessions: Array<{
		__typename?: 'WatchlistSession'
		id: string
		name: string
		ems: string
		targetCompID: string
		senderCompID: string
		sessionType: FixSessionType
		state: SessionState
	}>
}

export type GetWatchlistSessionQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type GetWatchlistSessionQuery = {
	__typename?: 'Query'
	watchlistSession?: {
		__typename?: 'WatchlistSession'
		id: string
		createdAt: string
		updatedAt: string
		name: string
		ems: string
		sessionType: FixSessionType
		beginString: FixBeginString
		senderCompID: string
		targetCompID: string
		socketConnectHost?: string | null
		socketConnectPort?: number | null
		maxMessagesPerSecond: number
		startDay?: string | null
		startTime: string
		endDay?: string | null
		endTime: string
		timeZone: string
		weekdays?: string | null
		heartbeatIntervalSec?: number | null
		reconnectIntervalSec?: number | null
		refreshOnLogon: boolean
		resetOnLogon: boolean
		resetOnLogout: boolean
		resetOnDisconnect: boolean
		state: SessionState
		technicalSupportLink?: string | null
		organizations: Array<{ __typename?: 'Organization'; id: string }>
	} | null
}

export type CreateWatchlistSessionFormQueryVariables = Exact<{
	[key: string]: never
}>

export type CreateWatchlistSessionFormQuery = {
	__typename?: 'Query'
	emsNames: Array<string>
}

export type EditWatchlistSessionFormQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type EditWatchlistSessionFormQuery = {
	__typename?: 'Query'
	emsNames: Array<string>
	watchlistSession?: {
		__typename?: 'WatchlistSession'
		id: string
		createdAt: string
		updatedAt: string
		name: string
		ems: string
		sessionType: FixSessionType
		beginString: FixBeginString
		senderCompID: string
		targetCompID: string
		socketConnectHost?: string | null
		socketConnectPort?: number | null
		maxMessagesPerSecond: number
		startDay?: string | null
		startTime: string
		endDay?: string | null
		endTime: string
		timeZone: string
		weekdays?: string | null
		heartbeatIntervalSec?: number | null
		reconnectIntervalSec?: number | null
		refreshOnLogon: boolean
		resetOnLogon: boolean
		resetOnLogout: boolean
		resetOnDisconnect: boolean
		state: SessionState
		technicalSupportLink?: string | null
		organizations: Array<{ __typename?: 'Organization'; id: string }>
	} | null
}

export type AdminInstrumentsQueryVariables = Exact<{
	orgId?: InputMaybe<Scalars['ID']['input']>
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: InstrumentsSortCol
	sortDesc: Scalars['Boolean']['input']
}>

export type AdminInstrumentsQuery = {
	__typename?: 'Query'
	instruments: {
		__typename?: 'Instruments'
		total: number
		items: Array<{
			__typename?: 'Instrument'
			id: string
			description: string
			marketName: string
			marketCapUsdM?: number | null
			notionalAverageDailyVolumeUsd?: number | null
			currency: string
			primaryBloombergTicker: string
			countActivityEvents: number
			countActivityEventsLastMonth: number
			liquidity?: number | null
			liquidityCategory?: LiquidityCategory | null
			earningsDate?: string | null
			holdings: {
				__typename?: 'InstrumentHoldings'
				fuzzyTotalAmount: string
				rawTotalAmount: number
				totalValueHeldUSD: number
				totalSharesHeld: number
				holders: Array<{
					__typename?: 'Holder'
					fuzzyHoldingAmount: string
					rawHoldingAmount: number
					valueHeldUSD: number
					trend: HoldingTrend
					reportDate?: string | null
					sharesHeld: number
					organization: {
						__typename?: 'OrganizationPublic'
						id: string
						name: string
					}
				}>
			}
			ranking?: {
				__typename?: 'InstrumentRanking'
				ranking: number
				score: number
				totalHoldersContribution: number
				keyHoldersContribution: number
				liquidityContribution: number
				marketCapContribution: number
				totalHeldPercentageContribution: number
			} | null
		}>
	}
}

export type AdminInstrumentQueryVariables = Exact<{
	id: Scalars['ID']['input']
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
}>

export type AdminInstrumentQuery = {
	__typename?: 'Query'
	instrument: {
		__typename?: 'Instrument'
		id: string
		createdAt: string
		updatedAt: string
		active: boolean
		deactivatedAt?: string | null
		isin: string
		marketID: string
		currency: string
		description: string
		referencePrice: number
		sedol: string
		ric: string
		primaryBloombergTicker: string
		countActivityEvents: number
		countActivityEventsLastMonth: number
		averageDailyVolume?: number | null
		preTradeLISValueCurrency: number
		staticRefPriceCollarPercent: number
		dynamicRefPriceCollarPercent: number
		operatingMarketID: string
		marketName: string
		marketCountry: string
		marketCountryCode: string
		marketCapUsdM?: number | null
		marketCapCategory?: MarketCapCategory | null
		percentChangeClose2days?: number | null
		percentChangeClose30days?: number | null
		percentChangeClose120days?: number | null
		closeVolume1day?: number | null
		averageVolume5Days?: number | null
		averageVolume30Days?: number | null
		dollarDailyAverage30Days?: number | null
		liquidityAmount10Days?: number | null
		liquidityVolume10Days?: number | null
		liquidityCategory?: LiquidityCategory | null
		liquidity?: number | null
		volatility5Days?: number | null
		volatility10Days?: number | null
		securityType: string
		securityTypeDescription: string
		sector: string
		calendarID: string
		executionVenue: string
		notionalAverageDailyVolumeUsd?: number | null
		holdings: {
			__typename?: 'InstrumentHoldings'
			rawTotalAmount: number
			totalValueHeldUSD: number
			totalSharesHeld: number
			holders: Array<{
				__typename?: 'Holder'
				fuzzyHoldingAmount: string
				rawHoldingAmount: number
				trend: HoldingTrend
				reportDate?: string | null
				valueHeldUSD: number
				valueChangedUSD: number
				valueChangedADVPercent: number
				sharesHeld: number
				organization: {
					__typename?: 'OrganizationPublic'
					id: string
					name: string
				}
			}>
		}
		ranking?: {
			__typename?: 'InstrumentRanking'
			ranking: number
			score: number
			totalHoldersContribution: number
			keyHoldersContribution: number
			liquidityContribution: number
			marketCapContribution: number
			totalHeldPercentageContribution: number
		} | null
		activity: {
			__typename?: 'Events'
			total: number
			items: Array<{
				__typename?: 'Event'
				id: string
				recordedAt: string
				activityType: EventActivityType
				side?: Side | null
				status: string
				entityId?: string | null
				organization?: {
					__typename?: 'Organization'
					id: string
					name: string
				} | null
				user?: { __typename?: 'User'; id: string; name: string } | null
			}>
		}
	}
}

export type InsightsOrgsQueryVariables = Exact<{ [key: string]: never }>

export type InsightsOrgsQuery = {
	__typename?: 'Query'
	organizations: Array<{
		__typename?: 'Organization'
		id: string
		name: string
	}>
}

export type InsightsOrgWatchlistsQueryVariables = Exact<{
	type?: InputMaybe<OrgType>
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: OrganizationItemsSortCol
	sortDesc: Scalars['Boolean']['input']
}>

export type InsightsOrgWatchlistsQuery = {
	__typename?: 'Query'
	organizationsPaginated: {
		__typename?: 'OrganizationItems'
		total: number
		items: Array<{
			__typename?: 'Organization'
			id: string
			name: string
			liveWatchlistItemsCount: number
			liveWatchlistUpdatedAt?: string | null
			liveWatchlistAutomated: boolean
			passiveWatchlistItemsCount: number
			passiveWatchlistUpdatedAt?: string | null
			intersectionsCount: number
		}>
	}
}

export type InsightsIntersectionsQueryVariables = Exact<{
	orgId?: InputMaybe<Scalars['ID']['input']>
	excludePassive?: InputMaybe<Scalars['Boolean']['input']>
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: IntersectionsSortCol
	sortDesc: Scalars['Boolean']['input']
}>

export type InsightsIntersectionsQuery = {
	__typename?: 'Query'
	intersections: {
		__typename?: 'Intersections'
		total: number
		items: Array<{
			__typename?: 'Intersection'
			instrument: {
				__typename?: 'Instrument'
				id: string
				description: string
				marketName: string
				marketCapUsdM?: number | null
				notionalAverageDailyVolumeUsd?: number | null
				currency: string
				primaryBloombergTicker: string
				holdings: {
					__typename?: 'InstrumentHoldings'
					rawTotalAmount: number
					totalValueHeldUSD: number
					holders: Array<{
						__typename?: 'Holder'
						rawHoldingAmount: number
						trend: HoldingTrend
						reportDate?: string | null
						valueHeldUSD: number
						organization: {
							__typename?: 'OrganizationPublic'
							id: string
							name: string
						}
					}>
				}
				ranking?: {
					__typename?: 'InstrumentRanking'
					ranking: number
					score: number
					totalHoldersContribution: number
					keyHoldersContribution: number
					liquidityContribution: number
					marketCapContribution: number
					totalHeldPercentageContribution: number
				} | null
			}
			buyItems: Array<{
				__typename?: 'IntersectionItem'
				watchlistItemId: string
				isLive: boolean
				organization: {
					__typename?: 'Organization'
					id: string
					name: string
				}
			}>
			sellItems: Array<{
				__typename?: 'IntersectionItem'
				watchlistItemId: string
				isLive: boolean
				organization: {
					__typename?: 'Organization'
					id: string
					name: string
				}
			}>
			latestOriginationAlert?: {
				__typename?: 'OriginationAlert'
				id: string
				side: Side
				createdAt: string
				instrument: { __typename?: 'Instrument'; id: string }
			} | null
		}>
	}
}

export type InsightsOriginationAlertsQueryVariables = Exact<{
	orgId: Scalars['ID']['input']
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: OriginationAlertsSortCol
	sortDesc: Scalars['Boolean']['input']
}>

export type InsightsOriginationAlertsQuery = {
	__typename?: 'Query'
	originationAlerts: {
		__typename?: 'OriginationAlerts'
		total: number
		items: Array<{
			__typename?: 'OriginationAlert'
			id: string
			side: Side
			createdAt: string
			reason?: string | null
			instrument: {
				__typename?: 'Instrument'
				description: string
				marketName: string
				primaryBloombergTicker: string
			}
			contactedByUser: { __typename?: 'User'; name: string }
		}>
	}
}

export type InsightsLiveWatchlistQueryVariables = Exact<{
	orgId?: InputMaybe<Scalars['ID']['input']>
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: LiveWatchlistItemsSortCol
	sortDesc: Scalars['Boolean']['input']
}>

export type InsightsLiveWatchlistQuery = {
	__typename?: 'Query'
	liveWatchlist: {
		__typename?: 'LiveWatchlistItems'
		total: number
		updatedAt?: string | null
		items: Array<{
			__typename?: 'LiveWatchlistItem'
			id: string
			createdAt: string
			side: Side
			volume?: number | null
			price?: number | null
			currency?: string | null
			notionalAmountUsd?: number | null
			currentContraCount: number
			recentContraCount: number
			distributionScore?: {
				__typename?: 'DistributionScore'
				score: number
				suggestDistribution: boolean
				suggestedContrasCount: number
				totalContrasCount: number
			} | null
			organization: {
				__typename?: 'Organization'
				id: string
				name: string
			}
			user: { __typename?: 'User'; id: string; name: string }
			instrument: {
				__typename?: 'Instrument'
				id: string
				description: string
				marketName: string
				marketCapUsdM?: number | null
				averageDailyVolume?: number | null
				notionalAverageDailyVolumeUsd?: number | null
				currency: string
				referencePrice: number
				primaryBloombergTicker: string
				holdings: {
					__typename?: 'InstrumentHoldings'
					rawTotalAmount: number
					totalValueHeldUSD: number
					holders: Array<{
						__typename?: 'Holder'
						rawHoldingAmount: number
						trend: HoldingTrend
						reportDate?: string | null
						valueHeldUSD: number
						organization: {
							__typename?: 'OrganizationPublic'
							id: string
							name: string
							category: OrgCategory
						}
					}>
				}
				ranking?: {
					__typename?: 'InstrumentRanking'
					ranking: number
					score: number
					totalHoldersContribution: number
					keyHoldersContribution: number
					liquidityContribution: number
					marketCapContribution: number
					totalHeldPercentageContribution: number
				} | null
			}
			intersection?: {
				__typename?: 'Intersection'
				instrument: {
					__typename?: 'Instrument'
					id: string
					description: string
					marketName: string
					marketCapUsdM?: number | null
					notionalAverageDailyVolumeUsd?: number | null
					currency: string
					holdings: {
						__typename?: 'InstrumentHoldings'
						rawTotalAmount: number
						totalValueHeldUSD: number
						holders: Array<{
							__typename?: 'Holder'
							rawHoldingAmount: number
							trend: HoldingTrend
							reportDate?: string | null
							valueHeldUSD: number
							organization: {
								__typename?: 'OrganizationPublic'
								id: string
								name: string
							}
						}>
					}
				}
				buyItems: Array<{
					__typename?: 'IntersectionItem'
					watchlistItemId: string
					isLive: boolean
					organization: {
						__typename?: 'Organization'
						id: string
						name: string
					}
				}>
				sellItems: Array<{
					__typename?: 'IntersectionItem'
					watchlistItemId: string
					isLive: boolean
					organization: {
						__typename?: 'Organization'
						id: string
						name: string
					}
				}>
			} | null
			latestOriginationAlert?: {
				__typename?: 'OriginationAlert'
				id: string
				side: Side
				createdAt: string
				instrument: { __typename?: 'Instrument'; id: string }
			} | null
		}>
	}
}

export type InstrumentDistributionScoreAttributesQueryVariables = Exact<{
	instrumentId: Scalars['ID']['input']
	side: Side
	orgId: Scalars['ID']['input']
}>

export type InstrumentDistributionScoreAttributesQuery = {
	__typename?: 'Query'
	instrumentDistributionScoreAttributes: {
		__typename?: 'DistributionScoreAttributes'
		factors: Array<{
			__typename?: 'DistributionScoreFactor'
			label: string
			score: number
			attributes?: Array<string | null> | null
		}>
		contras: Array<{
			__typename?: 'DistributionContra'
			score: number
			isPadded: boolean
			org: { __typename?: 'Organization'; id: string; name: string }
			contributions: Array<{
				__typename?: 'DistributionScoreFactor'
				label: string
				score: number
				attributes?: Array<string | null> | null
			}>
		}>
	}
}

export type DistributionScoreWeightsQueryVariables = Exact<{
	[key: string]: never
}>

export type DistributionScoreWeightsQuery = {
	__typename?: 'Query'
	distributionScoreWeights: Array<{
		__typename?: 'DistributionScoreWeight'
		factor: string
		weight: number
	}>
}

export type InsightsLiveWatchlistEntriesQueryVariables = Exact<{
	orgId?: InputMaybe<Scalars['ID']['input']>
	userId?: InputMaybe<Scalars['ID']['input']>
	instrumentId?: InputMaybe<Scalars['ID']['input']>
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	previousEntriesLimit: Scalars['Int']['input']
}>

export type InsightsLiveWatchlistEntriesQuery = {
	__typename?: 'Query'
	liveWatchlistEntries: {
		__typename?: 'LiveWatchlistEntryItems'
		total: number
		items: Array<{
			__typename?: 'LiveWatchlistEntry'
			id: string
			type: WatchlistEntryType
			timestamp: string
			side: Side
			volume?: number | null
			previousVolume?: number | null
			price?: number | null
			currency: string
			daysAdtv?: number | null
			notionalUsd?: number | null
			orgId: string
			orgName: string
			userId: string
			userName: string
			instrumentId: string
			instrumentDescription: string
			instrumentRank: number
			instrumentMarketName: string
			instrumentMarketCapUsdM?: number | null
			instrumentAverageDailyVolume?: number | null
			instrumentReferencePrice?: number | null
			currentContraCount: number
			recentContraCount: number
			previousEntries: Array<{
				__typename?: 'LiveWatchlistEntry'
				id: string
				type: WatchlistEntryType
				timestamp: string
				side: Side
				volume?: number | null
				previousVolume?: number | null
				price?: number | null
				currency: string
				daysAdtv?: number | null
				notionalUsd?: number | null
				orgId: string
				orgName: string
				userId: string
				userName: string
				instrumentId: string
				instrumentDescription: string
				instrumentRank: number
				instrumentMarketName: string
				instrumentMarketCapUsdM?: number | null
				instrumentAverageDailyVolume?: number | null
				instrumentReferencePrice?: number | null
				currentContraCount: number
				recentContraCount: number
				distributionScore?: {
					__typename?: 'DistributionScore'
					score: number
				} | null
			}>
			distributionScore?: {
				__typename?: 'DistributionScore'
				score: number
			} | null
		}>
	}
}

export type InsightsExportLiveWatchlistEntriesQueryVariables = Exact<{
	orgId?: InputMaybe<Scalars['ID']['input']>
	limit: Scalars['Int']['input']
}>

export type InsightsExportLiveWatchlistEntriesQuery = {
	__typename?: 'Query'
	exportLiveWatchlistEntries: Array<{
		__typename?: 'LiveWatchlistEntry'
		id: string
		type: WatchlistEntryType
		timestamp: string
		side: Side
		volume?: number | null
		previousVolume?: number | null
		price?: number | null
		currency: string
		daysAdtv?: number | null
		notionalUsd?: number | null
		orgId: string
		orgName: string
		userId: string
		userName: string
		instrumentId: string
		instrumentDescription: string
		instrumentRank: number
		instrumentMarketName: string
		instrumentMarketCapUsdM?: number | null
		instrumentAverageDailyVolume?: number | null
		instrumentReferencePrice?: number | null
		currentContraCount: number
		recentContraCount: number
		distributionScore?: {
			__typename?: 'DistributionScore'
			score: number
		} | null
	}>
}

export type InsightsPassiveWatchlistQueryVariables = Exact<{
	orgId?: InputMaybe<Scalars['ID']['input']>
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: PassiveWatchlistItemsSortCol
	sortDesc: Scalars['Boolean']['input']
}>

export type InsightsPassiveWatchlistQuery = {
	__typename?: 'Query'
	passiveWatchlist: {
		__typename?: 'PassiveWatchlistItems'
		total: number
		updatedAt?: string | null
		items: Array<{
			__typename?: 'PassiveWatchlistItem'
			id: string
			createdAt: string
			side: Side
			currentContraCount: number
			recentContraCount: number
			organization: {
				__typename?: 'Organization'
				id: string
				name: string
			}
			user: { __typename?: 'User'; id: string; name: string }
			instrument: {
				__typename?: 'Instrument'
				id: string
				description: string
				marketName: string
				primaryBloombergTicker: string
				holdings: {
					__typename?: 'InstrumentHoldings'
					fuzzyTotalAmount: string
					rawTotalAmount: number
					totalValueHeldUSD: number
					holders: Array<{
						__typename?: 'Holder'
						rawHoldingAmount: number
						trend: HoldingTrend
						reportDate?: string | null
						valueHeldUSD: number
						organization: {
							__typename?: 'OrganizationPublic'
							id: string
							name: string
							category: OrgCategory
						}
					}>
				}
				ranking?: {
					__typename?: 'InstrumentRanking'
					ranking: number
					score: number
					totalHoldersContribution: number
					keyHoldersContribution: number
					liquidityContribution: number
					marketCapContribution: number
					totalHeldPercentageContribution: number
				} | null
			}
		}>
	}
}

export type UnsupportedTickersQueryVariables = Exact<{
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: UnsupportedTickerSortCol
	sortDesc: Scalars['Boolean']['input']
}>

export type UnsupportedTickersQuery = {
	__typename?: 'Query'
	unsupportedTickers: {
		__typename?: 'UnsupportedTickers'
		total: number
		items: Array<{
			__typename?: 'UnsupportedTicker'
			id: string
			createdAt: string
			lastReferencedAt: string
			ticker: string
			tickerOrganizations: Array<{
				__typename?: 'UnsupportedTickerOrganization'
				tickerSource: UnsupportedSource
				organization: {
					__typename?: 'Organization'
					id: string
					name: string
				}
			}>
		}>
	}
}

export type UserDetailsQueryVariables = Exact<{ [key: string]: never }>

export type UserDetailsQuery = {
	__typename?: 'Query'
	me: {
		__typename?: 'User'
		id: string
		email: string
		name: string
		authStrategy?: string | null
		organization: { __typename?: 'Organization'; id: string; name: string }
	}
}

export type MetricsQueryVariables = Exact<{
	input: MetricsInput
}>

export type MetricsQuery = {
	__typename?: 'Query'
	metrics: {
		__typename?: 'Metrics'
		bookbuilds: Array<{
			__typename?: 'BookbuildMetrics'
			id: string
			date: string
			notional: number
			cumulativeNotional: number
			count: number
		}>
	}
	organizations: Array<{
		__typename?: 'Organization'
		id: string
		name: string
	}>
	emsSessions: Array<{ __typename?: 'EmsSession'; id: string; ems: string }>
}

export type EditOrganizationHoldingsQueryVariables = Exact<{
	orgId: Scalars['ID']['input']
}>

export type EditOrganizationHoldingsQuery = {
	__typename?: 'Query'
	organization?: {
		__typename?: 'Organization'
		name: string
		holdingsOwners: Array<{
			__typename?: 'HoldingsOwner'
			id: string
			ownerID: number
			name: string
			holderType: string
			lei: string
			country: string
			city: string
			parentID?: number | null
			parentName?: string | null
			totalHoldings: number
			totalHoldingsNotionalUsd: number
			totalChildren: number
		}>
	} | null
}

export type HoldingsOwnersQueryVariables = Exact<{
	ownerId?: InputMaybe<Scalars['Int']['input']>
	searchQuery?: InputMaybe<Scalars['String']['input']>
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
}>

export type HoldingsOwnersQuery = {
	__typename?: 'Query'
	holdingsOwners: {
		__typename?: 'HoldingsOwnersResponse'
		total: number
		items: Array<{
			__typename?: 'HoldingsOwner'
			id: string
			ownerID: number
			name: string
			holderType: string
			lei: string
			country: string
			city: string
			parentID?: number | null
			parentName?: string | null
			totalHoldings: number
			totalHoldingsNotionalUsd: number
			totalChildren: number
		}>
	}
}

export type RankedInstrumentsQueryVariables = Exact<{
	ownerIds: Array<Scalars['Int']['input']> | Scalars['Int']['input']
}>

export type RankedInstrumentsQuery = {
	__typename?: 'Query'
	rankedInstruments: Array<{
		__typename?: 'RankedInstrument'
		liquidity: number
		totalHolders: number
		totalHeldPercentage: number
		totalHeldUsdMil: number
		ownersHeldPercentage: number
		ownersHeldUsdMil: number
		lisUsd: number
		ranking: number
		score: number
		instrument: {
			__typename?: 'Instrument'
			id: string
			sedol: string
			primaryBloombergTicker: string
			description: string
			marketID: string
			marketName: string
			marketCountry: string
			marketCapUsdM?: number | null
			notionalAverageDailyVolumeUsd?: number | null
		}
	}>
}

export type YFinanceQueryVariables = Exact<{
	sedol: Scalars['String']['input']
	isin: Scalars['String']['input']
	priceHistoryDays?: InputMaybe<Scalars['Int']['input']>
	priceHistoryInterval?: InputMaybe<Scalars['String']['input']>
}>

export type YFinanceQuery = { __typename?: 'Query'; yfinance: unknown }

export type TrendingEquitiesListsQueryVariables = Exact<{
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: TrendingEquitiesSortCol
	sortDesc: Scalars['Boolean']['input']
	filter?: InputMaybe<Scalars['String']['input']>
}>

export type TrendingEquitiesListsQuery = {
	__typename?: 'Query'
	trendingEquitiesLists: {
		__typename?: 'TrendingEquitiesListItems'
		total: number
		items: Array<{
			__typename?: 'TrendingEquitiesList'
			id: string
			createdAt: string
			updatedAt: string
			endedAt?: string | null
			active: boolean
			emailSentAt?: string | null
			emailSentBy?: {
				__typename?: 'User'
				id: string
				name: string
			} | null
			participants?: Array<{
				__typename?: 'Organization'
				id: string
				name: string
			}> | null
			instruments: Array<{
				__typename?: 'TrendingEquitiesInstrument'
				id: string
				state?: TrendingInstrumentState | null
				changedAt?: string | null
				changedBy?: {
					__typename?: 'User'
					id: string
					name: string
				} | null
				instrument: {
					__typename?: 'Instrument'
					id: string
					description: string
					marketName: string
					primaryBloombergTicker: string
				}
				responses: Array<{
					__typename?: 'TrendingEquitiesResponse'
					id: string
					createdAt: string
					updatedAt: string
					side: Side
					activityType: ActivityType
					user: { __typename?: 'User'; id: string; name: string }
					organization: {
						__typename?: 'Organization'
						id: string
						name: string
					}
				}>
			}>
		}>
	}
}

export type TrendingEquitiesListQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type TrendingEquitiesListQuery = {
	__typename?: 'Query'
	trendingEquitiesList?: {
		__typename?: 'TrendingEquitiesList'
		id: string
		createdAt: string
		updatedAt: string
		endedAt?: string | null
		active: boolean
		emailSentAt?: string | null
		emailSentBy?: { __typename?: 'User'; id: string; name: string } | null
		participants?: Array<{
			__typename?: 'Organization'
			id: string
			name: string
		}> | null
		instruments: Array<{
			__typename?: 'TrendingEquitiesInstrument'
			id: string
			state?: TrendingInstrumentState | null
			changedAt?: string | null
			changedBy?: { __typename?: 'User'; id: string; name: string } | null
			instrument: {
				__typename?: 'Instrument'
				id: string
				description: string
				marketName: string
				primaryBloombergTicker: string
			}
			responses: Array<{
				__typename?: 'TrendingEquitiesResponse'
				id: string
				createdAt: string
				updatedAt: string
				side: Side
				activityType: ActivityType
				user: { __typename?: 'User'; id: string; name: string }
				organization: {
					__typename?: 'Organization'
					id: string
					name: string
				}
			}>
		}>
	} | null
}

export type AdminPriceDiscoveriesQueryVariables = Exact<{
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: PriceDiscoverySortCol
	sortDesc: Scalars['Boolean']['input']
	filter?: InputMaybe<Scalars['String']['input']>
}>

export type AdminPriceDiscoveriesQuery = {
	__typename?: 'Query'
	priceDiscoveries: {
		__typename?: 'PriceDiscoveries'
		total: number
		items: Array<{
			__typename?: 'PriceDiscovery'
			id: string
			side: Side
			interestedCount: number
			yesCount: number
			notInterestedCount: number
			manualSelectionFirmsCount: number
			suggestedDistributionFirmsCount: number
			createdAt: string
			endsAt: string
			endedAt?: string | null
			cancelledAt?: string | null
			status: PriceDiscoveryStatus
			instrument: {
				__typename?: 'Instrument'
				id: string
				description: string
				marketName: string
				primaryBloombergTicker: string
			}
			user: {
				__typename?: 'User'
				id: string
				name: string
				organization: {
					__typename?: 'Organization'
					id: string
					name: string
				}
			}
		}>
	}
}

export type AdminPriceDiscoveryQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type AdminPriceDiscoveryQuery = {
	__typename?: 'Query'
	priceDiscovery?: {
		__typename?: 'PriceDiscovery'
		id: string
		side: Side
		volume: number
		price?: number | null
		daysAdtv?: number | null
		interestedCount: number
		notInterestedCount: number
		manualSelectionFirmsCount: number
		suggestedDistributionFirmsCount: number
		holdersCount: number
		createdAt: string
		endsAt: string
		endedAt?: string | null
		cancelledAt?: string | null
		status: PriceDiscoveryStatus
		instrument: {
			__typename?: 'Instrument'
			id: string
			description: string
			marketName: string
			primaryBloombergTicker: string
			currency: string
			holdings: {
				__typename?: 'InstrumentHoldings'
				rawTotalAmount: number
				holders: Array<{
					__typename?: 'Holder'
					rawHoldingAmount: number
					organization: {
						__typename?: 'OrganizationPublic'
						id: string
					}
				}>
			}
		}
		user: { __typename?: 'User'; name: string }
		organization: { __typename?: 'Organization'; name: string }
		cancelledBy?: { __typename?: 'User'; name: string } | null
		opportunities: Array<{
			__typename?: 'PriceDiscoveryOpportunity'
			id: string
			interested?: boolean | null
			respondedAt?: string | null
			volume?: number | null
			sensitivityType?: PriceDiscoverySensitivityType | null
			sensitivityValue?: number | null
			hoursRequired?: number | null
			pmRegion?: PmRegion | null
			adminRespondedAt?: string | null
			manualDistribution: boolean
			suggestedDistribution: boolean
			organization: {
				__typename?: 'Organization'
				id: string
				name: string
			}
			respondedBy?: { __typename?: 'User'; name: string } | null
			adminRespondedBy?: { __typename?: 'User'; name: string } | null
		}>
		originationAlert?: {
			__typename?: 'OriginationAlert'
			createdAt: string
			contactedByUser: { __typename?: 'User'; name: string }
		} | null
		manualDistributionFirms: Array<{
			__typename?: 'OrganizationPublic'
			id: string
			name: string
			category: OrgCategory
		}>
	} | null
}

export type ManagementBookbuildsQueryVariables = Exact<{
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: BookbuildsSortCol
	sortDesc: Scalars['Boolean']['input']
	status?: InputMaybe<Array<Status> | Status>
}>

export type ManagementBookbuildsQuery = {
	__typename?: 'Query'
	bookbuilds: {
		__typename?: 'Bookbuilds'
		total: number
		items: Array<{
			__typename?: 'Bookbuild'
			id: string
			side: Side
			status: Status
			updatedAt: string
			cancelRequestedAt?: string | null
			createdAt: string
			endsAt: string
			daysAdtv?: number | null
			instrument: {
				__typename?: 'Instrument'
				id: string
				description: string
			}
			broker: { __typename?: 'BrokerPublic'; name: string }
			user?: { __typename?: 'User'; name: string } | null
		}>
	}
}

export type ManagementOrdersQueryVariables = Exact<{
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: OrdersSortCol
	sortDesc: Scalars['Boolean']['input']
	status?: InputMaybe<Array<Status> | Status>
}>

export type ManagementOrdersQuery = {
	__typename?: 'Query'
	orders: {
		__typename?: 'Orders'
		total: number
		items: Array<{
			__typename?: 'Order'
			id: string
			createdAt: string
			amendedAt?: string | null
			cancelledAt?: string | null
			status: Status
			bookbuild: {
				__typename?: 'BookbuildPublic'
				side: Side
				endsAt: string
				instrument: {
					__typename?: 'Instrument'
					description: string
					marketName: string
				}
			}
			user: { __typename?: 'User'; name: string }
			broker: { __typename?: 'BrokerPublic'; name: string }
		}>
	}
}

export type ManagementUsersQueryVariables = Exact<{
	limit: Scalars['Int']['input']
	offset: Scalars['Int']['input']
	sortBy: UsersSortCol
	sortDesc: Scalars['Boolean']['input']
	filter?: InputMaybe<Scalars['String']['input']>
}>

export type ManagementUsersQuery = {
	__typename?: 'Query'
	users: {
		__typename?: 'Users'
		total: number
		items: Array<{
			__typename?: 'User'
			id: string
			name: string
			email: string
			disabled: boolean
			lastAppOpenedAt?: string | null
		}>
	}
}

export type ManagementUserQueryVariables = Exact<{
	id: Scalars['ID']['input']
}>

export type ManagementUserQuery = {
	__typename?: 'Query'
	user?: {
		__typename?: 'User'
		id: string
		email: string
		name: string
		disabled: boolean
		traderId?: string | null
		bloombergUserId?: string | null
		lastAppOpenedAt?: string | null
		preferences: { __typename?: 'UserPreferences'; timeZone: string }
	} | null
}

export type ExportUsersToCsvQueryVariables = Exact<{
	orgId: Scalars['String']['input']
}>

export type ExportUsersToCsvQuery = {
	__typename?: 'Query'
	exportUsersToCSV: string
}

export type DistributionOrgsQueryVariables = Exact<{ [key: string]: never }>

export type DistributionOrgsQuery = {
	__typename?: 'Query'
	distributionOrgs: Array<{
		__typename?: 'OrganizationPublic'
		id: string
		name: string
		category: OrgCategory
		exclude: boolean
		recentlyAdded: boolean
	}>
}

export type PriceDiscoveryResponseOrgUsersQueryVariables = Exact<{
	orgId: Scalars['ID']['input']
}>

export type PriceDiscoveryResponseOrgUsersQuery = {
	__typename?: 'Query'
	organization?: {
		__typename?: 'Organization'
		users: Array<{ __typename?: 'User'; id: string; name: string }>
	} | null
}

export const UserEntityFragmentDoc = gql`
	fragment UserEntity on UserEntity {
		entity {
			id
			name
			lei
			defaultBroker {
				id
				name
			}
			brokers {
				broker {
					id
					name
				}
			}
		}
		isDefault
	}
`
export const OrganizationPublicFragmentDoc = gql`
	fragment OrganizationPublic on OrganizationPublic {
		id
		name
		category
		exclude
		recentlyAdded
	}
`
export const UserFragmentDoc = gql`
	fragment User on User {
		id
		email
		disabled
		sessionId
		name
		minFirmsDistribution
		roles {
			id
		}
		organization {
			id
			name
			type
			inactivityTimeoutMins
		}
		entities {
			...UserEntity
		}
		preferences {
			opportunitiesView
			opportunitiesSort
		}
		auth0Id
		exclusions {
			...OrganizationPublic
		}
	}
	${UserEntityFragmentDoc}
	${OrganizationPublicFragmentDoc}
`
export const SectorFragmentDoc = gql`
	fragment Sector on Sector {
		name
	}
`
export const CountryFragmentDoc = gql`
	fragment Country on Country {
		id
		name
	}
`
export const ProfileFragmentDoc = gql`
	fragment Profile on Profile {
		id
		createdAt
		updatedAt
		marketCapMax
		marketCapMin
		sectors {
			...Sector
		}
		countries {
			...Country
		}
	}
	${SectorFragmentDoc}
	${CountryFragmentDoc}
`
export const InstrumentFragmentDoc = gql`
	fragment Instrument on Instrument {
		id
		isin
		marketID
		currency
		description
		sedol
		primaryBloombergTicker
		referencePrice
		averageDailyVolume
		preTradeLISValueCurrency
		marketName
		staticRefPriceCollarPercent
	}
`
export const BookbuildFragmentDoc = gql`
	fragment Bookbuild on Bookbuild {
		id
		instrument {
			...Instrument
		}
		daysAdtv
		upsizeDaysAdtv
		volume
		price
		upsizeVolume
		upsizePrice
		side
		createdAt
		endsAt
		startedAt
		cancelRequestedAt
		cancelRequestedBy
		cancelRequestJustification
		cancelledAt
		cancelledJustification
		failedAt
		failedBy
		failedJustification
		endedAt
		executingAt
		status
		referencePrice
		clientOrderId
		strategy
		notionalUsd
		notionalGbp
		notionalCash
		instrumentPrimaryAdv
		targetTariff
		upsizeTariff
		distribution {
			priceDiscovery
			liveWatchlists
			passiveInterest
			directOrgIds
			excludedOrgIds
		}
		captureDownsized
		allocability {
			id
			date
			demandPercent
			allocablePercent
			totalOrders
			dealPrice
			dealVolume
			allocations
			downsized
		}
		reach {
			priceDiscoveryFirms
			liveWatchlistsFirms
			passiveInterestFirms
			directFirms
		}
		deal {
			id
			price
			volume
			daysAdtv
			totalOrders
			referencePrice
			cashGross
			createdAt
			completedAt
			tariff
		}
		broker {
			id
			name
		}
		entity {
			id
			name
		}
		brokerLimitViolation {
			currentExposureUsd
			potentialExposureUsd
			allocatedOrderExposureUsd
			currentExposureLimitPercentage
			potentialExposureLimitPercentage
			allocatedOrderExposureLimitPercentage
			notionalUsd
			entityBroker {
				broker {
					id
					name
				}
				limitUsd
			}
		}
	}
	${InstrumentFragmentDoc}
`
export const AllocationFragmentDoc = gql`
	fragment Allocation on Allocation {
		id
		side
		organization {
			id
			name
		}
		counterparty {
			id
			name
		}
		allocation
		grossCash
		createdAt
		scoreTime
		scoreVwap
		deal {
			id
			completedAt
			referencePrice
			price
			volume
			daysAdtv
			cashGross
		}
		tariff
	}
`
export const DealFragmentDoc = gql`
	fragment Deal on Deal {
		id
		price
		volume
		daysAdtv
		cashGross
		referencePrice
		createdAt
		completedAt
		bookbuild {
			id
			instrument {
				...Instrument
			}
			side
			endedAt
		}
		allocations {
			...Allocation
		}
	}
	${InstrumentFragmentDoc}
	${AllocationFragmentDoc}
`
export const LiveWatchlistItemFragmentDoc = gql`
	fragment LiveWatchlistItem on LiveWatchlistItem {
		id
		createdAt
		side
		volume
		price
		currency
		hasLivePriceDiscovery
		hasLivePriceDiscoveryOpportunity
		user {
			id
			name
		}
		instrument {
			id
			description
			marketName
			averageDailyVolume
			primaryBloombergTicker
			currency
			holdings {
				fuzzyTotalAmount
				holders {
					fuzzyHoldingAmount
					trend
					reportDate
					organization {
						...OrganizationPublic
					}
					valueChangedADVPercent
					valueChangedUSD
				}
			}
		}
		distributionScore {
			totalContrasCount
			suggestedContrasCount
			suggestDistribution
		}
	}
	${OrganizationPublicFragmentDoc}
`
export const LiveWatchlistEntryFragmentDoc = gql`
	fragment LiveWatchlistEntry on LiveWatchlistEntry {
		id
		type
		timestamp
		side
		volume
		previousVolume
		price
		currency
		daysAdtv
		notionalUsd
		orgId
		orgName
		userId
		userName
		instrumentId
		instrumentDescription
		instrumentRank
		instrumentMarketName
		instrumentMarketCapUsdM
		instrumentAverageDailyVolume
		instrumentReferencePrice
		distributionScore {
			score
		}
		currentContraCount
		recentContraCount
	}
`
export const BookbuildPublicFragmentDoc = gql`
	fragment BookbuildPublic on BookbuildPublic {
		id
		instrument {
			...Instrument
		}
		side
		daysAdtv
		volume
		startedAt
		endsAt
		endedAt
		cancelledAt
		failedAt
		executingAt
		referencePrice
		allocability {
			allocablePercent
			demandPercent
		}
		instrumentPrimaryAdv
	}
	${InstrumentFragmentDoc}
`
export const OpportunityOrderFragmentDoc = gql`
	fragment OpportunityOrder on Order {
		id
		amendedAt
		cancelledAt
		score
		clientOrderId
		volume
		price
		upsizeVolume
		upsizePrice
		allocation {
			...Allocation
		}
		broker {
			id
			name
		}
		entity {
			id
			name
		}
	}
	${AllocationFragmentDoc}
`
export const OpportunityFragmentDoc = gql`
	fragment Opportunity on Opportunity {
		id
		bookbuild {
			...BookbuildPublic
		}
		order {
			...OpportunityOrder
		}
		liveWatchlistMatches {
			id
		}
		passiveInterestMatches {
			id
		}
		directed
		interested
		matchedBy
		status
	}
	${BookbuildPublicFragmentDoc}
	${OpportunityOrderFragmentDoc}
`
export const NotificationFragmentDoc = gql`
	fragment Notification on Notification {
		id
		createdAt
		readAt
		url
		title
		description
		sticky
	}
`
export const OrderFragmentDoc = gql`
	fragment Order on Order {
		id
		createdAt
		amendedAt
		cancelledAt
		score
		clientOrderId
		volume
		price
		upsizeVolume
		upsizePrice
		status
		notionalUsd
		notionalGbp
		notionalCash
		upsizeNotionalUsd
		upsizeNotionalGbp
		upsizeNotionalCash
		daysAdtv
		upsizeDaysAdtv
		bookbuild {
			...BookbuildPublic
			instrumentPrimaryAdv
		}
		allocation {
			...Allocation
		}
		broker {
			id
			name
		}
		brokerLimitViolation {
			currentExposureUsd
			potentialExposureUsd
			allocatedOrderExposureUsd
			currentExposureLimitPercentage
			potentialExposureLimitPercentage
			allocatedOrderExposureLimitPercentage
			entityBroker {
				broker {
					id
					name
				}
			}
		}
		targetTariff
		upsizeTariff
	}
	${BookbuildPublicFragmentDoc}
	${AllocationFragmentDoc}
`
export const HoldingsOwnerFragmentDoc = gql`
	fragment HoldingsOwner on HoldingsOwner {
		id
		ownerID
		name
		holderType
		lei
		country
		city
		parentID
		parentName
		totalHoldings
		totalHoldingsNotionalUsd
		totalChildren
	}
`
export const OrganizationFragmentDoc = gql`
	fragment Organization on Organization {
		id
		name
		type
		connection {
			id
			name
			strategy
		}
		inactivityTimeoutMins
		fixCompId
		usersCount
		entities {
			id
			name
			lei
		}
		category
		holdingsOwners {
			...HoldingsOwner
		}
	}
	${HoldingsOwnerFragmentDoc}
`
export const ConnectionFragmentDoc = gql`
	fragment Connection on Connection {
		id
		name
		strategy
	}
`
export const FixLogFragmentDoc = gql`
	fragment FixLog on FixLog {
		id
		recordedAt
		direction
		session
		message
	}
`
export const ApiKeyFragmentDoc = gql`
	fragment APIKey on APIKey {
		id
		createdAt
		expiryDate
		organization {
			id
			name
		}
	}
`
export const HolderFragmentDoc = gql`
	fragment Holder on Holder {
		fuzzyHoldingAmount
		trend
		reportDate
		valueChangedUSD
		valueChangedADVPercent
		organization {
			...OrganizationPublic
		}
	}
	${OrganizationPublicFragmentDoc}
`
export const TradeFragmentDoc = gql`
	fragment Trade on Trade {
		id
		crossId
		createdAt
		sentAt
		acknowledgedAt
		executedAt
		rejectedAt
		rejectedReason
		failedAt
		volume
		grossCash
		organization {
			id
			name
		}
	}
`
export const EmsFragmentDoc = gql`
	fragment EMS on EmsSession {
		id
		createdAt
		updatedAt
		name
		ems
		sessionType
		ioiSession {
			id
			name
		}
		beginString
		senderCompID
		targetCompID
		socketAddressHost
		socketAddressPort
		maxMessagesPerSecond
		startDay
		startTime
		endDay
		endTime
		timeZone
		weekdays
		heartbeatIntervalSec
		reconnectIntervalSec
		tlsPrivateKeyFilepath
		tlsCertificateFilepath
		tlsCAFilepath
		refreshOnLogon
		resetOnLogon
		resetOnLogout
		resetOnDisconnect
		strategy
		supportDistributionPopup
		technicalSupportLink
		organizations {
			id
		}
	}
`
export const IoiFragmentDoc = gql`
	fragment IOI on IoiSession {
		id
		createdAt
		updatedAt
		name
		ems
		sessionType
		beginString
		senderCompID
		targetCompID
		socketConnectHost
		socketConnectPort
		maxMessagesPerSecond
		startDay
		endDay
		weekdays
		startTime
		endTime
		timeZone
		heartbeatIntervalSec
		reconnectIntervalSec
		refreshOnLogon
		resetOnLogon
		resetOnLogout
		resetOnDisconnect
		technicalSupportLink
		state
		tlsSkipVerification
		tlsCertExpiry
		strategy
		emsSession {
			id
			name
		}
	}
`
export const WatchlistSessionFragmentDoc = gql`
	fragment WatchlistSession on WatchlistSession {
		id
		createdAt
		updatedAt
		name
		ems
		sessionType
		beginString
		senderCompID
		targetCompID
		socketConnectHost
		socketConnectPort
		maxMessagesPerSecond
		startDay
		startTime
		endDay
		endTime
		timeZone
		weekdays
		heartbeatIntervalSec
		reconnectIntervalSec
		refreshOnLogon
		resetOnLogon
		resetOnLogout
		resetOnDisconnect
		state
		technicalSupportLink
		organizations {
			id
		}
	}
`
export const TrendingEquitiesListFragmentDoc = gql`
	fragment TrendingEquitiesList on TrendingEquitiesList {
		id
		createdAt
		updatedAt
		endedAt
		active
		emailSentAt
		emailSentBy {
			id
			name
		}
		participants {
			id
			name
		}
		instruments {
			id
			state
			changedAt
			changedBy {
				id
				name
			}
			instrument {
				id
				description
				marketName
				primaryBloombergTicker
			}
			responses {
				id
				createdAt
				updatedAt
				side
				activityType
				user {
					id
					name
				}
				organization {
					id
					name
				}
			}
		}
	}
`
export const UserEventsFragmentDoc = gql`
	fragment UserEvents on UserEvents {
		total
		types
		items {
			id
			eventTime
			eventType
			eventSource
			eventProperties
			user {
				id
				name
			}
		}
	}
`
export const CreateBookbuildDocument = gql`
	mutation CreateBookbuild($bookbuild: CreateBookbuildInput!) {
		createBookbuild(bookbuild: $bookbuild) {
			...Bookbuild
		}
	}
	${BookbuildFragmentDoc}
`

export function useCreateBookbuildMutation() {
	return Urql.useMutation<
		CreateBookbuildMutation,
		CreateBookbuildMutationVariables
	>(CreateBookbuildDocument)
}
export const UpdateBookbuildSensitivityDocument = gql`
	mutation UpdateBookbuildSensitivity(
		$id: ID!
		$input: UpdateBookbuildSensitivityInput!
	) {
		updateBookbuildSensitivity(id: $id, input: $input) {
			...Bookbuild
		}
	}
	${BookbuildFragmentDoc}
`

export function useUpdateBookbuildSensitivityMutation() {
	return Urql.useMutation<
		UpdateBookbuildSensitivityMutation,
		UpdateBookbuildSensitivityMutationVariables
	>(UpdateBookbuildSensitivityDocument)
}
export const CancelBookbuildDocument = gql`
	mutation CancelBookbuild($id: ID!, $justification: String!) {
		cancelBookbuild(id: $id, justification: $justification) {
			...Bookbuild
		}
	}
	${BookbuildFragmentDoc}
`

export function useCancelBookbuildMutation() {
	return Urql.useMutation<
		CancelBookbuildMutation,
		CancelBookbuildMutationVariables
	>(CancelBookbuildDocument)
}
export const DistributeAndCommitBookbuildDocument = gql`
	mutation DistributeAndCommitBookbuild(
		$id: ID!
		$distribution: DistributeAndCommitBookbuildInput!
	) {
		distributeAndCommitBookbuild(id: $id, input: $distribution) {
			...Bookbuild
		}
	}
	${BookbuildFragmentDoc}
`

export function useDistributeAndCommitBookbuildMutation() {
	return Urql.useMutation<
		DistributeAndCommitBookbuildMutation,
		DistributeAndCommitBookbuildMutationVariables
	>(DistributeAndCommitBookbuildDocument)
}
export const AdminCancelBookbuildDocument = gql`
	mutation AdminCancelBookbuild($id: ID!, $justification: String!) {
		adminCancelBookbuild(id: $id, justification: $justification) {
			...Bookbuild
		}
	}
	${BookbuildFragmentDoc}
`

export function useAdminCancelBookbuildMutation() {
	return Urql.useMutation<
		AdminCancelBookbuildMutation,
		AdminCancelBookbuildMutationVariables
	>(AdminCancelBookbuildDocument)
}
export const AdminFailBookbuildDocument = gql`
	mutation AdminFailBookbuild($id: ID!, $justification: String!) {
		adminFailBookbuild(id: $id, justification: $justification) {
			...Bookbuild
		}
	}
	${BookbuildFragmentDoc}
`

export function useAdminFailBookbuildMutation() {
	return Urql.useMutation<
		AdminFailBookbuildMutation,
		AdminFailBookbuildMutationVariables
	>(AdminFailBookbuildDocument)
}
export const AdminCompleteBookbuildDealDocument = gql`
	mutation AdminCompleteBookbuildDeal($bookbuildID: ID!) {
		adminCompleteBookbuildDeal(bookbuildID: $bookbuildID)
	}
`

export function useAdminCompleteBookbuildDealMutation() {
	return Urql.useMutation<
		AdminCompleteBookbuildDealMutation,
		AdminCompleteBookbuildDealMutationVariables
	>(AdminCompleteBookbuildDealDocument)
}
export const AdminPartialCompleteBookbuildDocument = gql`
	mutation AdminPartialCompleteBookbuild($id: ID!) {
		adminPartialCompleteBookbuild(id: $id)
	}
`

export function useAdminPartialCompleteBookbuildMutation() {
	return Urql.useMutation<
		AdminPartialCompleteBookbuildMutation,
		AdminPartialCompleteBookbuildMutationVariables
	>(AdminPartialCompleteBookbuildDocument)
}
export const AdminSetBookbuildStrategyDocument = gql`
	mutation AdminSetBookbuildStrategy($id: ID!, $strategy: DealStrategy!) {
		adminSetBookbuildStrategy(id: $id, strategy: $strategy) {
			...Bookbuild
		}
	}
	${BookbuildFragmentDoc}
`

export function useAdminSetBookbuildStrategyMutation() {
	return Urql.useMutation<
		AdminSetBookbuildStrategyMutation,
		AdminSetBookbuildStrategyMutationVariables
	>(AdminSetBookbuildStrategyDocument)
}
export const AdminSetCaptureDownsizedBookbuildDocument = gql`
	mutation AdminSetCaptureDownsizedBookbuild(
		$id: ID!
		$captureDownsized: Boolean!
	) {
		adminSetCaptureDownsizedBookbuild(
			id: $id
			captureDownsized: $captureDownsized
		) {
			...Bookbuild
		}
	}
	${BookbuildFragmentDoc}
`

export function useAdminSetCaptureDownsizedBookbuildMutation() {
	return Urql.useMutation<
		AdminSetCaptureDownsizedBookbuildMutation,
		AdminSetCaptureDownsizedBookbuildMutationVariables
	>(AdminSetCaptureDownsizedBookbuildDocument)
}
export const AdminSetBypassBrokerLimitsDocument = gql`
	mutation AdminSetBypassBrokerLimits(
		$id: ID!
		$bypassBrokerLimits: Boolean!
	) {
		adminSetBypassBrokerLimits(
			id: $id
			bypassBrokerLimits: $bypassBrokerLimits
		) {
			id
		}
	}
`

export function useAdminSetBypassBrokerLimitsMutation() {
	return Urql.useMutation<
		AdminSetBypassBrokerLimitsMutation,
		AdminSetBypassBrokerLimitsMutationVariables
	>(AdminSetBypassBrokerLimitsDocument)
}
export const CreateOrUpdateProfileDocument = gql`
	mutation CreateOrUpdateProfile($profile: ProfileInput!) {
		createOrUpdateProfile(profile: $profile) {
			...Profile
		}
	}
	${ProfileFragmentDoc}
`

export function useCreateOrUpdateProfileMutation() {
	return Urql.useMutation<
		CreateOrUpdateProfileMutation,
		CreateOrUpdateProfileMutationVariables
	>(CreateOrUpdateProfileDocument)
}
export const AddLiveWatchlistItemDocument = gql`
	mutation AddLiveWatchlistItem($input: LiveWatchlistItemInput!) {
		addLiveWatchlistItem(input: $input) {
			id
		}
	}
`

export function useAddLiveWatchlistItemMutation() {
	return Urql.useMutation<
		AddLiveWatchlistItemMutation,
		AddLiveWatchlistItemMutationVariables
	>(AddLiveWatchlistItemDocument)
}
export const DeleteLiveWatchlistItemDocument = gql`
	mutation DeleteLiveWatchlistItem($id: ID!, $force: Boolean!) {
		deleteLiveWatchlistItem(id: $id, force: $force) {
			id
		}
	}
`

export function useDeleteLiveWatchlistItemMutation() {
	return Urql.useMutation<
		DeleteLiveWatchlistItemMutation,
		DeleteLiveWatchlistItemMutationVariables
	>(DeleteLiveWatchlistItemDocument)
}
export const SetLiveWatchlistItemsDocument = gql`
	mutation SetLiveWatchlistItems($files: [Upload!]!) {
		setLiveWatchlistItems(files: $files) {
			itemsAddedCount
			unsupportedTickers {
				ticker
			}
		}
	}
`

export function useSetLiveWatchlistItemsMutation() {
	return Urql.useMutation<
		SetLiveWatchlistItemsMutation,
		SetLiveWatchlistItemsMutationVariables
	>(SetLiveWatchlistItemsDocument)
}
export const RequestLiveWatchlistAutomationDocument = gql`
	mutation RequestLiveWatchlistAutomation {
		requestLiveWatchlistAutomation
	}
`

export function useRequestLiveWatchlistAutomationMutation() {
	return Urql.useMutation<
		RequestLiveWatchlistAutomationMutation,
		RequestLiveWatchlistAutomationMutationVariables
	>(RequestLiveWatchlistAutomationDocument)
}
export const ClearLiveWatchlistItemsDocument = gql`
	mutation ClearLiveWatchlistItems {
		clearLiveWatchlistItems
	}
`

export function useClearLiveWatchlistItemsMutation() {
	return Urql.useMutation<
		ClearLiveWatchlistItemsMutation,
		ClearLiveWatchlistItemsMutationVariables
	>(ClearLiveWatchlistItemsDocument)
}
export const ClearRecentLiveWatchlistItemsDocument = gql`
	mutation ClearRecentLiveWatchlistItems {
		clearRecentLiveWatchlistItems
	}
`

export function useClearRecentLiveWatchlistItemsMutation() {
	return Urql.useMutation<
		ClearRecentLiveWatchlistItemsMutation,
		ClearRecentLiveWatchlistItemsMutationVariables
	>(ClearRecentLiveWatchlistItemsDocument)
}
export const AddPassiveWatchlistItemDocument = gql`
	mutation AddPassiveWatchlistItem($input: PassiveWatchlistItemInput!) {
		addPassiveWatchlistItem(input: $input) {
			id
		}
	}
`

export function useAddPassiveWatchlistItemMutation() {
	return Urql.useMutation<
		AddPassiveWatchlistItemMutation,
		AddPassiveWatchlistItemMutationVariables
	>(AddPassiveWatchlistItemDocument)
}
export const DeletePassiveWatchlistItemDocument = gql`
	mutation DeletePassiveWatchlistItem($id: ID!) {
		deletePassiveWatchlistItem(id: $id) {
			id
		}
	}
`

export function useDeletePassiveWatchlistItemMutation() {
	return Urql.useMutation<
		DeletePassiveWatchlistItemMutation,
		DeletePassiveWatchlistItemMutationVariables
	>(DeletePassiveWatchlistItemDocument)
}
export const SetPassiveWatchlistItemsDocument = gql`
	mutation SetPassiveWatchlistItems($files: [Upload!]!) {
		setPassiveWatchlistItems(files: $files) {
			itemsAddedCount
			unsupportedTickers {
				ticker
			}
		}
	}
`

export function useSetPassiveWatchlistItemsMutation() {
	return Urql.useMutation<
		SetPassiveWatchlistItemsMutation,
		SetPassiveWatchlistItemsMutationVariables
	>(SetPassiveWatchlistItemsDocument)
}
export const ClearPassiveWatchlistItemsDocument = gql`
	mutation ClearPassiveWatchlistItems {
		clearPassiveWatchlistItems
	}
`

export function useClearPassiveWatchlistItemsMutation() {
	return Urql.useMutation<
		ClearPassiveWatchlistItemsMutation,
		ClearPassiveWatchlistItemsMutationVariables
	>(ClearPassiveWatchlistItemsDocument)
}
export const SetPassiveWatchlistSettingsDocument = gql`
	mutation SetPassiveWatchlistSettings(
		$settings: PassiveWatchlistSettingsInput!
	) {
		setPassiveWatchlistSettings(settings: $settings) {
			sellPremiumPercent
			buyDiscountPercent
			advPercent
			autoMigration
			reactionTimeHours
		}
	}
`

export function useSetPassiveWatchlistSettingsMutation() {
	return Urql.useMutation<
		SetPassiveWatchlistSettingsMutation,
		SetPassiveWatchlistSettingsMutationVariables
	>(SetPassiveWatchlistSettingsDocument)
}
export const UpdatePreferencesDocument = gql`
	mutation UpdatePreferences($userId: ID!, $preferences: PreferencesInput!) {
		updatePreferences(userId: $userId, preferences: $preferences) {
			opportunitiesView
		}
	}
`

export function useUpdatePreferencesMutation() {
	return Urql.useMutation<
		UpdatePreferencesMutation,
		UpdatePreferencesMutationVariables
	>(UpdatePreferencesDocument)
}
export const CreateOrderDocument = gql`
	mutation CreateOrder($order: CreateOrderInput!) {
		createOrder(order: $order) {
			...Order
		}
	}
	${OrderFragmentDoc}
`

export function useCreateOrderMutation() {
	return Urql.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(
		CreateOrderDocument
	)
}
export const UpdateOrderDocument = gql`
	mutation UpdateOrder($id: ID!, $order: UpdateOrderInput!) {
		updateOrder(id: $id, order: $order) {
			...Order
		}
	}
	${OrderFragmentDoc}
`

export function useUpdateOrderMutation() {
	return Urql.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(
		UpdateOrderDocument
	)
}
export const CancelOrderDocument = gql`
	mutation CancelOrder($id: ID!, $justification: String!) {
		cancelOrder(id: $id, justification: $justification) {
			...Order
		}
	}
	${OrderFragmentDoc}
`

export function useCancelOrderMutation() {
	return Urql.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(
		CancelOrderDocument
	)
}
export const MarkAllNotificationsAsReadDocument = gql`
	mutation markAllNotificationsAsRead {
		markAllNotificationsAsRead
	}
`

export function useMarkAllNotificationsAsReadMutation() {
	return Urql.useMutation<
		MarkAllNotificationsAsReadMutation,
		MarkAllNotificationsAsReadMutationVariables
	>(MarkAllNotificationsAsReadDocument)
}
export const MarkNotificationsAsReadDocument = gql`
	mutation markNotificationsAsRead($ids: [ID!]!) {
		markNotificationsAsRead(ids: $ids) {
			id
		}
	}
`

export function useMarkNotificationsAsReadMutation() {
	return Urql.useMutation<
		MarkNotificationsAsReadMutation,
		MarkNotificationsAsReadMutationVariables
	>(MarkNotificationsAsReadDocument)
}
export const UpdateUserDocument = gql`
	mutation UpdateUser($user: UpdateUserInput!) {
		updateUser(user: $user) {
			...User
		}
	}
	${UserFragmentDoc}
`

export function useUpdateUserMutation() {
	return Urql.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
		UpdateUserDocument
	)
}
export const CreateUserDocument = gql`
	mutation CreateUser($user: CreateUserInput!) {
		createUser(user: $user) {
			id
		}
	}
`

export function useCreateUserMutation() {
	return Urql.useMutation<CreateUserMutation, CreateUserMutationVariables>(
		CreateUserDocument
	)
}
export const AdminUpdateUserDocument = gql`
	mutation AdminUpdateUser(
		$userId: ID!
		$input: AdminUpdateUserInput!
		$roleIds: [ID!]!
		$preferences: PreferencesInput!
	) {
		adminUpdateUser(userId: $userId, input: $input) {
			id
		}
		updateRoles(userId: $userId, roleIds: $roleIds) {
			id
		}
		updatePreferences(userId: $userId, preferences: $preferences) {
			timeZone
		}
	}
`

export function useAdminUpdateUserMutation() {
	return Urql.useMutation<
		AdminUpdateUserMutation,
		AdminUpdateUserMutationVariables
	>(AdminUpdateUserDocument)
}
export const DeleteUserDocument = gql`
	mutation DeleteUser($id: ID!) {
		deleteUser(id: $id)
	}
`

export function useDeleteUserMutation() {
	return Urql.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
		DeleteUserDocument
	)
}
export const DisableUserDocument = gql`
	mutation DisableUser($id: ID!) {
		disableUser(id: $id)
	}
`

export function useDisableUserMutation() {
	return Urql.useMutation<DisableUserMutation, DisableUserMutationVariables>(
		DisableUserDocument
	)
}
export const CreateOrganizationDocument = gql`
	mutation CreateOrganization($organization: OrganizationInput!) {
		createOrganization(organization: $organization) {
			...Organization
		}
	}
	${OrganizationFragmentDoc}
`

export function useCreateOrganizationMutation() {
	return Urql.useMutation<
		CreateOrganizationMutation,
		CreateOrganizationMutationVariables
	>(CreateOrganizationDocument)
}
export const UpdateOrganizationDocument = gql`
	mutation UpdateOrganization($id: ID!, $organization: OrganizationInput!) {
		updateOrganization(id: $id, organization: $organization) {
			...Organization
		}
	}
	${OrganizationFragmentDoc}
`

export function useUpdateOrganizationMutation() {
	return Urql.useMutation<
		UpdateOrganizationMutation,
		UpdateOrganizationMutationVariables
	>(UpdateOrganizationDocument)
}
export const SendUserWelcomeEmailDocument = gql`
	mutation SendUserWelcomeEmail($id: ID!) {
		sendUserWelcomeEmail(id: $id)
	}
`

export function useSendUserWelcomeEmailMutation() {
	return Urql.useMutation<
		SendUserWelcomeEmailMutation,
		SendUserWelcomeEmailMutationVariables
	>(SendUserWelcomeEmailDocument)
}
export const SendOriginationAlertDocument = gql`
	mutation SendOriginationAlert($watchlistItemId: ID!, $reason: String!) {
		sendOriginationAlert(watchlistItemId: $watchlistItemId, reason: $reason)
	}
`

export function useSendOriginationAlertMutation() {
	return Urql.useMutation<
		SendOriginationAlertMutation,
		SendOriginationAlertMutationVariables
	>(SendOriginationAlertDocument)
}
export const CreateApiKeyDocument = gql`
	mutation CreateAPIKey($key: CreateAPIKeyInput!) {
		createAPIKey(key: $key) {
			token
			apiKey {
				...APIKey
			}
		}
	}
	${ApiKeyFragmentDoc}
`

export function useCreateApiKeyMutation() {
	return Urql.useMutation<
		CreateApiKeyMutation,
		CreateApiKeyMutationVariables
	>(CreateApiKeyDocument)
}
export const DeleteApiKeyDocument = gql`
	mutation DeleteAPIKey($key: DeleteAPIKeyInput!) {
		deleteAPIKey(key: $key)
	}
`

export function useDeleteApiKeyMutation() {
	return Urql.useMutation<
		DeleteApiKeyMutation,
		DeleteApiKeyMutationVariables
	>(DeleteApiKeyDocument)
}
export const CreateBrokerDocument = gql`
	mutation CreateBroker($input: CreateBrokerInput!) {
		createBroker(input: $input) {
			id
		}
	}
`

export function useCreateBrokerMutation() {
	return Urql.useMutation<
		CreateBrokerMutation,
		CreateBrokerMutationVariables
	>(CreateBrokerDocument)
}
export const UpdateBrokerDocument = gql`
	mutation UpdateBroker($id: ID!, $input: UpdateBrokerInput!) {
		updateBroker(id: $id, input: $input) {
			id
		}
	}
`

export function useUpdateBrokerMutation() {
	return Urql.useMutation<
		UpdateBrokerMutation,
		UpdateBrokerMutationVariables
	>(UpdateBrokerDocument)
}
export const DeleteBrokerDocument = gql`
	mutation DeleteBroker($id: ID!) {
		deleteBroker(id: $id) {
			id
		}
	}
`

export function useDeleteBrokerMutation() {
	return Urql.useMutation<
		DeleteBrokerMutation,
		DeleteBrokerMutationVariables
	>(DeleteBrokerDocument)
}
export const AdminEndBookbuildDocument = gql`
	mutation AdminEndBookbuild($id: ID!) {
		adminEndBookbuild(id: $id)
	}
`

export function useAdminEndBookbuildMutation() {
	return Urql.useMutation<
		AdminEndBookbuildMutation,
		AdminEndBookbuildMutationVariables
	>(AdminEndBookbuildDocument)
}
export const RetryTradeDocument = gql`
	mutation RetryTrade($id: ID!) {
		retryTrade(id: $id) {
			...Trade
		}
	}
	${TradeFragmentDoc}
`

export function useRetryTradeMutation() {
	return Urql.useMutation<RetryTradeMutation, RetryTradeMutationVariables>(
		RetryTradeDocument
	)
}
export const FailTradeDocument = gql`
	mutation FailTrade($id: ID!) {
		failTrade(id: $id) {
			...Trade
		}
	}
	${TradeFragmentDoc}
`

export function useFailTradeMutation() {
	return Urql.useMutation<FailTradeMutation, FailTradeMutationVariables>(
		FailTradeDocument
	)
}
export const AdminCancelOrderDocument = gql`
	mutation AdminCancelOrder($id: ID!, $justification: String!) {
		adminCancelOrder(id: $id, justification: $justification) {
			...Order
		}
	}
	${OrderFragmentDoc}
`

export function useAdminCancelOrderMutation() {
	return Urql.useMutation<
		AdminCancelOrderMutation,
		AdminCancelOrderMutationVariables
	>(AdminCancelOrderDocument)
}
export const CreateEmsDocument = gql`
	mutation CreateEMS($session: EmsSessionInput!) {
		createEMSSession(session: $session) {
			...EMS
		}
	}
	${EmsFragmentDoc}
`

export function useCreateEmsMutation() {
	return Urql.useMutation<CreateEmsMutation, CreateEmsMutationVariables>(
		CreateEmsDocument
	)
}
export const UpdateEmsDocument = gql`
	mutation UpdateEMS($id: ID!, $session: EmsSessionInput!) {
		updateEMSSession(id: $id, session: $session) {
			...EMS
		}
	}
	${EmsFragmentDoc}
`

export function useUpdateEmsMutation() {
	return Urql.useMutation<UpdateEmsMutation, UpdateEmsMutationVariables>(
		UpdateEmsDocument
	)
}
export const DeleteEmsDocument = gql`
	mutation DeleteEMS($id: ID!) {
		deleteEMSSession(id: $id) {
			id
		}
	}
`

export function useDeleteEmsMutation() {
	return Urql.useMutation<DeleteEmsMutation, DeleteEmsMutationVariables>(
		DeleteEmsDocument
	)
}
export const CreateIoiDocument = gql`
	mutation CreateIOI($session: IoiSessionInput!) {
		createIoiSession(session: $session) {
			...IOI
		}
	}
	${IoiFragmentDoc}
`

export function useCreateIoiMutation() {
	return Urql.useMutation<CreateIoiMutation, CreateIoiMutationVariables>(
		CreateIoiDocument
	)
}
export const UpdateIoiDocument = gql`
	mutation UpdateIOI($id: ID!, $session: IoiSessionInput!) {
		updateIoiSession(id: $id, session: $session) {
			...IOI
		}
	}
	${IoiFragmentDoc}
`

export function useUpdateIoiMutation() {
	return Urql.useMutation<UpdateIoiMutation, UpdateIoiMutationVariables>(
		UpdateIoiDocument
	)
}
export const DeleteIoiDocument = gql`
	mutation DeleteIOI($id: ID!) {
		deleteIoiSession(id: $id) {
			id
		}
	}
`

export function useDeleteIoiMutation() {
	return Urql.useMutation<DeleteIoiMutation, DeleteIoiMutationVariables>(
		DeleteIoiDocument
	)
}
export const CreateWatchlistSessionDocument = gql`
	mutation CreateWatchlistSession($session: WatchlistSessionInput!) {
		createWatchlistSession(session: $session) {
			...WatchlistSession
		}
	}
	${WatchlistSessionFragmentDoc}
`

export function useCreateWatchlistSessionMutation() {
	return Urql.useMutation<
		CreateWatchlistSessionMutation,
		CreateWatchlistSessionMutationVariables
	>(CreateWatchlistSessionDocument)
}
export const UpdateWatchlistSessionDocument = gql`
	mutation UpdateWatchlistSession(
		$id: ID!
		$session: WatchlistSessionInput!
	) {
		updateWatchlistSession(id: $id, session: $session) {
			...WatchlistSession
		}
	}
	${WatchlistSessionFragmentDoc}
`

export function useUpdateWatchlistSessionMutation() {
	return Urql.useMutation<
		UpdateWatchlistSessionMutation,
		UpdateWatchlistSessionMutationVariables
	>(UpdateWatchlistSessionDocument)
}
export const RespondToPriceDiscoveryDocument = gql`
	mutation RespondToPriceDiscovery($input: PriceDiscoveryResponseInput!) {
		respondToPriceDiscovery(input: $input) {
			id
			interested
			volume
			sensitivityType
			sensitivityValue
			hoursRequired
			pmRegion
		}
	}
`

export function useRespondToPriceDiscoveryMutation() {
	return Urql.useMutation<
		RespondToPriceDiscoveryMutation,
		RespondToPriceDiscoveryMutationVariables
	>(RespondToPriceDiscoveryDocument)
}
export const DeleteWatchlistSessionDocument = gql`
	mutation DeleteWatchlistSession($id: ID!) {
		deleteWatchlistSession(id: $id) {
			id
		}
	}
`

export function useDeleteWatchlistSessionMutation() {
	return Urql.useMutation<
		DeleteWatchlistSessionMutation,
		DeleteWatchlistSessionMutationVariables
	>(DeleteWatchlistSessionDocument)
}
export const DeleteUnsupportedTickerDocument = gql`
	mutation DeleteUnsupportedTicker($ticker: String!) {
		removeUnsupportedTicker(ticker: $ticker) {
			ticker
		}
	}
`

export function useDeleteUnsupportedTickerMutation() {
	return Urql.useMutation<
		DeleteUnsupportedTickerMutation,
		DeleteUnsupportedTickerMutationVariables
	>(DeleteUnsupportedTickerDocument)
}
export const UpdateOpportunityDocument = gql`
	mutation updateOpportunity($id: ID!, $interested: Boolean!) {
		updateOpportunity(id: $id, interested: $interested) {
			id
			interested
		}
	}
`

export function useUpdateOpportunityMutation() {
	return Urql.useMutation<
		UpdateOpportunityMutation,
		UpdateOpportunityMutationVariables
	>(UpdateOpportunityDocument)
}
export const SetOrganizationHoldingsOwnersDocument = gql`
	mutation setOrganizationHoldingsOwners($orgId: ID!, $ownerIds: [Int!]!) {
		setOrganizationHoldingsOwners(orgId: $orgId, ownerIds: $ownerIds)
	}
`

export function useSetOrganizationHoldingsOwnersMutation() {
	return Urql.useMutation<
		SetOrganizationHoldingsOwnersMutation,
		SetOrganizationHoldingsOwnersMutationVariables
	>(SetOrganizationHoldingsOwnersDocument)
}
export const CreateTrendingEquitiesListDocument = gql`
	mutation CreateTrendingEquitiesList($list: TrendingEquitiesListInput!) {
		createTrendingEquitiesList(list: $list) {
			...TrendingEquitiesList
		}
	}
	${TrendingEquitiesListFragmentDoc}
`

export function useCreateTrendingEquitiesListMutation() {
	return Urql.useMutation<
		CreateTrendingEquitiesListMutation,
		CreateTrendingEquitiesListMutationVariables
	>(CreateTrendingEquitiesListDocument)
}
export const UpdateTrendingEquitiesListDocument = gql`
	mutation UpdateTrendingEquitiesList(
		$id: ID!
		$list: TrendingEquitiesListInput!
	) {
		updateTrendingEquitiesList(id: $id, list: $list) {
			...TrendingEquitiesList
		}
	}
	${TrendingEquitiesListFragmentDoc}
`

export function useUpdateTrendingEquitiesListMutation() {
	return Urql.useMutation<
		UpdateTrendingEquitiesListMutation,
		UpdateTrendingEquitiesListMutationVariables
	>(UpdateTrendingEquitiesListDocument)
}
export const RespondToTrendingEquityDocument = gql`
	mutation RespondToTrendingEquity($instrumentID: ID!, $side: Side) {
		respondToTrendingEquity(instrumentID: $instrumentID, side: $side)
	}
`

export function useRespondToTrendingEquityMutation() {
	return Urql.useMutation<
		RespondToTrendingEquityMutation,
		RespondToTrendingEquityMutationVariables
	>(RespondToTrendingEquityDocument)
}
export const SendTrendingEquitiesEmailDocument = gql`
	mutation SendTrendingEquitiesEmail($listID: ID!) {
		sendTrendingEquitiesEmail(listID: $listID)
	}
`

export function useSendTrendingEquitiesEmailMutation() {
	return Urql.useMutation<
		SendTrendingEquitiesEmailMutation,
		SendTrendingEquitiesEmailMutationVariables
	>(SendTrendingEquitiesEmailDocument)
}
export const HideTrendingEquitiesHintDocument = gql`
	mutation HideTrendingEquitiesHint {
		hideTrendingEquitiesHint
	}
`

export function useHideTrendingEquitiesHintMutation() {
	return Urql.useMutation<
		HideTrendingEquitiesHintMutation,
		HideTrendingEquitiesHintMutationVariables
	>(HideTrendingEquitiesHintDocument)
}
export const CreatePriceDiscoveryDocument = gql`
	mutation CreatePriceDiscovery($input: PriceDiscoveryInput!) {
		createPriceDiscovery(input: $input) {
			id
		}
	}
`

export function useCreatePriceDiscoveryMutation() {
	return Urql.useMutation<
		CreatePriceDiscoveryMutation,
		CreatePriceDiscoveryMutationVariables
	>(CreatePriceDiscoveryDocument)
}
export const CancelPriceDiscoveryDocument = gql`
	mutation CancelPriceDiscovery($id: ID!) {
		cancelPriceDiscovery(id: $id)
	}
`

export function useCancelPriceDiscoveryMutation() {
	return Urql.useMutation<
		CancelPriceDiscoveryMutation,
		CancelPriceDiscoveryMutationVariables
	>(CancelPriceDiscoveryDocument)
}
export const SendPriceDiscoveryOriginationAlertDocument = gql`
	mutation SendPriceDiscoveryOriginationAlert(
		$priceDiscoveryId: ID!
		$input: PriceDiscoveryOriginationAlertInput!
	) {
		sendPriceDiscoveryOriginationAlert(
			priceDiscoveryId: $priceDiscoveryId
			input: $input
		)
	}
`

export function useSendPriceDiscoveryOriginationAlertMutation() {
	return Urql.useMutation<
		SendPriceDiscoveryOriginationAlertMutation,
		SendPriceDiscoveryOriginationAlertMutationVariables
	>(SendPriceDiscoveryOriginationAlertDocument)
}
export const ReloadWatchlistEngineDocument = gql`
	mutation ReloadWatchlistEngine {
		reloadWatchlistEngine
	}
`

export function useReloadWatchlistEngineMutation() {
	return Urql.useMutation<
		ReloadWatchlistEngineMutation,
		ReloadWatchlistEngineMutationVariables
	>(ReloadWatchlistEngineDocument)
}
export const ReloadIoiEngineDocument = gql`
	mutation ReloadIoiEngine {
		reloadIoiEngine
	}
`

export function useReloadIoiEngineMutation() {
	return Urql.useMutation<
		ReloadIoiEngineMutation,
		ReloadIoiEngineMutationVariables
	>(ReloadIoiEngineDocument)
}
export const UpdateDistributionOrgsDocument = gql`
	mutation UpdateDistributionOrgs($orgs: [DistributionOrgInput!]!) {
		updateDistributionOrgs(orgs: $orgs) {
			id
			name
			exclude
			recentlyAdded
		}
	}
`

export function useUpdateDistributionOrgsMutation() {
	return Urql.useMutation<
		UpdateDistributionOrgsMutation,
		UpdateDistributionOrgsMutationVariables
	>(UpdateDistributionOrgsDocument)
}
export const RespondNoToPriceDiscoveryAsAdminDocument = gql`
	mutation RespondNoToPriceDiscoveryAsAdmin(
		$priceDiscoveryOpportunityId: ID!
		$onBehalfOfUserId: ID!
	) {
		respondNoToPriceDiscoveryAsAdmin(
			priceDiscoveryOpportunityId: $priceDiscoveryOpportunityId
			onBehalfOfUserId: $onBehalfOfUserId
		)
	}
`

export function useRespondNoToPriceDiscoveryAsAdminMutation() {
	return Urql.useMutation<
		RespondNoToPriceDiscoveryAsAdminMutation,
		RespondNoToPriceDiscoveryAsAdminMutationVariables
	>(RespondNoToPriceDiscoveryAsAdminDocument)
}
export const MeDocument = gql`
	query Me {
		me {
			...User
		}
	}
	${UserFragmentDoc}
`

export function useMeQuery(
	options?: Omit<Urql.UseQueryArgs<MeQueryVariables>, 'query'>
) {
	return Urql.useQuery<MeQuery, MeQueryVariables>({
		query: MeDocument,
		...options,
	})
}
export const LiveWatchlistDocument = gql`
	query LiveWatchlist(
		$limit: Int!
		$offset: Int!
		$sortBy: LiveWatchlistItemsSortCol!
		$sortDesc: Boolean!
		$recentLiveLimit: Int!
		$recentLiveOffset: Int!
		$recentLiveSortBy: LiveWatchlistItemsSortCol!
		$recentLiveSortDesc: Boolean!
		$alertsSince: Time
		$priceDiscsActiveSince: Time
	) {
		liveWatchlist(
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
		) {
			items {
				...LiveWatchlistItem
			}
			total
			updatedAt
			automated
			automationRequested
			allowManualPriceDiscovery
			preventManualDeletion
		}
		recentLiveWatchlist(
			limit: $recentLiveLimit
			offset: $recentLiveOffset
			sortBy: $recentLiveSortBy
			sortDesc: $recentLiveSortDesc
		) {
			items {
				...LiveWatchlistItem
			}
			total
		}
		originationAlerts(
			since: $alertsSince
			limit: 666
			offset: 0
			sortBy: Date
			sortDesc: true
		) {
			total
			items {
				id
				side
				volume
				price
				createdAt
				instrument {
					id
					description
					marketName
					averageDailyVolume
					currency
					primaryBloombergTicker
					holdings {
						fuzzyTotalAmount
						holders {
							fuzzyHoldingAmount
							trend
							reportDate
							organization {
								...OrganizationPublic
							}
							valueChangedADVPercent
							valueChangedUSD
						}
					}
				}
				priceDiscovery {
					interestedCount
				}
				durationHours
				durationMinutes
				launchTime
				suggestion
			}
		}
		priceDiscoveries(
			limit: 666
			offset: 0
			sortBy: CREATED_AT
			sortDesc: true
			activeSince: $priceDiscsActiveSince
		) {
			total
			items {
				id
				side
				instrument {
					id
					description
					marketName
					primaryBloombergTicker
					holdings {
						fuzzyTotalAmount
						holders {
							reportDate
						}
					}
				}
				volume
				price
				daysAdtv
				user {
					name
				}
				interestedCount
				notInterestedCount
				manualSelectionFirmsCount
				suggestedDistributionFirmsCount
				holdersCount
				createdAt
				endsAt
				endedAt
				status
				cancelledAt
				cancelledBy {
					name
				}
				originationAlert {
					id
				}
				manualDistributionFirms {
					id
					name
					category
				}
			}
		}
		distributionOrgs {
			...OrganizationPublic
		}
	}
	${LiveWatchlistItemFragmentDoc}
	${OrganizationPublicFragmentDoc}
`

export function useLiveWatchlistQuery(
	options: Omit<Urql.UseQueryArgs<LiveWatchlistQueryVariables>, 'query'>
) {
	return Urql.useQuery<LiveWatchlistQuery, LiveWatchlistQueryVariables>({
		query: LiveWatchlistDocument,
		...options,
	})
}
export const PassiveWatchlistDocument = gql`
	query PassiveWatchlist(
		$limit: Int!
		$offset: Int!
		$sortBy: PassiveWatchlistItemsSortCol!
		$sortDesc: Boolean!
	) {
		passiveWatchlist(
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
		) {
			items {
				id
				createdAt
				side
				autoMigrated
				user {
					id
					name
				}
				instrument {
					id
					description
					marketName
					holdings {
						fuzzyTotalAmount
						holders {
							fuzzyHoldingAmount
							trend
							reportDate
							organization {
								...OrganizationPublic
							}
							valueChangedADVPercent
							valueChangedUSD
						}
					}
				}
			}
			total
			updatedAt
			settings {
				sellPremiumPercent
				buyDiscountPercent
				advPercent
				reactionTimeHours
				autoMigration
			}
		}
	}
	${OrganizationPublicFragmentDoc}
`

export function usePassiveWatchlistQuery(
	options: Omit<Urql.UseQueryArgs<PassiveWatchlistQueryVariables>, 'query'>
) {
	return Urql.useQuery<PassiveWatchlistQuery, PassiveWatchlistQueryVariables>(
		{ query: PassiveWatchlistDocument, ...options }
	)
}
export const ProfileDocument = gql`
	query Profile {
		profile {
			...Profile
		}
		sectors {
			...Sector
		}
		countries {
			...Country
		}
	}
	${ProfileFragmentDoc}
	${SectorFragmentDoc}
	${CountryFragmentDoc}
`

export function useProfileQuery(
	options?: Omit<Urql.UseQueryArgs<ProfileQueryVariables>, 'query'>
) {
	return Urql.useQuery<ProfileQuery, ProfileQueryVariables>({
		query: ProfileDocument,
		...options,
	})
}
export const PreviewProfileInstrumentCountsDocument = gql`
	query PreviewProfileInstrumentCounts($input: ProfileInput!) {
		previewProfileInstrumentsCount(input: $input) {
			total
		}
	}
`

export function usePreviewProfileInstrumentCountsQuery(
	options: Omit<
		Urql.UseQueryArgs<PreviewProfileInstrumentCountsQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		PreviewProfileInstrumentCountsQuery,
		PreviewProfileInstrumentCountsQueryVariables
	>({ query: PreviewProfileInstrumentCountsDocument, ...options })
}
export const OrganizationsDocument = gql`
	query Organizations {
		organizations {
			id
			name
			type
			category
			usersCount
			emsSession {
				name
			}
		}
	}
`

export function useOrganizationsQuery(
	options?: Omit<Urql.UseQueryArgs<OrganizationsQueryVariables>, 'query'>
) {
	return Urql.useQuery<OrganizationsQuery, OrganizationsQueryVariables>({
		query: OrganizationsDocument,
		...options,
	})
}
export const OrganizationDocument = gql`
	query Organization($id: ID!) {
		organization(id: $id) {
			...Organization
			emsSession {
				id
				name
			}
			watchlistSession {
				id
				name
			}
			disabledCurrencies
			disabledMarketIds
			disabledIsins
			liveWatchlistAutomated
			liveWatchlistClearEnabled
			liveWatchlistAutomationRequestedBy
			liveWatchlistAutomationRequestedAt
			passiveWatchlistAutomigration
			allowManualPriceDiscovery
			entities {
				id
				name
				defaultBroker {
					id
				}
				brokers {
					broker {
						id
						name
					}
					brokerCode
					limitUsd
					turquoiseShortcode
				}
			}
			holdingsOwners {
				...HoldingsOwner
			}
		}
	}
	${OrganizationFragmentDoc}
	${HoldingsOwnerFragmentDoc}
`

export function useOrganizationQuery(
	options: Omit<Urql.UseQueryArgs<OrganizationQueryVariables>, 'query'>
) {
	return Urql.useQuery<OrganizationQuery, OrganizationQueryVariables>({
		query: OrganizationDocument,
		...options,
	})
}
export const EditOrganizationDocument = gql`
	query EditOrganization($id: ID!) {
		organization(id: $id) {
			...Organization
			entities {
				id
				name
				defaultBroker {
					id
				}
				brokers {
					broker {
						id
						name
					}
					brokerCode
					limitUsd
					turquoiseShortcode
				}
			}
			emsSession {
				id
				name
			}
			watchlistSession {
				id
				name
			}
			disabledCurrencies
			disabledMarketIds
			disabledIsins
			liveWatchlistAutomated
			liveWatchlistClearEnabled
			passiveWatchlistAutomigration
			allowManualPriceDiscovery
		}
		connections {
			...Connection
		}
		brokers {
			id
			name
		}
		emsSessions {
			id
			name
		}
		watchlistSessions {
			id
			name
		}
		ioiSessions {
			id
			name
		}
	}
	${OrganizationFragmentDoc}
	${ConnectionFragmentDoc}
`

export function useEditOrganizationQuery(
	options: Omit<Urql.UseQueryArgs<EditOrganizationQueryVariables>, 'query'>
) {
	return Urql.useQuery<EditOrganizationQuery, EditOrganizationQueryVariables>(
		{ query: EditOrganizationDocument, ...options }
	)
}
export const CreateOrganizationDataDocument = gql`
	query CreateOrganizationData {
		connections {
			...Connection
		}
		brokers {
			id
			name
		}
		emsSessions {
			id
			name
		}
		watchlistSessions {
			id
			name
		}
		ioiSessions {
			id
			name
		}
	}
	${ConnectionFragmentDoc}
`

export function useCreateOrganizationDataQuery(
	options?: Omit<
		Urql.UseQueryArgs<CreateOrganizationDataQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		CreateOrganizationDataQuery,
		CreateOrganizationDataQueryVariables
	>({ query: CreateOrganizationDataDocument, ...options })
}
export const UserCreateDataDocument = gql`
	query UserCreateData {
		organizations {
			...Organization
		}
		roles {
			id
		}
	}
	${OrganizationFragmentDoc}
`

export function useUserCreateDataQuery(
	options?: Omit<Urql.UseQueryArgs<UserCreateDataQueryVariables>, 'query'>
) {
	return Urql.useQuery<UserCreateDataQuery, UserCreateDataQueryVariables>({
		query: UserCreateDataDocument,
		...options,
	})
}
export const RolesDocument = gql`
	query Roles {
		roles {
			id
		}
	}
`

export function useRolesQuery(
	options?: Omit<Urql.UseQueryArgs<RolesQueryVariables>, 'query'>
) {
	return Urql.useQuery<RolesQuery, RolesQueryVariables>({
		query: RolesDocument,
		...options,
	})
}
export const DistributeDocument = gql`
	query Distribute($id: ID!) {
		bookbuild(id: $id) {
			...Bookbuild
			instrument {
				holdings {
					holders {
						...Holder
					}
				}
			}
			previewReach {
				liveWatchlistsFirms
				passiveInterestFirms
				priceDiscoveryFirms
			}
			recentPriceDiscovery {
				id
				interestedCount
			}
		}
		distributionOrgs {
			...OrganizationPublic
		}
		distributionBrokers {
			id
			name
		}
	}
	${BookbuildFragmentDoc}
	${HolderFragmentDoc}
	${OrganizationPublicFragmentDoc}
`

export function useDistributeQuery(
	options: Omit<Urql.UseQueryArgs<DistributeQueryVariables>, 'query'>
) {
	return Urql.useQuery<DistributeQuery, DistributeQueryVariables>({
		query: DistributeDocument,
		...options,
	})
}
export const DealSpreadsheetDocument = gql`
	query DealSpreadsheet($id: ID!) {
		deal(id: $id) {
			spreadsheet
		}
	}
`

export function useDealSpreadsheetQuery(
	options: Omit<Urql.UseQueryArgs<DealSpreadsheetQueryVariables>, 'query'>
) {
	return Urql.useQuery<DealSpreadsheetQuery, DealSpreadsheetQueryVariables>({
		query: DealSpreadsheetDocument,
		...options,
	})
}
export const BookbuildsDocument = gql`
	query Bookbuilds(
		$limit: Int!
		$offset: Int!
		$sortBy: BookbuildsSortCol!
		$sortDesc: Boolean!
		$status: [Status!]
	) {
		bookbuilds(
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
			status: $status
		) {
			total
			items {
				...Bookbuild
			}
		}
		distributionOrgs {
			...OrganizationPublic
		}
		distributionBrokers {
			id
			name
		}
	}
	${BookbuildFragmentDoc}
	${OrganizationPublicFragmentDoc}
`

export function useBookbuildsQuery(
	options: Omit<Urql.UseQueryArgs<BookbuildsQueryVariables>, 'query'>
) {
	return Urql.useQuery<BookbuildsQuery, BookbuildsQueryVariables>({
		query: BookbuildsDocument,
		...options,
	})
}
export const BookbuildsDraftsTotalDocument = gql`
	query BookbuildsDraftsTotal {
		bookbuilds(
			limit: 1
			offset: 0
			sortBy: CreatedAt
			sortDesc: true
			status: [draft]
		) {
			total
		}
	}
`

export function useBookbuildsDraftsTotalQuery(
	options?: Omit<
		Urql.UseQueryArgs<BookbuildsDraftsTotalQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		BookbuildsDraftsTotalQuery,
		BookbuildsDraftsTotalQueryVariables
	>({ query: BookbuildsDraftsTotalDocument, ...options })
}
export const OpportunitiesInterestsDocument = gql`
	query OpportunitiesInterests {
		opportunities(limit: 0, offset: 0, sortBy: CreatedAt, sortDesc: true) {
			total
		}
		liveWatchlist(limit: 0, offset: 0, sortBy: Created, sortDesc: true) {
			total
		}
		passiveWatchlist(limit: 0, offset: 0, sortBy: Created, sortDesc: true) {
			total
		}
		priceDiscoveryOpportunities(limit: 0, offset: 0) {
			total
		}
		trendingEquitiesActive {
			instruments {
				id
			}
		}
	}
`

export function useOpportunitiesInterestsQuery(
	options?: Omit<
		Urql.UseQueryArgs<OpportunitiesInterestsQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		OpportunitiesInterestsQuery,
		OpportunitiesInterestsQueryVariables
	>({ query: OpportunitiesInterestsDocument, ...options })
}
export const OpportunitiesTodayDocument = gql`
	query OpportunitiesToday(
		$priceDiscoveryLimit: Int!
		$priceDiscoveryOffset: Int!
		$priceDiscoveryActiveSince: Time
	) {
		todayOpportunities {
			...Opportunity
		}
		trendingEquitiesActive {
			id
			instruments {
				id
				isHolder
				instrument {
					id
					description
					marketName
					marketCountry
					sector
					primaryBloombergTicker
				}
				responses {
					id
					side
					updatedAt
					user {
						name
						id
					}
				}
			}
		}
		priceDiscoveryOpportunities(
			limit: $priceDiscoveryLimit
			offset: $priceDiscoveryOffset
			activeSince: $priceDiscoveryActiveSince
		) {
			total
			items {
				id
				interested
				volume
				daysAdtv
				notionalUsd
				sensitivityType
				sensitivityValue
				hoursRequired
				pmRegion
				respondedAt
				isHolder
				organization {
					id
					name
				}
				priceDiscovery {
					id
					side
					endsAt
					endedAt
					createdAt
					cancelledAt
					daysAdtvLowerBound
					daysAdtvUpperBound
					status
					instrument {
						id
						description
						primaryBloombergTicker
						marketName
						currency
						averageDailyVolume
						notionalAverageDailyVolumeUsd
					}
				}
				respondedBy {
					id
					name
				}
			}
		}
		me {
			id
			hideTrendingEquitiesHint
			entities {
				isDefault
				entity {
					id
					brokers {
						broker {
							id
							name
						}
					}
				}
			}
		}
	}
	${OpportunityFragmentDoc}
`

export function useOpportunitiesTodayQuery(
	options: Omit<Urql.UseQueryArgs<OpportunitiesTodayQueryVariables>, 'query'>
) {
	return Urql.useQuery<
		OpportunitiesTodayQuery,
		OpportunitiesTodayQueryVariables
	>({ query: OpportunitiesTodayDocument, ...options })
}
export const OpportunitiesDocument = gql`
	query Opportunities(
		$limit: Int!
		$offset: Int!
		$sortBy: OpportunitiesSortCol!
		$sortDesc: Boolean!
	) {
		opportunities(
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
		) {
			items {
				...Opportunity
			}
			total
		}
	}
	${OpportunityFragmentDoc}
`

export function useOpportunitiesQuery(
	options: Omit<Urql.UseQueryArgs<OpportunitiesQueryVariables>, 'query'>
) {
	return Urql.useQuery<OpportunitiesQuery, OpportunitiesQueryVariables>({
		query: OpportunitiesDocument,
		...options,
	})
}
export const NotificationsDocument = gql`
	query Notifications($limit: Int!) {
		notifications(limit: $limit) {
			total
			totalUnread
			items {
				...Notification
			}
		}
	}
	${NotificationFragmentDoc}
`

export function useNotificationsQuery(
	options: Omit<Urql.UseQueryArgs<NotificationsQueryVariables>, 'query'>
) {
	return Urql.useQuery<NotificationsQuery, NotificationsQueryVariables>({
		query: NotificationsDocument,
		...options,
	})
}
export const OrdersDocument = gql`
	query Orders(
		$limit: Int!
		$offset: Int!
		$sortBy: OrdersSortCol!
		$sortDesc: Boolean!
	) {
		orders(
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
		) {
			total
			items {
				id
				createdAt
				amendedAt
				cancelledAt
				status
				bookbuild {
					side
					endsAt
					instrument {
						description
						marketName
						currency
					}
				}
				allocation {
					allocation
					deal {
						price
					}
				}
			}
		}
	}
`

export function useOrdersQuery(
	options: Omit<Urql.UseQueryArgs<OrdersQueryVariables>, 'query'>
) {
	return Urql.useQuery<OrdersQuery, OrdersQueryVariables>({
		query: OrdersDocument,
		...options,
	})
}
export const OrderDocument = gql`
	query Order($id: ID!) {
		order(id: $id) {
			...Order
		}
	}
	${OrderFragmentDoc}
`

export function useOrderQuery(
	options: Omit<Urql.UseQueryArgs<OrderQueryVariables>, 'query'>
) {
	return Urql.useQuery<OrderQuery, OrderQueryVariables>({
		query: OrderDocument,
		...options,
	})
}
export const BookbuildDocument = gql`
	query Bookbuild($id: ID!) {
		bookbuild(id: $id) {
			...Bookbuild
		}
		distributionOrgs {
			...OrganizationPublic
		}
		distributionBrokers {
			id
			name
		}
	}
	${BookbuildFragmentDoc}
	${OrganizationPublicFragmentDoc}
`

export function useBookbuildQuery(
	options: Omit<Urql.UseQueryArgs<BookbuildQueryVariables>, 'query'>
) {
	return Urql.useQuery<BookbuildQuery, BookbuildQueryVariables>({
		query: BookbuildDocument,
		...options,
	})
}
export const AdminUsersDocument = gql`
	query AdminUsers(
		$limit: Int!
		$offset: Int!
		$sortBy: UsersSortCol!
		$sortDesc: Boolean!
		$filter: String
	) {
		users(
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
			filter: $filter
		) {
			total
			items {
				id
				name
				email
				disabled
				welcomeEmailSentAt
				sessionLastEventAt
				lastAppOpenedAt
				organization {
					id
					name
					type
				}
			}
		}
	}
`

export function useAdminUsersQuery(
	options: Omit<Urql.UseQueryArgs<AdminUsersQueryVariables>, 'query'>
) {
	return Urql.useQuery<AdminUsersQuery, AdminUsersQueryVariables>({
		query: AdminUsersDocument,
		...options,
	})
}
export const AdminUserDocument = gql`
	query AdminUser($id: ID!) {
		user(id: $id) {
			id
			email
			name
			disabled
			traderId
			bloombergUserId
			sessionStartedAt
			sessionLastEventAt
			auth0Id
			welcomeEmailSentAt
			watchlistEmailSentAt
			organization {
				id
				name
				type
				entities {
					id
					name
				}
			}
			entities {
				entity {
					id
					name
				}
				isDefault
			}
			roles {
				id
			}
			preferences {
				timeZone
			}
		}
	}
`

export function useAdminUserQuery(
	options: Omit<Urql.UseQueryArgs<AdminUserQueryVariables>, 'query'>
) {
	return Urql.useQuery<AdminUserQuery, AdminUserQueryVariables>({
		query: AdminUserDocument,
		...options,
	})
}
export const AdminUserEventsTooltipDocument = gql`
	query AdminUserEventsTooltip($userId: ID!, $limit: Int!) {
		user(id: $userId) {
			lastAppOpenedAt
			events(limit: $limit) {
				total
				items {
					id
					eventTime
					eventType
				}
			}
		}
	}
`

export function useAdminUserEventsTooltipQuery(
	options: Omit<
		Urql.UseQueryArgs<AdminUserEventsTooltipQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		AdminUserEventsTooltipQuery,
		AdminUserEventsTooltipQueryVariables
	>({ query: AdminUserEventsTooltipDocument, ...options })
}
export const AdminUserEventsDocument = gql`
	query AdminUserEvents(
		$userId: ID!
		$limit: Int!
		$offset: Int
		$excludeTypes: [String!]
	) {
		userEvents(
			userId: $userId
			limit: $limit
			offset: $offset
			excludeTypes: $excludeTypes
		) {
			...UserEvents
		}
	}
	${UserEventsFragmentDoc}
`

export function useAdminUserEventsQuery(
	options: Omit<Urql.UseQueryArgs<AdminUserEventsQueryVariables>, 'query'>
) {
	return Urql.useQuery<AdminUserEventsQuery, AdminUserEventsQueryVariables>({
		query: AdminUserEventsDocument,
		...options,
	})
}
export const AdminOrgUserEventsDocument = gql`
	query AdminOrgUserEvents(
		$orgId: ID!
		$limit: Int!
		$offset: Int
		$excludeTypes: [String!]
	) {
		organizationUserEvents(
			orgId: $orgId
			limit: $limit
			offset: $offset
			excludeTypes: $excludeTypes
		) {
			...UserEvents
		}
	}
	${UserEventsFragmentDoc}
`

export function useAdminOrgUserEventsQuery(
	options: Omit<Urql.UseQueryArgs<AdminOrgUserEventsQueryVariables>, 'query'>
) {
	return Urql.useQuery<
		AdminOrgUserEventsQuery,
		AdminOrgUserEventsQueryVariables
	>({ query: AdminOrgUserEventsDocument, ...options })
}
export const AdminPlatformUserEventsDocument = gql`
	query AdminPlatformUserEvents(
		$limit: Int!
		$offset: Int
		$excludeTypes: [String!]
	) {
		userEvents(
			limit: $limit
			offset: $offset
			excludeTypes: $excludeTypes
		) {
			...UserEvents
		}
	}
	${UserEventsFragmentDoc}
`

export function useAdminPlatformUserEventsQuery(
	options: Omit<
		Urql.UseQueryArgs<AdminPlatformUserEventsQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		AdminPlatformUserEventsQuery,
		AdminPlatformUserEventsQueryVariables
	>({ query: AdminPlatformUserEventsDocument, ...options })
}
export const FixSessionsDocument = gql`
	query FixSessions {
		fixSessions
	}
`

export function useFixSessionsQuery(
	options?: Omit<Urql.UseQueryArgs<FixSessionsQueryVariables>, 'query'>
) {
	return Urql.useQuery<FixSessionsQuery, FixSessionsQueryVariables>({
		query: FixSessionsDocument,
		...options,
	})
}
export const FixLogsDocument = gql`
	query FixLogs(
		$session: String
		$first: Int
		$after: ID
		$last: Int
		$before: ID
	) {
		fixLogs(
			session: $session
			first: $first
			after: $after
			last: $last
			before: $before
		) {
			edges {
				cursor
				node {
					...FixLog
				}
			}
			pageInfo {
				startCursor
				endCursor
				hasNextPage
				hasPreviousPage
			}
		}
		fixCloudLogs(session: $session) {
			items {
				id
				recordedAt
				direction
				session
				message
			}
			nextToken
		}
	}
	${FixLogFragmentDoc}
`

export function useFixLogsQuery(
	options?: Omit<Urql.UseQueryArgs<FixLogsQueryVariables>, 'query'>
) {
	return Urql.useQuery<FixLogsQuery, FixLogsQueryVariables>({
		query: FixLogsDocument,
		...options,
	})
}
export const ApiKeysDocument = gql`
	query APIKeys {
		listKeys {
			...APIKey
		}
	}
	${ApiKeyFragmentDoc}
`

export function useApiKeysQuery(
	options?: Omit<Urql.UseQueryArgs<ApiKeysQueryVariables>, 'query'>
) {
	return Urql.useQuery<ApiKeysQuery, ApiKeysQueryVariables>({
		query: ApiKeysDocument,
		...options,
	})
}
export const InstrumentDocument = gql`
	query Instrument($id: ID!) {
		instrument(id: $id) {
			...Instrument
		}
	}
	${InstrumentFragmentDoc}
`

export function useInstrumentQuery(
	options: Omit<Urql.UseQueryArgs<InstrumentQueryVariables>, 'query'>
) {
	return Urql.useQuery<InstrumentQuery, InstrumentQueryVariables>({
		query: InstrumentDocument,
		...options,
	})
}
export const FindInstrumentsDocument = gql`
	query FindInstruments($identifier: String!) {
		findInstruments(identifier: $identifier) {
			...Instrument
			holdings {
				holders {
					...Holder
				}
			}
		}
	}
	${InstrumentFragmentDoc}
	${HolderFragmentDoc}
`

export function useFindInstrumentsQuery(
	options: Omit<Urql.UseQueryArgs<FindInstrumentsQueryVariables>, 'query'>
) {
	return Urql.useQuery<FindInstrumentsQuery, FindInstrumentsQueryVariables>({
		query: FindInstrumentsDocument,
		...options,
	})
}
export const BrokersDocument = gql`
	query Brokers {
		brokers {
			id
			name
			fixEmsId
			sessions {
				turquoiseCompId
			}
			organizations {
				id
			}
		}
	}
`

export function useBrokersQuery(
	options?: Omit<Urql.UseQueryArgs<BrokersQueryVariables>, 'query'>
) {
	return Urql.useQuery<BrokersQuery, BrokersQueryVariables>({
		query: BrokersDocument,
		...options,
	})
}
export const BrokerDocument = gql`
	query Broker($id: ID!, $withPassword: Boolean) {
		broker(id: $id, withPassword: $withPassword) {
			id
			name
			fixEmsId
			strategy
			technicalSupportLink
			thresholdPercentage
			sessions {
				id
				executionVenue
				turquoiseCompId
				turquoiseMemberId
				turquoiseTraderGroup
				socketConnectHost
				socketConnectPort
				sessionStartTime
				sessionEndTime
				sessionTimeZone
				maxMessagesPerSecond
				weekdays
				ignoreTradingCalendar
				password
				tempPassword
			}
			organizations {
				id
				name
				entities {
					entity {
						id
						name
						defaultBroker {
							id
						}
					}
					brokerCode
					limitUsd
					turquoiseShortcode
				}
			}
		}
	}
`

export function useBrokerQuery(
	options: Omit<Urql.UseQueryArgs<BrokerQueryVariables>, 'query'>
) {
	return Urql.useQuery<BrokerQuery, BrokerQueryVariables>({
		query: BrokerDocument,
		...options,
	})
}
export const AdminBookbuildsDocument = gql`
	query AdminBookbuilds(
		$limit: Int!
		$offset: Int!
		$sortBy: BookbuildsSortCol!
		$sortDesc: Boolean!
	) {
		bookbuilds(
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
		) {
			total
			items {
				id
				side
				status
				updatedAt
				cancelRequestedAt
				createdAt
				endsAt
				daysAdtv
				instrument {
					id
					description
				}
				organization {
					name
				}
				broker {
					name
				}
			}
		}
	}
`

export function useAdminBookbuildsQuery(
	options: Omit<Urql.UseQueryArgs<AdminBookbuildsQueryVariables>, 'query'>
) {
	return Urql.useQuery<AdminBookbuildsQuery, AdminBookbuildsQueryVariables>({
		query: AdminBookbuildsDocument,
		...options,
	})
}
export const AdminBookbuildDocument = gql`
	query AdminBookbuild($id: ID!) {
		bookbuild(id: $id) {
			...Bookbuild
			instrument {
				primaryBloombergTicker
				ranking {
					ranking
					score
				}
				sector
				marketCountryCode
				holdings {
					fuzzyTotalAmount
					holders {
						organization {
							id
							name
						}
						fuzzyHoldingAmount
						trend
						reportDate
						valueChangedUSD
						valueChangedADVPercent
					}
				}
			}
			user {
				name
				exclusions {
					id
				}
			}
			updatedAt
			suspendedAt
			suspendedReason
			organization {
				id
				name
			}
			entity {
				id
				name
			}
			orders {
				id
				createdAt
				amendedAt
				cancelledAt
				score
				organization {
					id
					name
				}
				broker {
					name
				}
				price
				volume
				daysAdtv
				notionalUsd
				upsizeDaysAdtv
				upsizeVolume
				upsizePrice
				upsizeNotionalUsd
				targetTariff
				upsizeTariff
				brokerLimitViolation {
					currentExposureUsd
					potentialExposureUsd
					allocatedOrderExposureUsd
					currentExposureLimitPercentage
					potentialExposureLimitPercentage
					allocatedOrderExposureLimitPercentage
					notionalUsd
					entityBroker {
						broker {
							id
							name
						}
						limitUsd
					}
				}
			}
			opportunities {
				id
				organization {
					id
					name
					users {
						id
						email
						name
						sessionStartedAt
						sessionLastEventAt
						lastAppOpenedAt
						lastEvent {
							id
							eventTime
							eventType
						}
					}
				}
				order {
					id
				}
				directed
				interested
				interestedUpdatedAt
				interestedUpdatedBy {
					id
					email
					name
				}
				matchedBy
			}
			deal {
				id
				price
				volume
				daysAdtv
				totalOrders
				referencePrice
				cashGross
				createdAt
				completedAt
				allocations {
					id
					side
					organization {
						id
						name
					}
					counterparty {
						id
						name
					}
					allocation
					grossCash
					createdAt
					proRata
					vwap
					scoreTime
					scoreVwap
					scoreAdjusted
					order {
						id
						volume
						price
						upsizeVolume
						upsizePrice
						broker {
							name
						}
					}
					trades {
						...Trade
					}
					tariff
				}
				trades {
					...Trade
				}
				tariff
			}
			fixLogs {
				cursor
				node {
					...FixLog
				}
			}
			bypassBrokerLimits
			notionalUsd
			upsizeNotionalUsd
			potentialDeal {
				id
				daysAdtv
				volume
				notionalUsd
				price
				broker {
					id
					name
				}
				allocations {
					id
					organization {
						id
						name
					}
					volume
					daysAdtv
					notionalUsd
					broker {
						name
					}
					dealPercentage
					tariff
					order {
						id
						brokerLimitViolation {
							id
							entityBroker {
								limitUsd
							}
							notionalUsd
							allocatedOrderExposureUsd
							allocatedOrderExposureLimitPercentage
							potentialExposureUsd
							potentialExposureLimitPercentage
						}
					}
				}
				tariff
			}
			deal {
				id
				price
				volume
				daysAdtv
				notionalUsd
				allocations {
					id
					organization {
						id
						name
					}
					side
					order {
						id
						price
						volume
						notionalUsd
						id
						brokerLimitViolation {
							id
							entityBroker {
								limitUsd
							}
							notionalUsd
							allocatedOrderExposureUsd
							allocatedOrderExposureLimitPercentage
							potentialExposureUsd
							potentialExposureLimitPercentage
						}
					}
					daysAdtv
					notionalUsd
					broker {
						id
						name
					}
					dealPercentage
				}
			}
			canEnd
			canCancel
			canPartialComplete
			targetTariff
			upsizeTariff
			instrumentPrimaryAdv
			previewReach {
				liveWatchlistsFirms
				passiveInterestFirms
				priceDiscoveryFirms
			}
		}
		distributionOrgs {
			...OrganizationPublic
		}
	}
	${BookbuildFragmentDoc}
	${TradeFragmentDoc}
	${FixLogFragmentDoc}
	${OrganizationPublicFragmentDoc}
`

export function useAdminBookbuildQuery(
	options: Omit<Urql.UseQueryArgs<AdminBookbuildQueryVariables>, 'query'>
) {
	return Urql.useQuery<AdminBookbuildQuery, AdminBookbuildQueryVariables>({
		query: AdminBookbuildDocument,
		...options,
	})
}
export const AdminBookbuildV2Document = gql`
	query AdminBookbuildV2($id: ID!) {
		bookbuild(id: $id) {
			id
			bypassBrokerLimits
			canCancel
			cancelledAt
			cancelledJustification
			cancelRequestedAt
			cancelRequestedBy
			cancelRequestJustification
			canEnd
			canPartialComplete
			captureDownsized
			createdAt
			daysAdtv
			endedAt
			endsAt
			executingAt
			failedAt
			failedBy
			failedJustification
			instrumentPrimaryAdv
			notionalUsd
			price
			side
			startedAt
			status
			strategy
			suspendedAt
			suspendedReason
			targetTariff
			updatedAt
			upsizeDaysAdtv
			upsizeNotionalUsd
			upsizePrice
			upsizeTariff
			upsizeVolume
			volume
			allocability {
				id
				date
				dealPrice
				dealVolume
				demandPercent
				downsized
				allocablePercent
				totalOrders
				totalOrders
			}
			brokerLimitViolation {
				id
				allocatedOrderExposureLimitPercentage
				allocatedOrderExposureUsd
				notionalUsd
				potentialExposureLimitPercentage
				potentialExposureUsd
				entityBroker {
					limitUsd
				}
			}
			broker {
				id
				name
			}
			distribution {
				directOrgIds
				excludedOrgIds
				liveWatchlists
				passiveInterest
				priceDiscovery
			}
			deal {
				id
				completedAt
				createdAt
				daysAdtv
				notionalUsd
				price
				tariff
				volume
				executedVolume
				executedDaysAdtv
				executedNotionalUsd
				executedOriginatorTariffBps
				allocations {
					id
					allocation
					daysAdtv
					dealPercentage
					notionalUsd
					executedVolume
					executedDaysAdtv
					executedNotionalUsd
					executedParticipantTariffBps
					side
					tariff
					broker {
						id
						name
					}
					counterparty {
						id
						name
					}
					order {
						id
						price
						broker {
							id
							name
						}
						brokerLimitViolation {
							id
							allocatedOrderExposureLimitPercentage
							allocatedOrderExposureUsd
							notionalUsd
							potentialExposureLimitPercentage
							potentialExposureUsd
							entityBroker {
								limitUsd
							}
						}
					}
					organization {
						id
						name
					}
					trades {
						id
						acknowledgedAt
						createdAt
						crossId
						executedAt
						failedAt
						cancelledAt
						grossCash
						rejectedAt
						rejectedReason
						sentAt
						volume
						organization {
							id
							name
						}
					}
				}
			}
			instrument {
				id
				currency
				description
				marketName
				preTradeLISValueCurrency
				primaryBloombergTicker
				referencePrice
				sedol
				holdings {
					fuzzyTotalAmount
					holders {
						fuzzyHoldingAmount
						reportDate
						trend
						valueChangedADVPercent
						valueChangedUSD
						organization {
							id
							name
						}
					}
				}
				ranking {
					ranking
					score
				}
			}
			opportunities {
				id
				directed
				interested
				interestedUpdatedAt
				matchedBy
				interestedUpdatedBy {
					id
					email
					name
				}
				order {
					id
				}
				organization {
					id
					name
					users {
						id
						email
						lastAppOpenedAt
						name
						sessionLastEventAt
						sessionStartedAt
						lastEvent {
							id
							eventTime
							eventType
						}
					}
				}
			}
			orders {
				id
				amendedAt
				createdAt
				daysAdtv
				notionalUsd
				price
				score
				status
				targetTariff
				upsizeDaysAdtv
				upsizeNotionalUsd
				upsizePrice
				upsizeTariff
				upsizeVolume
				user {
					id
					name
				}
				volume
				broker {
					id
					name
				}
				brokerLimitViolation {
					id
					allocatedOrderExposureLimitPercentage
					allocatedOrderExposureUsd
					notionalUsd
					potentialExposureLimitPercentage
					potentialExposureUsd
					entityBroker {
						limitUsd
					}
				}
				organization {
					id
					name
				}
			}
			organization {
				id
				name
			}
			potentialDeal {
				id
				daysAdtv
				notionalUsd
				price
				tariff
				volume
				allocations {
					id
					daysAdtv
					dealPercentage
					notionalUsd
					tariff
					volume
					order {
						id
						brokerLimitViolation {
							id
							allocatedOrderExposureLimitPercentage
							allocatedOrderExposureUsd
							notionalUsd
							potentialExposureLimitPercentage
							potentialExposureUsd
							entityBroker {
								limitUsd
							}
						}
					}
					broker {
						id
						name
					}
					organization {
						id
						name
					}
				}
			}
			previewReach {
				liveWatchlistsFirms
				passiveInterestFirms
				priceDiscoveryFirms
			}
			reach {
				liveWatchlistsFirms
				passiveInterestFirms
				priceDiscoveryFirms
			}
			user {
				name
				exclusions {
					id
				}
			}
		}
		distributionOrgs {
			id
			name
			category
			exclude
		}
	}
`

export function useAdminBookbuildV2Query(
	options: Omit<Urql.UseQueryArgs<AdminBookbuildV2QueryVariables>, 'query'>
) {
	return Urql.useQuery<AdminBookbuildV2Query, AdminBookbuildV2QueryVariables>(
		{ query: AdminBookbuildV2Document, ...options }
	)
}
export const AdminBookbuildFixLogsDocument = gql`
	query AdminBookbuildFixLogs($id: ID!) {
		bookbuild(id: $id) {
			id
			status
			fixLogs {
				cursor
				node {
					id
					recordedAt
					direction
					session
					message
				}
			}
			fixCloudLogs {
				id
				recordedAt
				direction
				session
				message
			}
		}
	}
`

export function useAdminBookbuildFixLogsQuery(
	options: Omit<
		Urql.UseQueryArgs<AdminBookbuildFixLogsQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		AdminBookbuildFixLogsQuery,
		AdminBookbuildFixLogsQueryVariables
	>({ query: AdminBookbuildFixLogsDocument, ...options })
}
export const AdminOrdersPaginatedDocument = gql`
	query AdminOrdersPaginated(
		$limit: Int!
		$offset: Int!
		$sortBy: OrdersSortCol!
		$sortDesc: Boolean!
	) {
		orders(
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
		) {
			total
			items {
				id
				createdAt
				amendedAt
				cancelledAt
				status
				bookbuild {
					side
					endsAt
					instrument {
						description
						marketName
					}
				}
				organization {
					name
				}
				broker {
					name
				}
			}
		}
	}
`

export function useAdminOrdersPaginatedQuery(
	options: Omit<
		Urql.UseQueryArgs<AdminOrdersPaginatedQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		AdminOrdersPaginatedQuery,
		AdminOrdersPaginatedQueryVariables
	>({ query: AdminOrdersPaginatedDocument, ...options })
}
export const AdminOrderDocument = gql`
	query AdminOrder($id: ID!) {
		order(id: $id) {
			...Order
			organization {
				name
			}
			entity {
				name
			}
		}
	}
	${OrderFragmentDoc}
`

export function useAdminOrderQuery(
	options: Omit<Urql.UseQueryArgs<AdminOrderQueryVariables>, 'query'>
) {
	return Urql.useQuery<AdminOrderQuery, AdminOrderQueryVariables>({
		query: AdminOrderDocument,
		...options,
	})
}
export const ListEmsDocument = gql`
	query ListEMS {
		emsSessions {
			id
			name
			ems
			targetCompID
			senderCompID
			sessionType
		}
	}
`

export function useListEmsQuery(
	options?: Omit<Urql.UseQueryArgs<ListEmsQueryVariables>, 'query'>
) {
	return Urql.useQuery<ListEmsQuery, ListEmsQueryVariables>({
		query: ListEmsDocument,
		...options,
	})
}
export const ViewEmsDocument = gql`
	query ViewEMS($id: ID!) {
		emsSession(id: $id) {
			...EMS
		}
	}
	${EmsFragmentDoc}
`

export function useViewEmsQuery(
	options: Omit<Urql.UseQueryArgs<ViewEmsQueryVariables>, 'query'>
) {
	return Urql.useQuery<ViewEmsQuery, ViewEmsQueryVariables>({
		query: ViewEmsDocument,
		...options,
	})
}
export const CreateEmsFormDocument = gql`
	query CreateEMSForm {
		emsNames
		ioiSessions {
			id
			name
		}
	}
`

export function useCreateEmsFormQuery(
	options?: Omit<Urql.UseQueryArgs<CreateEmsFormQueryVariables>, 'query'>
) {
	return Urql.useQuery<CreateEmsFormQuery, CreateEmsFormQueryVariables>({
		query: CreateEmsFormDocument,
		...options,
	})
}
export const EditEmsFormDocument = gql`
	query EditEMSForm($id: ID!) {
		emsSession(id: $id) {
			...EMS
		}
		emsNames
		ioiSessions {
			id
			name
		}
	}
	${EmsFragmentDoc}
`

export function useEditEmsFormQuery(
	options: Omit<Urql.UseQueryArgs<EditEmsFormQueryVariables>, 'query'>
) {
	return Urql.useQuery<EditEmsFormQuery, EditEmsFormQueryVariables>({
		query: EditEmsFormDocument,
		...options,
	})
}
export const ListIoiSessionsDocument = gql`
	query ListIOISessions(
		$sortBy: SessionsSortCol
		$sortDesc: Boolean
		$filter: String
	) {
		ioiSessions(sortBy: $sortBy, sortDesc: $sortDesc, filter: $filter) {
			id
			name
			ems
			targetCompID
			senderCompID
			sessionType
			state
		}
	}
`

export function useListIoiSessionsQuery(
	options?: Omit<Urql.UseQueryArgs<ListIoiSessionsQueryVariables>, 'query'>
) {
	return Urql.useQuery<ListIoiSessionsQuery, ListIoiSessionsQueryVariables>({
		query: ListIoiSessionsDocument,
		...options,
	})
}
export const GetIoiSessionDocument = gql`
	query GetIOISession($id: ID!) {
		ioiSession(id: $id) {
			...IOI
		}
	}
	${IoiFragmentDoc}
`

export function useGetIoiSessionQuery(
	options: Omit<Urql.UseQueryArgs<GetIoiSessionQueryVariables>, 'query'>
) {
	return Urql.useQuery<GetIoiSessionQuery, GetIoiSessionQueryVariables>({
		query: GetIoiSessionDocument,
		...options,
	})
}
export const CreateIoiFormDocument = gql`
	query CreateIOIForm {
		emsNames
	}
`

export function useCreateIoiFormQuery(
	options?: Omit<Urql.UseQueryArgs<CreateIoiFormQueryVariables>, 'query'>
) {
	return Urql.useQuery<CreateIoiFormQuery, CreateIoiFormQueryVariables>({
		query: CreateIoiFormDocument,
		...options,
	})
}
export const EditIoiFormDocument = gql`
	query EditIOIForm($id: ID!) {
		ioiSession(id: $id) {
			...IOI
		}
		emsNames
	}
	${IoiFragmentDoc}
`

export function useEditIoiFormQuery(
	options: Omit<Urql.UseQueryArgs<EditIoiFormQueryVariables>, 'query'>
) {
	return Urql.useQuery<EditIoiFormQuery, EditIoiFormQueryVariables>({
		query: EditIoiFormDocument,
		...options,
	})
}
export const ListWatchlistSessionsDocument = gql`
	query ListWatchlistSessions(
		$sortBy: SessionsSortCol
		$sortDesc: Boolean
		$filter: String
	) {
		watchlistSessions(
			sortBy: $sortBy
			sortDesc: $sortDesc
			filter: $filter
		) {
			id
			name
			ems
			targetCompID
			senderCompID
			sessionType
			state
		}
	}
`

export function useListWatchlistSessionsQuery(
	options?: Omit<
		Urql.UseQueryArgs<ListWatchlistSessionsQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		ListWatchlistSessionsQuery,
		ListWatchlistSessionsQueryVariables
	>({ query: ListWatchlistSessionsDocument, ...options })
}
export const GetWatchlistSessionDocument = gql`
	query GetWatchlistSession($id: ID!) {
		watchlistSession(id: $id) {
			...WatchlistSession
		}
	}
	${WatchlistSessionFragmentDoc}
`

export function useGetWatchlistSessionQuery(
	options: Omit<Urql.UseQueryArgs<GetWatchlistSessionQueryVariables>, 'query'>
) {
	return Urql.useQuery<
		GetWatchlistSessionQuery,
		GetWatchlistSessionQueryVariables
	>({ query: GetWatchlistSessionDocument, ...options })
}
export const CreateWatchlistSessionFormDocument = gql`
	query CreateWatchlistSessionForm {
		emsNames
	}
`

export function useCreateWatchlistSessionFormQuery(
	options?: Omit<
		Urql.UseQueryArgs<CreateWatchlistSessionFormQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		CreateWatchlistSessionFormQuery,
		CreateWatchlistSessionFormQueryVariables
	>({ query: CreateWatchlistSessionFormDocument, ...options })
}
export const EditWatchlistSessionFormDocument = gql`
	query EditWatchlistSessionForm($id: ID!) {
		watchlistSession(id: $id) {
			...WatchlistSession
		}
		emsNames
	}
	${WatchlistSessionFragmentDoc}
`

export function useEditWatchlistSessionFormQuery(
	options: Omit<
		Urql.UseQueryArgs<EditWatchlistSessionFormQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		EditWatchlistSessionFormQuery,
		EditWatchlistSessionFormQueryVariables
	>({ query: EditWatchlistSessionFormDocument, ...options })
}
export const AdminInstrumentsDocument = gql`
	query AdminInstruments(
		$orgId: ID
		$limit: Int!
		$offset: Int!
		$sortBy: InstrumentsSortCol!
		$sortDesc: Boolean!
	) {
		instruments(
			orgId: $orgId
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
		) {
			total
			items {
				id
				description
				marketName
				marketCapUsdM
				notionalAverageDailyVolumeUsd
				currency
				primaryBloombergTicker
				countActivityEvents(exceptOrgId: $orgId)
				countActivityEventsLastMonth(exceptOrgId: $orgId)
				liquidity
				liquidityCategory
				earningsDate
				holdings {
					fuzzyTotalAmount
					rawTotalAmount
					totalValueHeldUSD
					totalSharesHeld
					holders {
						fuzzyHoldingAmount
						rawHoldingAmount
						valueHeldUSD
						trend
						reportDate
						sharesHeld
						organization {
							id
							name
						}
					}
				}
				ranking {
					ranking
					score
					totalHoldersContribution
					keyHoldersContribution
					liquidityContribution
					marketCapContribution
					totalHeldPercentageContribution
				}
			}
		}
	}
`

export function useAdminInstrumentsQuery(
	options: Omit<Urql.UseQueryArgs<AdminInstrumentsQueryVariables>, 'query'>
) {
	return Urql.useQuery<AdminInstrumentsQuery, AdminInstrumentsQueryVariables>(
		{ query: AdminInstrumentsDocument, ...options }
	)
}
export const AdminInstrumentDocument = gql`
	query AdminInstrument($id: ID!, $limit: Int!, $offset: Int!) {
		instrument(id: $id) {
			id
			createdAt
			updatedAt
			active
			deactivatedAt
			isin
			marketID
			currency
			description
			referencePrice
			sedol
			ric
			primaryBloombergTicker
			countActivityEvents
			countActivityEventsLastMonth
			averageDailyVolume
			preTradeLISValueCurrency
			staticRefPriceCollarPercent
			dynamicRefPriceCollarPercent
			operatingMarketID
			marketName
			marketCountry
			marketCountryCode
			marketCapUsdM
			marketCapCategory
			percentChangeClose2days
			percentChangeClose30days
			percentChangeClose120days
			closeVolume1day
			averageVolume5Days
			averageVolume30Days
			dollarDailyAverage30Days
			liquidityAmount10Days
			liquidityVolume10Days
			liquidityCategory
			liquidity
			volatility5Days
			volatility10Days
			securityType
			securityTypeDescription
			sector
			calendarID
			executionVenue
			holdings {
				rawTotalAmount
				totalValueHeldUSD
				totalSharesHeld
				holders {
					fuzzyHoldingAmount
					rawHoldingAmount
					trend
					reportDate
					valueHeldUSD
					valueChangedUSD
					valueChangedADVPercent
					sharesHeld
					organization {
						id
						name
					}
				}
			}
			ranking {
				ranking
				score
				totalHoldersContribution
				keyHoldersContribution
				liquidityContribution
				marketCapContribution
				totalHeldPercentageContribution
			}
			activity(limit: $limit, offset: $offset) {
				total
				items {
					id
					recordedAt
					activityType
					side
					status
					entityId
					organization {
						id
						name
					}
					user {
						id
						name
					}
				}
			}
			notionalAverageDailyVolumeUsd
		}
	}
`

export function useAdminInstrumentQuery(
	options: Omit<Urql.UseQueryArgs<AdminInstrumentQueryVariables>, 'query'>
) {
	return Urql.useQuery<AdminInstrumentQuery, AdminInstrumentQueryVariables>({
		query: AdminInstrumentDocument,
		...options,
	})
}
export const InsightsOrgsDocument = gql`
	query InsightsOrgs {
		organizations {
			id
			name
		}
	}
`

export function useInsightsOrgsQuery(
	options?: Omit<Urql.UseQueryArgs<InsightsOrgsQueryVariables>, 'query'>
) {
	return Urql.useQuery<InsightsOrgsQuery, InsightsOrgsQueryVariables>({
		query: InsightsOrgsDocument,
		...options,
	})
}
export const InsightsOrgWatchlistsDocument = gql`
	query InsightsOrgWatchlists(
		$type: OrgType
		$limit: Int!
		$offset: Int!
		$sortBy: OrganizationItemsSortCol!
		$sortDesc: Boolean!
	) {
		organizationsPaginated(
			type: $type
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
		) {
			total
			items {
				id
				name
				liveWatchlistItemsCount
				liveWatchlistUpdatedAt
				liveWatchlistAutomated
				passiveWatchlistItemsCount
				passiveWatchlistUpdatedAt
				intersectionsCount
			}
		}
	}
`

export function useInsightsOrgWatchlistsQuery(
	options: Omit<
		Urql.UseQueryArgs<InsightsOrgWatchlistsQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		InsightsOrgWatchlistsQuery,
		InsightsOrgWatchlistsQueryVariables
	>({ query: InsightsOrgWatchlistsDocument, ...options })
}
export const InsightsIntersectionsDocument = gql`
	query InsightsIntersections(
		$orgId: ID
		$excludePassive: Boolean
		$limit: Int!
		$offset: Int!
		$sortBy: IntersectionsSortCol!
		$sortDesc: Boolean!
	) {
		intersections(
			orgId: $orgId
			excludePassive: $excludePassive
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
		) {
			total
			items {
				instrument {
					id
					description
					marketName
					marketCapUsdM
					notionalAverageDailyVolumeUsd
					currency
					primaryBloombergTicker
					holdings {
						rawTotalAmount
						totalValueHeldUSD
						holders {
							rawHoldingAmount
							trend
							reportDate
							valueHeldUSD
							organization {
								id
								name
							}
						}
					}
					ranking {
						ranking
						score
						totalHoldersContribution
						keyHoldersContribution
						liquidityContribution
						marketCapContribution
						totalHeldPercentageContribution
					}
				}
				buyItems {
					watchlistItemId
					isLive
					organization {
						id
						name
					}
				}
				sellItems {
					watchlistItemId
					isLive
					organization {
						id
						name
					}
				}
				latestOriginationAlert {
					id
					side
					instrument {
						id
					}
					createdAt
				}
			}
		}
	}
`

export function useInsightsIntersectionsQuery(
	options: Omit<
		Urql.UseQueryArgs<InsightsIntersectionsQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		InsightsIntersectionsQuery,
		InsightsIntersectionsQueryVariables
	>({ query: InsightsIntersectionsDocument, ...options })
}
export const InsightsOriginationAlertsDocument = gql`
	query InsightsOriginationAlerts(
		$orgId: ID!
		$limit: Int!
		$offset: Int!
		$sortBy: OriginationAlertsSortCol!
		$sortDesc: Boolean!
	) {
		originationAlerts(
			orgId: $orgId
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
		) {
			total
			items {
				id
				side
				instrument {
					description
					marketName
					primaryBloombergTicker
				}
				contactedByUser {
					name
				}
				createdAt
				reason
			}
		}
	}
`

export function useInsightsOriginationAlertsQuery(
	options: Omit<
		Urql.UseQueryArgs<InsightsOriginationAlertsQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		InsightsOriginationAlertsQuery,
		InsightsOriginationAlertsQueryVariables
	>({ query: InsightsOriginationAlertsDocument, ...options })
}
export const InsightsLiveWatchlistDocument = gql`
	query InsightsLiveWatchlist(
		$orgId: ID
		$limit: Int!
		$offset: Int!
		$sortBy: LiveWatchlistItemsSortCol!
		$sortDesc: Boolean!
	) {
		liveWatchlist(
			orgId: $orgId
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
		) {
			total
			updatedAt
			items {
				id
				createdAt
				side
				volume
				price
				currency
				notionalAmountUsd
				distributionScore {
					score
					suggestDistribution
					suggestedContrasCount
					totalContrasCount
				}
				currentContraCount
				recentContraCount
				organization {
					id
					name
				}
				user {
					id
					name
				}
				instrument {
					id
					description
					marketName
					marketCapUsdM
					averageDailyVolume
					notionalAverageDailyVolumeUsd
					currency
					referencePrice
					primaryBloombergTicker
					holdings {
						rawTotalAmount
						totalValueHeldUSD
						holders {
							rawHoldingAmount
							trend
							reportDate
							valueHeldUSD
							organization {
								id
								name
								category
							}
						}
					}
					ranking {
						ranking
						score
						totalHoldersContribution
						keyHoldersContribution
						liquidityContribution
						marketCapContribution
						totalHeldPercentageContribution
					}
				}
				intersection {
					instrument {
						id
						description
						marketName
						marketCapUsdM
						notionalAverageDailyVolumeUsd
						currency
						holdings {
							rawTotalAmount
							totalValueHeldUSD
							holders {
								rawHoldingAmount
								trend
								reportDate
								valueHeldUSD
								organization {
									id
									name
								}
							}
						}
					}
					buyItems {
						watchlistItemId
						isLive
						organization {
							id
							name
						}
					}
					sellItems {
						watchlistItemId
						isLive
						organization {
							id
							name
						}
					}
				}
				latestOriginationAlert {
					id
					side
					instrument {
						id
					}
					createdAt
				}
			}
		}
	}
`

export function useInsightsLiveWatchlistQuery(
	options: Omit<
		Urql.UseQueryArgs<InsightsLiveWatchlistQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		InsightsLiveWatchlistQuery,
		InsightsLiveWatchlistQueryVariables
	>({ query: InsightsLiveWatchlistDocument, ...options })
}
export const InstrumentDistributionScoreAttributesDocument = gql`
	query InstrumentDistributionScoreAttributes(
		$instrumentId: ID!
		$side: Side!
		$orgId: ID!
	) {
		instrumentDistributionScoreAttributes(
			instrumentId: $instrumentId
			side: $side
			orgId: $orgId
		) {
			factors {
				label
				score
				attributes
			}
			contras {
				org {
					id
					name
				}
				score
				isPadded
				contributions {
					label
					score
					attributes
				}
			}
		}
	}
`

export function useInstrumentDistributionScoreAttributesQuery(
	options: Omit<
		Urql.UseQueryArgs<InstrumentDistributionScoreAttributesQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		InstrumentDistributionScoreAttributesQuery,
		InstrumentDistributionScoreAttributesQueryVariables
	>({ query: InstrumentDistributionScoreAttributesDocument, ...options })
}
export const DistributionScoreWeightsDocument = gql`
	query DistributionScoreWeights {
		distributionScoreWeights {
			factor
			weight
		}
	}
`

export function useDistributionScoreWeightsQuery(
	options?: Omit<
		Urql.UseQueryArgs<DistributionScoreWeightsQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		DistributionScoreWeightsQuery,
		DistributionScoreWeightsQueryVariables
	>({ query: DistributionScoreWeightsDocument, ...options })
}
export const InsightsLiveWatchlistEntriesDocument = gql`
	query InsightsLiveWatchlistEntries(
		$orgId: ID
		$userId: ID
		$instrumentId: ID
		$limit: Int!
		$offset: Int!
		$previousEntriesLimit: Int!
	) {
		liveWatchlistEntries(
			orgId: $orgId
			userId: $userId
			instrumentId: $instrumentId
			limit: $limit
			offset: $offset
			previousEntriesLimit: $previousEntriesLimit
		) {
			total
			items {
				...LiveWatchlistEntry
				previousEntries {
					...LiveWatchlistEntry
				}
			}
		}
	}
	${LiveWatchlistEntryFragmentDoc}
`

export function useInsightsLiveWatchlistEntriesQuery(
	options: Omit<
		Urql.UseQueryArgs<InsightsLiveWatchlistEntriesQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		InsightsLiveWatchlistEntriesQuery,
		InsightsLiveWatchlistEntriesQueryVariables
	>({ query: InsightsLiveWatchlistEntriesDocument, ...options })
}
export const InsightsExportLiveWatchlistEntriesDocument = gql`
	query InsightsExportLiveWatchlistEntries($orgId: ID, $limit: Int!) {
		exportLiveWatchlistEntries(orgId: $orgId, limit: $limit) {
			...LiveWatchlistEntry
		}
	}
	${LiveWatchlistEntryFragmentDoc}
`

export function useInsightsExportLiveWatchlistEntriesQuery(
	options: Omit<
		Urql.UseQueryArgs<InsightsExportLiveWatchlistEntriesQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		InsightsExportLiveWatchlistEntriesQuery,
		InsightsExportLiveWatchlistEntriesQueryVariables
	>({ query: InsightsExportLiveWatchlistEntriesDocument, ...options })
}
export const InsightsPassiveWatchlistDocument = gql`
	query InsightsPassiveWatchlist(
		$orgId: ID
		$limit: Int!
		$offset: Int!
		$sortBy: PassiveWatchlistItemsSortCol!
		$sortDesc: Boolean!
	) {
		passiveWatchlist(
			orgId: $orgId
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
		) {
			total
			updatedAt
			items {
				id
				createdAt
				side
				currentContraCount
				recentContraCount
				organization {
					id
					name
				}
				user {
					id
					name
				}
				instrument {
					id
					description
					marketName
					primaryBloombergTicker
					holdings {
						fuzzyTotalAmount
						rawTotalAmount
						totalValueHeldUSD
						holders {
							rawHoldingAmount
							trend
							reportDate
							valueHeldUSD
							organization {
								id
								name
								category
							}
						}
					}
					ranking {
						ranking
						score
						totalHoldersContribution
						keyHoldersContribution
						liquidityContribution
						marketCapContribution
						totalHeldPercentageContribution
					}
				}
			}
		}
	}
`

export function useInsightsPassiveWatchlistQuery(
	options: Omit<
		Urql.UseQueryArgs<InsightsPassiveWatchlistQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		InsightsPassiveWatchlistQuery,
		InsightsPassiveWatchlistQueryVariables
	>({ query: InsightsPassiveWatchlistDocument, ...options })
}
export const UnsupportedTickersDocument = gql`
	query UnsupportedTickers(
		$limit: Int!
		$offset: Int!
		$sortBy: UnsupportedTickerSortCol!
		$sortDesc: Boolean!
	) {
		unsupportedTickers(
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
		) {
			total
			items {
				id
				createdAt
				lastReferencedAt
				ticker
				tickerOrganizations {
					organization {
						id
						name
					}
					tickerSource
				}
			}
		}
	}
`

export function useUnsupportedTickersQuery(
	options: Omit<Urql.UseQueryArgs<UnsupportedTickersQueryVariables>, 'query'>
) {
	return Urql.useQuery<
		UnsupportedTickersQuery,
		UnsupportedTickersQueryVariables
	>({ query: UnsupportedTickersDocument, ...options })
}
export const UserDetailsDocument = gql`
	query UserDetails {
		me {
			id
			email
			name
			authStrategy
			organization {
				id
				name
			}
		}
	}
`

export function useUserDetailsQuery(
	options?: Omit<Urql.UseQueryArgs<UserDetailsQueryVariables>, 'query'>
) {
	return Urql.useQuery<UserDetailsQuery, UserDetailsQueryVariables>({
		query: UserDetailsDocument,
		...options,
	})
}
export const MetricsDocument = gql`
	query Metrics($input: MetricsInput!) {
		metrics(input: $input) {
			bookbuilds {
				id
				date
				notional
				cumulativeNotional
				count
			}
		}
		organizations {
			id
			name
		}
		emsSessions {
			id
			ems
		}
	}
`

export function useMetricsQuery(
	options: Omit<Urql.UseQueryArgs<MetricsQueryVariables>, 'query'>
) {
	return Urql.useQuery<MetricsQuery, MetricsQueryVariables>({
		query: MetricsDocument,
		...options,
	})
}
export const EditOrganizationHoldingsDocument = gql`
	query EditOrganizationHoldings($orgId: ID!) {
		organization(id: $orgId) {
			name
			holdingsOwners {
				...HoldingsOwner
			}
		}
	}
	${HoldingsOwnerFragmentDoc}
`

export function useEditOrganizationHoldingsQuery(
	options: Omit<
		Urql.UseQueryArgs<EditOrganizationHoldingsQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		EditOrganizationHoldingsQuery,
		EditOrganizationHoldingsQueryVariables
	>({ query: EditOrganizationHoldingsDocument, ...options })
}
export const HoldingsOwnersDocument = gql`
	query HoldingsOwners(
		$ownerId: Int
		$searchQuery: String
		$limit: Int!
		$offset: Int!
	) {
		holdingsOwners(
			ownerId: $ownerId
			searchQuery: $searchQuery
			limit: $limit
			offset: $offset
		) {
			items {
				...HoldingsOwner
			}
			total
		}
	}
	${HoldingsOwnerFragmentDoc}
`

export function useHoldingsOwnersQuery(
	options: Omit<Urql.UseQueryArgs<HoldingsOwnersQueryVariables>, 'query'>
) {
	return Urql.useQuery<HoldingsOwnersQuery, HoldingsOwnersQueryVariables>({
		query: HoldingsOwnersDocument,
		...options,
	})
}
export const RankedInstrumentsDocument = gql`
	query RankedInstruments($ownerIds: [Int!]!) {
		rankedInstruments(ownerIds: $ownerIds) {
			instrument {
				id
				sedol
				primaryBloombergTicker
				description
				marketID
				marketName
				marketCountry
				marketCapUsdM
				notionalAverageDailyVolumeUsd
			}
			liquidity
			totalHolders
			totalHeldPercentage
			totalHeldUsdMil
			ownersHeldPercentage
			ownersHeldUsdMil
			lisUsd
			ranking
			score
		}
	}
`

export function useRankedInstrumentsQuery(
	options: Omit<Urql.UseQueryArgs<RankedInstrumentsQueryVariables>, 'query'>
) {
	return Urql.useQuery<
		RankedInstrumentsQuery,
		RankedInstrumentsQueryVariables
	>({ query: RankedInstrumentsDocument, ...options })
}
export const YFinanceDocument = gql`
	query YFinance(
		$sedol: String!
		$isin: String!
		$priceHistoryDays: Int
		$priceHistoryInterval: String
	) {
		yfinance(
			sedol: $sedol
			isin: $isin
			priceHistoryDays: $priceHistoryDays
			priceHistoryInterval: $priceHistoryInterval
		)
	}
`

export function useYFinanceQuery(
	options: Omit<Urql.UseQueryArgs<YFinanceQueryVariables>, 'query'>
) {
	return Urql.useQuery<YFinanceQuery, YFinanceQueryVariables>({
		query: YFinanceDocument,
		...options,
	})
}
export const TrendingEquitiesListsDocument = gql`
	query TrendingEquitiesLists(
		$limit: Int!
		$offset: Int!
		$sortBy: TrendingEquitiesSortCol!
		$sortDesc: Boolean!
		$filter: String
	) {
		trendingEquitiesLists(
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
			filter: $filter
		) {
			items {
				...TrendingEquitiesList
			}
			total
		}
	}
	${TrendingEquitiesListFragmentDoc}
`

export function useTrendingEquitiesListsQuery(
	options: Omit<
		Urql.UseQueryArgs<TrendingEquitiesListsQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		TrendingEquitiesListsQuery,
		TrendingEquitiesListsQueryVariables
	>({ query: TrendingEquitiesListsDocument, ...options })
}
export const TrendingEquitiesListDocument = gql`
	query TrendingEquitiesList($id: ID!) {
		trendingEquitiesList(id: $id) {
			...TrendingEquitiesList
		}
	}
	${TrendingEquitiesListFragmentDoc}
`

export function useTrendingEquitiesListQuery(
	options: Omit<
		Urql.UseQueryArgs<TrendingEquitiesListQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		TrendingEquitiesListQuery,
		TrendingEquitiesListQueryVariables
	>({ query: TrendingEquitiesListDocument, ...options })
}
export const AdminPriceDiscoveriesDocument = gql`
	query AdminPriceDiscoveries(
		$limit: Int!
		$offset: Int!
		$sortBy: PriceDiscoverySortCol!
		$sortDesc: Boolean!
		$filter: String
	) {
		priceDiscoveries(
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
			filter: $filter
		) {
			total
			items {
				id
				side
				instrument {
					id
					description
					marketName
					primaryBloombergTicker
				}
				user {
					id
					name
					organization {
						id
						name
					}
				}
				interestedCount
				yesCount
				notInterestedCount
				manualSelectionFirmsCount
				suggestedDistributionFirmsCount
				createdAt
				endsAt
				endedAt
				cancelledAt
				status
			}
		}
	}
`

export function useAdminPriceDiscoveriesQuery(
	options: Omit<
		Urql.UseQueryArgs<AdminPriceDiscoveriesQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		AdminPriceDiscoveriesQuery,
		AdminPriceDiscoveriesQueryVariables
	>({ query: AdminPriceDiscoveriesDocument, ...options })
}
export const AdminPriceDiscoveryDocument = gql`
	query AdminPriceDiscovery($id: ID!) {
		priceDiscovery(id: $id) {
			id
			side
			instrument {
				id
				description
				marketName
				primaryBloombergTicker
				currency
				holdings {
					rawTotalAmount
					holders {
						rawHoldingAmount
						organization {
							id
						}
					}
				}
			}
			volume
			price
			daysAdtv
			user {
				name
			}
			organization {
				name
			}
			interestedCount
			notInterestedCount
			manualSelectionFirmsCount
			suggestedDistributionFirmsCount
			holdersCount
			createdAt
			endsAt
			endedAt
			cancelledAt
			cancelledBy {
				name
			}
			status
			opportunities {
				id
				interested
				organization {
					id
					name
				}
				respondedAt
				respondedBy {
					name
				}
				volume
				sensitivityType
				sensitivityValue
				hoursRequired
				pmRegion
				adminRespondedAt
				adminRespondedBy {
					name
				}
				manualDistribution
				suggestedDistribution
			}
			originationAlert {
				createdAt
				contactedByUser {
					name
				}
			}
			manualDistributionFirms {
				id
				name
				category
			}
		}
	}
`

export function useAdminPriceDiscoveryQuery(
	options: Omit<Urql.UseQueryArgs<AdminPriceDiscoveryQueryVariables>, 'query'>
) {
	return Urql.useQuery<
		AdminPriceDiscoveryQuery,
		AdminPriceDiscoveryQueryVariables
	>({ query: AdminPriceDiscoveryDocument, ...options })
}
export const ManagementBookbuildsDocument = gql`
	query ManagementBookbuilds(
		$limit: Int!
		$offset: Int!
		$sortBy: BookbuildsSortCol!
		$sortDesc: Boolean!
		$status: [Status!]
	) {
		bookbuilds(
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
			status: $status
		) {
			total
			items {
				id
				side
				status
				updatedAt
				cancelRequestedAt
				createdAt
				endsAt
				daysAdtv
				instrument {
					id
					description
				}
				broker {
					name
				}
				user {
					name
				}
			}
		}
	}
`

export function useManagementBookbuildsQuery(
	options: Omit<
		Urql.UseQueryArgs<ManagementBookbuildsQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		ManagementBookbuildsQuery,
		ManagementBookbuildsQueryVariables
	>({ query: ManagementBookbuildsDocument, ...options })
}
export const ManagementOrdersDocument = gql`
	query ManagementOrders(
		$limit: Int!
		$offset: Int!
		$sortBy: OrdersSortCol!
		$sortDesc: Boolean!
		$status: [Status!]
	) {
		orders(
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
			status: $status
		) {
			total
			items {
				id
				createdAt
				amendedAt
				cancelledAt
				status
				bookbuild {
					side
					endsAt
					instrument {
						description
						marketName
					}
				}
				user {
					name
				}
				broker {
					name
				}
			}
		}
	}
`

export function useManagementOrdersQuery(
	options: Omit<Urql.UseQueryArgs<ManagementOrdersQueryVariables>, 'query'>
) {
	return Urql.useQuery<ManagementOrdersQuery, ManagementOrdersQueryVariables>(
		{ query: ManagementOrdersDocument, ...options }
	)
}
export const ManagementUsersDocument = gql`
	query ManagementUsers(
		$limit: Int!
		$offset: Int!
		$sortBy: UsersSortCol!
		$sortDesc: Boolean!
		$filter: String
	) {
		users(
			limit: $limit
			offset: $offset
			sortBy: $sortBy
			sortDesc: $sortDesc
			filter: $filter
		) {
			total
			items {
				id
				name
				email
				disabled
				lastAppOpenedAt
			}
		}
	}
`

export function useManagementUsersQuery(
	options: Omit<Urql.UseQueryArgs<ManagementUsersQueryVariables>, 'query'>
) {
	return Urql.useQuery<ManagementUsersQuery, ManagementUsersQueryVariables>({
		query: ManagementUsersDocument,
		...options,
	})
}
export const ManagementUserDocument = gql`
	query ManagementUser($id: ID!) {
		user(id: $id) {
			id
			email
			name
			disabled
			traderId
			bloombergUserId
			lastAppOpenedAt
			preferences {
				timeZone
			}
		}
	}
`

export function useManagementUserQuery(
	options: Omit<Urql.UseQueryArgs<ManagementUserQueryVariables>, 'query'>
) {
	return Urql.useQuery<ManagementUserQuery, ManagementUserQueryVariables>({
		query: ManagementUserDocument,
		...options,
	})
}
export const ExportUsersToCsvDocument = gql`
	query ExportUsersToCSV($orgId: String!) {
		exportUsersToCSV(orgId: $orgId)
	}
`

export function useExportUsersToCsvQuery(
	options: Omit<Urql.UseQueryArgs<ExportUsersToCsvQueryVariables>, 'query'>
) {
	return Urql.useQuery<ExportUsersToCsvQuery, ExportUsersToCsvQueryVariables>(
		{ query: ExportUsersToCsvDocument, ...options }
	)
}
export const DistributionOrgsDocument = gql`
	query DistributionOrgs {
		distributionOrgs {
			id
			name
			category
			exclude
			recentlyAdded
		}
	}
`

export function useDistributionOrgsQuery(
	options?: Omit<Urql.UseQueryArgs<DistributionOrgsQueryVariables>, 'query'>
) {
	return Urql.useQuery<DistributionOrgsQuery, DistributionOrgsQueryVariables>(
		{ query: DistributionOrgsDocument, ...options }
	)
}
export const PriceDiscoveryResponseOrgUsersDocument = gql`
	query PriceDiscoveryResponseOrgUsers($orgId: ID!) {
		organization(id: $orgId) {
			users {
				id
				name
			}
		}
	}
`

export function usePriceDiscoveryResponseOrgUsersQuery(
	options: Omit<
		Urql.UseQueryArgs<PriceDiscoveryResponseOrgUsersQueryVariables>,
		'query'
	>
) {
	return Urql.useQuery<
		PriceDiscoveryResponseOrgUsersQuery,
		PriceDiscoveryResponseOrgUsersQueryVariables
	>({ query: PriceDiscoveryResponseOrgUsersDocument, ...options })
}
