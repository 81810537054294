/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 30
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/appital/ATB/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'uat' | 'development' | 'local';

export const ApiKey: Record<Environment, string> = {
  production: '',
  uat: '',
  development: '',
  local: ''
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '30',
    branch: 'main',
    source: 'web',
    versionId: 'ba690747-2b9b-4aac-80e7-53ab4ff90210'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  "Appital ID"?: string;
  "Auth Strategy"?: string;
  "Auth0 ID"?: string;
  "EMS Name"?: string;
  "Org ID"?: string;
  "Org Name"?: string;
  "Org Type"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Unique Items | true |
   * | Item Type | string |
   */
  Roles?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Screen Height"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Screen Width"?: number;
}

export interface AppOpenedProperties {
  "Page Domain": string;
  "Page Location": string;
  "Page Path": string;
  "Page URL": string;
  "UTM Campaign"?: string;
  "UTM Medium"?: string;
  "UTM Source"?: string;
}

export interface BookBuildCancelClickedProperties {
  "BookBuild ID": string;
  /**
   * this should be the total number of firms that have been excluded from the distribution in the BookBuild
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Total Firms Excluded": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "Total Firms Selected": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "Total Firms Unselected": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "Total Holders Selected": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "Total Holders Unselected": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "Total Live Watchlist Firms Included"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "Total Non-Holders Selected": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "Total Passive Watchlist Firms Included"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "Total Profile Firms Included"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Total Watchlist Firms Included"?: number;
}

export interface BookBuildStartClickedProperties {
  "BookBuild ID": string;
  /**
   * this should be the total number of firms that have been excluded from the distribution in the BookBuild
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Total Firms Excluded": number;
  /**
   * How many firms did the client select across manual, watchlist & profile distribution. This should be based on what the client sees in the UI
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "Total Firms Selected": number;
  /**
   * How many firms leftover were not selected; so total firms selected abd total firms unselected equals total number possible to be distributed to for that BookBuild. This should be based on what the client sees in the UI
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "Total Firms Unselected": number;
  /**
   * How many holders did the client select in the distribution
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "Total Holders Selected": number;
  /**
   * any holders that the user did not select in manual distribution
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "Total Holders Unselected": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "Total Live Watchlist Firms Included"?: number;
  /**
   * How many non-holders did the client select in the distribution
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "Total Non-Holders Selected": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "Total Passive Watchlist Firms Included"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "Total Profile Firms Included"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Total Suggested Distribution Firms Selected"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   * | Min Value | 0 |
   */
  "Total Watchlist Firms Included"?: number;
}

export interface DistributionExclusionsFirmAddedProperties {
  "BookBuild ID": string;
  /**
   * This should be populated with either HF or LO depending on the type of firm
   */
  "Firm Classification": string;
}

export interface DistributionExclusionsFirmRemovedProperties {
  "BookBuild ID": string;
  /**
   * This should be populated with either HF or LO depending on the type of firm
   */
  "Firm Classification": string;
}

export interface DistributionLiveWatchlistsIncludedProperties {
  "BookBuild ID": string;
}

export interface DistributionManualAllHoldersIncludedProperties {
  "BookBuild ID": string;
  "Is Price Discovery"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Item Count": number;
}

export interface DistributionManualFirmAddedProperties {
  "BookBuild ID": string;
  /**
   * highlights if the firm included was a holder or not, and if yes a holder, what holding bucket they fell into eg 0-1%, 1-5%
   */
  "Holding Bucket"?: string;
  "Is Price Discovery"?: boolean;
}

export interface DistributionManualFirmRemovedProperties {
  "BookBuild ID": string;
  "Holding Bucket"?: string;
  "Is Price Discovery"?: boolean;
}

export interface DistributionPassiveWatchlistsIncludedProperties {
  "BookBuild ID": string;
}

export interface DistributionProfilesIncludedProperties {
  "BookBuild ID": string;
}

export interface DistributionWatchlistsIncludedProperties {
  "BookBuild ID": string;
}

export interface LiveWatchlistClearAllClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Item Count": number;
}

export interface OpportunityViewedProperties {
  "BookBuild ID": string;
  "Opportunity ID": string;
}

export interface PageViewedProperties {
  "Page Domain": string;
  "Page Location": string;
  "Page Path": string;
  "Page URL": string;
}

export interface PassiveWatchlistClearAllClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Item Count": number;
}

export interface PriceDiscoveryClickedProperties {
  "Appital Orb Displayed": boolean;
}

export interface TrendingEquitiesHelpIconClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Item Count": number;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AppOpened implements BaseEvent {
  event_type = 'App Opened';

  constructor(
    public event_properties: AppOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookBuildCancelClicked implements BaseEvent {
  event_type = 'BookBuild Cancel Clicked';

  constructor(
    public event_properties: BookBuildCancelClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookBuildStartClicked implements BaseEvent {
  event_type = 'BookBuild Start Clicked';

  constructor(
    public event_properties: BookBuildStartClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DistributionExclusionsFirmAdded implements BaseEvent {
  event_type = 'Distribution Exclusions Firm Added';

  constructor(
    public event_properties: DistributionExclusionsFirmAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DistributionExclusionsFirmRemoved implements BaseEvent {
  event_type = 'Distribution Exclusions Firm Removed';

  constructor(
    public event_properties: DistributionExclusionsFirmRemovedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DistributionLiveWatchlistsIncluded implements BaseEvent {
  event_type = 'Distribution Live Watchlists Included';

  constructor(
    public event_properties: DistributionLiveWatchlistsIncludedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DistributionManualAllHoldersIncluded implements BaseEvent {
  event_type = 'Distribution Manual All Holders Included';

  constructor(
    public event_properties: DistributionManualAllHoldersIncludedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DistributionManualFirmAdded implements BaseEvent {
  event_type = 'Distribution Manual Firm Added';

  constructor(
    public event_properties: DistributionManualFirmAddedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DistributionManualFirmRemoved implements BaseEvent {
  event_type = 'Distribution Manual Firm Removed';

  constructor(
    public event_properties: DistributionManualFirmRemovedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DistributionPassiveWatchlistsIncluded implements BaseEvent {
  event_type = 'Distribution Passive Watchlists Included';

  constructor(
    public event_properties: DistributionPassiveWatchlistsIncludedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DistributionProfilesIncluded implements BaseEvent {
  event_type = 'Distribution Profiles Included';

  constructor(
    public event_properties: DistributionProfilesIncludedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DistributionWatchlistsIncluded implements BaseEvent {
  event_type = 'Distribution Watchlists Included';

  constructor(
    public event_properties: DistributionWatchlistsIncludedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LiveWatchlistClearAllClicked implements BaseEvent {
  event_type = 'Live Watchlist Clear All Clicked';

  constructor(
    public event_properties: LiveWatchlistClearAllClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LiveWatchlistUploadOpened implements BaseEvent {
  event_type = 'Live Watchlist Upload Opened';
}

export class OpportunityViewed implements BaseEvent {
  event_type = 'Opportunity Viewed';

  constructor(
    public event_properties: OpportunityViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PageViewed implements BaseEvent {
  event_type = 'Page Viewed';

  constructor(
    public event_properties: PageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PassiveWatchlistClearAllClicked implements BaseEvent {
  event_type = 'Passive Watchlist Clear All Clicked';

  constructor(
    public event_properties: PassiveWatchlistClearAllClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PassiveWatchlistUploadOpened implements BaseEvent {
  event_type = 'Passive Watchlist Upload Opened';
}

export class PriceDiscoveryClicked implements BaseEvent {
  event_type = 'Price Discovery Clicked';

  constructor(
    public event_properties: PriceDiscoveryClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TrendingEquitiesHelpIconClicked implements BaseEvent {
  event_type = 'Trending Equities Help Icon Clicked';

  constructor(
    public event_properties: TrendingEquitiesHelpIconClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class WatchlistUploadOpened implements BaseEvent {
  event_type = 'Watchlist Upload Opened';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * App Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/App%20Opened)
   *
   * Owner: Pete Correia
   *
   * @param properties The event's properties (e.g. Page Domain)
   * @param options Amplitude event options.
   */
  appOpened(
    properties: AppOpenedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AppOpened(properties), options);
  }

  /**
   * BookBuild Cancel Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/BookBuild%20Cancel%20Clicked)
   *
   * Triggered when user clicks on the Cancel BookBuild button in the distribution UI: 
   *
   * * In the EMS pop-up
   *
   * * when user is logged in to the Appital UI and completing distribution there
   *
   * Owner: Pete Correia
   *
   * @param properties The event's properties (e.g. BookBuild ID)
   * @param options Amplitude event options.
   */
  bookBuildCancelClicked(
    properties: BookBuildCancelClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookBuildCancelClicked(properties), options);
  }

  /**
   * BookBuild Start Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/BookBuild%20Start%20Clicked)
   *
   * Triggered when the user clicks 'Start BookBuild' in the distribution UI: 
   *
   * * Via pop-up in EMS 
   *
   * * When logged in to Appital UI
   *
   * Owner: Pete Correia
   *
   * @param properties The event's properties (e.g. BookBuild ID)
   * @param options Amplitude event options.
   */
  bookBuildStartClicked(
    properties: BookBuildStartClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookBuildStartClicked(properties), options);
  }

  /**
   * Distribution Exclusions Firm Added
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/Distribution%20Exclusions%20Firm%20Added)
   *
   * Each time user selects an individual firm to target in manual seelction; if holder or not holder (this detail is captured in Holding Bucket property)
   *
   * Owner: Katherine Jones
   *
   * @param properties The event's properties (e.g. BookBuild ID)
   * @param options Amplitude event options.
   */
  distributionExclusionsFirmAdded(
    properties: DistributionExclusionsFirmAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DistributionExclusionsFirmAdded(properties), options);
  }

  /**
   * Distribution Exclusions Firm Removed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/Distribution%20Exclusions%20Firm%20Removed)
   *
   * Each time user selects an individual firm to target in manual seelction; if holder or not holder (this detail is captured in Holding Bucket property)
   *
   * Owner: Katherine Jones
   *
   * @param properties The event's properties (e.g. BookBuild ID)
   * @param options Amplitude event options.
   */
  distributionExclusionsFirmRemoved(
    properties: DistributionExclusionsFirmRemovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DistributionExclusionsFirmRemoved(properties), options);
  }

  /**
   * Distribution Live Watchlists Included
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/Distribution%20Live%20Watchlists%20Included)
   *
   * When user targets BookBuild via Watchlists
   *
   * @param properties The event's properties (e.g. BookBuild ID)
   * @param options Amplitude event options.
   */
  distributionLiveWatchlistsIncluded(
    properties: DistributionLiveWatchlistsIncludedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DistributionLiveWatchlistsIncluded(properties), options);
  }

  /**
   * Distribution Manual All Holders Included
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/Distribution%20Manual%20All%20Holders%20Included)
   *
   * When user selects 'Include all Holders' toggle in distribution UI: 
   *
   * * EMS pop-up 
   *
   * * Logged in via Appital UI
   *
   * Owner: Pete Correia
   *
   * @param properties The event's properties (e.g. BookBuild ID)
   * @param options Amplitude event options.
   */
  distributionManualAllHoldersIncluded(
    properties: DistributionManualAllHoldersIncludedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DistributionManualAllHoldersIncluded(properties), options);
  }

  /**
   * Distribution Manual Firm Added
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/Distribution%20Manual%20Firm%20Added)
   *
   * Each time user selects an individual firm to target in manual seelction; if holder or not holder (this detail is captured in Holding Bucket property)
   *
   * Owner: Pete Correia
   *
   * @param properties The event's properties (e.g. BookBuild ID)
   * @param options Amplitude event options.
   */
  distributionManualFirmAdded(
    properties: DistributionManualFirmAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DistributionManualFirmAdded(properties), options);
  }

  /**
   * Distribution Manual Firm Removed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/Distribution%20Manual%20Firm%20Removed)
   *
   * Each time user removes an inidividual firm from manual distribution
   *
   * Owner: Pete Correia
   *
   * @param properties The event's properties (e.g. BookBuild ID)
   * @param options Amplitude event options.
   */
  distributionManualFirmRemoved(
    properties: DistributionManualFirmRemovedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DistributionManualFirmRemoved(properties), options);
  }

  /**
   * Distribution Passive Watchlists Included
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/Distribution%20Passive%20Watchlists%20Included)
   *
   * When user targets BookBuild via Watchlists
   *
   * @param properties The event's properties (e.g. BookBuild ID)
   * @param options Amplitude event options.
   */
  distributionPassiveWatchlistsIncluded(
    properties: DistributionPassiveWatchlistsIncludedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DistributionPassiveWatchlistsIncluded(properties), options);
  }

  /**
   * Distribution Profiles Included
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/Distribution%20Profiles%20Included)
   *
   * When user selects to target their BookBuild via 'profiles'
   *
   * Owner: Pete Correia
   *
   * @param properties The event's properties (e.g. BookBuild ID)
   * @param options Amplitude event options.
   */
  distributionProfilesIncluded(
    properties: DistributionProfilesIncludedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DistributionProfilesIncluded(properties), options);
  }

  /**
   * Distribution Watchlists Included
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/Distribution%20Watchlists%20Included)
   *
   * When user targets BookBuild via Watchlists
   *
   * Owner: Pete Correia
   *
   * @param properties The event's properties (e.g. BookBuild ID)
   * @param options Amplitude event options.
   */
  distributionWatchlistsIncluded(
    properties: DistributionWatchlistsIncludedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DistributionWatchlistsIncluded(properties), options);
  }

  /**
   * Live Watchlist Clear All Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/Live%20Watchlist%20Clear%20All%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Item Count)
   * @param options Amplitude event options.
   */
  liveWatchlistClearAllClicked(
    properties: LiveWatchlistClearAllClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new LiveWatchlistClearAllClicked(properties), options);
  }

  /**
   * Live Watchlist Upload Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/Live%20Watchlist%20Upload%20Opened)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  liveWatchlistUploadOpened(
    options?: EventOptions,
  ) {
    return this.track(new LiveWatchlistUploadOpened(), options);
  }

  /**
   * Opportunity Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/Opportunity%20Viewed)
   *
   * When user views an opportunity in the Appital UI. This should be triggered when user clicks on the opportunity tile in the UI (not just opens the opportunity page)
   *
   * Owner: Pete Correia
   *
   * @param properties The event's properties (e.g. BookBuild ID)
   * @param options Amplitude event options.
   */
  opportunityViewed(
    properties: OpportunityViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpportunityViewed(properties), options);
  }

  /**
   * Page Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/Page%20Viewed)
   *
   * Owner: Pete Correia
   *
   * @param properties The event's properties (e.g. Page Domain)
   * @param options Amplitude event options.
   */
  pageViewed(
    properties: PageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PageViewed(properties), options);
  }

  /**
   * Passive Watchlist Clear All Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/Passive%20Watchlist%20Clear%20All%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Item Count)
   * @param options Amplitude event options.
   */
  passiveWatchlistClearAllClicked(
    properties: PassiveWatchlistClearAllClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PassiveWatchlistClearAllClicked(properties), options);
  }

  /**
   * Passive Watchlist Upload Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/Passive%20Watchlist%20Upload%20Opened)
   *
   * Owner: Katherine Jones
   *
   * @param options Amplitude event options.
   */
  passiveWatchlistUploadOpened(
    options?: EventOptions,
  ) {
    return this.track(new PassiveWatchlistUploadOpened(), options);
  }

  /**
   * Price Discovery Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/Price%20Discovery%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Appital Orb Displayed)
   * @param options Amplitude event options.
   */
  priceDiscoveryClicked(
    properties: PriceDiscoveryClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PriceDiscoveryClicked(properties), options);
  }

  /**
   * Trending Equities Help Icon Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/Trending%20Equities%20Help%20Icon%20Clicked)
   *
   * Event to track when users click on the help icon for trending equities
   *
   * @param properties The event's properties (e.g. Item Count)
   * @param options Amplitude event options.
   */
  trendingEquitiesHelpIconClicked(
    properties: TrendingEquitiesHelpIconClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TrendingEquitiesHelpIconClicked(properties), options);
  }

  /**
   * Watchlist Upload Opened
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/appital/ATB/events/main/latest/Watchlist%20Upload%20Opened)
   *
   * Owner: Pete Correia
   *
   * @param options Amplitude event options.
   */
  watchlistUploadOpened(
    options?: EventOptions,
  ) {
    return this.track(new WatchlistUploadOpened(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
