import { devtoolsExchange } from '@urql/devtools'
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch'
import { retryExchange } from '@urql/exchange-retry'
import { useLogout } from 'auth/Logout'
import { useAuthToken } from 'auth/useAuthToken'
import { API_ENDPOINT } from 'core/utils/api'
import { OperationDefinitionNode } from 'graphql'
import { ReactNode, useMemo } from 'react'
import {
	ClientOptions,
	CombinedError,
	Exchange,
	Operation,
	Provider,
	cacheExchange,
	createClient,
	errorExchange,
} from 'urql'
import { fromPromise, map, mergeMap, pipe, tap } from 'wonka'

const ENDPOINT = API_ENDPOINT + '/graphql'

type FetchOptions = Exclude<ClientOptions['fetchOptions'], undefined>

// Custom exchange which allows a promise to be used when setting the
// fetchOptions for each request.
// See: https://community.auth0.com/t/auth0-react-how-to-use-getaccesstokensilently-for-my-urql-auth-exchange/59402
const fetchOptionsExchange =
	(fn: (fetchOptions: FetchOptions) => Promise<FetchOptions>): Exchange =>
	({ forward }) =>
	(ops$) => {
		return pipe(
			ops$,
			mergeMap((operation: Operation) => {
				const result = fn(operation.context.fetchOptions || {})
				return pipe(
					fromPromise(result),
					map((fetchOptions) => ({
						...operation,
						context: { ...operation.context, fetchOptions },
					}))
				)
			}),
			forward
		)
	}

// Custom exchange which appends the operation name to any graphql request
// for easier testing/mocking/debugging
const operationNameExchange: Exchange =
	({ forward }) =>
	(ops$) =>
		pipe(
			ops$,
			tap((operation) => {
				const definition = operation.query.definitions.find(
					(d) => d.kind === 'OperationDefinition'
				) as OperationDefinitionNode

				const operationName = definition?.name?.value || ''

				operation.context.url += `?operationName=${operationName}`
			}),
			forward
		)

export function GraphQLProvider({ children }: { children: ReactNode }) {
	const getToken = useAuthToken()
	const logout = useLogout()

	const client = useMemo(() => {
		const defaultExchanges = [
			cacheExchange,
			retryExchange({
				initialDelayMs: 1000,
				maxDelayMs: 10000,
				maxNumberAttempts: 30,
				randomDelay: false,
				retryIf: (_, operation) => operation.kind === 'query',
			}),
			errorExchange({
				onError: (error: CombinedError) => {
					const status = error.response?.status
					const message = error.networkError?.message?.toLowerCase()

					if (
						status === 403 ||
						status === 401 ||
						message?.includes('forbidden')
					) {
						logout(window.location.origin + '/auth/signedout')
					}
				},
			}),
			operationNameExchange,
			fetchOptionsExchange(async (fetchOptions) => {
				const token = await getToken()
				return {
					...fetchOptions,
					headers: {
						Authorization: token ? `Bearer ${token}` : '',
					},
				}
			}),
			multipartFetchExchange,
		]

		return createClient({
			url: ENDPOINT,
			exchanges:
				import.meta.env.NODE_ENV === 'production'
					? defaultExchanges
					: [devtoolsExchange, ...defaultExchanges],
		})
	}, [logout, getToken])

	return <Provider value={client}>{children}</Provider>
}
