import * as RadixTooltip from '@radix-ui/react-tooltip'
import clsx from 'clsx'
import { ComponentProps, forwardRef, ReactElement, ReactNode } from 'react'

/**
 * Props are mostly derived from Radix UI.
 *
 * @see https://www.radix-ui.com/docs/primitives/components/tooltip#api-reference
 */
type Props = Omit<ComponentProps<'div'>, 'content'> & {
	children: ReactElement
	content: ReactNode
	delayDuration?: number
	side?: 'top' | 'right' | 'bottom' | 'left'
	sideOffset?: number
	align?: 'center' | 'start' | 'end'
	alignOffset?: number
	color?:
		| 'blue-darkish'
		| 'blue-darker'
		| 'blue-dark'
		| 'blue-highlight'
		| 'radial-gradient'
	disableHoverableContent?: boolean
	open?: boolean
	onOpenChange?: (open: boolean) => void
}

/**
 * Tooltip is a popup that displays information related to an element when the
 * element receives keyboard focus or the mouse hovers over it.
 *
 * Uses Radix UI's Tooltip under the hood.
 *
 * @see https://www.radix-ui.com/docs/primitives/components/tooltip#api-reference
 *
 * @example
 * <Tooltip content="Tooltip content">
 *   <button>Tooltip trigger</button>
 * </Tooltip>
 */
const Tooltip = forwardRef<HTMLDivElement, Props>(function Tooltip(
	{
		children,
		content,
		delayDuration,
		side,
		sideOffset = 5,
		align,
		alignOffset,
		color = 'blue-darkish',
		disableHoverableContent,
		open,
		onOpenChange,
		className,
		...props
	},
	ref
) {
	if (!content) return children

	let bgColor
	let arrowColor

	switch (color) {
		case 'blue-darkish':
			bgColor = 'bg-blue-darkish'
			arrowColor = 'fill-blue-darkish'
			break
		case 'blue-darker':
			bgColor = 'bg-blue-darker'
			arrowColor = 'fill-blue-darker'
			break
		case 'blue-dark':
			bgColor = 'bg-blue-dark'
			arrowColor = 'fill-blue-dark'
			break
		case 'blue-highlight':
			bgColor = 'bg-blue-highlight'
			arrowColor = 'fill-blue-highlight'
			break
		case 'radial-gradient':
			bgColor =
				'bg-blue-darkish bg-[radial-gradient(ellipse_154.78%_344.19%_at_-6.72%_-12.10%,_rgba(243.20,_177.28,_30.80,_0.20)_0%,_rgba(84,_100,_255,_0.20)_13%,_rgba(86,_189,_162,_0.20)_83%)]'
			arrowColor = 'fill-transparent'
			break
	}

	return (
		<RadixTooltip.Provider>
			<RadixTooltip.Root
				delayDuration={delayDuration}
				disableHoverableContent={disableHoverableContent}
				open={open}
				onOpenChange={onOpenChange}
			>
				<RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>

				<RadixTooltip.Portal>
					<RadixTooltip.Content
						{...props}
						className={clsx(
							'z-99 select-none rounded-[4px] px-3 py-2 text-xs leading-none text-white shadow-[0px_0px_36px_0px_rgba(0,0,0,.40)] will-change-[transform,opacity] data-[state=delayed-open]:data-[side=bottom]:animate-fade-in-slide-down data-[state=delayed-open]:data-[side=left]:animate-fade-in-slide-right data-[state=delayed-open]:data-[side=right]:animate-fade-in-slide-right data-[state=delayed-open]:data-[side=top]:animate-fade-in-slide-up',
							bgColor,
							className
						)}
						side={side}
						sideOffset={sideOffset}
						align={align}
						alignOffset={alignOffset}
						ref={ref}
					>
						{content}
						<RadixTooltip.Arrow className={arrowColor} />
					</RadixTooltip.Content>
				</RadixTooltip.Portal>
			</RadixTooltip.Root>
		</RadixTooltip.Provider>
	)
})

export default Tooltip
